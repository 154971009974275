const query = (id, isPreview) => `
{
    videosWrapper(id: "${id}", preview: ${isPreview}) {
        videoCollection{
          items{
            title
            youtubeVideoId
            enableWatchLater
          }
        }
  }
}`

module.exports = { query }