import React from "react"
import { screen, render } from "@testing-library/react"
import GlobalSearchListing from "./GlobalSearchListing"
import { globalSearchListing } from "./GlobalSearchListing.mock"
import "@testing-library/jest-dom"

describe("GlobalSearchListing", () => {
  beforeEach(() => {
    render(<GlobalSearchListing {...globalSearchListing} />)
  });
  test("testing imagesCollection[0] Image and description", () => {
    expect(screen.getByText('Loading...')).toBeInTheDocument()
  })


})
