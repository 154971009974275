import React from 'react';
import Link from "../../UI/Link/Link"
import PropTypes from "prop-types";
import Typography from '../../UI/Typography/Typography';
import moment from "moment";
import { dateFormat } from '../../../constants/form.constants';
import { COCOA_LIFE_TAG_LABEL } from '../../../constants/label.constants';

const SearchElement = (props) => {
  const {
    slug,
    seoData: { title = '', articleType = '', articleDate, description = {} },
    isBlogsPage,
    contentStyle,
  } = props;

  let dateVar = moment(articleDate);
  let publishDate = dateVar.format(dateFormat);
  const charStr = articleType && articleType?.match(/\b\w/g).join('');
  const articleIcon = articleType === "Progress Blog" ? charStr[1] : articleType?.charAt(0)
  const artcileClass = articleType && articleType?.split(' ').join('')

  const resultDate = (!isBlogsPage && articleType) ? `${contentStyle?.resultDate}` : `${contentStyle?.resultDateSmallFont}`

  return (
    <>
      {!isBlogsPage && <div className={`${contentStyle?.resultTypeContainer} ${artcileClass}`}>{articleIcon}</div>}
      <div className={contentStyle?.resultContent}>
        <div className={contentStyle?.resultMetaData}>
          {(!isBlogsPage && articleType) &&
          <span>
            <span className={contentStyle?.resultTypeText}>
              {articleType}
            </span>
          </span>
          }
           {(!isBlogsPage && articleDate) && 
           <span>&nbsp;-&nbsp;</span>}
          {articleDate &&
            <span className={resultDate}>
              {publishDate}
            </span>
          }
        </div>
        {title && <Typography className={`${isBlogsPage ? contentStyle?.textGreenBig : contentStyle?.textGreen}`} content={`${COCOA_LIFE_TAG_LABEL} - ${title}`} />}
        {description && <Typography className={contentStyle?.resultDescription} content={description?.description} />}
        {(isBlogsPage && slug) && <div className={contentStyle?.resultReadMore} data-action-detail={"Read More"}>Read More </div>}
      </div>
    </>
  )
}

SearchElement.propTypes = {
    slug: PropTypes.string,
    seoData: PropTypes.shape({
      title: PropTypes.string,
      articleType: PropTypes.string,
      articleDate: PropTypes.string,
      description: PropTypes.object,
    }),
    isBlogsPage: PropTypes.bool,
    contentStyle: PropTypes.shape({
      resultTypeContainer: PropTypes.string,
      resultContent: PropTypes.string,
      resultMetaData: PropTypes.string,
      resultTypeText: PropTypes.string,
      resultDate: PropTypes.string,
      textGreen: PropTypes.string,
      textGreenBig: PropTypes.string,
      resultDescription: PropTypes.string,
      resultReadMore: PropTypes.string,
      readMoreIcon: PropTypes.string,
      resultDateSmallFont: PropTypes.string
    })
  }
export default SearchElement  