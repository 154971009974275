import React from "react"
import { screen, render, fireEvent, cleanup } from "@testing-library/react"
import Accordian from "./Accordian"
import { accordian, impactAccordian } from "./Accordian.mock"
import "@testing-library/jest-dom"

describe("render Accordian", () => {
  render(<Accordian {...accordian} />)
  test("testing render title", () => {
    expect(screen.getByText("2021 GLOBAL PROGRESS DASHBOARD")).toBeInTheDocument()
  })
  test("testing render description", () => {
    render(<Accordian {...accordian} />)
    expect(screen.getByText("This dashboard demonstrates the scale")).toBeInTheDocument()
  })
  test("testing render contentRowsCollection items[0] title", () => {
    render(<Accordian {...accordian} />)
    expect(screen.getByText("Sustainable cocoa farming")).toBeInTheDocument()
  })
  test("testing render contentRowsCollection items[0] title", () => {
    render(<Accordian {...accordian} />)
    expect(screen.getByText("Bottom Text")).toBeInTheDocument()
  })
  xtest("testing render contentRowsCollection items[0] Image", () => {
    render(<Accordian {...accordian} />)
    const image = screen.getAllByRole(/presentation/i);
    expect(image[0].src).toContain('cl-origin-icon-farming')
  })
  test("testing render contentRowsCollection items[1] title", () => {
    render(<Accordian {...accordian} />)
    expect(screen.getByText("Empowered cocoa communities")).toBeInTheDocument()
  })
  test("testing render contentRowsCollection items[1] bottomText", () => {
    render(<Accordian {...accordian} />)
    expect(screen.getByText("Bottom Text 2")).toBeInTheDocument()
  })
  test("testing render contentRowsCollection items[0] contentCollection description", () => {
    render(<Accordian {...accordian} />)
    expect(screen.getByText("description 1")).toBeInTheDocument()
  })
  test("testing render contentRowsCollection items[1] contentCollection description", () => {
    render(<Accordian {...accordian} />)
    expect(screen.getByText("description 2")).toBeInTheDocument()
  })
  test("testing render handleToggleHref items[1] contentCollection description", () => {
    render(<Accordian {...accordian} />)
      const anchorTag = screen.getAllByRole('link', { name: 'partnership' })
      fireEvent.click(anchorTag[0])
      const aWithouthash = screen.getAllByRole('link', { name: 'impact' })
      fireEvent.click(aWithouthash[0])
      const anchorTag1 = screen.getByRole('link', { name: 'Ghana Dashboard' })
      fireEvent.click(anchorTag1)
  })
  test("testing render handleToogle items[1] contentCollection description", () => {
    render(<Accordian {...accordian} />)
    const buttonTag = screen.getAllByRole('button')
    fireEvent.click(buttonTag[0])
  })
})

describe("render Accordian for Impact Page", () => {
  beforeEach(() => {
    render(<Accordian {...impactAccordian} />)
  });
  afterEach(() => {
    cleanup();
  });
  test("testing render title", () => {
    expect(screen.getByText("Impact Accordian Title")).toBeInTheDocument()
  })
  test("testing render title", () => {
    const anchorTag = screen.getAllByRole('button', { name: 'Empowered cocoa communities +' })
    fireEvent.click(anchorTag[0])
  })
})

