export const twoCardsWrapper ={
  "heading": "Measure Progress",
  "wrapperLink": {
    "url": "/progress/a-major-milestone-to-accelerate-our-journey/",
    "title": "progress",
    "scrollToElementId": null,
    "target": "_self",
    "gaEventClass": null,
    "gaEventLabel": null
  },
  "variant": null,
  "cardsCollection": {
    "items": [
      {
        "tagName": 'tagName',
        "title": 'title',
        "text": 'Executive',
        "textTheme": null,
        "variant": "BLOG_CARD_WITH_BG_IMG",
        "images": {
          "altText": null,
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3Cz9HdJKo47iMQcwfrr4q3/cb78a48920f293eafa967d9d40d42fc1/cl-teaserbig-progress-a-major-milestone-to-accelerate-our-journey.jpg",
            "title": "cl-teaserbig-progress-a-major-milestone-to-accelerate-our-journey",
            "description": "",
            "width": 1440,
            "height": 820
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3Cz9HdJKo47iMQcwfrr4q3/cb78a48920f293eafa967d9d40d42fc1/cl-teaserbig-progress-a-major-milestone-to-accelerate-our-journey.jpg",
            "title": "cl-teaserbig-progress-a-major-milestone-to-accelerate-our-journey",
            "description": "",
            "width": 1440,
            "height": 820
          }
        },
        "themeImages": {
          "altText": null,
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3Cz9HdJKo47iMQcwfrr4q3/cb78a48920f293eafa967d9d40d42fc1/cl-teaserbig-progress-a-major-milestone-to-accelerate-our-journey.jpg",
            "title": "cl-teaserbig-progress-a-major-milestone-to-accelerate-our-journey",
            "description": "",
            "width": 1440,
            "height": 820
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3Cz9HdJKo47iMQcwfrr4q3/cb78a48920f293eafa967d9d40d42fc1/cl-teaserbig-progress-a-major-milestone-to-accelerate-our-journey.jpg",
            "title": "cl-teaserbig-progress-a-major-milestone-to-accelerate-our-journey",
            "description": "",
            "width": 1440,
            "height": 820
          }
        },
        "link": null,
        "bgTheme": null
      },
      {
        "tagName": "Progress Blog",
        "title": "A major milestone to accelerate our journey",
        "text": "__By Cathy Pieters, Global Director Cocoa Life__ - *04/30/19*",
        "textTheme": "TEXTBLACK",
        "variant": "BLOG_CARD_WITH_THEME",
        "images": null,
        "themeImages": null,
        "link": {
          "url": "/progress/a-major-milestone-to-accelerate-our-journey/",
          "title": null,
          "scrollToElementId": null,
          "target": "_self",
          "gaEventClass": null,
          "gaEventLabel": null
        },
        "bgTheme": "orangeTheme"
      }
    ]
  },
  "seeMoreLink": null,
  "socialLinksContainerCollection": {
    "items": [
      {
        "label": null,
        "iconClass": "fa fa-twitter",
        "link": {
          "url": "https://twitter.com/intent/tweet?text=%22Building%20community%20trust%20is%20the%20first%20step%20in%20building%20a%20successful%20program.%22%20-%20A.%20Assanvo&url=https%3A%2F%2Fwww.cocoalife.org&hashtags=CocoaLife",
          "target": "_self",
          "gaEventClass": "event_socialmedia_exit",
          "gaEventLabel": "twitter",
          "image": null
        }
      }
    ]
  }
}
