const query = (id, isPreview) => `
{
  textContent(id: "${id}", preview: ${isPreview}) {
       title
    subTitle
    markerTextCollection(limit:10){
      items{
        imageText
        image {
          altText
          imageDesktop {
            url
            title
            width
            height
            description
          }
          imageMobile {
            url
            title 
            width
            height
            description
          }
        }
      }
    }
     shortDescription
      shortDescriptionSecondary
      variant
      elementId
  }
}
`

module.exports = { query }
