import React, {useEffect, useRef} from "react"
import PropTypes from "prop-types"
import * as styles from "./ImageCardsLayout-tw-styles"
import ImageCard from "../UI/ImageCard/ImageCard"
import Link from "../UI/Link/Link"
import Typography from "../UI/Typography/Typography"
import { get } from "../../helpers/utils"
import Slider from "react-slick"
import { onDotClick } from '../../helpers/slider'
import {compact, last} from 'lodash'
import useScreenSize from '../../hooks/useScreenSize'

const ImageCardsLayout = props => {
  const {
    cardsCollection = {},
    text,
    title,
    variant,
    link,
    isSlider,
    eventClass,
    carditems = [],
    sliderSettings = {},
  } = props
  const sliderRef = useRef()
  const screen = useScreenSize()
  const { slidesToShowTab = 3, slidesToShowMobile = 1.2} = sliderSettings || {}
  const dotsListId = Math.round(Math.random() * 1000000)

  useEffect(() => {
   if(typeof window !== 'undefined' &&  variant === "FIVE_COLUMN_CARDS_LAYOUT" && !screen?.isDesktop){
      const activeSlide = last(compact(document?.location?.pathname?.split("/")))
      const current = window?.document?.getElementById(activeSlide)?.parentElement?.parentElement
      const index = Number(current?.getAttribute("data-index"))
      sliderRef?.current?.slickGoTo(index)
    }
  }, [screen])

  const settings = {
    dots: false,
    arrows: false,
    swipe: false,
    draggable: true,
    variableWidth: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          swipe: true,
          swipeToSlide: true,
          slidesToShow: slidesToShowTab,
          slidesToScroll: 1,
          dots: true,
          appendDots: dots => <ul id={dotsListId} onClick={() => {onDotClick(dotsListId, slidesToShowTab)}}>{dots}</ul>,
          speed: 500,
          arrows: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 420,
        settings: {
          swipe: true,
          swipeToSlide: true,
          slidesToShow: slidesToShowMobile,
          dots: true,
          appendDots: dots => <ul id={dotsListId} onClick={() => {onDotClick(dotsListId, slidesToShowTab)}}>{dots}</ul>,
          speed: 500,
          arrows: false,
          variableWidth: false,
        },
      },
    ],
  }

  const style = styles[variant] || styles.defaultStyle
  const cards =
    carditems.length > 0 ? carditems : get(cardsCollection, "items", [])

  const buildSlides = () => {
    return cards?.map((card, index) => {
      return (
        <div key={index} className={style?.linkCardsWrpr} id={card?.elementId}>
          {card?.link ? (
            <Link {...card.link} className={style?.linkWrpr}>
              <ImageCard {...card} />
            </Link>
          ) : (
            <ImageCard {...card} />
          )}
        </div>
      )
    })
  }

  const buildSlider = () => {
    if (isSlider) {
      return (
        <div className={style?.cardWrpr}>
          <Slider {...settings} className={style?.sliderMob} ref={sliderRef}>
            {buildSlides()}
          </Slider>
        </div>
      )
    } else {
      return <div className={style?.cardsWrapr}>{buildSlides()}</div>
    }
  }

  return (
    <div className={style?.container}>
      {title && <Typography className={style?.heading} content={title} />}
      {text && (
        <Typography
          className={style?.description}
          allow_br_tag={true}
          content={text}
        />
      )}
      {cards?.length > 0 && buildSlider()}
      {link?.label && (
        <div className="text-center">
          <div className={`${style?.btnContainer} "inline-Block"`}>
            <Link {...link} className={style?.btnWrpr} />
          </div>
        </div>
      )}
    </div>
  )
}

ImageCardsLayout.propTypes = {
  cardsCollection: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  link: PropTypes.object,
  isSlider: PropTypes.bool,
  sliderSettings: PropTypes.object,
  eventClass: PropTypes.string,
  carditems: PropTypes.array,
}

export default ImageCardsLayout
