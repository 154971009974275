export const accordian = {
    "title": "2021 GLOBAL PROGRESS DASHBOARD",
    "description": "This dashboard demonstrates the scale",
    "contentRowsCollection": {
        "items": [
            {
                "title": "Sustainable cocoa farming",
                "image": {
                    "title": "Farming Icon",
                    "description": "",
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/56QPexlqVWen2woCqkjuEF/7531e51d42cdb6a268963458a769b9d1/cl-origin-icon-farming.png",
                    "width": 88,
                    "height": 88
                },
                "contentCollection": {
                    "items": [
                        {
                            "description": "description 1",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "contentCollection linkLabel 1",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                            "description": "We are proud of Cocoa Life’s <a class=\"whiteLink\" href=\"/impact/\" title=\"Cocoa Life’s impact\">progress and impact in cocoa communities</a>",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "contentCollection linkLabel 1",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                                "description": "we believe that cross-sector collaboration and <a class=\"dashboard-anchor-link whiteLink toogle14\" href =\"#question-11\" id= \"partnership1\" title=\"What is the role of partnerships within Cocoa Life?\">partnership</a> is key to lasting change. That’s why we work with governments, non-government organizations, supply chain partners and communities to leverage their expertise, networks and ensure that the program is effectively rolled out on the ground.\n\nWe are proud of Cocoa Life’s progress and impact in cocoa communities and are committed to continuing our efforts in order to ensure the cocoa volumes for all Mondelēz International’s chocolate brands are sourced from the program by 2025.\n\nWe want to ensure cocoa is ‘made right’, with respect to people and planet. Cocoa Life is investing directly in tackling the root causes of challenges, including climate change, gender inequality, poverty and child labor.\n\nThese challenges are complex, and there are no quick or easy solutions, which is why our program takes a holistic approach.\n\nWe focus our efforts on the three areas where we can make the most difference: sustainable cocoa farming businesses; empowered cocoa communities; conserved and restored forests.\n\nCrucially, we believe that cross-sector collaboration and <a class=\"whiteLink dashboard-anchor-link\" href=\"#question-11\" class=\"\" title=\"What is the role of partnerships within Cocoa Life?\" id= \"partnership2\">partnership</a> is key to lasting change. That’s why we work with governments, non-government organizations, supply chain partners and communities to leverage their expertise, networks and ensure that the program is effectively rolled out on the ground.\n\nWe are proud of Cocoa Life’s and will continue our efforts in order to ensure the cocoa volumes for all Mondelēz International’s chocolate brands are sourced from the program by 2025.",        
                                "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "contentCollection linkLabel 1",
                                      "variant": "FAQ_ACCORDIAN",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                            "description": "we believe that cross-sector collaboration and <a class=\"dashboard-anchor-link whiteLink toogle14\" href =\"impact\" id= \"impact1\" title=\"What is the role of partnerships within Cocoa Life?\">impact</a> is key to lasting change. That’s why we work with governments, non-government organizations, supply chain partners and communities to leverage their expertise, networks and ensure that the program is effectively rolled out on the ground.\n\nWe are proud of Cocoa Life’s progress and impact in cocoa communities and are committed to continuing our efforts in order to ensure the cocoa volumes for all Mondelēz International’s chocolate brands are sourced from the program by 2025.\n\nWe want to ensure cocoa is ‘made right’, with respect to people and planet. Cocoa Life is investing directly in tackling the root causes of challenges, including climate change, gender inequality, poverty and child labor.\n\nThese challenges are complex, and there are no quick or easy solutions, which is why our program takes a holistic approach.\n\nWe focus our efforts on the three areas where we can make the most difference: sustainable cocoa farming businesses; empowered cocoa communities; conserved and restored forests.\n\nCrucially, we believe that cross-sector collaboration and <a class=\"whiteLink dashboard-anchor-link\" href=\"#question-11\" class=\"\" title=\"What is the role of partnerships within Cocoa Life?\" id= \"partnership2\">partnership</a> is key to lasting change. That’s why we work with governments, non-government organizations, supply chain partners and communities to leverage their expertise, networks and ensure that the program is effectively rolled out on the ground.\n\nWe are proud of Cocoa Life’s and will continue our efforts in order to ensure the cocoa volumes for all Mondelēz International’s chocolate brands are sourced from the program by 2025.",        
                            "linkWrapperCollection": {
                            "items": [
                                {
                                  "linkLabel": "contentCollection linkLabel 1",
                                  "variant": "FAQ_ACCORDIAN",
                                  "link": {
                                    "label":"link label 1",
                                    "title": "link title 1",
                                    "url": "/link-url/1/",
                                    "image" :{
                                        "title": "Community Icon",
                                        "description": "",
                                        "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                        "width": 88,
                                        "height": 88
                                    }

                                  }
                                }
                            ]
                        }
                    },
                        {
                            "description": "description 2",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "linkLabel 2",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        }
                    ]
                },
                "bottomText": "Bottom Text",
                "variant": "BG_ORANGE"
            },
            {
                "title": "Empowered cocoa communities",
                "image": {
                    "title": "Community Icon",
                    "description": "",
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                    "width": 88,
                    "height": 88
                },
                "contentCollection": {
                    "items": [
                        {
                            "description": "<div>Communities with a CODEC and Community Action Plan activated<span><sup>2</sup></span></div>\n<div>2,482</div>",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "linkLabel 1",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                            "description": "<div>CAP projects completed in communities</div>\n<div>5,568</div>",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "linkLabel 2",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 2",
                                        "title": "link title 2",
                                        "url": "/link-url/2/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        }
                    ]
                },
                "bottomText": "Bottom Text 2",
                "variant": "BG_PINK"
            }
        ]
    },
    "quickLinksCollection": {
        "items": [
            {
                "label": "Côte d'Ivoire Dashboard",
                "title": "Côte d'Ivoire Dashboard",
                "url": "/in-the-cocoa-origins/cocoa-life-in-cote-divoire#dashboard",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
            },
            {
                "label": "Ghana Dashboard",
                "title": "Ghana Dashboard",
                "url": "in-the-cocoa-origins/cocoa-life-in-ghana#dashboard",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
            }
        ]
    },
    "variant": 'FAQ_ACCORDIAN'
}

export const impactAccordian = {
    "title": "Impact Accordian Title",
    "description": "This dashboard demonstrates the scale",
    "contentRowsCollection": {
        "items": [
            {
                "title": "Sustainable cocoa farming",
                "image": {
                    "title": "Farming Icon",
                    "description": "",
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/56QPexlqVWen2woCqkjuEF/7531e51d42cdb6a268963458a769b9d1/cl-origin-icon-farming.png",
                    "width": 88,
                    "height": 88
                },
                "contentCollection": {
                    "items": [
                        {
                            "description": "description 1",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "contentCollection linkLabel 1",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                            "description": "We are proud of Cocoa Life’s <a class=\"whiteLink\" href=\"/impact/\" title=\"Cocoa Life’s impact\">progress and impact in cocoa communities</a>",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "contentCollection linkLabel 1",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                                "description": "we believe that cross-sector collaboration and <a class=\"dashboard-anchor-link whiteLink toogle14\" href =\"#question-11\" id= \"partnership1\" title=\"What is the role of partnerships within Cocoa Life?\">partnership</a> is key to lasting change. That’s why we work with governments, non-government organizations, supply chain partners and communities to leverage their expertise, networks and ensure that the program is effectively rolled out on the ground.\n\nWe are proud of Cocoa Life’s progress and impact in cocoa communities and are committed to continuing our efforts in order to ensure the cocoa volumes for all Mondelēz International’s chocolate brands are sourced from the program by 2025.\n\nWe want to ensure cocoa is ‘made right’, with respect to people and planet. Cocoa Life is investing directly in tackling the root causes of challenges, including climate change, gender inequality, poverty and child labor.\n\nThese challenges are complex, and there are no quick or easy solutions, which is why our program takes a holistic approach.\n\nWe focus our efforts on the three areas where we can make the most difference: sustainable cocoa farming businesses; empowered cocoa communities; conserved and restored forests.\n\nCrucially, we believe that cross-sector collaboration and <a class=\"whiteLink dashboard-anchor-link\" href=\"#question-11\" class=\"\" title=\"What is the role of partnerships within Cocoa Life?\" id= \"partnership2\">partnership</a> is key to lasting change. That’s why we work with governments, non-government organizations, supply chain partners and communities to leverage their expertise, networks and ensure that the program is effectively rolled out on the ground.\n\nWe are proud of Cocoa Life’s and will continue our efforts in order to ensure the cocoa volumes for all Mondelēz International’s chocolate brands are sourced from the program by 2025.",        
                                "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "contentCollection linkLabel 1",
                                      "variant": "FAQ_ACCORDIAN",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                            "description": "description 2",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "linkLabel 2",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        }
                    ]
                },
                "bottomText": "Bottom Text",
                "variant": "BG_ORANGE"
            },
            {
                "title": "Empowered cocoa communities",
                "image": {
                    "title": "Community Icon",
                    "description": "",
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                    "width": 88,
                    "height": 88
                },
                "contentCollection": {
                    "items": [
                        {
                            "description": "<div>Communities with a CODEC and Community Action Plan activated<span><sup>2</sup></span></div>\n<div>2,482</div>",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "linkLabel 1",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 1",
                                        "title": "link title 1",
                                        "url": "/link-url/1/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                            "description": "<div>CAP projects completed in communities</div>\n<div>5,568</div>",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "linkLabel 2",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 2",
                                        "title": "link title 2",
                                        "url": "/link-url/2/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        },
                        {
                            "description": "<div><img src='https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png'></img><br><p>testing para</p></div>",
                            "linkWrapperCollection": {
                                "items": [
                                    {
                                      "linkLabel": "linkLabel 2",
                                      "variant": "",
                                      "link": {
                                        "label":"link label 2",
                                        "title": "link title 2",
                                        "url": "/link-url/2/",
                                        "image" :{
                                            "title": "Community Icon",
                                            "description": "",
                                            "url": "https://images.ctfassets.net/qggsjlmpzfmx/EeukY7eTlLP61KkkT1ElJ/346852b66f30ff91a68c0ddaf4a3f627/cl-origin-icon-community.png",
                                            "width": 88,
                                            "height": 88
                                        }

                                      }
                                    }
                                ]
                            }
                        }
                    ]
                },
                "bottomText": "Bottom Text 2",
                "variant": "BG_PINK"
            }
        ]
    },
    "quickLinksCollection": {
        "items": [
            {
                "label": "Côte d'Ivoire Dashboard",
                "title": "Côte d'Ivoire Dashboard",
                "url": "/in-the-cocoa-origins/cocoa-life-in-cote-divoire#dashboard",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
            },
            {
                "label": "Ghana Dashboard",
                "title": "Ghana Dashboard",
                "url": "in-the-cocoa-origins/cocoa-life-in-ghana#dashboard",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
            }
        ]
    },
    "variant": 'LEFT_ALIGNED_TEXT_ACCORDIAN'
}
