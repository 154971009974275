import React from "react"
import * as styles from "./Input-tw-styles"
import PropTypes from "prop-types"
import Typography from "../Typography/Typography"

function CheckBox(props) {
  const { name, id, value, label, variant, onChange, isValid, isRequired, checked } =
    props

  const style = styles[variant] || styles.defaultStyle

  return (
    <div>
      <div className={`${style?.chkWrpr} ${id}`}>
        <input
          className={`${style?.checkBox} ${!isValid ? style?.error : ""} `}
          type="checkbox"
          value={value}
          id={id}
          name={name}
          onChange={e => onChange(e, id)}
          checked={checked}
        />
        { !isValid && <span className={isValid? style?.asteriskCheckboxStyle : style?.errorLabelCheckbox}>*</span>}
        <label className={style?.checkBoxText} htmlFor={id}>
          {<Typography content={label} />}
        </label>
      </div>
    </div>
  )
}

CheckBox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  checked:PropTypes.bool
}

export default CheckBox
