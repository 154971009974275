export const articleHeader = {
    "articleTagName": "NEWSROOM ARTICLE",
    "tagTextTheme": "BLACK",
    "heading": "INDIA: BITTERSWEET CHOCOLATE",
    "publishedDate": null,
    "video": null,
    "subHeading": "__This article highlights the main challenges for cocoa growth and production, leading India to currently import a big part of it from other producing countries. Experts argue that, by putting strategies into place and giving farmers opportunity and access to the right information and practices, it would increase their income and allow India to rely more on its local cocoa corps.__",
    "socialShareLinksCollection": {
        "items": [
            {
                "tagName": "share"
            },
            {
                "tagName": "facebook"
            },
            {
                "tagName": "twitter"
            },
            {
                "tagName": "linkedIn"
            },
            {
                "tagName": "whatsapp"
            },
            {
                "tagName": "pinterest"
            },
            {
                "tagName": " "
            },
        ]
    },
    "imagesCollection": {
        "items": [
            {
                "altText": null,
                "imageMobile": {
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/3DoTDUhM4XKYFm4kC876sJ/c2f8c610316f3cce47a2a0f7776af33b/cl-brand-logo-milka__1_.png",
                    "title": "cl-brand-logo-milka (1)",
                    "description": "",
                    "width": 240,
                    "height": 240
                },
                "imageDesktop": {
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/5jjYS3xIE7iZkBkpXUY2SH/8161b98e823999f152f011be9e865d95/cl-brand-logo-milka.png",
                    "title": "cl-brand-logo-milka",
                    "description": "",
                    "width": 240,
                    "height": 240
                }
            }
        ]
    },
    "readMoreTagsCollection": {
        "items": [
            {
                "tagName": " India"
            },
            {
                "tagName": "Chocolate"
            },
            {
                "tagName": "Farming"
            }
        ]
    },
    "variant": null
}