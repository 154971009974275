const query = (id, isPreview) => `{
    map(id: "${id}", preview: ${isPreview}) {   
        title
        backgroundImage {
            title
            description
            url
            height
            width
        }
        factsCollection{
            items{
              description
              variant
            }
        }
     }
}`

module.exports = { query }