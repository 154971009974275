export const fixedProductBrandImage = {
  "cardsCollection": {
    "items": [
      {
        "url": "https://www.barry-callebaut.com/sustainability/cocoa-sustainability",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "barry callebaut",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/7mRoe3a0U53OGb6YruGwdA/3e1759a1c3ec52359d36b05057309fde/cl-partner-logo-barry-callebaut.png",
            "title": "cl-partner-logo-barry-callebaut",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/7mRoe3a0U53OGb6YruGwdA/3e1759a1c3ec52359d36b05057309fde/cl-partner-logo-barry-callebaut.png",
            "title": "cl-partner-logo-barry-callebaut",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "http://www.careinternational.org.uk/fighting-poverty/fighting-hunger/cocoa-life-partnership",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "care",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/7mpKT9h9SlUbes6CxIn70F/263d38313db332d544a50a3c43c54e6c/cl-partner-logo-care.png",
            "title": "cl-partner-logo-care",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/7mpKT9h9SlUbes6CxIn70F/263d38313db332d544a50a3c43c54e6c/cl-partner-logo-care.png",
            "title": "cl-partner-logo-care",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "http://www.cargill.com/150/en/MONDELEZ-COCOA-LIFE.jsp",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "cargill",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/7Cvje9f4GfVCzh7TAbRICB/dd71939124b4752299d128395b07d5e2/cl-partner-logo-cargill.png",
            "title": "cl-partner-logo-cargill",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/7Cvje9f4GfVCzh7TAbRICB/dd71939124b4752299d128395b07d5e2/cl-partner-logo-cargill.png",
            "title": "cl-partner-logo-cargill",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://crighana.org/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "Child Rights International",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/6Cd1JJp8tkuyRFEEUfv9Z7/58ae76543079e026e96d01d336eeada7/child-rights-international.png",
            "title": "child-rights-international",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/6Cd1JJp8tkuyRFEEUfv9Z7/58ae76543079e026e96d01d336eeada7/child-rights-international.png",
            "title": "child-rights-international",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "http://www.ecomtrading.com/sustainability-in-cocoa-our-vision/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "ecom",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3CBDDlIVrwnfOqKLh1XOzZ/85e1735afc0965dd1161073055079617/cl-partner-logo-ecom.png",
            "title": "cl-partner-logo-ecom",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3CBDDlIVrwnfOqKLh1XOzZ/85e1735afc0965dd1161073055079617/cl-partner-logo-ecom.png",
            "title": "cl-partner-logo-ecom",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://www.fairtrade.org.uk/Farmers-and-Workers/Cocoa/Cocoa-Life/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "fairtrade",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/4zNKs0FJdcI3PhZqLtnApE/d2e1eee599837c09f356f4658eba1479/cl-partner-logo-fairtrade.png",
            "title": "cl-partner-logo-fairtrade",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/4zNKs0FJdcI3PhZqLtnApE/d2e1eee599837c09f356f4658eba1479/cl-partner-logo-fairtrade.png",
            "title": "cl-partner-logo-fairtrade",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "http://impactum.africa/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "Impactum",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/4jMwGtKkV9qMtxmJJ0ZoVV/02bb8d0cff9448a70239bd28d41550ea/impactum.png",
            "title": "impactum",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/4jMwGtKkV9qMtxmJJ0ZoVV/02bb8d0cff9448a70239bd28d41550ea/impactum.png",
            "title": "impactum",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://jacobsfoundation.org/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "jacobs foundation",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/5FWaAZO3u33qOPM2udQc1c/78b65e83b9d4ca4e8c587ff088c4c941/cl-partner-logo-jacobs-foundation.png",
            "title": "cl-partner-logo-jacobs-foundation",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/5FWaAZO3u33qOPM2udQc1c/78b65e83b9d4ca4e8c587ff088c4c941/cl-partner-logo-jacobs-foundation.png",
            "title": "cl-partner-logo-jacobs-foundation",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://www.ofi.com/products-and-ingredients/cocoa.html",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "ofi - make it real",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/2wpoJ6sma2n0qeto92HRF4/a5fb24ef0d5b4be5560ff4135ef20d95/cl-partner-logo-olam-cocoa__1_.png",
            "title": "cl-partner-logo-olam-cocoa (1)",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/2wpoJ6sma2n0qeto92HRF4/a5fb24ef0d5b4be5560ff4135ef20d95/cl-partner-logo-olam-cocoa__1_.png",
            "title": "cl-partner-logo-olam-cocoa (1)",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://www.purprojet.com/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "PUR Project",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/6mYuhV55tG98eiBcXXIw2Y/e76d80e24e3ff6bbbb4055b93c7187ae/pur.png",
            "title": "pur",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/6mYuhV55tG98eiBcXXIw2Y/e76d80e24e3ff6bbbb4055b93c7187ae/pur.png",
            "title": "pur",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "http://www.savethechildren.org/site/c.8rKLIXMGIpI4E/b.9298251/k.DA7B/Mondelez.htm",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "save the children",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3r0rHHGPBOzieE64a3fjpC/19d82e7636ea143d618e022153583391/cl-partner-logo-save-the-children.png",
            "title": "cl-partner-logo-save-the-children",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3r0rHHGPBOzieE64a3fjpC/19d82e7636ea143d618e022153583391/cl-partner-logo-save-the-children.png",
            "title": "cl-partner-logo-save-the-children",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://www.solidaridadnetwork.org/news/mondelez-partners-with-solidaridad-to-strengthen-cocoa-farming-communities/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "solidaridad",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/5jK4qM6ZIVMwCkFiwbfkvO/7f31797cb090b00e30abd9fa37961bff/cl-partner-logo-solidaridad.png",
            "title": "cl-partner-logo-solidaridad",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/5jK4qM6ZIVMwCkFiwbfkvO/7f31797cb090b00e30abd9fa37961bff/cl-partner-logo-solidaridad.png",
            "title": "cl-partner-logo-solidaridad",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://leplanteur.ci/en-US/welcome/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "Stag",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/NLg4FXhLH6S5gFHkwD18t/cb90d34c3645f38c45ac14b67a892941/stag-forestree.png",
            "title": "stag-forestree",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/NLg4FXhLH6S5gFHkwD18t/cb90d34c3645f38c45ac14b67a892941/stag-forestree.png",
            "title": "stag-forestree",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://www.swisscontact.org/en/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "swisscontact",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/5RQQHWbh4ci4wFrL97i9ex/ca7b2df71262ed09e2f3519161b0097e/cl-partner-logo-swisscontact.png",
            "title": "cl-partner-logo-swisscontact",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/5RQQHWbh4ci4wFrL97i9ex/ca7b2df71262ed09e2f3519161b0097e/cl-partner-logo-swisscontact.png",
            "title": "cl-partner-logo-swisscontact",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://touton.com/products/cocoa/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "Touton Specialties",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/c3wKT33XOpgiMneLsbiBu/d5b2a5fe36cbe3e113388bc7d2703d4b/cl-partner-logo-touton.png",
            "title": "cl-partner-logo-touton",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/c3wKT33XOpgiMneLsbiBu/d5b2a5fe36cbe3e113388bc7d2703d4b/cl-partner-logo-touton.png",
            "title": "cl-partner-logo-touton",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://www.treeglobal.com/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "Tree Global",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/MXwsVll0Kg21DbtWNcEXR/461bd4d9cd1453de98a5b7d8dcde34c8/tree-global.png",
            "title": "tree-global",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/MXwsVll0Kg21DbtWNcEXR/461bd4d9cd1453de98a5b7d8dcde34c8/tree-global.png",
            "title": "tree-global",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://wahanavisi.org/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "Wahana Visi",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3CxrxxZey319fpVRRPJhuN/dc166ce821f5269385988cda0f9ff380/wahana-visi.png",
            "title": "wahana-visi",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3CxrxxZey319fpVRRPJhuN/dc166ce821f5269385988cda0f9ff380/wahana-visi.png",
            "title": "wahana-visi",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      },
      {
        "url": "https://www.wvi.org/ghana/article/cocoa-life-project-improves-farmers-lives-ghana/",
        "target": "_blank",
        "scrollToElementId": null,
        "image": {
          "altText": "world vision",
          "imageDesktop": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/2YUYjjrAxNEDICDefvPO05/7ea0a8e8d060981dcf256f593db54647/cl-partner-logo-world-vision.png",
            "title": "cl-partner-logo-world-vision",
            "width": 240,
            "height": 240,
            "description": ""
          },
          "imageMobile": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/2YUYjjrAxNEDICDefvPO05/7ea0a8e8d060981dcf256f593db54647/cl-partner-logo-world-vision.png",
            "title": "cl-partner-logo-world-vision",
            "width": 240,
            "height": 240,
            "description": ""
          }
        }
      }
    ]
  },
  "link": {
    "url": "/progress/story-6-growing-opportunities-through-education/",
    "target": "_self",
    "scrollToElementId": null,
    "gaEventClass": null,
    "gaEventLabel": null
  },
  "imagesCollection": {
    "items": [
      {
        "altText": "Matthias Lange",
        "imageDesktop": {
          "url": "https://images.ctfassets.net/qggsjlmpzfmx/55tZrQWsXLYDSJHB8lSah4/dba41672592dc2ab2a909c057f57e80c/matthias.png",
          "title": "matthias",
          "width": 200,
          "height": 200,
          "description": "<p>Matthias Lange</p>\n<p>Executive Director,</p>\n<p>International Cocoa Initiative</p>"
        },
        "imageMobile": {
          "url": "https://images.ctfassets.net/qggsjlmpzfmx/55tZrQWsXLYDSJHB8lSah4/dba41672592dc2ab2a909c057f57e80c/matthias.png",
          "title": "matthias",
          "width": 200,
          "height": 200,
          "description": "<p>Matthias Lange,</p>\n<p>Executive Director,</p>\n<p>International Cocoa Initiative</p>"
        }
      },
      {
        "altText": "Irina Hotz",
        "imageDesktop": {
          "url": "https://images.ctfassets.net/qggsjlmpzfmx/4wcyTy2Ks93t78dJwMXC4u/023cdf2549399d1faf4fe169a11a18f7/irina.png",
          "title": "irina",
          "width": 200,
          "height": 200,
          "description": "<p>Irina Hotz,</p>\n<p>Co-Lead of Learning Societies,</p>\n<p>Jacob’s Foundation</p>"
        },
        "imageMobile": {
          "url": "https://images.ctfassets.net/qggsjlmpzfmx/4wcyTy2Ks93t78dJwMXC4u/023cdf2549399d1faf4fe169a11a18f7/irina.png",
          "title": "irina",
          "width": 200,
          "height": 200,
          "description": "<p>Irina Hotz,</p>\n<p>Co-Lead of Learning Societies,</p>\n<p>Jacob’s Foundation</p>"
        }
      },
      {
        "altText": "Michael Komlorm",
        "imageDesktop": {
          "url": "https://images.ctfassets.net/qggsjlmpzfmx/59mbZSxxKHk2zUcF0UdvWm/2d472866d07be77d3155989669bf20c6/michael.png",
          "title": "michael",
          "width": 200,
          "height": 200,
          "description": "<p>Michael Komlorm,</p>\n<p>Cocoa Life Reading Club</p>\n <p>Graduate</p>"
        },
        "imageMobile": {
          "url": "https://images.ctfassets.net/qggsjlmpzfmx/59mbZSxxKHk2zUcF0UdvWm/2d472866d07be77d3155989669bf20c6/michael.png",
          "title": "michael",
          "width": 200,
          "height": 200,
          "description": "<p>Michael Komlorm,</p>\n<p>Cocoa Life Reading Club</p>\n <p>Graduate</p>"
        }
      }
    ]
  },
  "variant": "IMAGE_WITH_DESCRIPTION",
  "showImageDescription": true
}
