export const defaultStyle = {
  linkWrapper: "my-[32px]",
  linkClass: "highlightLink flex p-2 py-[6px] items-center",
  imgWrapperClass:
    "imgWrapperClass w-[12%] md:w-[9%] mr-[10px] md:mr-[20px] flex justify-end",
  linkText: "linkText w-full md:w-[91%]",
  icon: "",
  linkTextColor:"text-textBlack",
}

export const BG_LIGHT_GREEN = {
  ...defaultStyle,
  linkWrapper: "my-[32px] bg-lightGreen",
}

export const BG_ORANGE = {
  ...defaultStyle,
  linkWrapper: "my-[32px] bg-orange",
}

export const BG_BURGUNDY = {
  ...defaultStyle,
  linkWrapper: "my-[32px] bg-redVelvet",
}

export const BG_PINK = {
  ...defaultStyle,
  linkWrapper: "my-[32px] bg-darkPink",
}

export const BG_YELLOW = {
  ...defaultStyle,
  linkWrapper: "my-[32px] bg-yellow",
}

export const BG_VIOLET = {
  ...defaultStyle,
  linkWrapper: "my-[32px] bg-purple",
}

export const BG_GREY = {
  ...defaultStyle,
  linkWrapper: "my-[32px] bg-footerGrey darkArrow",
}

export const BG_YELLOW_BLACK_ARROW = {
  ...defaultStyle,
  linkWrapper: "my-[32px] bg-yellow darkArrow",
}