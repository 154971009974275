import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { get } from "../../helpers/utils"
import Link from "../UI/Link/Link"
import * as styles from "./NewsCardsList-tw-styles"
import Typography from "../UI/Typography/Typography"
import Button from "../UI/Button/Button"

const NewscardsList = props => {
  const { title, articlesCollection, variant, loadMoreArticle = {} } = props
  const style = styles[variant] || styles.defaultStyle
  const cards = articlesCollection && get(articlesCollection, "items", [])
  const [newsCardCollection, setNewsCardCollection] = useState([])
  const [startIndex, setStartIndex] = useState(0)
  const newsPerPage = 10 ;

  useEffect(()=>{
    const newsCard = cards.slice(0, newsPerPage);
    setNewsCardCollection(newsCard)
    setStartIndex(newsPerPage)
  },[])

  const handleLoadMore = () =>{
    const end = startIndex + newsPerPage
    const updatedNews = cards.slice(startIndex, end)
    setNewsCardCollection([
      ...newsCardCollection,
      ...updatedNews
    ])
    setStartIndex(end)
  }

  return (
    <div className={style?.newsCardWrapper}>
      {title && <Typography content={title} className={style?.sectionTitle} />}
      {newsCardCollection.length > 0 &&
        newsCardCollection.map((item, index) => (
          <div key={index} className={style?.list}>
            <div className={style?.listInner}>
              {item?.tag && (
                <Typography content={item?.tag} className={style?.tag} />
              )}
              {item?.title && (
                <Typography content={item?.title} className={style?.headline} />
              )}
              {item?.description && (
                <Typography
                  content={item?.description}
                  className={style?.description}
                />
              )}
              {item.link && (
                <Link
                  className={style?.link}
                  {...item?.link}
                  label=""
                  gaEventLabel={"https://www.ft.com/content/878d51e8-120c-43ae-bf93-1ec34fdb0c70"}
                >
                  {item?.link?.label}
                </Link>
              )}
            </div>
          </div>
        ))}
      {loadMoreArticle && newsCardCollection?.length !== cards?.length && (
        <div className={style?.loadMoreBtnWrapper}>
          <Button className={style?.loadMoreBtn} onClick={handleLoadMore}>
            {loadMoreArticle?.name}
          </Button>
        </div>
      )}
    </div>
  )
}

NewscardsList.propTypes = {
  title: PropTypes.string,
  articlesCollection: PropTypes.object,
  variant: PropTypes.string,
  loadMoreArticle: PropTypes.object
}

export default NewscardsList
