const query = (isPreview) => `{
  footerCollection(limit: 1, preview: ${isPreview}) {
    items {
      copyRight
      socialLinksCollection(limit: 5) {
        items {
          label
          variant
          socialLinksCollection(limit: 5) {
            items {
              label
              iconClass
              link {
                url
                target
                gaEventClass
                gaEventLabel
                image {
                  altText
                  imageDesktop {
                    url
                    title
                    description
                    width
                    height
                  }
                  imageMobile {
                    url
                    title
                    description
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
      mainLinksCollection(limit: 10) {
        items {
          label
          linksCollection(limit: 10) {
            items {
              label
              title
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                altText
                imageDesktop {
                  url
                  title
                  description
                  width
                  height
                }
                imageMobile {
                  url
                  title
                  description
                  width
                  height
                }
              }
            }
          }
        }
      }
      brandLinksCollection(limit: 5) {
        items {
          url
          gaEventClass
          gaEventLabel
          image {
            altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
          }
        }
      }
    }
  }
}
  `
module.exports = { query }
