import React from "react"
import { screen, render } from "@testing-library/react"
import TextContent from "./TextContent"
import { textContent } from "./TextContent.mock"

describe("TextContent", () => {
  render(<TextContent {...textContent} />)
  test("test heading shortDescription", () => {
    expect(screen.getByText("YOUTH ENTERPRISE"))
  })
  test("test title", () => {
    render(<TextContent {...textContent} />)
    expect(screen.getByText("title"))
  })
  test("test heading shortDescriptionSecondary", () => {
    render(<TextContent {...textContent} />)
    expect(screen.getByText("shortDescriptionSecondary"))
  })

})


