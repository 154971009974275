export const constantReqBody = {
    'requestDomain': 'www.cocoalife.org',
    'requestCountry': 'US',
    'requestLanguage': 'EN',
    'requestCampaign': 'CCCH140101_LifeNewsletter_Registration_COCOA_Website',
    'requestTransaction': 'CCCH140101 Cocoa Life Newsletter Registration (IN)',
    'requestLegalListName': 'Privacy_Policy_EN',
    'requestLegalTextName': 'PrivacyPolicy_EN'

}
 export const dateFormat = 'MM/DD/YYYY'

export const labels = {
    dateOfBirth: "Birthday",
    day: "Day",
    month: "Month",
    year: "Year",
  }  

export const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
]