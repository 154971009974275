export const imageWithText = {
    "heading": "Heading mock",
    "description": "description mock",
    "isReversed": null,
    "elementId": null,
    "showBackground": true,
    "variant": "IMAGE_ONLY",
    "image": {
      "title": "image3",
      "description": "",
      "url": "https://images.ctfassets.net/qggsjlmpzfmx/1beKPrduLW1HQKuH7Gd1RD/31328d92b5033803bd85020f36fe34b6/cl-brands-cocoaProcessing3.jpg",
      "width": 1160,
      "height": 760
    },
    "backgroundImage": true,
    "link": null
  }