import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"
import Image from "../UI/Image/Image"

const ImageWithText = props => {
  const {
    heading,
    description,
    image,
    link,
    style,
    isReversed,
    headingIsLink,
    hasContent,
    backgroundImage,
    elementId = "",
    formLink,
    isClafeActive,
    showBackground,
  } = { ...props }
  const desc =
    typeof description === "object" ? description?.description : description

  const bgStyle = {}
  if (showBackground && backgroundImage) {
    bgStyle.backgroundImage = `url(${backgroundImage?.url})`
  }
  return (
    <div className={style?.wrapper} style={bgStyle}>
      {backgroundImage && !showBackground && (
        <div className={style?.bgImg}>
          <Image contentfulImage={backgroundImage} />
        </div>
      )}
      <div
        className={`${style?.imgTxtContainer} ${
          isReversed ? style?.reverse : ""
        }`}
      >
        {image && (
          <div className={style?.imgWrapper}>
            <span className={style?.gradBg}></span>
            <div className={style?.subimgWrpr}>
              <Image contentfulImage={image} className={style?.textImg} />
            </div>
          </div>
        )}
        <div
          className={`${hasContent ? style?.textContent : style?.textWrpr}`}
          id={elementId}
        >
          {heading ? (
            headingIsLink ? (
              <Link {...link} className={style?.textLink}>
                {heading}
              </Link>
            ) : (
              <Typography className={style?.textHead} content={heading} />
            )
          ) : null}
          {desc && <Typography content={desc} className={style?.textDesc} />}
          {isClafeActive && formLink ? (
            <Link {...formLink} className={style?.textLink} />
          ) : (
            link && <Link {...link} className={style?.textLink} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageWithText

ImageWithText.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headingIsLink: PropTypes.bool,
  link: PropTypes.object,
  image: PropTypes.object,
  style: PropTypes.object,
  isReversed: PropTypes.bool,
  hasContent: PropTypes.bool,
  backgroundImage: PropTypes.object,
  elementId: PropTypes.string,
  formLink: PropTypes.object,
  isClafeActive: PropTypes.bool,
  showBackground: PropTypes.bool,
}
