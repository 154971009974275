import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import { get } from "../../helpers/utils"
import ImageCard from "../UI/ImageCard/ImageCard"
import * as styles from "./TwoCardsWrapper-tw-styles"
import { bgThemes, textThemes } from "../../constants/variants.constants"
import {gaImageClick} from '../../constants/gtm.constants'
import SocialLinks from "../UI/SocialLinks/SocialLinks"
import useImage from "../../hooks/useImage"

const TwoCardsWrapper = props => {
  const { cardsCollection, heading, wrapperLink, variant, seeMoreLink,socialLinksContainerCollection } = props

  const style = styles[variant] || styles.defaultStyle
  const {
    wrapper,
    wrprHeading,
    wrprHeadingDiv,
    wrprHeadingContainer,
    wrprLink,
    wrprDiv,
    sWrprLink,
    redirectionLink,
    redirectionDiv,
    cardsWrpr,
    cardLink,
    bgStyle,
    noContentWrpr,
    socialLinkWrp,
  } = style

  const cards = get(cardsCollection, "items", [])

  const socialLinks =
  socialLinksContainerCollection && get(socialLinksContainerCollection, "items", [])

  useEffect(() => {
    const isSamePage = cards.map((item)=> {
      return(item?.link?.url === window.location.pathname)
    });
    if (isSamePage?.includes(true)) {
      if (typeof window !== "undefined") {
        window?.scrollTo(0,0)
      }
    }
  }, [])

  const renderCard = card => {
    return card?.link ? (
      <Link {...card?.link} className={cardLink} gaEventClass={gaImageClick}>
        <ImageCard {...card} />
      </Link>
    ) : (
      <ImageCard {...card} />
    )
  }

  const handleClick = (wrapperLink)=>{
    if (typeof window !== "undefined") {
      window?.open(wrapperLink?.url, wrapperLink?.target);
    }
  }

  const buildCards = () => {
    return cards?.map((card, idx) => {
      const { themeImages, bgTheme, text, title, textTheme } = card
      const noContent = text || title ? "" : noContentWrpr
      const gradient =
        text || title
          ? "linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),"
          : ""
      const bgColor = bgThemes[bgTheme] || "bg-white"
      const textColor = textThemes[textTheme] || "text-charcol"
      const className = `${cardsWrpr} ${bgColor} ${textColor} ${noContent} ${
        themeImages ? bgStyle : ""
      } `
      const { altText, foregroundImg } = useImage(themeImages)

      return (
        <div
          key={idx}
          className={className}
          style={
            foregroundImg && {
              backgroundImage: `${gradient}url(${foregroundImg?.url})`,
            }
          }
        >
          {renderCard(card)}
        </div>
      )
    })
  }
  return (
    <div className={wrapper}>
      {heading && (
        <div className={wrprHeadingContainer}>
          <div className={wrprHeadingDiv}>
            <p className={wrprHeading}>{heading}</p>
          </div>
        </div>
      )}
      {wrapperLink ? (
        <div onClick={()=>handleClick(wrapperLink)}  data-testid="wrapper-link" className={wrprLink}>
          {cards?.length && buildCards()}
        </div>
      ) : (
        <div className={wrprDiv}>{buildCards()}</div>
      )}
      {seeMoreLink && (
        <div className={redirectionDiv}>
          <Link {...seeMoreLink} className={redirectionLink} />
        </div>
      )}
      {socialLinks && socialLinks?.length > 0 && (
          <div className={sWrprLink}>
            {socialLinks.map((item, idx) => (
              <SocialLinks {...item} key={idx} />
            ))}
          </div>
        )}
    </div>
  )
}

TwoCardsWrapper.propTypes = {
  cardsCollection: PropTypes.object,
  socialLinksContainerCollection:PropTypes.object,
  heading: PropTypes.string,
  wrapperLink: PropTypes.object,
  variant: PropTypes.string,
  seeMoreLink: PropTypes.object,
  socialIcon:  PropTypes.object,
}

export default TwoCardsWrapper
