import { element } from "prop-types"

// update page url with query params when searchTerm, filters and page values(refer updatePageUrlOld function)

export const updatePageUrl = ({ filters, search, pageNo, isBlogsPage = false }) => {
  if (typeof window !== "undefined") {
    const pathName = window?.location?.pathname

    // search query
    const searchQuery = search || "*"
    //filter query
    const filtersArr = isBlogsPage ? ["Progress Blog"] : ["Library Item"]
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        filtersArr.unshift(filters[key])
      }
    })

    const isFilterEmpty = Object.values(filters).every(x => x === null || x === '');

    // page number query
    const pageQuery = pageNo ? pageNo : 0

    // create query params
    let queryParams = ""
    queryParams += `SearchText=${searchQuery}`
    queryParams += `&Filter=${filtersArr}`
    queryParams += pageQuery ? `&page=${pageQuery}` : ""
    queryParams += isFilterEmpty ? "" : `&JumpToLibrary=${true}`

    const newUrl =
      window.location.origin +
      pathName +
      `${queryParams ? "?" + queryParams : ""}`

    window?.history?.pushState(null, null, newUrl)
  }
}

export const getUrlValues = (filters) => {
  const queryFilterOption = {
    docType: '',
    focusAreas: '',
    region: ''
  }
  if (typeof window !== "undefined") {
    const params = window.location.search
    const searchMatch = params.match(/SearchText=([\w %_-]+)&?/)
    const filterMatch = params.match(/Filter=([^&#]*)/) || []
    const pageNoMatch = params.match(/page=([^&#]*)/)
    const filterQuery = decodeURIComponent(filterMatch[1]).split(',')
    if (filters) {
      const { documentTypes = [], focusAreas = [], region = [] } = filters
      filterQuery.forEach(filterItem => {
        if (documentTypes.includes(filterItem)) {
          queryFilterOption.docType = filterItem
        } else if (focusAreas.includes(filterItem)) {
          queryFilterOption.focusAreas = filterItem
        } else if (region.includes(filterItem)) {
          queryFilterOption.region = filterItem
        }
      })
    }
    const searchTerm =
      searchMatch && searchMatch[1]
        ? searchMatch[1].trim().replaceAll("%20", " ")
        : ""
    const pageNo =
      pageNoMatch && pageNoMatch[1]
        ? pageNoMatch[1].trim().replaceAll("%20", " ")
        : 0

    return {
      search: searchTerm !== "*" ? searchTerm : "",
      filter: queryFilterOption,
      pageNo: pageNo ? pageNo : 0
    }
  }
}

export const buildDropdownOptions = (values, defaultOptn) => {
  const options = values.map(item => {
    return {
      value: item,
      label: item,
    }
  })
  return [{ label: defaultOptn, value: '' }, ...options]
}
