export const defaultStyle = {}

export const BG_ORANGE = {
  accordianwholeText: "w-full text-brown text-16 px-20",
  accordionItem: "accordionItem relative bg-orange border-white border-b-[3px]",
}

export const BG_PINK = {
  accordianwholeText: "w-full text-brown text-16 px-20",
  accordionItem: "accordionItem relative bg-darkPink border-white border-b-[3px]",
}

export const BG_GREEN = {
  accordianwholeText: "w-full text-brown text-16 px-20",
  accordionItem: "accordionItem relative bg-lightGreen border-white border-b-[3px]",
}
export const BG_GREY = {
  accordianwholeText: "w-full text-brown text-16 px-20",
  accordionItem: "accordionItem relative bg-footerGrey border-white border-b-[3px]",
}

export const BG_REDVELVET = {
  accordianwholeText: "w-full text-brown text-16 px-20 ",
  accordionItem: "accordionItem relative bg-redVelvet border-white border-b-[3px]",
}

export const BG_YELLOW = {
  accordianwholeText: "w-full text-brown text-16 px-20 ",
  accordionItem: "accordionItem relative bg-yellow border-white border-b-[3px]",
}