const query = (id, isPreview) => `{
  sitemap(id: "${id}", preview: ${isPreview}) {
    heading
    linksCollection (limit:20){
      items {             
        label
        link {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
        }
    dropdownItemsCollection(limit:10) {
        items {
          __typename ...on Link {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
          }
           __typename ...on NavigationItem {
            label
        link {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
        }
            dropdownItemsCollection(limit:10) {
        items {
          __typename ...on Link {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
          }
        }}
            
            
          }
          }
        }           
      }
    }
  
   }
  } 
`
module.exports = { query }