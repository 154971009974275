import React, { useState } from "react"
import PropTypes from "prop-types"
import Image from "../Image/Image"
import * as styles from "./ImageCard-tw-styles"
import Typography from "../Typography/Typography"
import Link from "../Link/Link"
import { deslashify } from "../../../helpers/utils"
import variants from "../../../constants/variants.constants"
import { gaEventClick } from "../../../constants/gtm.constants"
import useImage from "../../../hooks/useImage"

const ImageCard = props => {
  const {
    text,
    tagName,
    title,
    images,
    link,
    variant,
    gaEventClass,
    gaEventLabel,
    hasCardAnimation,
  } = props

  const [imgHoverClass, setImgHoverClass] = useState("")
  const style = styles[variant] || styles.defaultStyle
  let currentPath = ""
  if (typeof window !== "undefined") {
    currentPath = window?.location?.pathname
  }

  const onMouseEnter = () => {
    if (!hasCardAnimation) {
      return
    }
    switch (variant) {
      case variants?.FIVE_COLUMN_CARDS:
        return setImgHoverClass("")
      case variants?.FOUR_IMAGE_SLIDER:
        return setImgHoverClass("")
      default:
        return setImgHoverClass("scale-[1.05] transiImg")
    }
  }
  const onMouseLeave = () => {
    if (!hasCardAnimation) {
      return
    }
    setImgHoverClass("")
  }

  const activeCard =
    deslashify(currentPath) === deslashify(link?.url) &&
    variant === "FIVE_COLUMN_CARDS"

  const activeHeading =
    style?.activeHeading && title ? style?.activeHeading : ""
    const { altText, foregroundImg } = useImage(images)
  return (
    <div className={`${style?.imageBoxWraper} ${activeHeading}`}>
      <div className={style?.imageBoxWrpr}>
        <div className={style?.imgContainer}>
          {images && (
            <Image
              contentfulImage={foregroundImg}
              alt={altText}
              className={`${imgHoverClass} ${style?.imgWrpr} ${
                activeCard ? "borderActive" : ""
              }`}
              eventClass={gaEventClass}
              eventLabel={gaEventLabel}
            />
          )}
        </div>
        <div className={style?.contentWraprBox}>
          <div className={style?.contentWrapr }>
            {tagName && <Typography content={tagName} className={style?.tagHeading}/>}
            {title && (
              <Typography
                className={`${style?.heading} ${!text ? style?.arrowIcon : "!mb-[16px]"}`}
                content={title}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            )}
            {text && (
              <Typography className={`${style?.description} ${style?.arrowIcon}`} content={text} />
            )}
            {link?.label && <Link {...link} className={style?.linkWrpr} gaEventClass={gaEventClick} gaEventLabel={link?.label}/>}
            {!activeCard && (
              <span
                className={style?.gradTop}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              ></span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ImageCard.propTypes = {
  tagName: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.object,
  images: PropTypes.object,
  variant: PropTypes.string,
  hasCardAnimation: PropTypes.bool,
  gaEventClass: PropTypes.string,
  gaEventLabel: PropTypes.string,
  style: PropTypes.object,
}

export default ImageCard
