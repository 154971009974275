export const articleVideo = {
    "heading": "articleVideo heading",
    "description": "articleVideo description",
    "video": {
        "title": "Video title",
        "youtubeVideoId": "lUbn33qDG5g",
        "posterImage": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3uMVMRBVRTjknY0KxJljOB/d1d46ffc5d7688350fb9380628a06817/maxresdefault.jpg",
            "title": "posterImage Title",
            "description": "posterImage description"
        },
        "isModal": null
    },
    "variant": "HALF_WIDTH_VIDEO_LINK_WRAPPER_BURGUNDY_THEME",
      "isContentReversed": false,
      linkWrapper: {
    linksCollection: {
      items: [
        {
        linkLabel: "To find out more about this story, visit the blog",
        textTheme: "WHITE",
        variant: "BG_BURGUNDY",
        link: {
          url: "/progress/story-6-growing-opportunities-through-education/",
          target: "_self",
          scrollToElementId: null,
          gaEventClass: null,
          gaEventLabel: null,
        },
      }
      ],
    },
  },
}
