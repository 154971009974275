import React, {useState as useStateMock} from "react"
import { render, screen, fireEvent,cleanup } from "@testing-library/react"
import ArticleListing from "./ArticleListing"
import { liberarySearch, progressBlog, searchResult } from "./ArticleListing.mock"
import "@testing-library/jest-dom"
import { searchLibraryGtm } from "../../constants/gtm.constants"
import ArticlesList from "./ArticlesList/ArticlesList"
import { SearchElement } from "./ArticleListing.mock"
jest.mock('react', () => ({
  ...jest.requireActual('react'),
  useState: jest.fn(),
}));

describe("Library Search test case ", () => {
  const setIsLoading = jest.fn();
  const useStateMock = () => [false, setIsLoading];

  jest.spyOn(React, 'useState').mockImplementation(useStateMock);
  beforeEach(() => {
    Object.defineProperty(window, "location", {
      writable: true,
      value: { assign: jest.fn() },
    })
    window.location = {
      search: "?Filter=Ghana,Library%20Item",
    }
    render(<ArticleListing {...liberarySearch} />)
  });
  afterEach(() => {
    cleanup();
    jest.clearAllMocks();
});
  test("renders liberary Search component", () => {
    render(<ArticleListing {...liberarySearch} />)
  })
  test("renders button Search component", () => {
    const button = screen.getAllByRole('button')
    fireEvent.click(button[0])
    fireEvent.click(button[1])
  })
  test("renders select Filter button Search component", () => {
    const button = screen.getAllByRole('combobox')
    fireEvent.change(button[0])
    fireEvent.change(button[1])
    fireEvent.change(button[2])
  })
})

describe("Progress Blog test Case ", () => {
  const setIsLoading = jest.fn();
  const useStateMock = () => [false, setIsLoading];

  jest.spyOn(React, 'useState').mockImplementation(useStateMock);
  beforeEach(() => {
    Object.defineProperty(window, "location", {
      writable: true,
      value: { assign: jest.fn() },
    })
    window.location = {
      search: "?SearchText=Impact&Filter=Progress%20Blog",
    }
    render(<ArticleListing {...progressBlog} />)
  });
  afterEach(() => {
    cleanup();
    jest.clearAllMocks();
});
  test("renders ProgressBlog compoent", async () => {
    render(<ArticleListing {...progressBlog} />)
  })
  test("renders ProgressBlog compoent", async () => {
    
  })
})

describe("Progress Blog test Case with search results ", () => {
  const setIsLoading = jest.fn();
  const useStateMock = () => [false, setIsLoading];

  jest.spyOn(React, 'useState').mockImplementation(useStateMock);
  beforeEach(() => {
    Object.defineProperty(window, "location", {
      writable: true,
      value: { assign: jest.fn() },
    })
    window.location = {
      search: "?SearchText=Impact&Filter=Progress%20Blog",
    }
    render(<ArticleListing {...progressBlog} />)
  });
  afterEach(() => {
    cleanup();
    jest.clearAllMocks();
});
  test("renders ProgressBlog compoent", async () => {
    jest.clearAllMocks();
    const setSearchResult = jest.fn();
    const useStateMockSearch = () => [searchResult, setSearchResult];
    jest.spyOn(React, 'useState').mockImplementation(useStateMockSearch);
    render(<ArticleListing {...progressBlog} />)
  })
})

describe("ArticlesList", () => {
  test("renders article information correctly", () => {
    const { container, getByText } = render(<ArticlesList {...SearchElement} />)
    expect(getByText("Article Description")).toBeInTheDocument()
    expect(getByText("04/10/2023")).toBeInTheDocument()
  })
})