import React from "react"
import { screen, render, cleanup, fireEvent } from "@testing-library/react"
import NewsCardsList from "./NewsCardsList"
import { newsCardsList } from "./NewsCardsList.mock"
import "@testing-library/jest-dom"

describe("render NewsCardsList component", () => {
    beforeEach(() => {
        render(<NewsCardsList {...newsCardsList} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render title", () => {
        const title = screen.getByText("LATEST NEWS")
        expect(title).toBeInTheDocument()
    })
    test("render articlesCollection tag", () => {
        const tag = screen.getByText('FINANCIAL TIMES')
        expect(tag).toBeInTheDocument()
    })
    test("render articlesCollection title", () => {
        const title = screen.getByText('CADBURY-MAKER MONDELEZ TO INVEST $600')
        expect(title).toBeInTheDocument()
    })
    test("render articlesCollection link", () => {
        const link = screen.getAllByRole('link')
        expect(link[1]).toBeInTheDocument()
    })
    test("render articlesCollection description", () => {
        const description = screen.getByText('Cadbury chocolate-maker Mondelez International doubled down on sustainable cocoa sourcing on Wednesday, pledging to spend an additional $600 million by 2030 on efforts to combat child labour, farmer poverty and deforestation in cocoa.')
        expect(description).toBeInTheDocument()
    })
    test("render handleLoadMoreButton", () => {
        const button = screen.getByRole('button')
        fireEvent.click(button)
    })

})