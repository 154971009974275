export const defaultStyle = {
    circleWrapper: "floating-circle",
    circleContainer: "circle-container text-white w-full h-0 py-[50%] mb-24 rounded-[50%] relative flex items-center justify-center",
    typography: "text font-medium",
}
export const TEXT_WITH_ORANGE_BG = {
    ...defaultStyle,
    circleContainer: "circle-container text-white w-full h-0 py-[50%] mb-24 rounded-[50%] relative bg-orange flex items-center justify-center",
}
export const TEXT_WITH_BURGUNDY_BG = {
    ...defaultStyle,
    circleContainer: "circle-container text-white w-full h-0 py-[50%] mb-24 rounded-[50%] relative bg-redVelvet flex items-center justify-center",
}
export const TEXT_WITH_PINK_BG = {
    ...defaultStyle,
    circleContainer: "circle-container text-white w-full h-0 py-[50%] mb-24 rounded-[50%] relative bg-darkPink flex items-center justify-center",
}
export const TEXT_WITH_GREEN_BG = {
    ...defaultStyle,
    circleContainer: "circle-container text-white w-full h-0 py-[50%] mb-24 rounded-[50%] relative bg-lightGreen flex items-center justify-center",
}