import React from "react"
import { screen, render } from "@testing-library/react"
import ArticleVideo from "./ArticleVideo"
import { articleVideo } from "./ArticleVideo.mock"
import '@testing-library/jest-dom'

describe("ArticleVideo", () => {
  render(<ArticleVideo {...articleVideo} />)
  test("testing heading", () => {
    expect(screen.getByText("articleVideo heading")).toBeInTheDocument()
  })
  test("testing heading", () => {
    render(<ArticleVideo {...articleVideo} />)
    expect(screen.getByText("articleVideo description")).toBeInTheDocument()
  })
  test("testing Video Component", () => {
    render(<ArticleVideo {...articleVideo} />)
    expect(screen.getByText("articleVideo description")).toBeInTheDocument()
  })
  test("testing LinkWrapper linkLabel", () => {
    render(<ArticleVideo {...articleVideo} />)
    expect(screen.getByText("To find out more about this story, visit the blog")).toBeInTheDocument()
  })
  test("testing LinkWrapper link href", () => {
    render(<ArticleVideo {...articleVideo} />)
    expect(screen.getByText('To find out more about this story, visit the blog').closest('a')).toHaveAttribute('href', '/progress/story-6-growing-opportunities-through-education/')
  })

})
