export const defaultStyle = {}

export const ReceipeListStyles = {
  stepsContainer:
    "stepsContainer flex flex-wrap my-60 px-20 lg:px-auto justify-center",
  olClass: "list-decimal w-full lg:w-[54%] md:w-[77%] mx-auto",
  liDescription:
    "liDescription inline-flex items-center md:w-[81.333%] w-3/4 ml-[20px] text-[18px]",
  roundedHeading:
    "bg-liBgRound reciepeLi bg-contain bg-no-repeat md:h-[78px]  md:w-[78px] h-[35px] w-[42px] font-ProxBold md:text-[45px] text-[30px] flex  items-center justify-center w-[10%]",
  receipeHeading:
    "receipeHeading text-darkBlue text-[30px] font-ProxBold w-full mx-auto flex justify-center my-0 leading-[30px] tracking-[1px]",
  receipeSubheading:
    "subheading text-darkBlue text-[30px] font-ProxBold w-full justify-center mx-auto flex text-center mt-[4.5px] mb-0 leading-[30px]",
  receipeMainWrpr:
    "receipeMainWrpr lg:w-[1170px] w-full mx-auto flex flex-wrap",
  headingWrpr: "flex mx-auto flex-wrap mt-40 w-[615px]",
}

export const NEWS_CARDS_LIST = {
  newsCardWrapper: "news-card-section max-w-[960px] m-auto py-50",
  sectionTitle:
    "section-title border-b border-darkGrey text-black px-15 md:px-30 pb-16 text-40 font-alternate",
  list: "list border-b border-darkGrey",
  listInner: "w-full md:max-w-[590px] pt-20 pb-40 px-15 md:pl-30 md:pr-0",
  tag: "tag text-lightGreen font-alternate text-3xl mb-12 ml-[-10px]",
  headline: "headline mb-16 text-40 font-alternate leading-9 text-textBlack",
  description: "description mb-16 text-17 font-brand leading-6 text-textBlack md:w-[590px]",
  link: "link arrowLink text-lightGreen text-17 font-brand inline-block pr-40 bg-no-repeat bg-right",
  loadMoreBtnWrapper: 'loadMoreWrapper text-center px-30 py-50 text-lightGreen',
  loadMoreBtn: 'loadMoreBtn text-14 w-[155px] py-10 border-1 border-lightGreen rounded-[100px] hover:bg-lightGreen hover:text-white'
}
