import React from "react"
import { screen, render, cleanup } from "@testing-library/react"
import LinkWrappersLayout from "./LinkWrappersLayout"
import { linkWrappersLayout } from "./LinkWrappersLayout.mock"
import "@testing-library/jest-dom"

describe("render ImageCardsSlider component", () => {
    beforeEach(() => {
        render(<LinkWrappersLayout {...linkWrappersLayout} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render link", () => {
        const link = screen.getByRole('link', {name: /Download publication/i})
        expect(link).toBeInTheDocument()
        expect(link.href).toContain('April_2015_Cocoa_Life_Cote_dIvoire_Needs_Assessment_Exec_summary')
    })

})