import React from "react"
import { screen, render, cleanup } from "@testing-library/react"
import Map from "./Map"
import { map } from "./Map.mock"
import "@testing-library/jest-dom"

describe("render Map component", () => {
    beforeEach(() => {
        render(<Map {...map} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render title", () => {
        const title = screen.getByText("CÔTE D'IVOIRE")
        expect(title).toBeInTheDocument()
    })
    test("render backgroundImage", () => {
        const backgroundImage = screen.getByRole('presentation')
        expect(backgroundImage.src).toContain('cl-origins-cotedivoire')
    })
    test("render backgroundImage", () => {
        const description1 = screen.getByText('Farmers currently participating in Cocoa Life')
        expect(description1).toBeInTheDocument()
        const description2 = screen.getByText('Cocoa Life active in country since')
        expect(description2).toBeInTheDocument()
    })

})