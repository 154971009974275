import React from "react"
import "@testing-library/jest-dom"
import { screen, render, fireEvent, cleanup } from "@testing-library/react"
import TwoCardsWrapper from "./TwoCardsWrapper"
import { twoCardsWrapper } from "./TwoCardsWrapper.mock"

describe("TwoCardsWrapper", () => {
  beforeEach(() => {
    Object.defineProperty(window, "location", {
      writable: true,
      value: { assign: jest.fn() },
    })
    window.location = {
      pathname: "/progress/a-major-milestone-to-accelerate-our-journey/",
    }
    render(<TwoCardsWrapper {...twoCardsWrapper} />)
  });
  afterEach(() => {
    cleanup();
    jest.clearAllMocks();
});
  
  test("testing  heading", () => {
    expect(screen.getByText("Measure Progress"))
  })
  test("testing  Image Card title tagName text", () => {
    expect(screen.getByText("title"))
    expect(screen.getByText("tagName"))
    expect(screen.getByText("Executive"))
    const handleClick = screen.getByText("Executive")
    fireEvent.click(handleClick)
  })
})



