import { monthNames, labels } from "../constants/form.constants"
import moment from 'moment'
import axios from 'axios';

const getYearsArray = () => {
  // Date of Birth variables
  const currentYr = new Date().getFullYear();
  // year differnce will be 74 which is  ((currentYear-26) - (currentYear-90) ) 
  const startingYear = 78;
  const lastYear = currentYr - 16;
  const years = []
  Array.from({ length: startingYear}, (_, i) => {
    years.push({
      value: lastYear - i,
      label: lastYear - i,
    })
  })
  return years.reverse()
}

const getMonthsArray = () => {
  const months = []
  monthNames.forEach((monthName, i) => {
    months.push({ value: i + 1, label: monthName })
  })
  return months
}

const getDaysArray = (
  yearSelected = new Date().getFullYear(),
  monthSelected = new Date().getMonth() + 1
) => {
  const daysInMonth = new Date(yearSelected, monthSelected, 0).getDate();
  const days = []
  Array.from({ length: daysInMonth }, (_, i) => {
    days.push({ value: i + 1, label: i + 1 })
  })
  return days
}

const buildInputObject = (options, name, label) => {
  return {
    options,
    name,
    id: name,
    label,
  }
}

export const buildDobObject = (yearSelected, monthSelected) => {
  const years = getYearsArray()
  const months = getMonthsArray()
  const daysOfMonth = getDaysArray(yearSelected, monthSelected)
  const obj = { label: labels?.dateOfBirth }
  obj["years"] = buildInputObject(years, "year", labels?.year)
  obj["months"] = buildInputObject(months, "month", labels?.month)
  obj["days"] = buildInputObject(daysOfMonth, "day", labels?.day)

  return obj
}

export const getAge = (birthDate) => {
  return moment().diff(birthDate, 'years');
}

export const sendData = async (url, params) => {
  try {
    const result = await axios.post(url,{ ...params })
    return result?.data || []
  } catch (error) {
    return error
  }
  
}