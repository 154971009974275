import React from "react"
import { screen, render, cleanup } from "@testing-library/react"
import Sitemap from "./Sitemap"
import { sitemap } from "./Sitemap.mock"
import "@testing-library/jest-dom"

describe("render Sitemap component", () => {
    beforeEach(() => {
        render(<Sitemap {...sitemap} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render section heading", () => {
        const title = screen.getByText("SITEMAP")
        expect(title).toBeInTheDocument()
    })
    test("render sitemap links", () => {
        const link = screen.getByTitle('Home')
        expect(link).toBeInTheDocument()
        expect(link).toHaveAttribute('href', '/')
    })
    test("render sitemap links", () => {
        const link = screen.getByTitle('Approach')
        expect(link).toBeInTheDocument()
        expect(link).toHaveAttribute('href', '/the-program/approach/')
    })
    test("render sitemap links", () => {
        const link = screen.getByTitle('Child Protection')
        expect(link).toBeInTheDocument()
    })
    test("render sitemap links", () => {
        const link = screen.getByTitle('Climate Change')
        expect(link).toBeInTheDocument()
    })
    test("render sitemap links", () => {
        const link = screen.getByTitle("Women's Empowerment")
        expect(link).toBeInTheDocument()
        expect(link).toHaveAttribute('href', '/the-program/womens-empowerment/')
    })
    test("render sitemap links", () => {
        const link = screen.getByTitle("Partners")
        expect(link).toBeInTheDocument()
        expect(link).toHaveAttribute('href', '/the-program/partners/')
    })
    test("render sitemap links", () => {
        const link = screen.getByTitle("In the Cocoa Origins")
        expect(link).toBeInTheDocument()
        expect(link).toHaveAttribute('href', '/in-the-cocoa-origins/')
    })
    test("render sitemap links", () => {
        const link = screen.getByTitle("Interactive Farm Map")
        expect(link).toBeInTheDocument()
        expect(link).toHaveAttribute('href', '/in-the-cocoa-origins/interactive-map/')
    })


})