const query = (id, isPreview) => `
{
  articleHeader(id: "${id}", preview: ${isPreview}) {
    articleTagName
    tagTextTheme
    heading
    publishedDate
    video {
      title
      youtubeVideoId
    }
    subHeading
    socialShareLinksCollection(limit: 5) {
      items {
        tagName
      }
    }
    imagesCollection(limit: 5) {
      items {
        altText
        imageMobile {
          url
          title
          description
          width
          height
        }
        imageDesktop {
          url
          title
          description
          width
          height
        }
      }
    }
    readMoreTagsCollection(limit: 10) {
      items {
        tagName
      }
    }
    variant
  }
}`

module.exports = { query }
