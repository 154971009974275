export const defaultStyle = {
  interventionContainer: "total-invention-area text-center text-textBlack",
  interventionSubContainer: "total-invention-sub-area w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px] border-b-[2px] border-solid border-white",
  interventionTitleWrap: "intervention-heading pt-12 mb-[50px] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[1px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[1px] after:content-[' '] after:bg-darkGrey after:z-14 ",
  interventionTitle: "text-[40px] leading-[0.9] bg-white px-[28px] inline-block font-alternate z-[15] relative",
  interventionMiddleContentText: "interventionMiddleContentText font-brand text-17 mb-16 leading-[1.375]",
  linkContainerList: "list-container-area",
  interventionImage: "h-[100px] mx-auto relative mb-[26px] w-auto",
  interventionImageCard: "image-card flex-1 p-16 min-[567px]:py-0",
  interventionMiddleColmns: "card w-full py-16 flex flex-col min-[567px]:flex-row",
  interventionDescriTextTop: "my-16 font-bold h-[4rem]",
  interventionDescriTextBottom: "my-16 h-[4rem]",
  interventionTextAfterList: "font-brand text-center",
  interventionTextCard: "flex",
  interventionSubTitle: "interventionSubTitle pt-16 mb-12 relative text-[30.4px] uppercase font-alternate leading-[0.96];"
}

export const FIVE_COLUMN_GRID = {
  interventionContainer: "measure-progress-section w-auto overflow-hidden lg:w-[960px] md:max-w-[960px] text-center m-auto px-15 md:px-30 lg:px-0 py-10",
  interventionTitleWrap: "measure-progress-heading mt-12 mb-50 font-alternate text-textBlack relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[1px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[1px] after:content-[' '] after:bg-darkGrey after:z-14",
  interventionTitle: "text-40 leading-none px-28 bg-white z-[15] inline-block relative",
  interventionMiddleContentText: "sub-heading mb-12 text-30 font-alternate text-textBlack",
  interventionSubTitle:'measure-progress-description text-17 font-brand text-textBlack mb-12',
}

export const TWO_COLUMN_GRID = {
  interventionContainer: "impact-verification lg:w-[900px] md:max-w-[900px] m-auto text-center py-40",
  interventionTitleWrap: "impact-verification-heading my-10",
  interventionTitle: "mb-12 text-30 font-alternate text-textBlack leading-none",
  interventionMiddleContentText: "impact-verification-description text-17 font-brand text-textBlack mb-16",
  interventionMiddleColmns: "impact-verification-card grid md:flex text-textBlack",
}

export const TWO_COL_GRID_WITH768_BREAKPOINT = {
  ...defaultStyle,
  interventionMiddleColmns: "card w-full pt-20 flex flex-col md:flex-row",
  interventionMiddleContentText: "interventionMiddleContentText font-brand text-17 mb-0 leading-[1.375]",
}
export const THREE_COLUMN_GRID_IMPACT_EVALUATIONS = {
    ...defaultStyle,
    interventionTitleWrap: "impact-evaluations-heading mb-0",
    interventionTitle: "mb-12 pb-24 text-30 font-alternate text-textBlack leading-[0.96]",
    interventionMiddleContentText: "interventionMiddleContentText font-brand text-17 mb-0 leading-[1.375]",
    interventionMiddleColmns: "card w-full py-0 flex flex-col min-[567px]:flex-row",
}