import React from "react"
import PropTypes from "prop-types"
import InstagramIcon from "../../../assests/icons/InstagramIcon"
import { labels } from "../../../constants/label.constants"

const Icons = {
  InstagramIcon,
}

const Icon = ({ name, className }) => {
  if (!name) {
    return null
  }
  const SelectedIcon = Icons[name] || null
  return SelectedIcon ? SelectedIcon(className, name) : null
}

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
}

export default Icon
