import React from "react"
import { screen, render, cleanup } from "@testing-library/react"
import BackLink from "./BackLink"
import { backLink } from "./BackLink.mock"
import "@testing-library/jest-dom"

describe("render BackLink component", () => {
    beforeEach(() => {
        render(<BackLink {...backLink} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render anchor tag with href  Image", () => {
        expect(screen.getByRole('link')).toBeInTheDocument()
        expect(screen.getByRole('link')).toHaveAttribute("href", "/brands/")
    })

})