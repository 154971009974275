export const  videosWrapper = {
    "videoCollection": {
      "items": [
        {
          "title": "Cocoa Life approach to address climate change",
          "youtubeVideoId": "HA2BuZno7-c",
          "isModal": false,
          "isImageToggle": true
        },
        {
          "title": "Cocoa Life combat deforestation",
          "youtubeVideoId": "_xrid0X4JRI"
        }
      ]
    }
  }
export const videosWrapperModelView = {
  "videoCollection": {
    "items": [
      {
        "title": "Cocoa Life approach to address climate change",
        "youtubeVideoId": "https://www.youtube.com/watch?v=oJwxBh8BIG8",
        "isModal": true,
        "isImageToggle": true
      }
    ]
  }
}