import React from 'react'
import PropTypes from "prop-types"
import LinkWrapper from '../UI/LinkWrapper/LinkWrapper'
import * as styles from "./BackLink-tw-styles"
import Link from '../UI/Link/Link'
import Image from '../UI/Image/Image'
import Typography from '../UI/Typography/Typography'
import { imageConstants } from '../../constants/image.constants'
import { gaEventClick } from '../../constants/gtm.constants'

const BackLink = (props) => {
 const { link ,variant}=props
const style = styles[variant] || styles["defaultStyle"]
 const {
   linkWrapper,
   linkClass,
   icon,
   imgWrapperClass,
   linkText,
   linkWrapperSub,
   linkWrapperText,
 } = style
  return (
    <div className={linkWrapper}>
      <div className={linkWrapperSub}>
        <div className={linkWrapperText}>
          <Link {...link}
           className=""
           gaEventClass = {gaEventClick}
           gaEventLabel = {link?.label}
          >
            <Typography content={link.label} />
          </Link>
        </div>
      </div>
    </div>
  )
}
BackLink.propTypes = {
  link: PropTypes.object,
  variant: PropTypes.string,
}


export default BackLink
