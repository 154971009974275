export const defaultStyle = {
  ingredientsText: "flex flex-wrap text-[15px] ingredientsText mt-40px mb-20px bg-white  lg:w-[1170px] mx-auto py-20 opacity-[.4] text-left lg:max-w-[94%] px-[6%]",
  richTextContainerBlock:"cocoaWarper text-center md:max-w-full mx-auto my-0",
  richTextWrapper:"contentBody text-textBlack",
  title: "textTitleLine",
  lineOnSides:'textTitleSideLineRightLeft',
  titleWithLine:'textTitleWithLine',
  mainLink:'primaryBtn',
  targetParagraph:"fullWidthAutoParagraph",
  targetParagraphElement:"targetParagraphElement",
}


