import React, { useState, useEffect } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import PropTypes from "prop-types"
import ArticleFilter from "./ArticlesFilter/ArticleFilter"
import { deslashify } from "../../helpers/utils"
import { getSearchResults } from "../../adapters/algolia-adapter"
import ArticlesList from "./ArticlesList/ArticlesList"
import Button from "../UI/Button/Button"
import { getUrlValues } from "../../helpers/search"
import { updatePageUrl } from "../../helpers/search"
import * as styles from './ArticleListing-tw-styles'

const ArticleListing = props => {
  const { heading, filters = {}, pageSlug } = props
  const isBlogsPage = deslashify(pageSlug) === "/progress"
  let updatedFilter = {};
  let queryParameters = {}
  const [pageNo, setPageNo] = useState(0)
  const [onload, setOnLoad] = useState(true)
  const [filterOptions, setFilterOptions] = useState(updatedFilter)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResult, setSearchResult] = useState({})
  const [pageDetails, setPageDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [handleSubmitFlag, sethandleSubmitFlag] = useState(false)

  const { hits = [], nbHits, nbPages, hitsPerPage, page } = searchResult

  const fetchArticles = (optionFilter = null, searchInput = null, pageNo = null, isBlogsPage = null,) => {
    if (typeof window !== "undefined") {
      const obj = {
        pageType: "Article Detail Page",
        search: "",
        filters: {},
        pageNo,
      }
      if (isBlogsPage) {
        queryParameters = new URLSearchParams(window?.location?.search)
        const search = queryParameters.get('SearchText')
        const filter = queryParameters.get('Filter').replace(/\s/gi, ' ')
        obj.search = search === '*' ? '' : search
        obj.filters.docType = filter
      } else {
        obj.search = searchInput ? searchInput : searchTerm
        obj.filters = optionFilter ? optionFilter : filterOptions
      }
      setIsLoading(true)
      // get search results from Algolia
      getSearchResults(obj)
        .then(res => {
          setSearchResult(res)
          sethandleSubmitFlag(false);
          setIsLoading(false)
        })
        .catch(err => {
          console.log("ERROR", err)
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    (async () => {
      const values = await getUrlValues(filters)
      setSearchTerm(values?.search)
      setFilterOptions(values?.filter)
      setPageNo(values?.pageNo)
      await updatePageUrl({
        filters: values?.filter,
        search: values?.search,
        pageNo: values?.pageNo,
        isBlogsPage
      })
      await fetchArticles(values?.filter, values?.search, pageNo, isBlogsPage)
      setOnLoad(false)
    })()
  }, [])

  useEffect(() => {
    if(!onload){
      (async () => {
        if(typeof window !="undefined"){
          window?.scrollTo(0,0)
        }
        const values = await getUrlValues(filters)
        setSearchTerm(values?.search)
        await updatePageUrl({
          filters: filterOptions,
          search: searchTerm,
          pageNo: pageNo,
          isBlogsPage
        })
        await fetchArticles(null,null,pageNo,isBlogsPage)
      })();
    }
  }, [pageNo])

  useEffect(() => {
    (async () => {
      if (handleSubmitFlag) {
        await updatePageUrl({
          filters: filterOptions,
          search: searchTerm,
          pageNo: pageNo,
          isBlogsPage
        })
        await fetchArticles(null, null, pageNo, isBlogsPage)
      }
    })();
  }, [filterOptions])

  useDeepCompareEffect(() => {
    setPageDetails({
      minValue: pageNo * hitsPerPage + 1,
      maxValue: pageNo * hitsPerPage + hits?.length,
    })
  }, [hits])

  const onFilterChange = (e, option) => {
    const result = { ...filterOptions }
    result[option] = e.target.value
    setFilterOptions(result)
  }

  const clearFilters = async () => {
    sethandleSubmitFlag(true)
    setFilterOptions((prevState) => ({
      ...prevState,
      docType: "",
      focusAreas: "",
      region: "",
    }));
    await updatePageUrl({
      filters: filterOptions,
      search: searchTerm,
      pageNo: pageNo,
      isBlogsPage
    })
    await fetchArticles(null, null, pageNo, isBlogsPage)
  }

  const onSearchChange = e => {
    setSearchTerm(e.target.value)
  }



  if (isLoading) {
    return <div className='pt-50 mx-auto md:w-[960px] max-w-full text-left mb-56 px-15 md:px-30 text-48 font-alternate text-textBlack'>Loading...</div>
  }

  return (
    <div className={styles?.wrapper.containerWrapper}>
      <h1 className={`${isBlogsPage ? 'text-40 pl-10' : 'text-48 pl-15 md:pl-30'} leading-36 font-alternate text-textBlack pb-26 border-b border-darkGrey`}>{heading}</h1>
      {filters && (
        <ArticleFilter
          filterStyle={styles?.selectFilter}
          filters={filters}
          fetchArticles={fetchArticles}
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
          filterOptions={filterOptions}
          onFilterChange={onFilterChange}
          clearFilters={clearFilters}
          pageNo={page}
          handleSubmitFlag={handleSubmitFlag}
          sethandleSubmitFlag={sethandleSubmitFlag}
        />
      )}
      {!hits?.length ? (
        <div className="noResulstFound p-12 md:pl-30 text-17 text-textBlack font-brand font-bold border-b-[3px] border-darkGrey">
          <span>No Results Found</span>
        </div>
      ) : (
        <div className="searchResultsWrapper">
          {(hits?.length > 0 && !isBlogsPage) &&
          <div className='resulstFound p-12 md:pl-30 text-17 text-textBlack font-brand font-bold border-b-[3px] border-darkGrey'>
          <span>Currently showing: {nbHits} result(s)</span>
          </div>
          }
          {hits?.length > 0 && hits?.map((article, idx) => {
            return (
              <ArticlesList
                {...article}
                index={idx}
                contentStyle={styles?.contentStyle}
                containerStyle={styles?.containerStyle}
                isBlogsPage={isBlogsPage}
                key={`${"article-list-item"+ idx}`}
              />)
          })
          }
          <div className="flex justify-end p-2 pb-60 paginationBlock">
            <span className="italic mr-15 text-darkGrey text-14 font-medium py-8">
              {pageDetails?.minValue} - {pageDetails?.maxValue} / {nbHits}
            </span>
            <Button
              className={`${styles?.contentStyle?.buttonStyle} ${parseInt(pageNo) === 0 ? 'opacity-50 inactive':'active hover:bg-[#cbcbcb]'}`}
              disabled={parseInt(pageNo) === 0}
              onClick={() => {
                setPageNo(parseInt(pageNo) - 1)
              }}
            >
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </Button>

            <Button
              className={`${styles?.contentStyle?.buttonStyle} ${pageNo === parseInt(nbPages) - 1 ? 'opacity-50 inactive':'active hover:bg-[#cbcbcb]'}`}
              disabled={pageNo === parseInt(nbPages) - 1}
              onClick={() => {
                setPageNo(parseInt(pageNo) + 1)
              }}
            >
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

ArticleListing.propTypes = {
  heading: PropTypes.string,
  filters: PropTypes.object,
  pageSlug: PropTypes.string,
}

export default ArticleListing
