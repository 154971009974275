export const RichTextModelProps = {
  "name": "Rich Text homepage",
  "variant": "FOOT_NOTE",
  "title": "Our Mission and Vision",
  "richText": {
    "json": {
      "nodeType": "document",
      "data": {},
      "content": [{
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "",
              "marks": [],
              "data": {}
            },
            {
              "nodeType": "paragraph",
              "data": {},
              "content": [
                {
                  "nodeType": "text",
                  "value": "That's why our mission is to lead cocoa sector transformation",
                  "marks": [],
                  "data": {}
                }
              ]
            },
            {
              "nodeType": "paragraph",
              "data": {},
              "content": [
                {
                  "nodeType": "text",
                  "value": "Independent chef insight conducted by Good Sense Research, March 2020.",
                  "marks": [],
                  "data": {}
                }
              ]
            },
            {
              "nodeType": "embedded-entry-inline",
              "data": {
                "target": {
                  "sys": {
                    "id": "46xijX3yvtrzT6bNVARkF",
                    "type": "Link",
                    "linkType": "Entry"
                  }
                }
              },
              "content": []
            },
            {
              "nodeType": "text",
              "value": "That's why our mission is to lead cocoa sector transformation",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "That's why our mission is to lead cocoa sector transformation",
              "marks": [],
              "data": {}
            },
            {
              "nodeType": "embedded-entry-inline",
              "data": {
                "target": {
                  "sys": {
                    "id": "3dGbpkmeXuJAzYIqOGCjne",
                    "type": "Link",
                    "linkType": "Entry"
                  }
                }
              },
              "content": []
            },
            {
              "nodeType": "text",
              "value": "89% of chefs stated that Philadelphia Original did not split with heated in a pan. Independent blind tests conducted by Good Sense Research in July 2020 with 101 chefs.",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "",
              "marks": [],
              "data": {}
            },
            {
              "nodeType": "embedded-entry-inline",
              "data": {
                "target": {
                  "sys": {
                    "id": "2CITsdU55E2wa49wZB2XXR",
                    "type": "Link",
                    "linkType": "Entry"
                  }
                }
              },
              "content": []
            },
            {
              "nodeType": "heading-2",
              "data":[],
              "content": [{
                "nodeType": "text",
                "value": "text",
                "marks": [],
                "data": {}
              }]
            },
            {
              "nodeType": "table",
              "data": {},
              "content": [
                {
                  "nodeType": "table-row",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "table-header-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "Cookie Subgroup",
                              "marks": [
                                {
                                  "type": "bold"
                                }
                              ],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-header-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "Cookies",
                              "marks": [
                                {
                                  "type": "bold"
                                }
                              ],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-header-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "Cookies used",
                              "marks": [
                                {
                                  "type": "bold"
                                }
                              ],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "nodeType": "table-row",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "www.philadelphia.de",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/cookies/ASP.NET_SessionId"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "ASP.NET_SessionId ",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": ",",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/cookies/BNES_ASP.NET_SessionId"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "BNES_ASP.NET_SessionId ",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": ",",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/cookies/BNES_OptanonConsent"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "BNES_OptanonConsent ",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": ",",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/cookies/OptanonConsent"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "OptanonConsent ",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": ",",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "OptanonAlertBoxClosed ",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": ",",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/cookies/BNI_persistence"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "BNI_persistence",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "Erstanbieter",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "nodeType": "table-row",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/host/myfonts.net"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "myfonts.net",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "__cf_bm",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "Drittanbieter",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "nodeType": "table-row",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/host/cookielaw.org"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "cookielaw.org",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "__cfduid",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "Drittanbieter",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "nodeType": "table-row",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/host/.onetrust.com"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": ".onetrust.com",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "__cfduid",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "Drittanbieter",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "nodeType": "table-row",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            },
                            {
                              "nodeType": "hyperlink",
                              "data": {
                                "uri": "https://cookiepedia.co.uk/host/onetrust.com"
                              },
                              "content": [
                                {
                                  "nodeType": "text",
                                  "value": "onetrust.com",
                                  "marks": [],
                                  "data": {}
                                }
                              ]
                            },
                            {
                              "nodeType": "text",
                              "value": "",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "__cfduid",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-cell",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "Drittanbieter",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "nodeType": "unordered-list",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "list-item",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "automatically deleted after every visit (session cookies) or remaining in place during multiple visits (persistent cookies);",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "list-item",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "delivered in a first-party (set by us) or third-party (set by another website) context.",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "nodeType": "ordered-list",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "list-item",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "automatically deleted after every visit (session cookies) or remaining in place during multiple visits (persistent cookies);",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "list-item",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "paragraph",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "text",
                              "value": "delivered in a first-party (set by us) or third-party (set by another website) context.",
                              "marks": [],
                              "data": {}
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "data": {},
                  "content": [
                    {
                      "data": {},
                      "marks": [
                        {
                          "type": "code"
                        }
                      ],
                      "value": "Conserved and restored forests",
                      "nodeType": "text"
                    }
                  ],
                  "nodeType": "paragraph"
                },
                {
                  "data": {},
                  "content": [
                    {
                      "data": {},
                      "marks": [
                        {
                          "type": "subscript"
                        }
                      ],
                      "value": "Conserved and restored forests",
                      "nodeType": "text"
                    }
                  ],
                  "nodeType": "paragraph"
                },
                {
                  "data": {},
                  "content": [
                    {
                      "data": {},
                      "marks": [
                        {
                          "type": "superscript"
                        }
                      ],
                      "value": "Conserved and restored forests",
                      "nodeType": "text"
                    }
                  ],
                  "nodeType": "paragraph"
                },
              ]
            },
            {
              "nodeType": "text",
              "value": "93% of chefs stated during blind taste tests that they felt that Philadelphia Original was either creamy or very creamy. Independent research conducted by Good Sense Research in July 2020 with 101 chefs with 3 competitor products.",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "",
              "marks": [],
              "data": {}
            },
            {
              "nodeType": "embedded-entry-inline",
              "data": {
                "target": {
                  "sys": {
                    "id": "2LctiZ97AfVfBmfALmgl70",
                    "type": "Link",
                    "linkType": "Entry"
                  }
                }
              },
              "content": []
            },
            {
              "nodeType": "text",
              "value": "33% of chefs feel Philadelphia Original performed best out of 4 cream cheeses when used in an icing and piped onto a cake which is a majority share versus the other three competitors in the independent research conducted by Good Sense Research in July 2020 with 101 chefs.",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "",
              "marks": [],
              "data": {}
            },
            {
              "nodeType": "embedded-entry-inline",
              "data": {
                "target": {
                  "sys": {
                    "id": "aVlhKaeleDb0L96ZfqRnD",
                    "type": "Link",
                    "linkType": "Entry"
                  }
                }
              },
              "content": []
            },
            {
              "nodeType": "text",
              "value": "86% of chefs said Philadelphia Original's liquid release",
              "marks": [],
              "data": {}
            }
          ]
        }
      ]
    },
    "links": {
      "entries": {
        "inline": [
          {
            "sys": {
              "id": "46xijX3yvtrzT6bNVARkF"
            },
            "__typename": "SuperScript",
            "superScript": "1."
          },
          {
            "sys": {
              "id": "3dGbpkmeXuJAzYIqOGCjne"
            },
            "__typename": "SuperScript",
            "superScript": "2."
          },
          {
            "sys": {
              "id": "2CITsdU55E2wa49wZB2XXR"
            },
            "__typename": "SuperScript",
            "superScript": "3."
          },
          {
            "sys": {
              "id": "2LctiZ97AfVfBmfALmgl70"
            },
            "__typename": "SuperScript",
            "superScript": "4."
          },
          {
            "sys": {
              "id": "aVlhKaeleDb0L96ZfqRnD"
            },
            "__typename": "SuperScript",
            "superScript": "5."
          }
        ]
      }
    }
},
"link": {
  "label": "Learn more",
  "title": null,
  "url": "/the-program/approach/",
  "target": "_self",
  "scrollToElementId": null,
  "gaEventClass": null,
  "gaEventLabel": null
},
"style": {
    richTextWrapper: "text-left mb-20px lg:w-[972px] lg:my-[45px]  m-10 lg:mx-auto lg:my-[80px]  my-0 opacity-50 text-15"
}
}