export const defaultStyle = {
    ListWrapper: 'ListWrapper',
    listStyle: {
        articleListWrapper: 'articleListWrapper',
        articleWrapper:'articleWrapper'
    }
}

export const selectFilter = {
    select:'container-filter italic focus:outline-none w-full border-b border-darkGrey pt-3 pb-8 text-darkGrey font-medium relative z-20 cursor-pointer bg-transparent',
    clearFilterWrapper: 'clearFilterWrapper pt-3 pb-8 px-0 md:px-12 mt-[8px] float-right',
    clearFilterBtn: 'clearFilterBtn text-darkGrey italic font-medium',
    error: '',
    selectConatiner: 'selectWrapper w-full min-[901px]:w-[200px]',
    searchInputWrapper: 'searchInputWrapper w-full flex px-12 md:pl-30 mt-30 max-[901px]:pt-30 min-[901px]:flex min-[901px]:justify-start inline',
    form: 'inputWrapper',
    input: 'placeholder:font-medium focus:outline-none text-16 text-textBlack italic font-meduim px-10 py-6 border rounded-[5px] border-darkGrey w-[63vw] md:w-[600px] min-[901px]:w-[750px]',
    selectedOptionColor: 'text-black'
}

export const wrapper = {
    containerWrapper:'mainWrapper max-w-[960px] m-auto pt-50'
}

export const containerStyle = {
    blogWrapper:'blog-wrapper py-22 px-12 border-b border-darkGrey pointer hover:bg-[#e5e5e580] transition ease-in-out',
    linkContainer:'link-container flex'
}

export const contentStyle = {
    linkContainer:'link-container',
    resultTypeContainer:'resultTypeContainer max-[413px]:hidden inline-block m-auto bg-darkPink text-white max-[600px]:w-[58px] max-[600px]:h-[50px] max-[600px]:leading-[50px] max-[600px]:text-[32px] w-[90px] h-[90px] leading-[90px] text-center text-48 rounded-[50%] font-light',
    resultContent:'result-content inline-block w-full md:w-[83%] pl-12',
    resultMetaData:'result-meta-data',
    resultTypeText:'result-type-text font-bold text-textBlack',
    libraryBox:'library-box bg-gray text-white uppercase text-xs py-[2px] px-5 mr-5',
    resultDate:'result-date italic text-textBlack',
    textGreen:'textGreen text-lightGreen font-bold text-[17px] leading-[23.4px]',
    textGreenBig: 'textGreenBig text-lightGreen font-bold text-[19.2px] leading-[26.4px]',
    resultDescription:'result-description text-textBlack',
    resultReadMore:'resultReadMore mt-15 text-lightGreen font-bold text-17 leading-[30px] link arrowLink inline-block pr-[42px] bg-no-repeat bg-right',
    readMoreIcon: 'arrowIcon listingReadMoreIcon flex',
    buttonStyle : 'bg-footerGrey text-darkGrey w-43 h-43 text-20',
    resultDateSmallFont: "resultDateSmallFont text-[12.8px] text-textBlack"
}

export const noRecordStyle = {
    noRecordContainer: 'no-record-container'
}


