import React from "react"
import { screen, render, cleanup, fireEvent } from "@testing-library/react"
import Header from "./Header"
import { headerCollection } from "./Header.mock"
import "@testing-library/jest-dom"
import useScreenSize from "../../../hooks/useScreenSize"
import Navigation from "./Navigation/Mobile/Navigation"
import SubMenuMobile from "./Navigation/Mobile/SubMenu/SubMenu"
import SubMenuDesktop from "./Navigation/Desktop/SubMenu.js/SubMenu"

describe("render Header component", () => {
  beforeEach(() => {
    useScreenSize.mockReturnValue({
      isDesktop: true,
      isMobile: false,
      isTablet: false,
      isHandheld: false,
    })
    Object.defineProperty(window, "location", {
      writable: true,
      value: { assign: jest.fn() },
    })
    window.location = {
      pathname: "/the-program/approach/",
    }
    render(<Header getSubMenuStatus={jest.fn()} {...headerCollection} />)
  })
  afterEach(() => {
    cleanup()
  })
  xtest("render logo color", () => {
    const logoImage = screen.getByAltText("logo color")
    expect(logoImage.src).toContain("mondelez_logo_color")
  })
  xtest("render logo color", () => {
    const logoImage = screen.getByAltText("cocoalife-10years-logo")
    expect(logoImage.src).toContain("cocoalife-10years-logo")
  })
  xtest("render lIcon browse library", () => {
    const icon = screen.getByAltText("Icon browse library")
    expect(icon.src).toContain("icon-browse-library")
  })
  test("render Browse library menu item", () => {
    expect(screen.getByText("Browse library")).toBeInTheDocument()
  })
  test("render Subscribe to newsletter menu item", () => {
    expect(screen.getByText("Subscribe to newsletter")).toBeInTheDocument()
  })
  test("render search input", () => {
    const searchInput = screen.getByRole("textbox")
    expect(searchInput).toHaveAttribute("name", "search-box")
    fireEvent.keyDown(searchInput)
    fireEvent.change(searchInput)
  })
  test("render  select box  and Options item", () => {
    expect(screen.getByRole("combobox")).toBeInTheDocument()
    expect(screen.getByRole("option", { name: "English" })).toBeInTheDocument()
    expect(screen.getByRole("option", { name: "English" })).toHaveAttribute(
      "value",
      "en"
    )
  })
  test("render  10 Year menu item", () => {
    expect(screen.getByRole("link", { name: "10 Years" })).toBeInTheDocument()
    expect(screen.getByRole("link", { name: "10 Years" })).toHaveAttribute(
      "href",
      "/ten-stories-for-ten-years/"
    )
  })
  test("render  The Program menu item", () => {
    expect(
      screen.getByRole("link", { name: "The Program" })
    ).toBeInTheDocument()
    expect(screen.getByRole("link", { name: "The Program" })).toHaveAttribute(
      "href",
      "/the-program/approach/"
    )
  })
  test("render Impact menu item", () => {
    expect(screen.getByRole("link", { name: "Impact" })).toBeInTheDocument()
    expect(screen.getByRole("link", { name: "Impact" })).toHaveAttribute(
      "href",
      "/impact/"
    )
  })
  test("render In the cocoa Origins menu item", () => {
    expect(
      screen.getByRole("link", { name: "In the cocoa Origins" })
    ).toBeInTheDocument()
    expect(
      screen.getByRole("link", { name: "In the cocoa Origins" })
    ).toHaveAttribute("href", "/in-the-cocoa-origins/")
  })
  test("render Progress Blog menu item", () => {
    expect(
      screen.getByRole("link", { name: "Progress Blog" })
    ).toBeInTheDocument()
  })
  test("render Newsroom menu item", () => {
    expect(screen.getByRole("link", { name: "Newsroom" })).toBeInTheDocument()
    expect(screen.getByRole("link", { name: "Newsroom" })).toHaveAttribute(
      "href",
      "/newsroom/"
    )
  })
  test("render FAQ menu item", () => {
    expect(screen.getByRole("link", { name: "FAQ" })).toBeInTheDocument()
    expect(screen.getByRole("link", { name: "FAQ" })).toHaveAttribute(
      "href",
      "/faq/"
    )
  })
  test("render handleChange on select country menu item", () => {
    const select = screen.getByRole('combobox')
    fireEvent.change(select);
  })
})
jest.mock("../../../hooks/useScreenSize")

describe("Header component", () => {
  beforeEach(() => {
    useScreenSize.mockReturnValue({
      isMobile: true,
      isTablet: false,
      isHandheld: false,
    })
  })

  afterEach(() => {
    jest.resetAllMocks()
  })

  test("renders the mobile navigation when the screen width is less than 901 pixels", () => {
    const { getByTestId } = render(<Header />)
    const mobileNavigation = getByTestId("navigation-mobile")
    expect(mobileNavigation).toBeInTheDocument()
  })
  test("should toggle search when search icon is clicked", () => {
    const { getByTestId, queryByTestId } = render(<Navigation searchTerm="impact" />)
    const searchIcon = screen.getByTestId("search-icon")
    fireEvent.click(searchIcon)

    const searchInput = screen.getByTestId("search-input")
    expect(searchInput).toBeInTheDocument()
  })

})

describe("Submenu Mobile", () => {
  beforeEach(() => {
    useScreenSize.mockReturnValue({
      isMobile: true,
      isTablet: false,
      isHandheld: false,
    })
  })

  afterEach(() => {
    jest.resetAllMocks()
  })
  test("should render a list of navigation items", () => {
    const menuItems = [
      { url: "/impact", label: "Our Progress", target: "_self" },
      { url: "/impact", label: "Dashboard", target: "_self" },
      { url: "/impact", label: "KPIs", target: "_self" },
    ]

    const { getByText } = render(<SubMenuMobile menuItems={menuItems} />)
    const menuItem1 = getByText("Our Progress")
    const menuItem2 = getByText("Dashboard")
    const menuItem3 = getByText("KPIs")

    expect(menuItem1).toBeInTheDocument()
    expect(menuItem2).toBeInTheDocument()
    expect(menuItem3).toBeInTheDocument()
  })
})
describe("SubMenu Desktop", () => {
  beforeEach(() => {
    useScreenSize.mockReturnValue({
      isMobile: false,
      isTablet: false,
      isHandheld: false,
    })
  })
  afterEach(() => {
    jest.resetAllMocks()
  })
  const menuItems = [
    {
      url: "/the-program/approach/",
      label: "Approach",
      scrollToElementId: "Approach",
      target: "_self",
    },
    {
      url: "/the-program/approach/",
      label: "Key Areas",
      scrollToElementId: "focus-areas",
      target: "_self",
    },
  ]

  test("sets active item correctly", () => {
    render(<SubMenuDesktop menuItems={menuItems} />)
    expect(screen.getByText("Approach")).toHaveClass(
      "text-white px-0 text-[17px] font-ProxBold font-bold hover:text-black transition transition-all ease duration-[300ms] event_menu_click"
    )
  })
  test("sets active item correctly on scroll", () => {
    render(<SubMenuDesktop menuItems={menuItems} />)
    const programsElement = document.createElement("div")
    programsElement.id = "Approach"
    document.body.appendChild(programsElement)
    fireEvent.scroll(window, { target: { scrollY: 500 } })
    expect(screen.getByText("Approach")).toHaveClass(
      "text-white px-0 text-[17px] font-ProxBold font-bold hover:text-black transition transition-all ease duration-[300ms] event_menu_click"
    )
  })
})
describe("Navigation", () => {
  test("opens and closes mobile menu modal", () => {
    render(<Navigation />)
    fireEvent.click(screen.getByTestId("search-input"))
    expect(screen.getByText("Browse library")).toBeInTheDocument()
  })

  test("opens and closes mobile menu modal", () => {
    render(<Navigation navigation={headerCollection?.navigationCollection?.items}/>)
    fireEvent.click(screen.getByTestId("search-input"))
    expect(screen.getByText("Browse library")).toBeInTheDocument()
  })

  test("submitting search form with search term updates URL", () => {
    render(<Navigation />)
    const searchIcon = screen.getByTestId("search-icon")
    fireEvent.click(searchIcon)
    const searchInput = screen.getByPlaceholderText("What are you looking for?")
    fireEvent.change(searchInput, { target: { value: "test" } })
    fireEvent.keyPress(searchInput, { key: "Enter", code: 13, charCode: 13 })
    expect(window.location.href).toContain("https://www.cocoalife.org/")
  })
})






