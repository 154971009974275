import React from "react"
import PropTypes from "prop-types"
import ModalWrapper from "../../Wrappers/ModalWrapper/ModalWrapper"
import { labels } from "../../../constants/label.constants"

const VideoModal = props => {
  const { isModalOpen, closeVideoModal, isIframe, src, title } = props

  return (
    <ModalWrapper isModalOpen={isModalOpen} closeModal={closeVideoModal}>
      <div className={"relative w-770 h-400"}>
        {isIframe ? (
          <iframe
            // loading="lazy"
            src={`${src}&autoplay=1`}
            frameBorder="0"
            width="100%"
            height="100%"
            allowFullScreen
            title={title || "YouTube video player"}
            allow="autoplay; encrypted-media"
            />
        ) : (
          <video
            // poster={poster}
            controls
            autoPlay={true}
            // id={id}
            width={"100%"}
            height={"100%"}
            className={"video-player"}
          >
            <source src={src} type="video/mp4" />
            {labels?.videoNotSupported}
          </video>
        )}
      </div>
    </ModalWrapper>
  )
}

VideoModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeVideoModal: PropTypes.func.isRequired,
  isIframe: PropTypes.bool,
  src: PropTypes.string,
  title: PropTypes.string,
}

export default VideoModal
