import React, { useState } from "react"
import { screen, render, cleanup, fireEvent } from "@testing-library/react"
import FormContainer from "./FormContainer"
import { formContainer } from "./FormContainer.mock"
import "@testing-library/jest-dom"

describe("render FormContainer component", () => {
    beforeEach(() => {
        render(<FormContainer {...formContainer} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render heading", () => {
        expect(screen.getByText('THERE IS A FUTURE IN COCOA AND IT IS BEING PLANTED TODAY! FOLLOW OUR PROGRESS AND SIGN UP TO OUR NEWSLETTER.')).toBeInTheDocument()
    })
    test("test handleSubmit event ", () => {
        const button = screen.getByRole('button')
        fireEvent.click(button)
    })
    test("test handleChange event ", () => {
        const input = screen.getAllByRole('textbox')
        fireEvent.change(input[0])
    })
    test("test handleChange checkbox ", () => {
        const checkbox = screen.getByRole('checkbox')
        fireEvent.click(checkbox)
    })
    test("test render select Onchnage ", () => {
        const select = screen.getByRole('combobox', { name: 'Day' })
        fireEvent.change(select);
    })
    test("test handleChange event email ", () => {
        const input = screen.getByRole('textbox', {name: "Email"})
        fireEvent.blur(input);
        fireEvent.change(input, {target: {name: 'email', value:"vanu@gmail.com"}})
    })
})