import React from "react"

const InteractiveMap = props => {
  return (
    <div>
      <div
        className="resp-container"
        style={{
          position: "relative",
          overflow: "hidden",
          paddingTop: "56.25%",
        }}
      >
        <iframe
          className="resp-iframe"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
          }}
          src="https://editor.giscloud.com/rest/1/maps/1042067/render.iframe?popups=true"
          // eslint-disable-next-line react/no-unknown-property
          gesture="media"
          allow="encrypted-media"
          allowFullScreen={true}
        ></iframe>
      </div>
    </div>
  )
}

export default InteractiveMap
