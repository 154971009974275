import * as rules from "../constants/rules.constants"

export const validateRequired = value => !(!value || value.trim() === "")

export const validateCheckbox = value => !!value

export const validateEmail = value => {
  return !!new RegExp(/^\w+([\.%-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(
    value
  )
}

function handleReqType(validateField, rule) {
  validateField.error = !validateRequired(validateField.value)
    ? rule.errorMessage
    : ""
  validateField.isValid = validateField.error === "" ? true : false
}

function handleEmailRule(validateField, rule) {
  if (validateField.value !== "") {
    validateField.error = !validateEmail(validateField.value)
      ? rule.errorMessage
      : ""
    validateField.isValid = validateField.error === "" ? true : false
  }
}

function handleChekboxRule(validateField, rule) {
  validateField.error = !validateCheckbox(validateField.value)
    ? rule.errorMessage
    : ""
  validateField.isValid = validateField.error === "" ? true : false
}


export const validate = field => {
  const validateField = field
  if (validateField.rules && validateField.rules.length > 0) {
    validateField.rules.forEach(rule => {
      switch (rule.type) {
        case rules.REQUIRED:
          handleReqType(validateField, rule)
          break
        case rules.EMAIL:
          handleEmailRule(validateField, rule)
          break
        case rules.CHECKBOX:
          handleChekboxRule(validateField, rule)
          break
        default:
          break
      }
    })
  }

  return validateField
}

export const orderList = (list, actualList) => {
  Object.entries(list).map(([key]) => {
    list[key] = actualList[key]
    return list[key]
  })
}
