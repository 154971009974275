const query = (id, isPreview) => `{
  imageLayout(id: "${id}", preview: ${isPreview}) {
    heading
    description
    variant
    imagesCollection {
      items {
        gaEventClass
        gaEventLabel
        image {
          url
          title
          description
          width
          height
        }
        link {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
          }
        }
      }
    }
  }
}
`

module.exports = { query }
