const query = (id, isPreview) => `{
    articleListing(id: "${id}", preview: ${isPreview}) {
      heading
      filters {
        region
        focusAreas
        documentTypes
      }
    }
  }`

module.exports = { query }
