import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import Image from "../UI/Image/Image"
import useImage from "../../hooks/useImage"
import * as styles from "./ImageAndText-tw-styles"
const ImageAndText = props => {
  const { title = "", imageTextCollection = {} } = props
  const style = styles["defaultStyle"]
  return (
    <div>
      {title && <Typography content={title} className="" />}
      <ul>
        {imageTextCollection?.items?.length > 0 &&
          imageTextCollection?.items?.map(item => {
            const { imageCollection } = item
            return (
              <li key={item?.imageText}>
                {item?.imageText && <h2>{item?.imageText}</h2>}
                <ul>
                  {imageCollection?.items?.length > 0 &&
                    imageCollection?.items?.map(image => {
                      const { altText, foregroundImg } = useImage(image)
                      return (
                        <li key={image?.url}>
                          {foregroundImg && (
                            <Image
                              alt={altText}
                              contentfulImage={foregroundImg}
                              className=""
                            />
                          )}
                        </li>
                      )
                    })}
                </ul>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

ImageAndText.propTypes = {
  title: PropTypes.string,
  imageTextCollection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default ImageAndText
