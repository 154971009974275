import React from "react"
import PropTypes from "prop-types"
import { labels } from "../../constants/label.constants"
import SocialShare from "../UI/SocialShare/SocialShare"
import Link from "../UI/Link/Link"
import Typography from "../UI/Typography/Typography"
import * as styles from "./ArticleHeader-tw-styles"
import Video from "../UI/Video/Video"
import { textThemes } from "../../constants/variants.constants"
import { get } from "../../helpers/utils"
import useImage from "../../hooks/useImage"
import Image from "../UI/Image/Image"

const ArticleHeader = props => {
  const {
    articleTagName,
    heading,
    imagesCollection,
    publishedDate,
    readMoreTagsCollection,
    socialShareLinksCollection,
    subHeading,
    tagTextTheme,
    variant,
    video,
    pageSlug,
  } = props

  const { searchLibSlug, socialShareData = [] } = labels?.adpLabels
  const style = styles[variant] || styles.defaultStyle

  const socialShareTags = get(socialShareLinksCollection, "items", [])
  const images = get(imagesCollection, "items", [])
  const readMoreTags = get(readMoreTagsCollection, "items", [])

  // render read more on tags
  const renderReadmoreTags = () => {
    return (
      <ul className={style.articleTagList}>
        {readMoreTags.map((tag, key) => {
          const url = new URL(`${process.env.GATSBY_URL}${searchLibSlug}`)
          url.search = `SearchText=*&Filter=${tag?.tagName}`
          return (
            <li key={key} className={style.articleTagListItem}>
              <Link
                className={style.articleTagLink}
                url={url.pathname + url.search}
              >
                {tag?.tagName}
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }

  // article social share
  const renderSocialLinks = () => {
    const shareLinks = socialShareData?.filter(link =>
      socialShareTags?.find(tag => tag?.tagName === link?.linkType)
    )
    return (
      <ul className={style.socialShareList}>
        {shareLinks.map((item, idx) => {
          return (
            <li key={idx} className={style.socialShareListItem}>
              <SocialShare
                linkType={item?.linkType}
                hashTag={item?.hasTag}
                pageSlug={pageSlug}
                pageHeading={heading}
                className={item?.className}
              />
            </li>
          )
        })}
      </ul>
    )
  }

  // render brand Images
  const renderImages = () => {
    return (
      <ul className={style.renderImagesList}>
        {images.map((image, idx) => {
          const { altText, foregroundImg } = useImage(image)

          if (!foregroundImg) {
            return null
          }

          return (
            <li className={style.renderImagesListItem} key={idx}>
              <Image contentfulImage={foregroundImg} alt={altText} />
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className={style.articleMainContainer}>
      <div className={style.articleSubContainer}>
        <div className={style.articleLeftDiv}>
          {articleTagName && (
            <Typography
              content={articleTagName}
              className={`${textThemes[tagTextTheme]} ${style.articleHeading}`}
            />
          )}
          {heading && (
            <Typography className={style.articleHeadingBig} content={heading} />
          )}
          {publishedDate && (
            <Typography
              content={publishedDate}
              className={style.articlepublishedDate}
            />
          )}
          {video && (
            <div className={style.articleVideoContainer}>
              <div className={style.articleVideoSub}>
                <Video {...video} />
              </div>
            </div>
          )}
          {subHeading && <Typography content={subHeading} className={style.articlesubHeading}/>}
        </div>
        <div className={style.articleRightDiv}>
          <div className={style.socialShareDiv}>
            {!!socialShareTags?.length && renderSocialLinks()}
          </div>
          {!!images?.length && <div className={style.brandImgDiv}>{renderImages()}</div>}

          {/* Read more on tags */}
          <div className={style.readMoreContainer}>
            {!!readMoreTags?.length && (
              <Typography
                className={style.readMoreText}
                content={labels?.adpLabels?.tagsText}
              />
            )}
            {!!readMoreTags?.length && renderReadmoreTags()}
          </div>
        </div>
      </div>
    </div>
  )
}

ArticleHeader.propTypes = {
  articleTagName: PropTypes.string,
  heading: PropTypes.string,
  imagesCollection: PropTypes.object,
  publishedDate: PropTypes.string,
  readMoreTagsCollection: PropTypes.object,
  socialShareLinksCollection: PropTypes.object,
  subHeading: PropTypes.string,
  tagTextTheme: PropTypes.string,
  variant: PropTypes.string,
  video: PropTypes.object,
  pageSlug: PropTypes.string,
}

export default ArticleHeader
