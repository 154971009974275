import React from "react"
import { screen, render, cleanup, fireEvent} from "@testing-library/react"
import ImageCardsLayout from "./ImageCardsLayout"
import { imageCardsLayout, FIVE_COLUMN_CARDS_LAYOUT } from "./ImageCardsLayout.mock"
import "@testing-library/jest-dom"

describe("render ImageCardsLayout component", () => {
    beforeEach(() => {
        render( <ImageCardsLayout {...imageCardsLayout} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render ImageCardsLayout title", () => {
        expect(screen.getByText('2017 YEAR IN REVIEW')).toBeInTheDocument()
    })
    test("render ImageCardsLayout text", () => {
        expect(screen.getByText('MONTHLY ACTIVITES')).toBeInTheDocument()
    })
    test("render ImageCardsLayout cardsCollection ", () => {
        expect(screen.getByText('MONTHLY ACTIVITES')).toBeInTheDocument()
    })
    test("render ImageCardsLayout cardsCollection title", () => {
        expect(screen.getByText('Q1')).toBeInTheDocument()
        expect(screen.getByText('Q2')).toBeInTheDocument()
    })
    test("render ImageCardsLayout cardsCollection text", () => {
        expect(screen.getByText('IPSOS PROVIDES THIRD-PARTY VERIFICATION')).toBeInTheDocument()
        expect(screen.getByText('APRIL 19, 2017 – BUSINESS SKILLS GAINED IN INDONESIA')).toBeInTheDocument()
    })
    test("render FIVE_COLUMN_CARDS_LAYOUT title", () => {
        cleanup()
        render( <ImageCardsLayout {...FIVE_COLUMN_CARDS_LAYOUT} />)
        const Q1 = screen.getByText('Q1')
        expect(Q1).toBeInTheDocument()
        fireEvent.mouseEnter(Q1)
        fireEvent.mouseLeave(Q1)
        const Q5 = screen.getByText('Q5')
        fireEvent.mouseEnter(Q5)
        fireEvent.mouseLeave(Q5)
    })

})