export const textContent = {
    "title": "title",
    "subTitle": "Marker meanings",
      "markerTextCollection": {
        "items": [
          {
            "imageText": "Cocoa Life presence",
            "image": {
              "altText": "Cocoa Life presence",
              "imageDesktop": {
                "url": "https://images.ctfassets.net/qggsjlmpzfmx/fhrjWTlSxT2REPtqc4fes/ca1da1b5571183a87a2fc96a37574dab/pin_cl_presence_LEGEND.jpg",
                "title": "pin cl presence LEGEND",
                "width": 68,
                "height": 84,
                "description": null
              },
              "imageMobile": {
                "url": "https://images.ctfassets.net/qggsjlmpzfmx/fhrjWTlSxT2REPtqc4fes/ca1da1b5571183a87a2fc96a37574dab/pin_cl_presence_LEGEND.jpg",
                "title": "pin cl presence LEGEND",
                "width": 68,
                "height": 84,
                "description": null
              }
            }
          },
          {
            "imageText": "Cocoa farms",
            "image": {
              "altText": "Cocoa farms",
              "imageDesktop": {
                "url": "https://images.ctfassets.net/qggsjlmpzfmx/68Zh9wE6lrAYnJz2yRZb1u/0ea060857569fcd0689db9e1f2aa6bae/pin_cocoafarm_LEGEND.jpg",
                "title": "pin cocoafarm LEGEND",
                "width": 68,
                "height": 86,
                "description": null
              },
              "imageMobile": {
                "url": "https://images.ctfassets.net/qggsjlmpzfmx/68Zh9wE6lrAYnJz2yRZb1u/0ea060857569fcd0689db9e1f2aa6bae/pin_cocoafarm_LEGEND.jpg",
                "title": "pin cocoafarm LEGEND",
                "width": 68,
                "height": 86,
                "description": null
              }
            }
          }
        ]
      },
    "shortDescription": "YOUTH ENTERPRISE",
    "shortDescriptionSecondary": "shortDescriptionSecondary",
    "variant": "TEXT_WITH_GREEN_BG"
  }
