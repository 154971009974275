export const defaultStyle = {
  aVContainer: "w-full",
  aVHeading: "font-bold text-2xl pb-20",
  aVDescription: "pb-20 text-center",
  aVcontent: "aVcontent w-full lg:w-2/5 mx-auto pt-40 text-center ",
  aVideo: "aVideo full mx-auto relative",
}

export const FULL_WIDTH_VIDEO_WRPR = {
  contentWrapper: "contentWrapper w-full border-white border-b-2",
  aVContainer:
    "approchPage aVContainer max-w-full w-full table flex-wrap justify-center text-center max-w-full flex flex-col-reverse",
  contentReversed: "flex flex-col-reverse",
  aVcontentHeading:
    "aVcontentHeading text-darkBlue text-[40px] mt-50 mb-20 flex justify-center",
  aVDescription: "aVDescription rwast ",
  aVcontent: "videoText",
  description:
    "description text-40 mb-20 lg:mb-40 px-20 lg:px-auto [&>h3]:leading-[50px]  [&>h2]:leading-[50px]",
  videoframe: "h-full w-full relative pt-[30px] pb-[54.15%]",
  videoItem: "h-full w-full max-w-full top-0 left-0 absolute",
  videoCntr: "videocontainer relative max-w-full",
}

export const HALF_WIDTH_VIDEO_LINK_WRAPPER_GREEN_THEME = {
  aVContainer: "w-full videoWrapper w-full md:w-8/12 flex flex-col-reverse",
  aVHeading: "font-bold text-2xl pb-20",
  aVDescription: "pb-20 text-center",
  aVcontent: "aVcontent w-full pt-40 text-center",
  aVideo: "aVideo full mx-auto relative",
  contentWrapper: "contentWrapper cocoaWarper px-16 md:px-30",
  aVcontentHeading:
    "aVcontentHeading titleIcon font-alternate text-48 text-left leading-[43.2px] text-lightGreen mb-16",
  videoframe: "h-full w-full relative pt-[30px] pb-[54.15%]",
  videoItem: "h-full w-full max-w-full top-0 left-0 absolute",
  videoCntr: "videocontainer relative max-w-full",
}

export const HALF_WIDTH_VIDEO_LINK_WRAPPER_ORANGE_THEME = {
  aVContainer: "w-full videoWrapper w-full md:w-8/12 flex flex-col-reverse",
  aVHeading: "font-bold text-2xl pb-20",
  aVDescription: "pb-20 text-center",
  aVcontent: "aVcontent w-full pt-40 text-center",
  aVideo: "aVideo full mx-auto relative",
  contentWrapper: "contentWrapper cocoaWarper px-16 md:px-30",
  aVcontentHeading:
    "aVcontentHeading titleIcon font-alternate text-48 text-left leading-[43.2px] text-orange mb-16",
  videoframe: "h-full w-full relative pt-[30px] pb-[54.15%]",
  videoItem: "h-full w-full max-w-full top-0 left-0 absolute",
  videoCntr: "videocontainer relative max-w-full",
}

export const HALF_WIDTH_VIDEO_LINK_WRAPPER_YELLOW_THEME = {
  aVContainer: "w-full videoWrapper w-full md:w-8/12 flex flex-col-reverse",
  aVHeading: "font-bold text-2xl pb-20",
  aVDescription: "pb-20 text-center",
  aVcontent: "aVcontent w-full pt-40 text-center",
  aVideo: "aVideo full mx-auto relative",
  contentWrapper: "contentWrapper cocoaWarper px-16 md:px-30",
  aVcontentHeading:
    "aVcontentHeading titleIcon font-alternate text-48 text-left leading-[43.2px] text-yellow mb-16",
  videoframe: "h-full w-full relative pt-[30px] pb-[54.15%]",
  videoItem: "h-full w-full max-w-full top-0 left-0 absolute",
  videoCntr: "videocontainer relative max-w-full",
}

export const HALF_WIDTH_VIDEO_LINK_WRAPPER_BURGUNDY_THEME = {
  aVContainer: "w-full videoWrapper w-full md:w-8/12 flex flex-col-reverse",
  aVHeading: "font-bold text-2xl pb-20",
  aVDescription: "pb-20 text-center",
  aVcontent: "aVcontent w-full pt-40 text-center",
  aVideo: "aVideo full mx-auto relative",
  contentWrapper: "contentWrapper cocoaWarper px-16 md:px-30",
  aVcontentHeading:
    "aVcontentHeading titleIcon font-alternate text-48 text-left leading-[43.2px] text-redVelvet mb-16",
  videoframe: "h-full w-full relative pt-[30px] pb-[54.15%]",
  videoItem: "h-full w-full max-w-full top-0 left-0 absolute",
  videoCntr: "videocontainer relative max-w-full",
}

export const HALF_WIDTH_VIDEO_LINK_WRAPPER_PINK_THEME = {
  aVContainer: "w-full videoWrapper w-full md:w-8/12 flex flex-col-reverse",
  aVHeading: "font-bold text-2xl pb-20",
  aVDescription: "pb-20 text-center",
  aVcontent: "aVcontent w-full pt-40 text-center",
  aVideo: "aVideo full mx-auto relative",
  contentWrapper: "contentWrapper cocoaWarper px-16 md:px-30",
  aVcontentHeading:
    "aVcontentHeading titleIcon font-alternate text-48 text-left leading-[43.2px] text-darkPink mb-16",
  videoframe: "h-full w-full relative pt-[30px] pb-[54.15%]",
  videoItem: "h-full w-full max-w-full top-0 left-0 absolute",
  videoCntr: "videocontainer relative max-w-full",
}

export const HALF_WIDTH_VIDEO_WITH_TEXT = {
  aVContainer: "w-full videoWrapper w-full md:w-8/12 flex flex-col-reverse",
  aVHeading: "font-bold text-2xl pb-20",
  aVDescription: "pb-20 text-center",
  aVcontent: "aVcontent w-full pt-16 text-center",
  aVideo: "aVideo full mx-auto relative",
  contentWrapper: "contentWrapper cocoaWarper px-16 md:px-30",
  aVcontentHeading:
    "aVcontentHeading font-brand text-17 text-left leading-[1.375] text-lightGreen mb-16",
  videoframe: "h-full w-full relative pt-[30px] pb-[54.15%]",
  videoItem: "h-full w-full max-w-full top-0 left-0 absolute",
  videoCntr: "videocontainer relative max-w-full",
}

export const HALF_WIDTH_VIDEO_WITH_TEXT_DOWN = {
  ...HALF_WIDTH_VIDEO_WITH_TEXT,
  aVContainer: "w-full videoWrapper w-full md:w-8/12",
  aVcontent: "aVcontent w-full pt-32 text-left text-textBlack",
  contentWrapper: "contentWrapper cocoaWarper px-16 md:px-30 pb-40"
}