export const defaultStyle = {
  imgTxtContainer:
    "flex flex-wrap lg:flex-nowrap items-center justify-between max-w-full pb-16 my-0 mx-auto relative w-[650px]  zMinus",
  imgWrapper: "lg:mx-[20px] lg:my-auto mx-auto mt-0 mb-10",
  textImg: "w-full min-w-[300px] min-h-[200px]",
  textContent:
    "text-lg leading-7 text-center w-[354px] m-w-[354px] text-[#7f7f7f] mx-auto my-auto",
  textHead: "text-[#00358e] font-bold	mx-auto",
  textDesc: "",
  textLink: "",
  imgBtn: "",
}




export const QUOTE_CONTAINER = {
  imgTxtContainer:
    "lg:flex flex-wrap lg:flex-nowrap lg:bg-transparent bg-darkBlue items-center rounded-[20px]  justify-between max-w-full my-0 mx-auto relative",
  wrapper: "wrapper lg:max-w-full lg:w-[1170px] lg:mx-auto mx-10 md:mx-30 md:mt-80 mt-30",
  imgWrapper: "QUOTE_CONTAINER lg:mx-[15px] lg:my-auto mx-auto mt-0 mb-10 md:mb-0 w-full flex",
  textImg:
    "lg:rounded-[20px] w-full lg:h-full lg:scale-[1] md:scale-[1.5] relative lg:static left-[-50%] md:left-[-25%] lg:left[auto] scale-[2] top-[23%] md:top-[15%] lg:top-[0]",
  textContent:
    "my-0 mx-auto px-0 py-8 relative text-white w-full bg-darkBlue lg:bg-transparent lg:absolute lg:text-white lg:width-1/2 lg:top-[15%] lg:left-[10%] text-3xl lg:w-[400px] lg:text-left text-center",
  textHead: "",
  textDesc:
    "text-white md:text-[30px] text-[21px] lg:w-[319px] md:leading-[33px] lg:h-auto px-[12px] md:px-[0] h-[260px] leading-[26px] text-center lg:text-left lg:ml-[133px] lg:mb-20",
  textLink: "textLink",
  imgBtn: "",
  subimgWrpr:
    " rounded-t-[20px] overflow-hidden h-[388px] md:h-[600px] lg:h-auto lg:min-h-[600px]",
  textWrpr:
    "lg:absolute lg:top-[100px] z-[2] md:w-[500px] md:mx-auto md:pb-10 md:pt-0 pb-0 ",
  gradBg:
    "lg:bg-gradBgLetToRigth  absolute w-[700px] lg:h-full rounded-l-[20px] z-[1]",
}

export const TEXT_LISTING = {
  textWrpr:
    "textWrpr lg:w-[1170px] lg:mx-auto mx-15 lg:mt-70 lg:mb-50 mt-20 max-w-full",
  textHead:
    "textHead lg:text-[40px] font-ProxNarrow flex justify-center lg:mb-30 text-[32px] mb-[7px] leading-[50px]",
  textDesc:
    "textDesc philiyFiveTxt lg:w-[750px] w-full mx-auto flex flex-wrap text-[18px] mb-[20px] px-15 tracking-[0]",
}
export const QUOTE_WITH_TEXT = {
  imgTxtContainer:
    " QUOTE_WITH_TEXT lg:top-[-9rem] lg:left-[116px] flex flex-wrap lg:flex-nowrap items-center justify-between max-w-full pb-16 my-0 mx-auto absolute w-full lg:px-[0px] px-[15px] lg:w-[319px] z-[2]",
  wrapper:
    "wrapper QUOTE_WITH_TEXT lg:max-w-[1170px] mx-auto  relative top-[-140px] lg:top-[-120px]",
  imgWrapper: "lg:mx-[20px] lg:my-auto mx-auto mt-0 mb-10 w-full flex w-[70px]",
  textImg:
    "lg:rounded-[20px] rounded-t-[20px] w-full md:h-[70px] md:w-[70px] lg:h-[50px] lg:w-[50px] h-[70px] w-[70px]",
  textContent:
    "my-0 mx-auto px-0 py-8 relative text-white w-full bg-darkBlue lg:bg-transparent lg:absolute lg:text-white lg:width-1/2 lg:top-[15%] lg:left-[10%] text-3xl lg:w-[400px] lg:text-left text-center",
  textHead: "text-white text-[30px] sdfsf",
  textDesc:
    "textDesc lg:w-3/4 md:w-[30%] w-[70%] lg:w-auto mx-auto flex  leading-[21px] text-center justify-center lg:text-left",
  textLink: "textLink",
  textWrpr:
    "absolute lg:top-[0px] text-white text-[20px] lg:w-[255px] w-full lg:ml-[80px] lg:mt-0 mt-[120px] left-0",
}
export const authorContent = {
  imgTxtContainer:
    "lg:flex my-auto mx-auto lg:absolute w-[28%] lg:bottom-[30%] lg:left-[10%] py-20",
  imgWrapper: "w-[70px] lg:mr-[15px] my-0 mx-auto",
  textImg: "",
  textContent: "text-white text-md lg:text-left text-center",
  textHead: "",
  textDesc:
    "text-white absolute text-[15px]  top-[-30px] px-auto w-full text-center",
  textLink: "",
  imgBtn: "",
}

export const blueBgImageGrid = {
  imgTxtContainer: "imgTxtContainer text-center",
  imgWrapper:
    "h-min overflow-hidden rounded-[2rem] borderActivepx-auto text-sm tracking-wide text-darkBlue font-medium before:pr-5 w-[195px] grid mx-auto",
  textImg: " ",
  textContent: "relative w-full px-auto",
  textHead:
    "text-xl  absolute top-[-52px] text-white px-auto w-full text-center'",
  textDesc: "",
  textLink: "flex px-auto",
  link: "link",
  imgBtn: "",
}

export const rangeCard = {
  imgTxtContainer:
    "bg-darkBlue rounded-[20px] items-center justify-between max-w-full my-0 mx-auto relative pb-25",
  wrapper: "",
  imgWrapper: " mx-auto mt-0 mb-10 pb-40",
  textImg:
    "rounded-[2rem]  hover:scale-[1.05] transition-all duration-1000 mx-auto ",
  textContent:
    "text-lg leading-7 text-center w-[354px] m-w-[354px] text-[#7f7f7f] mx-auto my-auto relative",
  textHead:
    "text-white absolute text-2xl  bottom-[100px] px-auto w-full text-center ",
  textDesc: "",
  imgBtn:
    "min-h-[50px] leading-39 text-white w-[300px] rounded-100 border-2 border-white absolute bottom-[25px] left-[5%] hover:bg-white hover:text-darkBlue animButton",
  textLink: "",
}

export const SLIDER_TEXT = {
  imgTxtContainer:
    "lg:px-auto md:w-[720px] w-[360px] lg:min-h-446  mx-auto float-none clear-both relative transition-all max-w-full",
  imgWrapper: "",
  textImg: "rounded-20 md:h-auto h-[360px]  w-auto ",
  textContent: "",
  textHead: "",
  textDesc: "",
  textLink: "",
  imgBtn: "",
  textWrpr:
    "text-center absolute left-0 bottom-0 w-full pt-[90px] pb-[21px] px-10 lg:text-[31px] text-[21px] lg:leading-10 text-white rounded-b-20 bg-videoGradient",
}
export const LEFT_QUOTE_CONTAINER = {
  imgTxtContainer:
    " lg:w-[1170px] flex flex-wrap flex-row-reverse lg:flex-nowrap items-center justify-between max-w-full my-0 mx-auto relative md:p-0 p-8",
  imgWrapper:
    "lg:mx-[20px] lg:my-auto mx-auto mt-0 mb-10 lg:w-[45%] flex items-center md:px-30 lg:px-0",
  textImg: "w-full ",
  textContent:
    "text-lg leading-7 text-center w-[354px] m-w-[354px] text-[#7f7f7f] mx-auto my-auto",
  textWrpr: "md:w-[460px] md:max-w-full mx-auto text-center p-8 lg:mb-0 mb-30 leading-[46.8px]",
  textHead: "text-[#00358e] font-ProxBold text-[30px] md:text-[39px] mb-20 font-bold mx-auto md:child:leading-[46.8px] child:leading-[36px]",
  textDesc: "description text-[#7f7f7f] text-[17px] md:text-[23px] text-[17px] mb-20 md:leading-[32.2px] leading-[23.8px]",
  textLink: "text-sm buttonEffect2 recipeBtn",
  imgBtn: "",
  bgImg: "absolute w-full",
  wrapper:"bg-no-repeat bg-left-top bg-[length:100%_100%]"
}
export const ERROR_CONTAINER={
  imgTxtContainer: "",
  imgWrapper: "",
  textImg: "",
  textWrpr:"mx-auto md:w-[437px] px-12 md:px-0 text-center",
  textContent:
    "text-lg leading-7 text-center w-[354px] m-w-[354px] text-[#7f7f7f] mx-auto my-auto",
  textHead: "md:text-[30px] text-[60px] tracking-[1px] md:leading-[30px] leading-[60px] my-15",
  textDesc: "text-[20px] leading-[28px] my-15",
  textLink: "uppercase font-ProxBold bg-white border-solid border-2 border-darkBlue block text-[18.75px] leading-[26.25px] mx-auto py-15 px-20 text-center w-[296px] h-[58px] max-w-[90%] rounded-[30px] my-15 hover:border-darkBlue hover:border-2 hover:text-white hover:bg-darkBlue transition duration-500 ease-in-out",
  imgBtn: "",
  wrapper:"pt-[85px] md:pb-[250px] pb-[50px] min-h-full relative top-[-70px] bg-cover bg-no-repeat bg-left-top"
}

export const TEXT_WITH_WHITE_BG = {
  wrapper:"",
  imgTxtContainer:"text-white bg-darkBlue lg:w-[945px] md:w-[440px] rounded-20 bg-white md:mx-auto p-25 mx-10",
  imgWrapper: "hi",
  textImg: "rounded-20 md:h-auto h-[360px]  w-auto ",
  textContent: "1",
  textHead: "text-[40px] text-darkBlue mt-5 text-center w-full font-ProxBold lg:mx-auto md:px-15 leading-[49px] ",
  textDesc: "text-[25px] flex w-full px-auto mx-auto text-center justify-center leading-30 lg:px-10 md:px-0 tracking-[0] mt-15 mb-20 lg:w-[750px] text-[#7f7f7f] formSubmitCard",
  textLink: "before:bg-downloadIcon before:bg-no-repeat before:bg-left-top before:bg-[length:100%_100%] before:inline-block before:mr-10 before:relative before:top-6 before:w-24 before:h-24 downloadBtn",
  imgBtn: "5",
  textWrpr: "",
}

export const IMAGE_ONLY ={
 ...defaultStyle,
 wrapper: "imageOnlyWrapper",
 imgTxtContainer:
 "cocoaWarper px-15 min-[567px]:px-30 flex flex-wrap lg:flex-nowrap items-center justify-between max-w-full pb-16 my-0 mx-auto relative w-[650px] zMinus",
 imgWrapper: "mt-0 mb-16 test w-full md:max-w-[580px]",
 textWrpr: "textWrpr w-full text-textBlack leading-[1.375] font-brand text-[17px] font-normal max-w-[585px]"
}

export const LEFT_IMAGE_RIGHT_TEXT = {
  wrapper: "image-text-wrpr text-textBlack",
  imgTxtContainer: "imgTxtContainer mx-auto w-full min-[960px]:w-[960px] table ",
  imgWrapper: "imgWrapper w-full md:w-4/12 px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px] md:table-cell md:max-w-[320px] ml-auto md:border-white md:border-r-1",
  subimgWrpr: "subimgWrpr",
  textContent: "textContent",
  // textImg: "w-full",
  textWrpr: "textWrpr w-full md:w-8/12 md:table-cell align-middle md:border-white md:border-l-1",
  textDesc: "textDesc md:max-w-[640px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px]",
}

export const LEFT_3COLUMN_IMAGE_RIGHT_TEXT = {
  wrapper: "three-col-img-wrpr text-textBlack mx-auto w-full min-[960px]:w-[960px] px-[15px] md:px-[30px]",
  imgTxtContainer: "imgTxtContainer max-w-[590px] flex justify-between items-center my-[32px]",
  imgWrapper: "imgWrapper min-w-[100px] w-[23%]",
  subimgWrpr: "subimgWrpr",
  textContent: "textContent",
  // textImg: "w-full",
  textWrpr: "textWrpr w-[76%] leading-[0.9] uppercase font-alternate text-[40px] font-normal"
}

export const LEFT_3COLUMN_IMAGE_RIGHT_TEXT_HIDE_MOB_IMG = {
  ...LEFT_3COLUMN_IMAGE_RIGHT_TEXT,
  wrapper: "three-col-img-wrpr text-textBlack mx-auto w-full min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px]",
  imgWrapper: "imgWrapper min-w-[100px] w-[23%] max-md:hidden",
  textWrpr: "textWrpr w-[76%] max-md:w-full leading-[0.9] uppercase font-alternate text-[40px] font-normal"
}

export const FULL_WIDTH_IMAGE_ONLY ={
  ...defaultStyle,
  wrapper: "fullWidthimgWrapper",
  imgTxtContainer:
  "cocoaWarper px-30 flex flex-wrap lg:flex-nowrap items-center justify-between max-w-full pb-16 my-0 mx-auto relative  zMinus",
  imgWrapper: "mt-0 mb-16 test w-full ",
 }
 export const INTERACTIVE_MAP ={
  ...defaultStyle,
  wrapper: "fullWidthimgWrapper bg-footerGrey",
  imgTxtContainer:
  "cocoaWarper px-30 flex flex-wrap lg:flex-nowrap items-center justify-between max-w-full pb-16 my-0 mx-auto relative  zMinus",
  imgWrapper: "mt-0 mb-16 test w-full ",
 }
 export const TOP_IMAGE_BOTTOM_TEXT ={
  wrapper: "three-col-img-wrpr text-textBlack mx-auto w-full min-[960px]:w-[960px] px-[15px] md:px-[30px]",
  imgTxtContainer: "imgTxtContainer md:w-6/12 mb-[32px]",
  imgWrapper: "imgWrapper",
  subimgWrpr: "subimgWrpr",
  textContent: "textContent",
  // textImg: "w-full",
  textWrpr: "textWrpr leading-[1.375] font-brand text-[17px] font-normal"
 }
export const IMAGE_ONLY_MAX_WIDTH_510PX = {
    textWrpr: "w-[345px] min-[1025px]:w-[510px] mt-[16px] pb-0",
    imgTxtContainer: "cocoaWarper px-15 min-[567px]:px-30",
}

export const IMAGE_TEXT_MAX_WIDTH_580PX = {
  ...defaultStyle,
  wrapper: "imageTextWrapper",
  imgTxtContainer:
  "cocoaWarper px-15 min-[567px]:px-30 flex flex-wrap lg:flex-nowrap items-center justify-between max-w-full pb-16 my-0 mx-auto relative w-[650px] zMinus",
  imgWrapper: "mt-0 mb-0 test w-full md:max-w-[580px]",
  textWrpr: "textWrpr w-full text-textBlack text-[14.4px] w-full italic leading-[1.375] font-brand font-normal max-w-[585px]"
}