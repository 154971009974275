import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { buildDropdownOptions } from "../../../helpers/search"
import { labels } from "../../../constants/label.constants"
import Select from "../../UI/Input/Select"
import SearchInput from "../../UI/SearchInput/SearchInput"
import Button from "../../UI/Button/Button"
import { updatePageUrl } from "../../../helpers/search"
import { searchLibraryGtm } from "../../../constants/gtm.constants"

const ArticleFilter = props => {
  const {
    filters,
    fetchArticles,
    searchTerm,
    onSearchChange,
    filterOptions,
    onFilterChange,
    clearFilters,
    pageNo,
    handleSubmitFlag,
    sethandleSubmitFlag,
    filterStyle
  } = props

  const { documentTypes, focusAreas, region } = filters
  const { defaultOptions, searchPlaceHldr } = labels?.alpLabels

  const documentTypeOptns = buildDropdownOptions(
    documentTypes?.sort(),
    defaultOptions?.docTypes
  )

  const focusAreasOptns = buildDropdownOptions(
    focusAreas?.sort(),
    defaultOptions?.focusAreas
  )

  const regionsOptions = buildDropdownOptions(region?.sort(), defaultOptions?.region)

  const handleSubmit = async () => {
    if (typeof window !== "undefined" && searchTerm) {
      //data layer push
      if (window?.dataLayer) {
        searchLibraryGtm.GAeventLabel = searchTerm
        window.dataLayer.push({ ...searchLibraryGtm })
      }
    }
  sethandleSubmitFlag(true)
    await fetchArticles(null,null,pageNo,false)
    await updatePageUrl({
      filters: filterOptions,
      search: searchTerm,
      pageNo: pageNo,
    })
  }

  return (
    <div className="librarySearchFilter">
      <div className="min-[901px]:flex min-[901px]:justify-around inline">
        <form className="block w-full min-[901px]:contents px-15">
          <Select
            style={filterStyle}
            options={regionsOptions}
            onChange={e => {
              onFilterChange(e, "region")
            }}
            selectedValue={filterOptions?.region}
          />
          <Select
            style={filterStyle}
            options={focusAreasOptns}
            onChange={e => {
              onFilterChange(e, "focusAreas")
            }}
            selectedValue={filterOptions?.focusAreas}
          />
          <Select
            style={filterStyle}
            options={documentTypeOptns}
            onChange={e => {
              onFilterChange(e, "docType")
            }}
            selectedValue={filterOptions?.docType}
          />
        </form>
        <div className={filterStyle.clearFilterWrapper}>
          <button className={filterStyle.clearFilterBtn} type="reset" onClick={clearFilters}>
            Clear filters <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
      <div className={filterStyle.searchInputWrapper}>
        <SearchInput
          style={filterStyle}
          placeholder={searchPlaceHldr}
          searchTerm={searchTerm}
          onChange={onSearchChange}
          onSearchSubmit={handleSubmit}
        />
        <Button
          onClick={handleSubmit}
          className="bg-lightGreen hover:bg-lightBlack text-white max-[568px]:w-[90px] w-[165px] font-bold ml-20 px-2 rounded-20"
        >
          Search
        </Button>
      </div>
    </div>
  )
}

ArticleFilter.propTypes = {
  filters: PropTypes.object,
  fetchArticles: PropTypes.func,
  searchTerm: PropTypes.string,
  onSearchChange: PropTypes.func,
  filterOptions: PropTypes.object,
  onFilterChange: PropTypes.func,
  clearFilters: PropTypes.func,
  pageNo: PropTypes.number,
  handleSubmitFlag: PropTypes.bool,
  sethandleSubmitFlag: PropTypes.func,
  filterStyle: PropTypes.object,
  clearFilterWrapper: PropTypes.string,
  clearFilterBtn: PropTypes.string,
  searchInputWrapper: PropTypes.string
}

export default ArticleFilter
