/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
const ComponentWrapper = props => {
  const { componentName } = props
  let Component = null
  // access and render component
  try {
    Component =
      require(`../components/${componentName}/${componentName}`).default
    const styles = require(`../components/${componentName}/${componentName}-tw-styles.js`)
    var style = styles[props.variant] || styles["defaultStyle"]
  } catch (error) {
    console.error(`${componentName} :: Error :: ${error.message}`)
    Component = null
  }
  return Component ? <Component {...props} style={style} /> : null
}

// components wrapper : maps and renders all components with their respective props from contentful
const ContentfulComponents = props => {
  const { componentsProps = [], pageType, pageSlug } = props
  if (componentsProps.length === 0) {
    return null
  }
  return (
    <>
      {componentsProps.map((prop, index) => {
        if (!prop) {
          return null
        }
        const componentName = Object?.keys(prop)[0]
        return (
          <ComponentWrapper
            componentName={componentName}
            {...prop[componentName]}
            pageType={pageType}
            pageSlug={pageSlug}
            key={index}
          />
        )
      })}
    </>
  )
}

ContentfulComponents.propTypes = {
  componentsProps: PropTypes.array,
  pageType: PropTypes.string,
  pageSlug: PropTypes.string,
}

ComponentWrapper.propTypes = {
  componentName: PropTypes.string,
}

export default ContentfulComponents
