export const defaultStyle = {
  textContainer: "text",
  sectionID: "",
  ingredientsText: "flex flex-wrap text-[15px] ingredientsText mt-40px mb-20px bg-white  lg:w-[1170px] mx-auto py-20 opacity-[.4] text-left lg:max-w-[94%] px-[6%]",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  textWrapper: "contentBody text-textBlack",
  title: "textTitleLine",
  lineOnSides: "textTitleSideLineRightLeft",
  titleWithLine: "textTitleWithLine",
  mainLink: "primaryBtn",
  targetParagraph: "fullWidthAutoParagraph",
  targetParagraphElement: "targetParagraphElement",
  paraContent: "paraContent md:max-w-[590px]",
}

export const PRIVACY_NOTICE = {
  ...defaultStyle,
  sectionID: "privacyNotice",
}

export const CONTACT_US = {
  ...defaultStyle,
  sectionID: "contactUs",
}

export const TERMS_OF_SERVICE = {
  ...defaultStyle,
  sectionID: "termsOfService",
  paraContent: "paraContent",
}

export const FOOT_NOTE = {
  textWrapper:
    "text-left mb-20px lg:w-[972px] lg:my-[45px] lg:mb-[85px] m-10 lg:mx-auto my-0 opacity-50 text-15 tracking-[0] leading-[20px] md:mx-40 ",
}

export const INGREDIENTS_TEXT = {
  textWrapper:
    "text-left ingredientsText mt-40px mb-20px lg:w-[1170px] lg:my-[45px] mx-auto my-0 hidden",
}
export const TEXT_CONTAINER_SECTION = {
  textWrapper:
    "text-left rangeINgredTxt sm:px-8 pt-[50px] lg:pt-[40px] pb-[25px] lg:w-[1170px] text-[15px] lg:my-[45px] mx-auto my-0 text-center ",
  title: "",
}
export const COMPANY_DETAILS = {
  textWrapper:
    "textWrapper text-left sm:px-8 pt-[50px] lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] mx-15 lg:mx-auto my-0",
  title: "text-center text-25 leading-25 py-25 tracking-[1px]",
  code: "",
  paragraph: "paragraph",
  list: "",
  ulList: "",
  mainLink: "",
  link: "text-errorRed",
}
export const CORPORATE_DETAILS = {
  textWrapper:
    "textWrapper COMPANY_DETAILS text-left md:px-0 px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] mx-15 lg:mx-auto my-0",
  title: "text-center text-30 leading-30 pb-20 pt-5 tracking-[1px]",
  code: "code",
  paragraph: "text-errorRed",
  list: "",
  ulList: "",
  mainLink: "mainLink",
  link: "text-errorRed",
}

export const COOKIES_INFO = {
  textWrapper:
    "textWrapper text-left lg:px-8 px-8 lg:pt-0 lg:w-[1170px] text-[15px] lg:mb-[45px] lg:mx-auto my-0 md:px-30 COOKIES_INFO",
  title: "text-center text-25 leading-25 py-25 tracking-[1px] ",
  code: "",
  paragraph: "paragraph",
  list: "list pb-[1.1em] list-none tracking-[0]",
  ulList: "listui",
  mainLink: "",
  link: "",
  table: "border-separate border-spacing-0 curved-table lg:mb-30 mb-10",
  tRow: "rounded-[0px]",
  tHeader:
    "bg-greyLightSmoke  text-14 first:rounded-tl-[5px] last:rounded-tr-[5px]",
  tData: "",
  mainLink: "mainLink buttonEffect2 text-white",
  tableCls: "mb-[32px]",
  paraContent: "cookie-policy m-auto pt-50 pb-40 px-7 text-textBlack md:px-30 md:max-w-[960px]",
}

export const TEXT_BOX_WITH_BUTTON = {
  textWrapper:
    "sm:px-8 PhillyFiveHdng textWrapper pb-[25px] lg:w-[720px] text-[21px] lg:text-[25px] lg:mb-[45px] lg:mx-auto mx-[2%] my-0 text-center",
  btnWrpr: "mt-20 buttonEffect2 ",
  title: "title",
  Heading: "text-[40px]",
  mainLink: "buttonEffect2 lg:mt-20 ",
  contentDesc: "lg:text-[25px] text-[21px] leading-8 pt-3 mb-30 text-[#7f7f7f]",
}

export const TEXT_BOX_WITH_BUTTON_SPACE = {
  textWrapper:
    "sm:px-8 PhillyFiveHdng textWrapper pb-[25px] lg:w-[720px] text-[21px] lg:text-[25px] lg:mb-[45px] lg:mx-auto mx-[10%] my-0 text-center mt-60",
  btnWrpr: "mt-20 buttonEffect2 ",
  title: "title",
  Heading: "text-[40px]",
  mainLink: "buttonEffect2 lg:mt-20 ",
  contentDesc: "lg:text-[25px] text-[21px] leading-8 pt-3 mb-30 text-[#7f7f7f]",
}

export const TEXT_WITH_GREEN_BG = {
  textContainer: "bg-lightGreen  ",
  textContainerBlock: "cocoaWarper wide text-center md:max-w-full mx-auto my-0",
  paraContent: "fullWidthAutoParagraph",
  textWrapper: "contentBody text-white",
  title: "textTitle",
}

export const TEXT_WITH_WHITE_BG = {
  textContainer: "bg-white",
  textContainerBlock: "cocoaWarper text-center md:max-w-full mx-auto my-0",
  paraContent: "fullWidthAutoParagraph",
  textWrapper: "contentBody text-textBlack",
  title: "textTitle",
}

export const TEXT_LEFT_WITH_WHITE_BG = {
  textContainer: "bg-white",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  paraContent: "paraContent md:max-w-[590px]",
  textWrapper: "contentBody text-textBlack",
  title:
    "md:max-w-[590px] text-48 mb-16 uppercase leading-[43px] font-alternate",
}

export const TEXT_WITH_WHITE_BG_HEADING_WRAPPER = {
  textContainer: "bg-white ",
  textContainerBlock: "cocoaWarper text-center md:max-w-full mx-auto my-0",
  paraContent: "fullWidthAutoParagraph",
  textWrapper: "contentBody text-textBlack",
  title: "textTitleLine",
  lineOnSides: "textTitleSideLineRightLeft",
  titleWithLine: "textTitleWithLine",
}

export const TEXT_WITH_YELLOW_BG = {
  textContainer: "bg-yellow",
  textContainerBlock:
    "cocoaWarper text-center md:max-w-full mx-auto my-0 !border-b-0",
  paraContent: "fullWidthAutoParagraph",
  textWrapper: "contentBody text-white",
  title: "textTitle",
}
export const TEXT_WITH_ORANGE_BG = {
  textContainer: "bg-orange",
  textContainerBlock:
    "cocoaWarper text-left md:max-w-full mx-auto my-0 !border-b-0",
  paraContent: "max-w-[590px] mb-0 [&>p]:mb-16",
  textWrapper: "contentBody text-white",
  title: "text-48 mb-16 uppercase leading-36 font-alternate",
  paragraph: "mb-16",
}

export const ARTICLE_DETAILS_CONTENT = {
  textContainer: "article-detail-content bg-white text",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  paraContent: "paraContent max-w-[590px]",
  textWrapper: "contentBody text-textBlack",
  title:
    "max-w-[590px] text-48 mb-16 uppercase leading-[43px] font-alternate",
  linkstyle: 'link-text'
}

/* title is not having lwft and right lines */
export const TEXT_WITH_PINK_TITLE = {
  ingredientsText:
    "flex flex-wrap text-[15px] ingredientsText mt-40px mb-20px bg-white  lg:w-[1170px] mx-auto py-20 opacity-[.4] text-left lg:max-w-[94%] px-[6%]",
  textContainerBlock: "cocoaWarper text-center md:max-w-full text-left",
  textWrapper: " text-textBlack px-16 md:px-30 pt-50",
  title:
    "text-left text-darkPink text-48 mb-16 font-alternate uppercase leading-[43px] md:leading-36",
  lineOnSides: "lineOnSides",
  titleWithLine: "titleWithLine",
  mainLink: "primaryBtn",
  targetParagraph: "fullWidthAutoParagraph",
  targetParagraphElement: "targetParagraphElement",
  textContainer: "textContainer  text-left",
  paraContent: "paraContent text-left w-full lg:w-8/12 md:max-w-[590px]",
}

export const TEXT_WITH_BURGUNDY_BG_NOT_FW = {
  ...defaultStyle,
  textContainer: "bg-redVelvet max-w-[900px] mx-auto mt-16 mb-40",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  textWrapper: "p-20 pb-[5px] text-white",
  title: "title font-alternate text-48 mb-16 leading-43 text-left max-w-[590px] ",
}

export const TEXT_WITH_BURGUNDY_BG = {
  ...defaultStyle,
  textContainer: "bg-redVelvet border-b-2 border-white",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  textWrapper: "contentBody text-white",
  title: "title font-alternate text-48 mb-16 leading-43 text-left max-w-[590px]",
}

export const TEXT_WITH_REDVELVET_BG_FIXED_WIDTH = {
  ...defaultStyle,
  textContainer: "fixed-width-bg w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] mt-[16px]",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  textWrapper: "contentBody text-white p-0",
  paraContent : "paraContent bg-redVelvet max-w-[630px] p-[20px]",
  title: "title font-alternate text-48 mb-16 leading-43 text-left max-w-[590px]",
}

export const TEXT_WITH_DARKRED_BG_FIXED_WIDTH = {
  ...defaultStyle,
  textContainer: "fixed-width-bg w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] mt-[16px]",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  textWrapper: "contentBody text-white p-0",
  paraContent : "paraContent bg-darkRed max-w-[600px] p-[20px] rounded-[25px]",
  title: "title font-alternate text-48 mb-16 leading-43 text-left max-w-[590px]",
}

export const BIG_TITLE_TEXT_BG_VARIANT = {
  textContainer: "border-white border-b-2",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  textWrapper: "contentBody text-white",
  title: "font-alternate text-textBlack text-30 leading-[29px] text-left max-w-[590px] uppercase relative left-[-10px] !mb-12",
  ingredientsText:
    "flex flex-wrap text-[15px] ingredientsText mt-40px mb-20px bg-white  lg:w-[1170px] mx-auto py-20 opacity-[.4] text-left lg:max-w-[94%] px-[6%]",
  lineOnSides: "",
  titleWithLine: "",
  mainLink: "primaryBtn",
  targetParagraph: "",
  targetParagraphElement: "targetParagraphElement",
  paraContent: "paraContent subtitle !mb-[32px] md:max-w-[590px] [&>h2]:!text-48 [&>h2]:!mb-32 [&>h2]:!leading-[43.2px] [&>p:first-of-type]:text-22 [&>p:first-of-type]:font-bold [&>p:first-of-type]:mb-8 [&>p:first-of-type]:leading-[26.4px]",
}

export const BIG_TEXT_WITH_PINK_BG = {
  ...BIG_TITLE_TEXT_BG_VARIANT,
  textContainer: "bg-darkPink border-white border-b-2",
}

export const BIG_TEXT_WITH_REDVELVET_BG = {
  ...BIG_TITLE_TEXT_BG_VARIANT,
  textContainer: "bg-redVelvet border-white border-b-2",
}

export const BIG_TEXT_WITH_YELLOW_BG = {
  ...BIG_TITLE_TEXT_BG_VARIANT,
  textContainer: "bg-yellow border-white border-b-2",
}

export const BIG_TEXT_WITH_ORANGE_BG = {
  ...BIG_TITLE_TEXT_BG_VARIANT,
  textContainer: "bg-orange border-white border-b-2",
}

export const BIG_TEXT_WITH_GREEN_BG = {
  ...BIG_TITLE_TEXT_BG_VARIANT,
  textContainer: "bg-lightGreen border-white border-b-2",
}

export const FULL_WIDTH_TEXT_CONTENT = {
  textContainer: "fullWidthContainer",
  ingredientsText: "",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  textWrapper: "px-15 md:px-30 lg:px-0 pt-[18px] text-textBlack",
  title: "wrprHeadingContainer w-full mx-auto md:px-[15px] pb-[40px] text-center text-textBlack",
  lineOnSides: "wrprHeadingDiv pt-12 leading-[0.9] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[0.5px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[0.5px] after:content-[' '] after:bg-darkGrey after:z-14",
  titleWithLine: "wrprHeading text-[40px] bg-white px-[28px] inline-block font-alternate z-[15] relative",
  mainLink: "",
  targetParagraph: "",
  targetParagraphElement: "",
  paraContent: "paraContent text-center",
}

export const TEXT_LEFT_WITH_WHITE_BG_TITLE = {
  textContainer: "bg-white text-with-white-bg",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0",
  paraContent: "paraContent md:max-w-[590px] pl-[15px] min-[567px]:pl-[30px] !mb-0",
  textWrapper: "contentBody text-textBlack",
  title: "textTitleLine text-center",
  lineOnSides: "textTitleSideLineRightLeft",
  titleWithLine: "textTitleWithLine",
}

export const LEFT_ALIGNED_TEXT_WITH_GREEN_BG = {
  textContainer: "bg-lightGreen  ",
  textContainerBlock: "cocoaWarper text-left max-w-[590px]",
  paraContent: "max-w-[590px]",
  textWrapper: "contentBody text-white",
  title: "textTitle",
}

export const LEFT_ALIGNED_TEXT_WITH_GREEN_BG_900PX = {
  textContainer: "textContainer bg-white",
  textContainerBlock: "cocoaWarper text-left px-15 min-[567px]:px-30 pt-0 pb-40",
  paraContent: "paraContent max-w-[590px] !mb-0 [&>p:last-child]:mb-0",
  textWrapper: "contentBody bg-lightGreen text-white !p-24",
  title: "text-[30.4px] pt-[32px] mb-12 uppercase font-alternate leading-[0.96] max-w-[590px]",
  linkstyle: 'text-textBlack underline cursor-pointer'
}

export const GREY_BG_TEXT_WITH_MARKER_CONTENT = {
  textContainer: "bg-footerGrey",
  textContainerBlock: "cocoaWarper",
  textWrapper: "contentBody text-textBlack flex flex-col md:flex-row items-start !pb-10 md:!pb-8",
  paraContent: "paraContent max-w-[590px] mr-20",
  markerMainWrapper: "Interactive-map pb-30 md:pl-10 pb-40 pt-50 md:pt-0",
  title: 'text-darkGrey text-14 mb-16 font-medium md:pl-20',
  listTypeNone: '!pl-0 md:!pl-20 !mb-0',
  imageText: 'text-14 italic text-textBlack font-medium',
  listItem: 'flex flex-row-reverse justify-end mb-8 items-center gap-8',
  image: 'w-[24px] h-30',
}

export const TEXT_LEFT_WITH_WHITE_BG_MAX_WIDTH590 = {
  ...TEXT_LEFT_WITH_WHITE_BG_TITLE,
  paraContent: "paraContent md:max-w-[590px] pl-0 !mb-0",
}


export const LEFT_ALIGNED_TEXT_WITH_BURGUNDY_BG_900PX = {
  textContainer: "bg-redVelvet max-w-[900px] mx-auto mt-0 mb-40",
  textContainerBlock: "cocoaWarper text-left md:max-w-full mx-auto my-0 p-20",
  textWrapper: "text-white max-w-[590px]",
  title: "title font-alternate text-40 mb-16 leading-[0.9] text-left max-w-[590px] font-normal uppercase",
}