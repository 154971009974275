import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "../../styles/accordianContent.css"
import Typography from "../UI/Typography/Typography"
import AccordianItem from "./AccordianItem/AccordianItem"
import * as style from "./Accordian-tw-styles"
import { SEE_ALSO } from "../../constants/label.constants"
import Link from "../UI/Link/Link"
import useScreenSize from "../../hooks/useScreenSize"

const Accordian = props => {
  const {
    title = "",
    description = "",
    contentRowsCollection = {},
    quickLinksCollection = {},
    variant = "",
    elementId = "",
  } = props
  const [clicked, setClicked] = useState(variant === "FAQ_ACCORDIAN" ? null : 0)
  const styles = style[variant] || style["defaultStyle"]
  const [contentRows, setContentRows] = useState([])
  const [shrinked, setShrinked] = useState(false)
  const [load, setLoad] = useState(true)
  const screen = useScreenSize()

  useEffect(() => {
    const { items = [] } = contentRowsCollection
    const result = items?.map((ele, index) => {
      const isActive = index == 0 && false
      return { ...ele, isActive: isActive }
    })
    setContentRows([...result])
    setLoad(false)
  }, [])
   
  useEffect(() => {
    if (typeof window !== "undefined") {
      // add page scroll handler for header
      window?.addEventListener("scroll", () => {
        if (window?.scrollY > 267) {
          setShrinked(true)
        } else {
          setShrinked(false)
        }
      })
      setLoad(false)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined" && !load && variant !== "FAQ_ACCORDIAN") {
      // stop scroll from browser history
      const scrollElementID = window?.location?.hash
      if(scrollElementID.includes('#')){
        const element =  document.getElementById('dashboard')
        const top = element?.offsetTop+57
        window?.scrollTo({top: top, behavior:"smooth"})
      }
    }
  }, [load])

  const calculteScroll = (index,scrollToElementId) => {
    const offsetTop = document.getElementById('accordionParent').offsetTop
      const element = document.getElementById(scrollToElementId)
      if (element) {
        const prevCount = index === 0 ? 0 : index
        const prevEleHeight = calPrevEleHeight(prevCount)
        const updatedOffset = screen?.isDesktop ? offsetTop+element?.offsetHeight : offsetTop
        const scrollPosition = updatedOffset+prevEleHeight
        return scrollPosition
      }
  }

  const calculteFaqScroll = (index,scrollToElementId) => {
    const offsetTop = document.getElementById('accordionParent').offsetTop
      const element = document.getElementById(scrollToElementId).getElementsByClassName('cursor-pointer')[0]
      if (element) {
        const prevCount = index === 0 ? 0 : index
        const prevEleHeight = calPrevEleHeight(prevCount)
        const updatedOffset = (shrinked && screen?.isDesktop) ? offsetTop+element?.clientHeight+prevCount*4 : offsetTop
        const scrollPosition = updatedOffset+prevEleHeight
        return scrollPosition
      }
  }

  const calPrevEleHeight  =(lastIndex)=>{
    let height = 0;
   while(lastIndex > 0){
        const ele =  document.getElementById('question-'+lastIndex).getElementsByClassName('cursor-pointer')[0]
        height = height+ele?.clientHeight
        lastIndex--;
   }
   return height
  }

  const handleFaqScroll = (index,scrollElementID)=>{
    // scroll to headline of accordion
    if (index !== clicked) {
      if (typeof window !== 'undefined') {
        const top = calculteFaqScroll(index,scrollElementID)
        window?.scrollTo({top: top, behavior:"smooth"})
      }
    }
  }

  const handleScroll = (index,scrollElementID)=>{
    // scroll to headline of accordion
    if (index !== clicked) {
      const top = calculteScroll(index,scrollElementID)
      if (typeof window !== 'undefined') {
      window?.scrollTo({top: top, behavior:"smooth"})
      }
    }
  }

  const handleToggle = (index,scrollElementID) => {
    if (variant !== "FAQ_ACCORDIAN") {
      setClicked(index === clicked ? null : index)
      handleScroll(index,scrollElementID)
    } else {
      const result = contentRows?.map((ele, idx) => {
        const isActive = index === idx
        return { ...ele, isActive: isActive }
      })
      setContentRows([...result])
      setClicked(clicked === null ? index : clicked === index ? null : index)
      handleFaqScroll(index,scrollElementID)
    }
  }
  
  const handleToggleHref = (clickedIdx, targetAccordion) => {
    let clickedIndex = null;
    if (variant === "FAQ_ACCORDIAN") {
      const nextHref = targetAccordion?.href
      const refIndex = nextHref.replace('#question-', '')
      const result = contentRows?.map((ele, index) => {
        const isActive = parseInt(refIndex) === parseInt(index)
        if (nextHref.includes('#')) {
          clickedIndex = nextHref?.charAt(0) === '#' ? refIndex : clickedIdx === clicked ? clickedIdx : clicked
          return { ...ele, isActive: isActive }
        } else {
          clickedIndex = clickedIdx === clicked ? clickedIdx : clicked
          return { ...ele }
        }
      })
      setContentRows([...result])
      setClicked(parseInt(clickedIndex))
      setTimeout(()=>{
        handleFaqScroll(clickedIndex,nextHref.replace('#', '')) 
      },200)
    }
  }

  const renderQuickLinksSection = () => {
    return (
      <div className={styles?.quickLinkWrapper}>
        {quickLinksCollection?.items?.map(item => (
          <div className={styles?.quickLinkDiv} key={item?.label}>
            <Link
              {...item}
              className={styles?.quickLink}
              gaEventLabel={item?.title}
            />
          </div>
        ))}
      </div>
    )
  }

  if (contentRows?.length === 0) {
  return <div className={styles?.loaderStyle}>Loading...</div>
  }

  return (
    <>
      <div className={styles?.interventionContainer} id={elementId}>
        <div className={styles?.interventionSubContainer}>
          <div className={styles?.interventionTitleWrap}>
            {title && <h1 className={styles?.title}>{title}</h1>}
          </div>

          {description && (
            <Typography content={description} className={styles?.description} />
          )}
          <div className={styles?.accordianComponent}>
            <ul className="accordian" id="accordionParent">
              {contentRows?.length > 0 && contentRows?.map((content, index) => (
                <AccordianItem
                  index={index}
                  linkStyle={styles?.linkColor}
                  key={index}
                  rowContent={content}
                  onToggle={()=>handleToggle(index,"question-"+ (parseInt(index)))}
                  active={clicked === index}
                  styles={styles}
                  handleToggleHref={handleToggleHref}
                />
              ))}
              {quickLinksCollection?.items?.length > 0 && (
                <li className={styles?.quickLinkMain}>
                  <div className={styles?.quickLinkLeft}>{SEE_ALSO}</div>
                  <div className={styles?.quickLinkRight}>
                    {renderQuickLinksSection()}
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

Accordian.propTypes = {
  title: PropTypes.string,
  elementId: PropTypes.string,
  variant: PropTypes.string,
  description: PropTypes.string,
  contentRowsCollection: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  quickLinksCollection: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

export default Accordian
