const query = (id, isPreview) => `
{
  backLink(id: "${id}", preview: ${isPreview}) {
    link {
                  label
                  title
                  url
                  target
                  scrollToElementId
                  gaEventClass
                  gaEventLabel
                }
                variant
  }
}

`
module.exports = { query }
