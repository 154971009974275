import React, { useEffect } from "react"
import Image from "../../UI/Image/Image"
import { useRef } from "react"
import Typography from "../../UI/Typography/Typography"
import PropTypes from "prop-types"
import LinkWrapper from "../../UI/LinkWrapper/LinkWrapper"
import * as accordianItemstyle from "./AccordianItem-tw-styles"
import useImage from "../../../hooks/useImage"
import Button from "../../UI/Button/Button"
import { gaEventClick } from "../../../constants/gtm.constants"
import Link from "../../UI/Link/Link"

const AccordianItem = ({ index, rowContent, active, onToggle, styles, linkStyle = '',handleToggleHref = ()=>{} }) => {
  const {
    title = "",
    image = {},
    link = {},
    contentCollection = {},
    bottomText = "",
    variant,
    reverseContent = false,
  } = rowContent
  const { altText, foregroundImg } = useImage(link?.image || image)
  const contentEl = useRef()
  const rowStyles =
    accordianItemstyle[variant] || accordianItemstyle["defaultStyle"]
  const { isActive } = rowContent

  return (
    <li
      id={"question-"+ (parseInt(index))}
      className={
        isActive || active
          ? rowStyles?.accordionItem + " ToggleOpened"
          : rowStyles?.accordionItem
      }
    >
      <Button
        className={styles?.accordianBtn}
        onClick={onToggle}
        dataActionDetail={title}
        gaEventClass={gaEventClick}
      >
        {title}
        <span className={styles?.accordianIcon}>{ active ? "-" : "+"} </span>
      </Button>
      <div>
        <div
          ref={contentEl}
          className={styles?.accordianWrapper}
          style={
             active
              ? { height: contentEl?.current?.scrollHeight }
              : { height: "0px" }
          }
        >
          <div className={styles?.accordianWrapperContent}>
            {foregroundImg && !reverseContent && (
              <div className={styles?.accordianContentIcon}>
                <Image
                  contentfulImage={foregroundImg}
                  alt={altText}
                  className={styles?.accordianContentImage}
                />
              </div>
            )}
            <div className={styles?.accordianContentText}>
              {contentCollection.items?.length > 0 &&
                contentCollection.items?.map((item, index) => (
                  <div key={index} className={styles?.accordianDetailWrap}>
                    <div className={styles?.accordianWrap}>
                      <div className={styles?.textLeft}>
                        <Typography
                          content={item?.description}
                          className={styles?.descriptionWrapper}
                          linkClass={linkStyle}
                          index={index}
                          handleClick={handleToggleHref}
                        />
                        {foregroundImg && reverseContent && (
                          <div className={styles?.accordianContentIcon}>
                            {link ?
                              <Link {...link}>
                                <Image
                                  contentfulImage={foregroundImg}
                                  alt={altText}
                                  className={styles?.accordianContentImage}
                                />
                              </Link> : <Image
                                contentfulImage={foregroundImg}
                                alt={altText}
                                className={styles?.accordianContentImage}
                              />}
                          </div>
                        )}
                        {item?.linkWrapperCollection?.items.map(
                          (link, index) => (
                            <LinkWrapper {...link} key={index} />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              {bottomText && (
                <div className={styles?.accordianwholeText}>
                  <Typography content={bottomText} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

AccordianItem.propTypes = {
  title: PropTypes.string,
  styles: PropTypes.object,
  active: PropTypes.bool,
  onToggle: PropTypes.func,
  rowContent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  linkStyle: PropTypes.string,
  index: PropTypes.number,
  handleToggleHref: PropTypes.func
}

export default AccordianItem
