const algoliasearch = require("algoliasearch")

// search recipes
const getSearchResults = async data => {
  const { search = "", pageNo, pageType, filters } = data
  try {
    /* setup algolia connection */
    const indexName = process.env.GATSBY_ALGOLIA_INDEX_CONTENTFUL_PAGES

    // init Algolia client
    const client = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_SEARCH_KEY
    )
    // target the index
    const index = client.initIndex(indexName)

    /* ~~~~~~~~~~ ooo ~~~~~~~~~~ */

    const facetFilters = []
   // const nowTimestamp = Date.now();
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        facetFilters.push(`seoData.keywords.keywords:${filters[key]}`)
      }
    })

    pageType && facetFilters.push(`pageType:${pageType}`)

    const options = {
      ...(facetFilters.length && { facetFilters: facetFilters }),
      page: pageNo,
      hitsPerPage: 20,
    }
    const searchTerm = search || ""

    const result = await index.search(searchTerm, {
      ...options,
    })
    return result
  } catch (error) {
    return { error: error.message }
  }
}

module.exports = { getSearchResults }
