import React from "react"
import PropTypes from "prop-types"
import Video from "../UI/Video/Video"
import Typography from "../UI/Typography/Typography"
import { get } from "../../helpers/utils"
import * as styles from "./VideosWrapper-tw-styles"

const VideosWrapper = props => {
    const {
        videoCollection = {},
    } = props
    const videos = get(videoCollection, "items", [])

    const style =  styles["defaultStyle"]

    const renderVideo = () => {
        return videos.map((elem, index) => (
            <div className={style?.cardWrapper} key={`video-container-${index}`}>
                <div className={style?.videoCard}>
                    <Video {...elem} style={style} />
                </div>
                {elem?.title && (
                    <div className={style?.titleContainer}>
                        <Typography className={style?.title}>{elem?.title}</Typography>
                    </div>
                )}
            </div>

        ))
      }
    return (
        <div className={style?.containerWrapper}>
            {videos && videos?.length > 0 && renderVideo()}
        </div>
    )
}

VideosWrapper.propTypes = {
    videoCollection: PropTypes.object
}

export default VideosWrapper
