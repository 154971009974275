const defaultStyle = {
  imageSlider: "fullWidthSlider",
  imageSliderWrapper: "fullWidthSliderWrapper relative",
  imgContainer: "h-full bg-cover bg-center bg-no-repeat",
  imageBoxWrpr: "h-[540px] md:h-[400px] lg:h-[600px] overflow-hidden relative",
  contentWrapr:
    "bg-[#0000004d] absolute top-0 left-0 right-0 m-auto px-15 flex flex-col w-full h-full items-center justify-center",
  heading:
    "max-w-[440px] w-full text-white text-2xl leading-[26px] md:text-[34px] md:leading-[34px] uppercase text-center mb-15",
  description: "max-w-[440px] w-full text-white text-base mb-15 text-center",
  linkWrpr:
    "bg-lightGreen w-full max-w-[270px] h-40 my-16 mx-10 text-2xl md:text-3xl leading-[40px] text-white text-bold capitalize text-center rounded-[38px]",
}

export { defaultStyle }

export const CARD_SLIDER_WITH_DOTS_ARROWS = {
  ...defaultStyle,
  imageSlider: "fullWidthSlider mb-30",
}
