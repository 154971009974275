export const defaultStyle = {
	linkWrapperSection:'linkWrapperSection cocoaWarper px-15 md:px-30',
}

export const LINK_WRAPPER_ONLY_ONE_LINK = {
	linkWrapperSection:'linkWrapperSection onlyOneLink cocoaWarper !pb-0 px-15 md:px-30',
}

export const Video_Link_Wrapper = {
	linkWrapperSection:'linkWrapperSection cocoaWarper',
}
