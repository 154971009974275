const query = (id, isPreview) => `{
  imageCardsLayout(id: "${id}", preview: ${isPreview}) {
    title
    text
    variant
    isSlider
    sliderSettings{
      slidesToShowMobile
      slidesToScrollMobile
      slidesToShowTab
      slidesToScrollTab
    }
    link {
      label
      title
      url
      target
      scrollToElementId
      gaEventClass
      gaEventLabel
      image {
        altText
        imageDesktop {
          url
          title
          description
          width
          height
        }
        imageMobile {
          url
          title
          description
          width
          height
        }
      }
    }
    cardsCollection {
      items {
        title
        text
        elementId
        variant
        gaEventClass
        gaEventLabel
        images {
          altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
        }
        link {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
          }
        }
      }
    }
  }
}

`
module.exports = { query }
