export const defaultStyle = {
  mainContainer: "bg-yellow -mt-[50px]",
  mainContainerWrapper:
    "cocoaWarper text-center md:max-w-full mx-auto my-0 !border-0",
  mainContainerWrapBox: "contentBody text-white !pt-0",
  brandlogos:
    "my-8 mx-0 text-center flex content-center justify-between flex-wrap px-0",
  linkWrapper: "secondaryBtn",
  brandLogosList: "my-32 mx-auto",
  brandlogosListItem: "b inline-block mx-8 pr-10",
  brandlogosImage: "c max-w-[105px] w-full h-auto border-0",
}

export const BRAND_ROUND_IMAGE_LIST = {
    ...defaultStyle,
    rowWrapper: "bg-[#fcba12] flex flex-wrap justify-between md:px-15",
    imgWrpr: "h-[120px] w-[120px]",
    listStyle: "flex",
    brandLogosList:"homePage-BrandList mt-32 mb-16 mx-auto",
}

export const IMAGE_WITH_DESCRIPTION = {
    mainContainer: "mainContainer",
    mainContainerWrapper: "mainContainerWrapper w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px]",
    mainContainerWrapBox: "mainContainerWrapBox",
    rowWrapper: "rowWrapper w-full",
    brandLogosList: "brandLogosList mt-[24px] mb-[32px]",
    brandlogosListItem: "brandlogosListItem inline-block w-full md:first:w-[58.33%] md:first:pr-[16px] md:last:w-[41.66%] md:last:pl-[16px] align-top",
    brandlogosImage: "brandlogosImage w-full h-auto border-0",
    brandlogosDesc: "brandlogosDesc text-[14.4px] max-w-[590px] w-full italic text-textBlack"
}
export const IMAGE_WITH_DESCRIPTION_W66_W34 = {
  ...IMAGE_WITH_DESCRIPTION,
  brandlogosListItem: "brandlogosListItem inline-block w-full md:first:w-[66%] md:first:pr-[16px] md:last:w-[34%] md:last:pl-[16px] align-top",
}
export const TOP_IMAGE_BOTTOM_TEXT = {
    mainContainer: "topImg-bottomText mainContainer",
    mainContainerWrapper: "mainContainerWrapper w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] border-white border-b-2",
    mainContainerWrapBox: "mainContainerWrapBox",
    rowWrapper: "rowWrapper w-full md:w-[66.66%] border-white border-l-1 border-r-1",
    brandLogosList: "brandLogosList mt-[30px] mb-[40px] w-full flex flex-wrap",
    brandlogosListItem: "brandlogosListItem w-full block md:w-[31%] md:mr-[3%] md:inline-block",
    brandlogosImage: "brandlogosImage w-[150px] h-auto mx-auto",
    brandlogosDesc: "brandlogosDesc mt-[10px] text-center font-bold text-[17px] leading-[1.375] text-textBlack"
}

export const ONLY_IMAGE = {
  ...defaultStyle,
  mainContainer: "mainContainer",
  brandLogosList: "brandLogosList differentSizeImg my-32 mx-auto md:flex",
  brandlogosListItem: "brandlogosListItem",
  brandlogosImage: "w-full",
  mainContainerWrapper:
    "twoImageSection w-full md:w-[960px] text-center md:max-w-full mx-auto my-0 !border-0",
  mainContainerWrapBox: "px-16 md:px-30 text-white !pt-0",
}

export const ONLY_IMAGE_REVERSE ={
    ...ONLY_IMAGE,    
    brandLogosList:"brandLogosList differentSizeImgReverse mt-16 mb-32 mx-auto md:flex",
    
}

export const ONLY_IMAGE_WITH_DESCRIPTION = {
  mainContainer: "mainContainer",
  mainContainerWrapper:
    "mainContainerWrapper w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px]",
  mainContainerWrapBox: "mainContainerWrapBox",
  rowWrapper: "rowWrapper w-full md:w-[66.66%]",
  brandLogosList: "brandLogosList mt-[16px] mb-[32px] md:pr-[16px]",
  brandlogosListItem: "brandlogosListItem inline-block w-full",
  brandlogosImage: "brandlogosImage w-full h-auto border-0",
  brandlogosDesc:
    "brandlogosDesc text-[17px] leading-[1.375] max-w-[590px] w-full text-textBlack",
}

export const LEFT_IMAGE_8COL_RIGHT_IMAGE_4COL = {
    mainContainer: "mainContainer",
    mainContainerWrapper: "mainContainerWrapper w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px]",
    mainContainerWrapBox: "mainContainerWrapBox",
    rowWrapper: "rowWrapper w-full max-w-[590px]",
    brandLogosList: "brandLogosList md:flex my-[32px]",
    brandlogosListItem: "brandlogosListItem inline-block w-full md:first:w-[66.66%] md:last:w-[33.33%] md:first:pr-[20px] md:last:pl-[40px] md:border-white md:first:border-r-1 md:last:border-l-1",
    brandlogosImage: "brandlogosImage w-full h-auto border-0",
    brandlogosDesc: "brandlogosDesc text-[14.4px] max-w-[590px] w-full italic text-textBlack"
}
export const LEFT_IMAGE_8COL_RIGHT_IMAGE_4COL_FW = {
    ...LEFT_IMAGE_8COL_RIGHT_IMAGE_4COL,   
    brandLogosList: "brandLogosList md:flex my-[32px]", 
    rowWrapper: "rowWrapper w-full",
}

export const LEFT_IMAGE_4COL_RIGHT_IMAGE_8COL = {
    ...LEFT_IMAGE_8COL_RIGHT_IMAGE_4COL,
    rowWrapper: "rowWrapper w-full",
    brandlogosListItem: "brandlogosListItem inline-block py-40 md:py-0 md:last:w-[66.66%] md:first:w-[33.33%] md:first:pr-[40px] md:last:pl-[20px]",    
    brandlogosImage:'brandlogosImage h-full md:h-auto',
    brandLogosList: "brandLogosList md:flex my-[32px]", 
}

export const LEFT_RIGHT_IMAGE_6COL ={
    ...LEFT_IMAGE_8COL_RIGHT_IMAGE_4COL,  
    rowWrapper: "rowWrapper w-full",
    brandLogosList: "brandLogosList md:flex my-[32px]",
    brandlogosListItem: "brandlogosListItem inline-block w-full md:w-[50%] pt-[50px] pb-[40px] md:first:pr-[30px] md:last:pl-[30px]",
}

export const IMAGES_WITH_RIGHT_ARROW = {
    ...defaultStyle,
    mainContainer: "",
    mainContainerWrapBox:"contentBody brandImg_right_arw_main text-white !py-0", 
    brandLogosList: "brandImg_ul md:table my-0 w-full",
    brandlogosListItem: "brandImg_right_arrow inline-block",
    brandlogosImage:"w-full max-w-[100px] h-auto max-[700px]:mx-auto min-h-[100px]",
}

export const BRAND_IMAGE_LIST_WITH_GREEN_BG = {
    ...defaultStyle,
    mainContainer: "partner-BrandList-grp bg-lightGreen -mt-[24px]",
    rowWrapper: "bg-lightGreen flex flex-wrap justify-between min-[900px]:px-30",
    imgWrpr: "h-[120px] w-[120px]",
    listStyle: "partner-BrandList mt-0 mx-auto max-[870px]:!pl-0 max-[870px]:!pr-0 !mb-0 !list-none",
    brandLogosList:"",
    linkWrapper: "",
    brandlogos: "brandLogos inline-block m-10 !mb-[3px] pr-10",
    mainContainerWrapBox: "contentBody text-white !pt-0 !pb-40",
    mainContainerWrapper:
    "cocoaWarper text-left min-[830px]:text-center md:max-w-full mx-auto my-0 !border-0",
}

export const LEFT_IMAGE_8COL_RIGHT_IMAGE_4COL_MAXWIDTH590 = {
  ...LEFT_IMAGE_8COL_RIGHT_IMAGE_4COL,
  brandlogosListItem: "brandlogosListItem inline-block first:w-[66.66%] last:w-[33.33%] first:pr-[15px] last:pl-[15px] md:border-white md:first:border-r-1 md:last:border-l-1",
}

export const LEFT_IMAGE_7COL_RIGHT_IMAGE_5COL_FW = {
  ...LEFT_IMAGE_8COL_RIGHT_IMAGE_4COL,  
    rowWrapper: "rowWrapper w-full",
    brandLogosList: "brandLogosList md:flex my-[32px]",
    brandlogosListItem: "brandlogosListItem inline-block w-full md:first:w-[58.33%] md:first:pr-[16px] md:last:w-[41.66%] md:last:pl-[16px] align-top",
}