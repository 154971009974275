import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import * as styles from "./Facts-tw-styles"

const Facts = props => {
  const { description, variant, id } = props
  const style = styles[variant] || styles["defaultStyle"]
  return (
    <div
      className={`${style?.circleWrapper} ${style?.circleWrapper}-${id + 1}`}
    >
      <div className={style?.circleContainer}>
        {description && (
          <Typography content={description} className={style?.typography} />
        )}
      </div>
    </div>
  )
}

Facts.propTypes = {
  description: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
}

export default Facts
