import React from "react"
import { screen, render, cleanup } from "@testing-library/react"
import ArticleHeader from "./ArticleHeader"
import { articleHeader } from "./ArticleHeader.mock"
import "@testing-library/jest-dom"

describe("render ArticleHeader component", () => {
    beforeEach(() => {
        render(<ArticleHeader {...articleHeader} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render section Poster Image", () => {
        render(<ArticleHeader {...articleHeader} />)
    })
})