import React from "react"
import { screen, render } from "@testing-library/react"
import TwoSectionLayout from "./TwoSectionLayout"
import { twoSectionLayout } from "./TwoSectionLayout.mock"

describe("TwoSectionLayout", () => {
  render(<TwoSectionLayout {...twoSectionLayout} />)
  test("testing sectionTitle", () => {
    expect(screen.getByText("sectionTitle"))
  })
  test("testing middleContent", () => {
    render(<TwoSectionLayout {...twoSectionLayout} />)
    expect(screen.getByText("middleContent"))
  })
  test("testing description", () => {
    render(<TwoSectionLayout {...twoSectionLayout} />)
    expect(screen.getByText("COMBATTING DEFORESTATION AND BUILDING CLIMATE CHANGE RESILIENCE"))
  })
  xtest("testing Image tag", () => {
    render(<TwoSectionLayout {...twoSectionLayout} />)
    const displayedImage = document.querySelectorAll("div img");
    expect(displayedImage[0].src).toContain('https://images.ctfassets.net/qggsjlmpzfmx/7d8GtIa8mVx5pDk4i6k7R1/694db2197b0488c22c999a25468ce081/conservation-forests.png?fm=webp&q=80')
    expect(displayedImage[1].src).toContain('https://images.ctfassets.net/qggsjlmpzfmx/7d8GtIa8mVx5pDk4i6k7R1/694db2197b0488c22c999a25468ce081/conservation-forests.png?fm=webp&q=80')

  })

})
