export const imageCardsSlider = {
    "cardsCollection": {
        "items": [
            {
                "title": "How Cocoa Life continues to help combat child labor",
                "text": null,
                "image": {
                    "title": "Child protection systems",
                    "description": "Child protection systems",
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/42T54bfRzMFR5Oq3mbX6un/d65ee34e33ba235f296d67234e205163/cs-help-protect-children.jpg",
                    "width": 1440,
                    "height": 600
                },
                "link": {
                    "label": "Read more",
                    "url": "/progress/strategy-to-help-protect-children/",
                    "scrollToElementId": null,
                    "target": "_self"
                },
                "variant": "FOUR_IMAGE_SLIDER"
            },
            {
                "title": "Mondelēz International announces the next phase of Cocoa Life",
                "text": "Making cocoa right\n\nby helping to lift people and shape landscapes",
                "image": {
                    "title": "Mondelēz International announces the next phase of Cocoa Life",
                    "description": "Mondelēz International announces the next phase of Cocoa Life",
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/2IBZXaGRoqjYQC3ESOTPw6/ce4d5f3293eac6b024e088cb790c18d8/header.jpg",
                    "width": 1440,
                    "height": 600
                },
                "link": {
                    "label": "Read more",
                    "url": "/progress/next-phase-of-cocoa-life/",
                    "scrollToElementId": null,
                    "target": "_self"
                },
                "variant": "FOUR_IMAGE_SLIDER"
            }
        ]
    },
    "sliderSettings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "slidesToShowTab": 1,
        "slidesToScrollTab": 1,
        "slidesToShowMobile": 1,
        "slidesToScrollMobile": 1,
        "dots": true,
        "arrow": true,
        "fade": true
    },
    "variant": "CARD_SLIDER_WITH_DOTS_ARROWS"
}