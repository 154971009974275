export const multiColumnsGrid = {
    "title": "OUR PROGRESS",
    "descriptionBefore": "descriptionBefore",
    "subTitle": "BY THE END OF 2021:",
    "contentColumnsCollection": {
      "items": [
        {
          "title": null,
          "textBefore": "testBefore",
          "image": {
            "altText":"title",
            "title": "title",
            "description": "",
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/4VUf8AFXUm7Myh0qdNU1Pf/480f7da1033b42cc9fac409cbb9151db/farmers.svg",
            "width": 73,
            "height": 65
          },
          "textAfter": "textAfter",
          "link": {
            "label": null,
            "title": null,
            "url": null,
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null,
            "image": null
          },
          "variant": null,
          "imageCardsCollection": {
            "items": []
          }
        }
      ]
    },
    "descriptionAfter": "descriptionAfter",
    "variant": null
  }
