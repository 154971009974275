import React from "react"
import PropTypes from "prop-types"
import { get } from "../../helpers/utils"
import Link from "../UI/Link/Link"
import * as styles from "./Sitemap-tw-styles"

const Sitemap = props => {
  const { heading, linksCollection, variant } = props
  const mainLinks = get(linksCollection, "items", [])

  const style = styles[variant] || styles?.defaultStyle

  const renderDropdownItem = item => {
    if (item.__typename === "Link") {
      return (
        <Link className={style?.innerlink} {...item}>
          {item?.label}
        </Link>
      )
    } else if (item.__typename === "NavigationItem") {
      const navLinks = item?.dropdownItemsCollection?.items || []
      return (
        <>
          <Link className={style?.innerlink} {...item.link}>
            {item?.link.label}
          </Link>
          {navLinks?.length > 0 && (
            <ul className={style?.innerlistWrpr}>
              {navLinks.map((link, index) => (
                <li className={style?.innerlist} key={index}>
                  <Link className={style?.innerlink} {...link}>
                    {link?.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </>
      )
    }
  }

  return (
    <div className={style?.sitemapContainer}>
      {heading && (
        <div className={style?.headerMain}>
          <div className={style?.header}>{heading}</div>
        </div>
      )}
      {mainLinks && (
        <div className={style?.linksWrprMain}>
          <div className={style?.linksWrpr}>
            <ul className={style?.listWrpr}>
              {mainLinks?.length > 0 &&
                mainLinks?.map((item, index) => (
                  <li className={style?.list} key={index}>
                    <Link className={style?.link} {...item?.link}>
                      {item?.label}
                    </Link>
                    {item?.dropdownItemsCollection?.items?.length > 0 && (
                      <ul className={style?.innerlistWrpr}>
                        {item?.dropdownItemsCollection?.items?.map(
                          (link, index) => (
                            <li className={style?.innerlist} key={index}>
                              {renderDropdownItem(link)}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

Sitemap.propTypes = {
  heading: PropTypes.string,
  linksCollection: PropTypes.object,
  variant: PropTypes.string,
}

export default Sitemap
