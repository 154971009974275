import React from "react"
import Link from "../UI/Link/Link"
import Image from "../UI/Image/Image"
import { get } from "../../helpers/utils"
import * as styles from "./FixedProductBrandImage-tw-styles"
import PropTypes from "prop-types"
import useImage from "../../hooks/useImage"
import Typography from "../UI/Typography/Typography"
import { gaEventClick } from "../../constants/gtm.constants"

const FixedProductBrandImg = props => {
  const {
    cardsCollection,
    imagesCollection,
    link,
    variant,
    showImageDescription,
  } = props
  const cards = get(cardsCollection, "items", [])
  const images = get(imagesCollection, "items", [])
  const style = styles[variant] || styles["defaultStyle"]
  return (
    <div className={style?.mainContainer}>
      <div className={style?.mainContainerWrapper}>
        <div className={style?.mainContainerWrapBox}>
          <div className={style?.rowWrapper}>
            {imagesCollection && (
              <ul className={style?.brandLogosList}>
                {images?.length > 0 &&
                  images?.map(image => {
                    const { altText, foregroundImg = {} } = useImage(image)
                    return foregroundImg  && (
                     <li
                        className={style?.brandlogosListItem}
                        key={foregroundImg?.title}
                      >
                        {foregroundImg?.url && (
                          <Image
                            alt={altText}
                            contentfulImage={foregroundImg}
                            className={style?.brandlogosImage}
                          />
                        )}
                        {showImageDescription && foregroundImg?.description && (
                          <Typography content={foregroundImg?.description} className={style?.brandlogosDesc} />
                        )}
                      </li>
                    )
                  })}
              </ul>
            )}
            {cardsCollection && (
              <ul className={style?.listStyle}>
                {cards?.map(list => {
                  const { altText, foregroundImg ={} } = useImage(list?.image)
                  return foregroundImg && (
                    <li className={style?.brandlogos} key={altText}>
                      {list?.url && (
                        <Link url={list?.url} target={list?.target} className={style?.linkWrapper}>
                          {foregroundImg?.url && (
                            <Image
                              alt={altText}
                              contentfulImage={foregroundImg}
                              className={style?.imgWrpr}
                            />
                          )}
                        </Link>
                      )}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
          <div className={style?.linkWrapperDiv}>
            {link && <Link {...link} className={style?.linkWrapper} gaEventLabel={link?.label} gaEventClass={gaEventClick} />}
          </div>
        </div>
      </div>
    </div>
  )
}

FixedProductBrandImg.propTypes = {
  cardsCollection: PropTypes.object,
  imagesCollection: PropTypes.object,
  link: PropTypes.object,
  variant: PropTypes.string,
  showImageDescription: PropTypes.bool,
}

export default FixedProductBrandImg
