import React from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import { deslashify } from "../../helpers/utils"

const Breadcrumb = props => {
  const { breadcrumbTitle, pageSlug } = props

  const crumbs = deslashify(pageSlug).split("/")
  const link =
    crumbs?.length && crumbs.slice(0, crumbs.length - 1).join("/") + "/"
  return (
    <div className="breadcrumb pl-[10rem] overflow-hidden w-full max-[900px]:hidden py-8 bg-lightGreen text-white text-17 font-brand font-bold">
      <div className="breadcrumb-inner w-full text-left rotate-180 leading-8 relative">
        <div className="min-[997px]:ml-[3rem] max-w-[960px] bg-no-repeat bg-right bg-contain bg-[url('../assests/icons/arrowwhite.png')] hover:text-black hover:bg-[url('../assests/icons/arrowblack.png')]">
          <Link
            className="block rotate-180 relative pl-[2.5rem] arrowLink"
            url={link}
          >
            {breadcrumbTitle}
          </Link>
        </div>
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  breadcrumbTitle: PropTypes.string,
  pageSlug: PropTypes.string,
}

export default Breadcrumb
