import React from "react"
import PropTypes from "prop-types"
import { get } from "../../helpers/utils"
import LinkWrapper from "../UI/LinkWrapper/LinkWrapper"
import * as styles from "./LinkWrappersLayout-tw-styles"

const LinkWrappersLayout = props => {
  const { linksCollection, variant } = props
  const style = styles[variant] || styles.defaultStyle
  const links = get(linksCollection, "items", [])
  return (
    <div className={style?.linkWrapperSection}>
      {links?.length &&
        links?.map((item, idx) => <LinkWrapper {...item} key={idx} />)}
    </div>
  )
}

LinkWrappersLayout.propTypes = {
  linksCollection: PropTypes.object,
  variant: PropTypes.string,
}

export default LinkWrappersLayout
