const query = (id, isPreview) => `
{
  fixedProductBrandImage(id: "${id}", preview: ${isPreview}) {
    cardsCollection {
      items {
        url
        target
        scrollToElementId
        image {
          altText
          imageDesktop {
            url
            title
            width
            height
            description
          }
          imageMobile {
            url
            title 
            width
            height
            description
          }
        }
      }
    }
    imagesCollection(limit: 30) {
      items {
        altText
        imageDesktop {
          url
          title
          width
          height
          description
        }
        imageMobile {
          url
          title
          width
          height
          description
        }
      }
    }
    showImageDescription
    link {
      title
      url
      label
      target
    }
    variant
  }
}`

module.exports = { query }
