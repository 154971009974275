import React from "react"
import PropTypes from "prop-types"
import InlineView from "./InlineView"
import ModalView from "./ModalView"
import useImage from "../../../hooks/useImage"

const Video = props => {
  const {
    sitecoreVideo,
    youtubeVideoId,
    posterImage,
    style,
    isModal,
    title,
    isImageToggle,
    enableWatchLater = false,
  } = props
  const isIframe = !!youtubeVideoId

  const { altText, foregroundImg } = useImage(posterImage)
  function getVideoLink() {
    if (isIframe) {
      const url  = enableWatchLater ? 'www.youtube.com' : 'www.youtube-nocookie.com'
      return `https://${url}/embed/${youtubeVideoId}?enablejsapi=1`
    }
    return sitecoreVideo ? sitecoreVideo.url : null
  }
  const videoLink = getVideoLink()

  return (
    <div>
      {isModal ? (
        <ModalView
          poster={foregroundImg}
          style={style}
          isIframe={isIframe}
          videoLink={videoLink}
          title={title}
        />
      ) : (
        <InlineView
          poster={foregroundImg}
          style={style}
          isIframe={isIframe}
          videoLink={videoLink}
          title={title}
          videoId={youtubeVideoId}
          isImageToggle={isImageToggle}
        />
      )}
    </div>
  )
}

Video.propTypes = {
  sitecoreVideo: PropTypes.object,
  youtubeVideoId: PropTypes.string,
  posterImage: PropTypes.object,
  style: PropTypes.object,
  isModal: PropTypes.bool,
  title: PropTypes.string,
  isImageToggle: PropTypes.bool,
  enableWatchLater: PropTypes.bool
}

export default Video
