export const sitemap = {
    "heading": "SITEMAP",
    "linksCollection": {
      "items": [
        {
          "label": "Home",
          "link": {
            "label": "Home",
            "title": "Home",
            "url": "/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": []
          }
        },
        {
          "label": "10 Years of COCOA LIFE",
          "link": {
            "label": "10 Years of COCOA LIFE",
            "title": "10 Years of COCOA LIFE",
            "url": "/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": [
              {
                "__typename": "Link",
                "label": "From Bean To Bar",
                "title": "From Bean To Bar",
                "url": "/ten-years-of-cocoa-life/from-bean-to-bar/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "Cadbury",
                "title": "Cadbury",
                "url": "/ten-years-of-cocoa-life/cadbury",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "Milka",
                "title": "Milka",
                "url": "/ten-years-of-cocoa-life/milka",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              }
            ]
          }
        },
        {
          "label": "10 Years of COCOA LIFE",
          "link": {
            "label": "10 Years of COCOA LIFE",
            "title": "10 Years of COCOA LIFE",
            "url": "/ten-stories-for-ten-years",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": []
          }
        },
        {
          "label": "The Program",
          "link": {
            "label": "The Program",
            "title": "The Program",
            "url": "/the-program/approach",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": [
              {
                "__typename": "Link",
                "label": "Approach",
                "title": "Approach",
                "url": "/the-program/approach/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "Child Protection",
                "title": "Child Protection",
                "url": "/the-program/child-protection/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "Climate Change",
                "title": "Climate Change",
                "url": "/the-program/climate-change/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "Women's Empowerment",
                "title": "Women's Empowerment",
                "url": "/the-program/womens-empowerment/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "Partners",
                "title": "Partners",
                "url": "/the-program/partners/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              }
            ]
          }
        },
        {
          "label": "In the Cocoa Origins",
          "link": {
            "label": "In the Cocoa Origins",
            "title": "In the Cocoa Origins",
            "url": "/in-the-cocoa-origins/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": [
              {
                "__typename": "Link",
                "label": "Interactive Farm Map",
                "title": "Interactive Farm Map",
                "url": "/in-the-cocoa-origins/interactive-map/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "NavigationItem",
                "label": "In Ghana",
                "link": {
                  "label": "In Ghana",
                  "title": "In Ghana",
                  "url": "/in-the-cocoa-origins/cocoa-life-in-ghana/",
                  "target": "_self",
                  "scrollToElementId": null,
                  "gaEventClass": null,
                  "gaEventLabel": null
                },
                "dropdownItemsCollection": {
                  "items": [
                    {
                      "__typename": "Link",
                      "label": "A story on Women's Empowerment in Ghana",
                      "title": "A story on Women's Empowerment in Ghana",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-ghana/a-story-on-womens-empowerment-in-ghana/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on Livelihoods in Ghana",
                      "title": "A story on Livelihoods in Ghana",
                      "url": "in-the-cocoa-origins/cocoa-life-in-ghana/a-story-on-livelihoods-in-ghana",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on Environment in Ghana",
                      "title": "A story on Environment in Ghana",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-ghana/a-story-on-environment-in-ghana/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": null,
                      "title": "A story on Farming in Ghana",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-ghana/a-story-on-farming-in-ghana/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on community in Ghana",
                      "title": "A story on community in Ghana",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-ghana/a-story-on-community-in-ghana/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    }
                  ]
                }
              },
              {
                "__typename": "NavigationItem",
                "label": "In Côte d'Ivoire",
                "link": {
                  "label": null,
                  "title": "In Côte d'Ivoire",
                  "url": "/in-the-cocoa-origins/cocoa-life-in-cote-divoire",
                  "target": "_self",
                  "scrollToElementId": null,
                  "gaEventClass": null,
                  "gaEventLabel": null
                },
                "dropdownItemsCollection": {
                  "items": [
                    {
                      "__typename": "Link",
                      "label": "A story on Environment in Cote D'ivoire",
                      "title": "A story on Environment in Cote D'ivoire",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-cote-divoire/a-story-on-environment-in-cote-divoire/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on Farming in Côte d'Ivoire",
                      "title": "A story on Farming in Côte d'Ivoire",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-cote-divoire/a-story-on-farming-in-cote-divoire/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on Community in Côte d'Ivoire",
                      "title": "A story on Community in Côte d'Ivoire",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-cote-divoire/a-story-on-community-in-cote-divoire/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    }
                  ]
                }
              },
              {
                "__typename": "NavigationItem",
                "label": "In Dominican Republic",
                "link": {
                  "label": "In Dominican Republic",
                  "title": "In Dominican Republic",
                  "url": "/in-the-cocoa-origins/cocoa-life-in-dominican-republic/",
                  "target": "_self",
                  "scrollToElementId": null,
                  "gaEventClass": null,
                  "gaEventLabel": null
                },
                "dropdownItemsCollection": {
                  "items": [
                    {
                      "__typename": "Link",
                      "label": "A story on women’s empowerment in Dominican Republic",
                      "title": "A story on women’s empowerment in Dominican Republic",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-dominican-republic/a-story-on-womens-empowerment-in-dominican-republic/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on Livelihoods in Dominican Republic",
                      "title": "A story on Livelihoods in Dominican Republic",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-dominican-republic/a-story-on-livelihoods-in-dominican-republic/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    }
                  ]
                }
              },
              {
                "__typename": "NavigationItem",
                "label": "In Indonesia",
                "link": {
                  "label": "In Indonesia",
                  "title": "In Indonesia",
                  "url": "/in-the-cocoa-origins/cocoa-life-in-indonesia/",
                  "target": "_self",
                  "scrollToElementId": null,
                  "gaEventClass": null,
                  "gaEventLabel": null
                },
                "dropdownItemsCollection": {
                  "items": [
                    {
                      "__typename": "Link",
                      "label": "A story on Community in Indonesia",
                      "title": "A story on Community in Indonesia",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-indonesia/a-story-on-community-in-indonesia/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on Youth in Indonesia",
                      "title": "A story on Youth in Indonesia",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-indonesia/a-story-on-youth-in-indonesia/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on Environment in Indonesia",
                      "title": "A story on Environment in Indonesia",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-indonesia/a-story-on-environment-in-indonesia/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    }
                  ]
                }
              },
              {
                "__typename": "NavigationItem",
                "label": "Cocoa Life in India",
                "link": {
                  "label": "Cocoa Life in India",
                  "title": "Cocoa Life in India",
                  "url": "/in-the-cocoa-origins/cocoa-life-in-india",
                  "target": "_self",
                  "scrollToElementId": null,
                  "gaEventClass": null,
                  "gaEventLabel": null
                },
                "dropdownItemsCollection": {
                  "items": [
                    {
                      "__typename": "Link",
                      "label": "A story on Environment in India",
                      "title": "A story on Environment in India",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-india/a-story-on-environment-in-india",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    },
                    {
                      "__typename": "Link",
                      "label": "A story on Women's Environment in India",
                      "title": "A story on Women's Environment in India",
                      "url": "/in-the-cocoa-origins/cocoa-life-in-india/a-story-on-womens-empowerment-in-india/",
                      "target": "_self",
                      "scrollToElementId": null,
                      "gaEventClass": null,
                      "gaEventLabel": null
                    }
                  ]
                }
              },
              {
                "__typename": "Link",
                "label": "A story on Farming in Brazil",
                "title": "A story on Farming in Brazil",
                "url": "/in-the-cocoa-origins/cocoa-life-in-brazil/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "A story on addressing Child Labor in Ghana and Cote d’Ivoire",
                "title": "A story on addressing Child Labor in Ghana and Cote d’Ivoire",
                "url": "/in-the-cocoa-origins/a-story-on-addressing-child-labor-in-ghana-and-cote-divoire/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              }
            ]
          }
        },
        {
          "label": "Progress",
          "link": {
            "label": "Progress",
            "title": "Progress",
            "url": "/progress?SearchText=*&Filter=Progress%20Blog&Filter=Progress%20Blog/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": []
          }
        },
        {
          "label": "Impact",
          "link": {
            "label": "Impact",
            "title": "Impact",
            "url": "/impact/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": []
          }
        },
        {
          "label": "Brands",
          "link": {
            "label": "Brands",
            "title": "Brands",
            "url": "/brands/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": [
              {
                "__typename": "Link",
                "label": "From Bean To Bar",
                "title": "From Bean To Bar",
                "url": "/brands/from-bean-to-bar/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "Cadbury",
                "title": "Cadbury",
                "url": "/brands/cadbury/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "Milk",
                "title": "Milka",
                "url": "/brands/milka/",
                "target": "_self",
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              }
            ]
          }
        },
        {
          "label": "Newsroom",
          "link": {
            "label": "Newsroom",
            "title": "Newsroom",
            "url": "/newsroom/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": []
          }
        },
        {
          "label": "Newsletter - Join Us!",
          "link": {
            "label": "Newsletter - Join Us!",
            "title": "Newsletter - Join Us!",
            "url": "/newsletter/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": null,
            "gaEventLabel": null
          },
          "dropdownItemsCollection": {
            "items": []
          }
        }
      ]
    }
  }