export const defaultStyle = {
    sitemapContainer: "sitemapContainer text-textBlack",
    headerMain: "headerMain mx-auto w-full min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[0px] border-b-2 border-white",
    header: "text-left font-alternate text-[40px] leading-[0.9] font-normal text-textBlack",
    linksWrprMain: "linksWrprMain mx-auto w-full min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px] border-b-2 border-white",
    linksWrpr:"linksWrpr",
    listWrpr:"block w-full",
    list:"list",
    link:"link block mb-[3px] leading-[1.5]",
    innerlistWrpr:"innerlistWrpr pl-[20px]",
    innerlist:"innerlist",
    innerlink:"innerlink block mb-[3px] leading-[1.5]",
    subinnerlistWrpr: "subinnerlistWrpr pl-[20px]",
    subinnerlink: "subinnerlink block mb-[3px] leading-[1.5]"
}