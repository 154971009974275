const query = (id, isPreview) => `
{
    twoCardsWrapper(id: "${id}", preview: ${isPreview}) {
      heading
      wrapperLink {
        url
        title
        scrollToElementId
        target
        gaEventClass
        gaEventLabel
      }
      variant
      cardsCollection(limit: 2) {
        items {
          tagName
          title
          text
          textTheme
          variant
          images {
            altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
          }
          themeImages {
            altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
          }
          link {
            url
            title
            scrollToElementId
            target
            gaEventClass
            gaEventLabel
          }
          bgTheme
        }
      }
      seeMoreLink {
          url
          label
          title
          scrollToElementId
          target
          gaEventClass
          gaEventLabel
      }
      socialLinksContainerCollection(limit:5){
      items{
        internalTitle
          label
                variant
                socialLinksCollection(limit: 5) {
                  items {
                    label
                    iconClass
                    link {
                      url
                      target
                      gaEventClass
                      gaEventLabel
                      image {
                        altText
                        imageDesktop {
                          url
                          title
                          description
                          width
                          height
                        }
                        imageMobile {
                          url
                          title
                          description
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
            }
    }
  }
`

module.exports = { query }
