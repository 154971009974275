import React from "react"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { get } from "../../helpers/utils"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import { PRIVACY_NOTICE } from "../../constants/label.constants"
import { gaEventClick } from "../../constants/gtm.constants"

const RichTextModel = props => {
  const { richText, title, link, style } = props
  const { json: jsonData, links } = richText
  const getHeadingId = value => {
    if(title == PRIVACY_NOTICE) {
      const findId = value &&  value.split(".")
      return "C".concat(findId[0])
    }
    return ""
  }
  const getEmbeddedEntryData = id => {
    const inlineData = get(links, "entries.inline", [])
    return inlineData.find(entry => entry?.sys?.id === id)
  }
  const RICHTEXT_OPTIONS = {
    renderText: text =>
      text
        .split("<br>")
        .flatMap((textContent, i) => [
          i > 0 && <br key={ Math.random().toString(36).substring(2,7)} />,
          <React.Fragment key={ Math.random().toString(36).substring(2,7)}>{textContent}</React.Fragment>,
        ]),
    RenderMark: {
      [MARKS.CODE]: text => <code key={ Math.random().toString(36).substring(2,7)} className={style?.code}>{text}</code>,
      [MARKS.SUBSCRIPT]: text => <sub key={ Math.random().toString(36).substring(2,7)} className={style?.code}>{text}</sub>,
      [MARKS.SUPERSCRIPT]: text => <sup key={ Math.random().toString(36).substring(2,7)} className={style?.code}>{text}</sup>
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Link key={ Math.random().toString(36).substring(2,7)} url={node?.data?.uri} className={style?.link}>
            {children}
          </Link>
        )
      },

      [INLINES.EMBEDDED_ENTRY]: node => {
        const entryId = get(node, "data.target.sys.id", "")
        const entry = getEmbeddedEntryData(entryId)
        let embedEntry = ""
        switch (entry?.__typename) {
          case "Link": 
            embedEntry = 
            <Link key={ Math.random().toString(36).substring(2,7)} url={entry.url} target={entry.target} className={style?.link}>
               <u><b>{entry?.label}</b></u>
            </Link>
            break
          default:
            break
        }
        return embedEntry
      },

      [BLOCKS.PARAGRAPH]: (node, children) => {
        children =
          children &&
          children.length > 0 &&
          children.filter(child =>
            typeof child === "string" && child === "" ? false : true
          )
        return (
          children &&
          children.length > 0 && <p key={ Math.random().toString(36).substring(2,7)} className={style?.paragraph}>{children}</p>
        )
      },

      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 key={ Math.random().toString(36).substring(2,7)}  id={getHeadingId(node?.content[0]?.value.trim())}>{children}</h2>
      },
      
      [BLOCKS.TABLE]: (node, children) => {
        return <table key={ Math.random().toString(36).substring(2,7)} className={style?.table}>{children}</table>
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        return <tr key={ Math.random().toString(36).substring(2,7)} className={style?.tRow}>{children}</tr>
      },

      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return <th key={ Math.random().toString(36).substring(2,7)} className={style?.tHeader}>{children}</th>
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return <td key={ Math.random().toString(36).substring(2,7)} className={style?.tData}>{children}</td>
      },

      [BLOCKS.OL_LIST]: (node, children) => {
        let listItems = []
        if (children.length > 0) {
          listItems = children.map((child, index) => (
            <span key={ Math.random().toString(36).substring(2,7)} id={index + 1}>
              {child}
            </span>
          ))
        }
        return <ol>{listItems}</ol>
      },
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul key={ Math.random().toString(36).substring(2,7)} className={style?.ulList}>{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => {
        //to remove p tage from <li>
        const normalisedListChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => (
              <li key={ Math.random().toString(36).substring(2,7)} className={style?.list}>{children}</li>
            ),
          },
        });
        return normalisedListChildren;
      },
    },
  }



  return (
    <div className={style?.richTextContainerBlock}>
      <div className={style?.richTextWrapper}> 
      {title && 
              <div className={style?.title}> 
                <div className={style?.lineOnSides}>
                  <span className={style?.titleWithLine}> {title}</span>
                </div> 
              </div>
            }
            <div className={style?.targetParagraph}>
            <div className={style?.targetParagraphElement}>
              {documentToReactComponents(jsonData, RICHTEXT_OPTIONS)}
            </div>
            </div>
      {link && <Link {...link} className={style?.mainLink} gaEventLabel={link?.label} gaEventClass={gaEventClick}/>}
      </div>
    </div>
  )
}
export default RichTextModel

RichTextModel.propTypes = {
  richText: PropTypes.object,
  title: PropTypes.string,
  link: PropTypes.object,
  style: PropTypes.object,
}
