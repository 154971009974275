export const defaultStyle = {
    sectionContainerWrapper: 'cocoaWarper text-center md:max-w-full mx-auto my-0',
    sectionContainer: 'contentBody text-textBlack targetParagraphElement',
    sectionHeadingContentPanel: '',
    title: 'textTitleLine',
    sectionTitleWrap: 'textTitleSideLineRightLeft',
    sectionTitle: 'textTitleWithLine',
    sectionMiddleContentText: '',
    sectionMiddleRow: 'fullWidthAutoParagraph',
    sectionImage: 'sectionImage mb-[16px]',
    sectionImageIcon: 'max-w-[450px] w-full h-auto mx-auto',
    linkWrpr: 'primaryBtn',
    richText:{ 
        mainLink:'primaryBtn',
    },
}

export const TOP_IMAGE_BOTTOM_CONTENT = {
     ...defaultStyle,
}


export const RIGHT_IMAGE_LEFT_CONTENT = {
    ...defaultStyle,

    sectionContainer: 'contentBody text-textBlack',
    sectionMiddleRow: 'sectionMiddleRow flex text-left flex-col md:flex-row',
    richText:'richText',
    sectionMiddleContentText:'sectionMiddleContentText mb-[90px]',
    sectionImage:'sectionImage w-full md:w-1/2 md:pl-15',
    contentWrapper:'contentWrapper w-full md:w-1/2 px-16 md:pl-0 md:pr-15',
    title: 'textTitleLine',
    sectionTitleWrap: 'textTitleSideLineRightLeft',
    sectionTitle: 'textTitleWithLine',
    sectionMiddleContentText: '',
    description:'description paraContent',
    sectionImg: 'sectionImg w-full',
}

export const RIGHT_IMAGE_LEFT_CONTENT_PLAIN_TITLE = {
    ...RIGHT_IMAGE_LEFT_CONTENT,
    sectionContainerWrapper: 'cocoaWarper plainTitleSection text-center md:max-w-full mx-auto my-0',
    sectionImage:'sectionImage w-[180px] mx-auto',
    contentWrapper:'contentWrapper w-full md:w-8/12 lg:px-30',
    title:'title font-alternate text-48 mb-16 leading-43 text-left max-w-[590px]',
    sectionTitle: 'sectionTitle',
    sectionTitleWrap: 'sectionTitleWrap',
    description:'description paraContent !mb-0 max-w-[590px]',
}

export const FIXED_WIDTH_LEFT_IMAGE_RIGHT_CONTENT = {
    ...RIGHT_IMAGE_LEFT_CONTENT,
    sectionContainerWrapper: 'cocoaWarper plainTitleSection text-center md:max-w-full mx-auto my-0',
    sectionImage:'sectionImage hidden md:block md:w-3/12 pr-[16px]',
    contentWrapper:'contentWrapper w-full md:w-9/12 md:pl-[16px]',
    title:'title font-alternate text-48 mb-16 leading-43 text-left max-w-[590px]',
    sectionTitle: 'sectionTitle',
    sectionTitleWrap: 'sectionTitleWrap',
    sectionContainer: 'contentBody text-textBlack !py-0 my-[32px] max-w-[650px]',
    sectionMiddleRow: 'sectionMiddleRow flex text-left flex-col md:flex-row',
    description:'description',
}

export const COL4_RIGHT_IMAGE_COL8_LEFT_CONTENT = {
    ...RIGHT_IMAGE_LEFT_CONTENT,
    sectionContainerWrapper: 'cocoaWarper plainTitleSection text-center md:max-w-full mx-auto my-0',
    sectionImage:'sectionImage w-full md: md:w-4/12 mx-auto md:pl-[16px]',
    contentWrapper:'contentWrapper w-full md:w-8/12 md:pr-[16px]',
    title:'title font-alternate text-48 mb-16 leading-43 text-left max-w-[590px]',
    sectionTitle: 'sectionTitle',
    sectionTitleWrap: 'sectionTitleWrap',
    sectionContainer: 'contentBody text-textBlack !py-0 mb-[32px]',
    sectionMiddleRow: "sectionMiddleRow flex text-left flex-col-reverse md:flex-row",
    description:'description max-w-[590px]',
}

export const RIGHT_IMG_COL6_LEFT_CONTENT_COL6_PLAIN_TITLE = {
    ...RIGHT_IMAGE_LEFT_CONTENT,
    sectionTitleWrap: '',
    sectionTitle: '',
    title: 'text-left font-alternate text-48 mb-16 leading-43 text-left max-w-[590px]',
    sectionMiddleRow: 'sectionMiddleRow flex text-left flex-col md:flex-row items-center',
    contentWrapper:'contentWrapper w-full md:w-1/2 md:pl-0 md:pr-30 pt-[20px]',
    description:'description paraContent max-w-[590px]',
    sectionImage:'sectionImage w-full md:w-1/2  md:pl-30 pt-50 md:pb-40 [&>img:first-of-type]:w-full [&>img:first-of-type]:max-w-[550px] [&>img:first-of-type]:mx-auto',
}

export const TOP_TEXT_BOTTOM_IMG_WITH_ORANGE_BG = {
    sectionContainerWrapper: 'cocoaWarper text-left md:max-w-full mx-auto my-0 bg-orange !w-full mb-[50px]',
    sectionContainer: 'contentBody text-textBlack max-w-[960px] mx-auto text-white',
    sectionHeadingContentPanel: '',
    title: 'title font-alternate text-textBlack text-30 leading-[29px] text-left max-w-[590px] uppercase relative left-[-10px]',
    sectionTitleWrap: '',
    sectionTitle: '',
    sectionMiddleContentText: 'sectionMiddleContentText paraContent subtitle !mt-12 !mb-[16px] md:max-w-[590px] [&>h2]:!text-48 [&>h2]:!mb-16 [&>h2]:!leading-[43.2px]',
    sectionMiddleRow: 'fullWidthAutoParagraph flex flex-col-reverse !max-w-[590px] !m-0',
    sectionImage: 'sectionImage mb-[16px]',
    sectionImageIcon: 'max-w-[590px] w-100% h-auto mx-auto',
    linkWrpr: 'primaryBtn',
    richText:{ 
        mainLink:'primaryBtn',
    },
}

export const RIGHT_IMG_COL5_LEFT_CONTENT_COL7 = {
    ...RIGHT_IMAGE_LEFT_CONTENT,
    sectionTitleWrap: '',
    sectionTitle: '',
    title: 'text-center font-alternate',
    sectionMiddleRow: 'sectionMiddleRow flex text-left flex-col-reverse md:flex-row pt-16',
    contentWrapper:'contentWrapper w-full md:w-[58.33%] md:pr-16 col7-left-content',
    description:'description paraContent max-w-[590px]',
    sectionImage:'sectionImage w-full md:w-[41.66%] md:pl-16 mb-16 md:mb-0',
    sectionTitleWrap:
    "sectionTitleWrap leading-[0.9] pt-12 mb-[50px] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[0.5px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[0.5px] after:content-[' '] after:bg-darkGrey after:z-14",
    sectionTitle: "sectionTitle wrprHeading text-[40px] bg-white px-[28px] inline-block font-alternate z-[15] relative"
}

export const RIGHT_IMG_COL5_LEFT_CONTENT_COL7_REVERSE = {
    ...RIGHT_IMAGE_LEFT_CONTENT,
    sectionTitleWrap: '',
    sectionTitle: '',
    title: 'text-center font-alternate',
    sectionMiddleRow: 'sectionMiddleRow flex text-left flex-col-reverse md:flex-row',
    contentWrapper:'contentWrapper w-full md:w-[41.66%] md:pr-16 col7-left-content',
    description:'description paraContent max-w-[590px]',
    sectionImage:'sectionImage w-full md:w-[58.33%] md:pl-16 mb-16 md:mb-0',
    sectionTitleWrap:
    "sectionTitleWrap leading-[0.9] pt-12 mb-[50px] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[0.5px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[0.5px] after:content-[' '] after:bg-darkGrey after:z-14",
    sectionTitle: "sectionTitle wrprHeading text-[40px] bg-white px-[28px] inline-block font-alternate z-[15] relative"
}

export const COL4_RIGHT_IMG_COL8_LEFT_CONTENT_WITH_TITLE = {
    ...RIGHT_IMAGE_LEFT_CONTENT_PLAIN_TITLE,
    sectionMiddleRow: 'sectionMiddleRow flex text-left flex-col-reverse md:flex-row',
    contentWrapper:'contentWrapper w-full md:w-8/12 md:pr-15',
    sectionImage:'sectionImage w-full md:w-4/12 mx-auto md:pl-15',
    title: 'textTitleLine',
    sectionTitleWrap: 'textTitleSideLineRightLeft',
    sectionTitle: 'textTitleWithLine',
    sectionMiddleContentText: '',
    description:'description paraContent',
}
export const RIGHT_IMAGE_LEFT_CONTENT_MOBILE_IMG_TOP = {
    ...RIGHT_IMAGE_LEFT_CONTENT,
    sectionContainer: 'contentBody text-textBlack !pb-10',
    sectionMiddleRow: 'sectionMiddleRow w-full flex text-left flex-col-reverse md:flex-row justify-between',
    contentWrapper:'contentWrapper w-full md:w-5/12 md:pr-15',
    sectionImage:'sectionImage w-full md:w-6/12 md:pl-15',
}
export const COL4_RIGHT_FULL_WIDTH_IMG_COL8_LEFT_CONTENT = {
    ...RIGHT_IMAGE_LEFT_CONTENT_PLAIN_TITLE,
    sectionContainer: 'contentBody text-textBlack !pt-0 !pb-0',
    contentWrapper:'contentWrapper w-full md:w-8/12 md:pr-15',
    sectionImage:'sectionImage w-full md:w-4/12 max-w-[450px] md:pl-15',
}