export const defaultStyle = {
  rangeCardWrpr: "rangeCardWrpr",
  gradTop: "hidden",
  imageBoxWrpr: "imageBoxWrpr w-full",
  imgWrpr: "imgWrpr mx-auto w-[80%]",
}
export const IMAGE_TEXT_BOX = {
  imageBoxWrpr: " block",
  imgContainer: "imgContainer ",
  imgWrpr:
    "imgWrpr flex scale-100 hover:scale-110 transiImg  rounded-[1rem] lg:w-auto lg:h-auto",
  heading:
    "absolute flex text-white font-ProxBold text-center leading-[29px] px-20 lg:px-auto lg:w-[275px] bottom-[25px] mx-auto  w-[270px] justify-center z-[1] text-[25px] lg:left-[auto] textShadow",
  contentWrapr: "flex justify-center relative",
  gradTop:
    "absolute lg:bottom-[0px] bottom-[-3px]  w-[22rem] rounded-20 h-[140px] bg-toTopGradient ",
}

export const ROW_REVERSE_CARD = {
  imageBoxWrpr:
    "cardsWrapr md:w-[750px] mx-auto py-20 flex flex-wrap justify-center",
  imgWrpr: "imgWrpr flex",
  contentWrapr:
    "contentWrapr flex md:w-1/2 flex-wrap  text-center justify-center",
  heading:
    "heading md:text-[18px] text-[16px] font-ProxBold leading-30 flex justify-center relative mt-[20px]  items-end",
  description:
    "description text-lightGrey md:text-[18px] text-[16px] px-[13px] lg:px-[auto] md:leading-[30px] leading-[28px]",
  imgContainer: "imgContainer flex justify-center md:w-1/2 mx-auto",
}
export const FIVE_COLUMN_CARDS = {
  imgWrpr:
    "h-min hoverborder overflow-hidden rounded-[30px] px-auto text-sm tracking-wide philyImg text-darkBlue font-medium before:pr-5 w-[195px] grid lg:w-[180px]",
  contentWrpr: "relative",
  heading:
    "text-xl leading-[20px] absolute flex text-white font-ProxBold px-auto  bottom-[60px] w-full justify-center z-[1] w-auto tracking-[0]",
  description:
    "text-white absolute text-[15px] px-auto lg:w-auto md:w-[195px] bottom-[40px] text-center  flex justify-center z-[1] leading-[20px]",
  gradTop:
    "absolute bottom-[0px]  w-[195px] rounded-b-[2rem] h-[140px] bg-toTopGradient max-w-full",
  contentWrapr: "contentWrapr flex justify-center w-full pb-12",
  imageBoxWrpr:
    "imageBoxWrpr min-h-[180px]  md:min-w-[180px] min-w-[160px] fivecards",
}
export const IMAGE_TEXT_COLUMN = {
  imgWrpr: "imgWrpr w-[160px] my-0 mx-auto",
  imageBoxWrpr: "imageBoxWrpr lg:w-[250px]",
  contentWrapr: "contentWrapr",
  heading: "flex justify-center font-ProxBold text-[25px] mb-10",
  description:
    "abtPldehia description text-lightGrey text-center leading-[30px] px-15 text-[18px] lg:w-auto w-3/4 mx-auto tracking-[0]",
}
export const FOUR_IMG_TEXT_CARD = {
  imageBoxWrpr: "lg:w-[250px] md:w-[290px] md:h-[332px] mb-30 md:mb-auto",
  imgWrpr: "imgWrpr w-[160px] my-0 mx-auto",
  heading: "flex justify-center font-ProxBold text-[25px] mb-5",
  description:
    "abtPldehia description text-lightGrey text-center leading-[30px] lg:px-17 text-[18px] lg:w-auto w-[54%] md:w-full mx-auto",
}

export const TWO_BY_TWO_CARDS = {
  contentWrpr: "contentWrpr flex w-full text-[33px] font-bold justify-center",
  imageBoxWrpr: "imageBoxWrpr w-2/5 ",
  title: "title text-[50px] font-normal",
  imgWrpr: "imgWrpr",
  description: "description text-lightGrey text-center leading-[30px] px-20",
}
export const BLUE_TWO_COLUMN = {
  imageBoxWrpr:
    "lg:w-[555px] lg:h-[555px] w-[100%] h-auto mb-20px bg-[#00329f] rounded-[2rem] bluLinkWrpr flex flex-wrap justify-center",
  contentWrapr: "relative lg:bottom-[5rem] md:bottom-[4.4rem] bottom-[1.4rem]",
  imgWrpr:
    "mx-auto mt-0 hover:scale-[1.02] transition-all duration-1000 mx-auto lg:w-[483px] lg:h-[483px] md:w-[522px] md:h-[522px] w-[314px] h-[314px]",
  heading:
    "BLUE_TWO_COLUMNHdng font-ProxNarrow px-29 text-[25px] leading-[25px] tracking-[1px] lg:pb-30 pb-20 text-white text-center min-h-[50px] lg:w-[65%] mx-auto my-0 ",
  description: "buttonRange font-ProxNarrow",
  linkWrpr: "buttonRange font-ProxNarrow",
}
export const THREE_COLUMN_IMAGE_CARDS = {
  imageBoxWraper:
    "lg:overflow-hidden lg:w-[354px] w-[95%] m-12 lg:p-auto lg:h-[354px] reciepImgCrd rounded-[20px]",
  imageBoxWrpr: " imageBoxWrpr",
  imgWrpr:
    "mx-auto  rounded-[20px] w-full h-full  hover:scale-[1.05] transition-all duration-1000",
  contentWrpr:
    "flex justify-center h-12 text-2xl relative inset-x-0 bottom-80 text-center w-full ",
  heading:
    "absolute flex text-white font-ProxBold font-normal text-center leading-[29px] px-auto w-[228px] bottom-[35px] mx-auto justify-center z-[1] text-[25px]",
  contentWrapr: "relative flex justify-center",
  gradTop:
    "absolute lg:bottom-[0px] bottom-[0]  w-[100%] rounded-20 h-[140px] bg-toTopGradient",
}
export const SLIDE_ONE_SINGLE_CARD = {
  imageBoxWrpr: "imageBoxWrpr p-40 mt-30 w-full ",
  imgContainer: "imgContainer",
  description:
    "description singleCrdCrsl text-24 text-lighterBlue absolute mt-[-54px] ml-[248px]",
  contentWrapr: "contentWrapr relative font-bold",
}
export const MULTI_SECTION_CAROUSEL_SUB_SLIDER_ONE = {
  imageBoxWrpr:
    "imageBoxWrpr w-full md:w-[58%] lg:w-[90%] mx-auto homeBanneImgWrpr",
  imgWrpr:
    "w-[85%] mx-auto lg:w-[90%] lg:ml-[-10x] lg:pr-30 md:relative lg:static md:top-[70px] md:w-[109%] mainBnrImg",
  imgContainer: "imgContainer flex lg:mt-[60px] ",
  description:
    "description singleCrdCrsl md:top-[-70px] lg:top-auto md:right-[-108px] lg:right-0 lg:top-0 md:text-[28px] lg:text-[24px] text-[21px] font-ProxBold font-normal text-[#5bc2e7] w-[60%] lg:w-1/2 absolute leading-[26px] md:leading-[35px] lg:leading-[30px] text-center lg:mt-[-25px] lg:ml-[265px] textShadow",
  contentWrapr:
    "contentWrapr relative font-bold lg:left-[-10px] lg:top-[-17px] lg:w-auto w-full justify-center flex",
}
export const MULTI_SECTION_CAROUSEL_SUB_SLIDER_TWO = {
  imageBoxWrpr:
    "SUB_SLIDER_Two  imageBoxWrpr lg:p-20 mt-30 md:mt-0 md:w-[64%] lg:w-full md:mx-auto mx-[17%] flex",
}
export const MULTI_SECTION_CAROUSEL_SUB_SLIDER_THREE = {
  heading: "heading SVG",
  description: "description SUB_SLIDER_THREE",
  imageBoxWrpr: "SUB_SLIDER_THREE  imageBoxWrpr px-60 mt-30 w-full ",
}
export const MULTI_SECTION_CAROUSEL_SUB_SLIDER_FOUR = {
  imageBoxWrpr: "imageBoxWrpr four w-full flex flex-wrap px-10 lg:pb-20 ",
  contentWrapr: "w-full justify-center items-center flex relative top-[-15px]",
  heading: "flex justify-center text-[31px] text-darkblue font-ProxBold mt-10 ",
}
export const recipePageSlider = {
  imgContainer: "block text-center overflow-hidden rounded-20",
  imgWrpr:
    "h-min  rounded-20 px-auto text-sm tracking-wide text-darkBlue font-medium before:pr-5 w-[195px] grid lg:w-[180px] hover:scale-[1.05] transition-all duration-1000",
  contentWrpr: "relative",
  heading:
    "text-3xl absolute flex text-white font-bold px-auto bottom-[30px] w-full justify-center z-[1]",
  description:
    "text-white absolute text-[15px] px-auto w-[195px] bottom-[10px] text-center  flex justify-center z-[1]",
  gradTop:
    "absolute bottom-[0px]  w-full rounded-20 h-[140px] bg-toTopGradient",
  contentWrapr: "contentWrapr relative",
}

export const BOTTOM_RECEPE_CARDS = {
  imgWrpr: "imgWrpr flex  rounded-[1rem] scale-100 hover:scale-110 transiImg",
  heading:
    "absolute flex text-white font-ProxBold text-center leading-[29px] px-auto lg:w-[239px] bottom-[35px] mx-auto w-full justify-center z-[1] lg:text-[25px]",
  contentWrapr: "flex justify-center",
  gradTop:
    "absolute lg:bottom-[0px] bottom-[-3px]  w-[100%] rounded-20 h-[140px] bg-toTopGradient md:w-[398px]  md:left-0",
}
export const fullScreenMenu = {
  imageBoxWrpr: "imageBoxWrpr w-2/5 ",
  imgContainer: "imgContainer flex p-15 w-[150px]",
  imgWrpr: " imgWrpr w-[150px] [h-150px]",
  heading: "text-[17px] ",
}

export const TAB_STYLES = {
  imageBoxWrpr: "imageBoxWrpr w-2/5 ",
  imgContainer: "imgContainer flex p-15 w-[150px]",
  imgWrpr: " imgWrpr w-[150px] [h-150px]",
  heading: "text-[17px] ",
}

export const Similar_Recipe_variant = {
  imageBoxWraper:
    "lg:overflow-hidden lg:w-[256px] lg:p-auto lg:h-full reciepImgCrd rounded-[20px] m-10",
  imageBoxWrpr: "",
  imgWrpr:
    "mx-auto rounded-[20px] w-full h-full scale-100 hover:scale-[1.05] transiImg ",
  contentWrpr:
    "flex justify-center h-12 text-2xl relative inset-x-0 bottom-80 text-center w-full ",
  heading:
    "absolute flex text-white font-bold text-center leading-[29px] px-auto w-[239px] bottom-[35px] mx-auto justify-center z-[1] text-[25px]",
  contentWrapr: "relative flex justify-center",
  gradTop:
    "absolute bottom-[0px]  w-[100%] rounded-20 h-[140px] bg-toTopGradient",
}
export const TWO_COLUMN_IMAGE_CARDS = {
  imgContainer: "imgContainer",
  imageBoxWraper:
    "lg:overflow-hidden lg:w-[256px] lg:p-auto lg:h-full reciepImgCrd rounded-[20px] m-10 classicimagewidth",
  imageBoxWrpr: " ",
  imgWrpr:
    "mx-auto rounded-[20px] w-full h-full scale-100 hover:scale-[1.05] transiImg",
  contentWrpr:
    "flex justify-center h-12 text-2xl relative inset-x-0 bottom-80 text-center w-full ",
  heading:
    "absolute flex text-white font-bold text-center leading-[29px] px-auto w-[239px] bottom-[35px] mx-auto justify-center z-[1] text-[25px]",
  contentWrapr: "relative flex justify-center",
  gradTop:
    "absolute bottom-[0px]  w-[100%] rounded-20 h-[140px] bg-toTopGradient",
}

export const FOUR_IMAGE_SLIDER = {
  imgContainer: "h-full bg-cover bg-center bg-no-repeat",
  imageBoxWrpr:
    "h-[540px] md:h-[100%] md:min-h-[340px] overflow-hidden relative z-[9999]",
  heading:
    "heading w-full text-white leading-[0.9] text-[40px] uppercase text-center mb-16 font-alternate",
  description:
    "w-full text-white text-17 leading-24 mb-16 text-center font-brand",
  contentWrapr:
    "max-w-[440px] m-auto flex flex-col w-full h-full items-center justify-center px-15 md:px-30 pb-6",
  contentWraprBox: "bg-[#0000004d] absolute top-0 left-0 right-0 bottom-0 imgOverlay",
  linkWrpr:
    "bg-lightGreen hover:bg-green w-full max-w-[270px] h-40 text-30 leading-40 text-white text-center rounded-38 cursor-pointer transition-all duration-300 ease-out font-alternate",
}

export const MONTHLY_ACTIVITIES_TWO_COLUMN = {
  contentWraprBox:"contentWraprBox w-full md:w-[66.66%] md:pl-[16px] md:mt-0",
  imageBoxWrpr: "cardsWrapr w-full flex-column md:flex justify-around mx-auto pt-[16px] md:pt-[24px] md:mb-[32px]",
  imgWrpr: "imgWrpr w-[210px] mx-auto mt-0 mb-[24px] md:mb-0 md:mt-[-40px]",
  contentWrapr:"contentWrapr",
  heading: "heading text-[28.8px] md:text-[32px] w-[40px] h-[40px] md:w-[45px] md:h-[45px] rounded-[50%] bg-lightGreen text-white font-alternate text-center leading-[1.5] absolute left-0 right-0 text-center mt-[0px] mb-[0px] mx-auto top-[0]",
  description: "description text-textBlack w-full",
  imgContainer: "imgContainer w-full md:w-[33.33%] md:pr-[16px]",
  imageBoxWraper: "imageBoxWraper pt-[0]",
  activeHeading : "activeHeading !pt-[45px]",
}

export const MONTHLY_ACTIVITIES_ARROW_IMAGE = {
  contentWraprBox:"contentWraprBox hidden",
  imageBoxWrpr: "cardsWrapr arrowContent w-full flex-column md:flex justify-around mx-auto md:pt-[16px] md:mb-[16px]",
  imgWrpr: "imgWrpr w-[68.5%] mx-auto d-block mt-[-32px] mx-auto !hidden md:!block mb-8",
  contentWrapr:"contentWrapr",
  heading: "heading text-[32px] w-[45px] h-[45px] rounded-[50%] bg-lightGreen text-white font-alternate text-center leading-[1.5] absolute left-0 right-0 text-center mx-auto top-0",
  description: "description text-textBlack w-full",
  imgContainer: "imgContainer w-full text-center",
}

export const CARDS_WITH_BG_COLOR = {
  imgWrpr: "m-auto w-auto h-[60px] min-h-[60px] min-w-[40px]",
  contentWrpr: "relative",
  heading: "text-xl absolute flex text-white font-ProxBold px-auto  bottom-[60px] w-full justify-center z-[1]",
  contentWrapr: "contentWrapr flex justify-center w-full",
  imageBoxWrpr: "imageBoxWrpr min-h-[180px]  md:min-w-[180px] min-w-[160px]",
  description: 'text-textBlack text-17 leading-none font-brand',
  imageBoxWraper: 'inner-wrapper md:min-h-[170px] max-[768px]:h-[210px]',
  imageBoxWrpr: 'sub-wrapper py-10 px-[15%] md:p-10',
  imgContainer: 'img-icon',
  
  contentWraprBox: "content-wrapper",
  contentWrapr: "inner-wrapper",
}
export const BLOG_CARD_WITH_BG_IMG = {
  tagHeading: "tagHeading left-[-10px] relative mb-[12px] font-alternate text-[30.4px] leading-[0.96] uppercase md:max-w-[420px]",
  imageBoxWrpr: "imageBoxWrpr w-full",
  imgWrpr: "imgWrpr mx-auto invisible",
  imageBoxWrpr: "cardsWrapr w-full",
  imgContainer: "imgContainer w-full",
  imageBoxWraper:"cardsContainerWrapr w-full",
  contentWraprBox: "contentWraprBox",
  contentWrapr: "contentWrapr px-15 min-[567px]:px-[30px] pt-[50px] pb-[30px] md:max-w-[480px]",
  heading: "heading big text-[48px] max-w-[590px] mb-[10px] uppercase font-alternate leading-[0.9] text-white",
  description: "description text-white md:max-w-[420px] mb-[10px]",
  arrowIcon: "arrowIcon arrowWhiteImg after:min-w-[30px] after:mt-[16px] after:bg-no-repeat after:h-[30px] after:bg-contain after:block",
}
export const BLOG_CARD_WITH_THEME = {
  tagHeading: "tagHeading left-[-10px] relative mb-[12px] font-alternate text-[30.4px] leading-[0.96] uppercase",
  imageBoxWrpr: "imageBoxWrpr w-full",
  imgWrpr: "imgWrpr mx-auto invisible",
  imageBoxWrpr: "cardsWrapr w-full",
  imgContainer: "imgContainer w-full",
  imageBoxWraper:"cardsContainerWrapr w-full",
  contentWraprBox: "contentWraprBox",
  contentWrapr: "contentWrapr px-15 min-[567px]:px-[30px] pt-[50px] pb-[30px] md:max-w-[480px]",
  heading: "heading big text-[48px] max-w-[590px] mb-[10px] uppercase font-alternate leading-[0.9] text-white",
  description: "description text-white mb-[10px]",
  arrowIcon: "arrowIcon arrowWhiteImg after:min-w-[30px] after:mt-[16px] after:bg-no-repeat after:h-[30px] after:bg-contain after:block",
}
export const BLOG_CARD_WITH_THEME_BRAND_IMAGE = {
  ...BLOG_CARD_WITH_THEME,
  arrowIcon: "arrowIcon arrowWhiteImg",
  heading: "heading big text-[40px] max-w-[590px] mb-[10px] uppercase font-alternate leading-[0.9] text-white",
  description: "brandpage_desc description text-white mb-[10px]",
}
export const BLOG_CARD_WITH_THEME_SMALL_TEXT = {
  ...BLOG_CARD_WITH_THEME,
  heading: "heading big text-[40px] max-w-[590px] mb-[10px] uppercase font-alternate leading-[0.9] text-white",
}
export const TESTIMONIAL_TOP_IMAGE_BOTTOM_TEXT ={
  imageBoxWrpr: "imageBoxWrpr w-full",
  imgWrpr: "imgWrpr mx-auto w-full max-w-[340px] mb-[22px] block rounded-[50%]",
  imageBoxWrpr: "cardsWrapr w-full",
  imgContainer: "imgContainer w-full",
  imageBoxWraper:"cardsContainerWrapr w-full",
  contentWraprBox: "contentWraprBox",
  contentWrapr: "contentWrapr",
  description: "description text-textBlack mb-0",
}

export const TEXT_CARD = {
  ...defaultStyle,
  imageBoxWrpr: "imageBoxWrpr w-full p-[24px]",
  tagHeading:'tagHeading',
  heading:'heading font-alternate text-[30.4px] leading-[0.96] pt-[32px] !mb-[12px]',
  description:'description [&>p:not(:last-child)]:mb-16',
}

export const TESTIMONIAL_ONLY_TEXT = {
...defaultStyle,
imageBoxWrpr: "imageBoxWrpr w-full onlyText max-w-[590px] py-[32px]",
description:'description testimonialDetails font-normal text-17 [&>p:first-child]:leading-[26px] leading-[23px]',
}

export const TESTIMONIAL_LEFT_TEXT_RIGHT_IMAGE = {
  ...TESTIMONIAL_TOP_IMAGE_BOTTOM_TEXT,
  imageBoxWraper:"testimonialLeftText testimonialRightImage my-32",
  description: "description testimonialDetails font-normal text-17 [&>p:first-child]:leading-[26px] leading-[23px]",
  imageBoxWrpr: "img-text-wrpr flex min-[567px]:flex-row-reverse flex-col-reverse justify-end min-[567px]:items-center",
  contentWraprBox: "contentWrapper md:pr-60 min-[567px]:w-9/12 min-[900px]:w-auto",
  imgWrpr: "image min-w-[150px] min-[567px]:min-w-full md:min-w-[150px] block",
  imgContainer: "imgContainer w-[158px] min-[567px]:w-3/12 md:w-[158px] max-w-[158px] pl-8",
  contentWrapr: "contentWrapr max-w-[590px]",
}
export const TESTIMONIAL_LEFT_IMAGE_RIGHT_TEXT = {
  ...TESTIMONIAL_TOP_IMAGE_BOTTOM_TEXT,
  imageBoxWraper:"testimonialLeftImage testimonialRightText my-32",
  description: "description testimonialDetails font-normal text-17 [&>p:first-child]:leading-[26px] leading-[23px]",
  imageBoxWrpr: "img-text-wrpr flex min-[567px]:flex-row flex-col-reverse justify-between min-[567px]:items-center",
  imgContainer: "imgContainer w-[158px] min-[567px]:w-3/12 md:min-w-[158px] max-w-[158px] pl-8",
  contentWraprBox: "contentWrapper md:pr-60  min-[567px]:w-[76%]",
  contentWrapr: "contentWrapr max-w-[590px]",
  imgWrpr: "image min-w-[150px] min-[567px]:min-w-full md:min-w-[150px] block",
}

export const BLOG_LONG_TITLE_TEXT= {
  ...BLOG_CARD_WITH_BG_IMG,
  description: "description text-white mb-[10px] [&>p:first-child]:mb-16 [&>p:first-child]:text-40 [&>p:first-child]:font-alternate [&>p:first-child]:leading-[36px] [&>p:first-child]:uppercase",
}

export const TOP_IMAGE_BOTTOM_TEXT_FIXED_IMG_WIDTH ={
  ...TESTIMONIAL_TOP_IMAGE_BOTTOM_TEXT,
  imgWrpr: "imgWrpr mx-auto w-full max-w-[190px] mb-[32px] block",
  description: "description text-textBlack mb-0 max-w-[590px]",
}

export const BLOG_CARD_WITH_THEME_SMALL_TEXT_ENVELOPE = {
  ...BLOG_CARD_WITH_THEME,
  contentWraprBox: "contentWraprBox relative z-[10]",
  imageBoxWrpr: "cardsWrapr w-full cardsWraprEnvelop relative",
  heading: "heading big text-[40px] max-w-[590px] mb-[10px] uppercase font-alternate leading-[0.9] text-white",
}

