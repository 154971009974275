import React from "react"
import { screen, render, cleanup } from "@testing-library/react"
import ImageWithText from "./ImageWithText"
import { imageWithText } from "./ImageWithText.mock"
import "@testing-library/jest-dom"

describe("render ImageCardsSlider component", () => {
    beforeEach(() => {
        render(<ImageWithText {...imageWithText} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render Heading", () => {
        const heading = screen.getByText('Heading mock')
        expect(heading).toBeInTheDocument()
    })
    test("render description", () => {
        const heading = screen.getByText('description mock')
        expect(heading).toBeInTheDocument()
    })
    test("render Image", () => {
        const img = screen.getByRole('presentation')
        expect(img).toBeInTheDocument()
        expect(img).toHaveAttribute("src");
    })
})