export const footerCollection = {
    "copyRight": "copyRight",
    "socialLinksCollection": {
        "items": [
            {
                "label": null,
                "variant": "LINKS_CONTAINER_LEFT",
                "socialLinksCollection": {
                    "items": [
                        {
                            "label": "Subscribe to newsletter",
                            "iconClass": "fa fa-envelope",
                            "link": {
                                "url": "/newsletter/",
                                "target": "_self",
                                "image": null
                            }
                        },
                        {
                            "label": "Watch Cocoa Life Youtube playlist",
                            "iconClass": "fa fa-youtube",
                            "link": {
                                "url": "https://www.youtube.com/playlist?list=PL-1_Yah-HlFnEM9Wej2mKcWze-72r5UJH",
                                "target": "_blank",
                                "image": null
                            }
                        },
                        {
                            "label": "Frequently asked questions",
                            "iconClass": "fa fa-question",
                            "link": {
                                "url": "/faq/",
                                "target": "_self",
                                "image": null
                            }
                        }
                    ]
                }
            },
            {
                "label": "Share this page",
                "variant": "LINKS_CONTAINER_RIGHT",
                "socialLinksCollection": {
                    "items": [
                        {
                            "label": null,
                            "iconClass": "fa fa-share",
                            "link": {
                                "url": "mailto:?Subject=Cocoa%20Life&Body=%0A%0ACocoa%20Life%20-%20https%3A%2F%2Fwww.cocoalife.org",
                                "target": "_blank",
                                "image": null
                            }
                        },
                        {
                            "label": null,
                            "iconClass": "fa fa-facebook",
                            "link": {
                                "url": "https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.cocoalife.org/impact",
                                "target": "_blank",
                                "image": null
                            }
                        },
                        {
                            "label": null,
                            "iconClass": "fa fa-twitter",
                            "link": {
                                "url": "https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.cocoalife.org&hashtags=CocoaLife",
                                "target": "_blank",
                                "image": null
                            }
                        }
                    ]
                }
            }
        ]
    },
    "mainLinksCollection": {
        "items": [
            {
                "label": null,
                "linksCollection": {
                    "items": [
                        {
                            "label": "Privacy Notice",
                            "title": null,
                            "url": "/privacy-notice/",
                            "target": "_self",
                            "scrollToElementId": null,
                            "gaEventClass": "event_external_link",
                            "gaEventLabel": "Privacy Notice",
                            "image": null
                        },
                        {
                            "label": " Terms of Service",
                            "title": null,
                            "url": "/terms-of-service/",
                            "target": "_self",
                            "scrollToElementId": null,
                            "gaEventClass": "event_external_link",
                            "gaEventLabel": " Terms of Service",
                            "image": null
                        },
                        {
                            "label": "Sitemap",
                            "title": null,
                            "url": "/sitemap/",
                            "target": "_self",
                            "scrollToElementId": null,
                            "gaEventClass": "event_external_link",
                            "gaEventLabel": "Sitemap",
                            "image": null
                        },
                        {
                            "label": "Contact Us",
                            "title": null,
                            "url": "/contact-us/",
                            "target": "_self",
                            "scrollToElementId": null,
                            "gaEventClass": "event_external_link",
                            "gaEventLabel": "Contact Us",
                            "image": null
                        },
                        {
                            "label": "Cookie Policy",
                            "title": null,
                            "url": "/cookie-policy/",
                            "target": "_self",
                            "scrollToElementId": null,
                            "gaEventClass": "event_external_link",
                            "gaEventLabel": "Cookie Policy",
                            "image": null
                        }
                    ]
                }
            }
        ]
    },
    "brandLinksCollection": {
        "items": [
            {
                "url": "https://www.mondelezinternational.com/europe",
                "image": {
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/1V6TCRFNVPWykiwd8ObthJ/e0a4666c7f553b6216ebaa4a867fc64e/cocoa_mondelez_logo.png",
                    "title": "cocoa-mondelez-logo",
                    "description": "",
                    "width": 4072,
                    "height": 1458
                }
            }
        ]
    }
}