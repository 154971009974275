export const onDotClick = (dotsListId, slidesToShow) => {
  if(slidesToShow === Math.round(slidesToShow)) return
    const arr = Array.from(document.getElementById(dotsListId).children)
    setTimeout(() => {
      if(!arr.find(ele => ele.className.includes("slick-active"))){
        arr[arr.length-1].classList.add("slick-active")
      }else{
        arr[arr.length-1].classList.remove("slick-active")
      }
    }, 100)
  }