import React from "react"
import PropTypes from "prop-types"
import Facts from "../Facts/Facts"
import { get } from "../../helpers/utils"
import Image from "../UI/Image/Image"
import * as styles from "./Map-tw-styles"

const Map = props => {
  const { title, backgroundImage, factsCollection } = props
  const facts = get(factsCollection, "items", [])
  const style = styles["defaultStyle"]
  return (
    <>
      <div className={style?.sectionContentPanel} id={`${backgroundImage?.title}`}>
        <div className={style?.sectionTitleWrap}>
          <span className={style?.sectionTitle}>{title}</span>
        </div>

        <div className={style?.sectionMiddleRow}>
          {backgroundImage && (
            <Image
              contentfulImage={backgroundImage}
              className={style?.sectionImageIcon}
            />
          )}
          {facts?.length > 0 &&
            facts?.map((fact, idx) => <Facts {...fact} key={idx} id={idx}/>)}
        </div>
      </div>
    </>
  )
}

Map.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.object,
  factsCollection: PropTypes.object,
}

export default Map;