import React from "react"
import PropTypes from "prop-types"
import Link from "../Link/Link"
import { imageConstants } from "../../../constants/image.constants"
import { textThemes } from "../../../constants/variants.constants"
import Image from "../Image/Image"
import Typography from "../Typography/Typography"
import * as styles from "./LinkWrapper-tw-styles"

const LinkWrapper = props => {
  const { linkLabel, variant, link, textTheme } = props
  const style = styles[variant] || styles.defaultStyle
  const { linkWrapper, linkClass, icon, imgWrapperClass, linkText } = style
  const blackArrow = linkWrapper.includes('darkArrow');
  const textColor = textThemes[textTheme] || "text-textBlack"
  return (
    <div className={linkWrapper}>
      <Link {...link} className={linkClass}>
        <div className={imgWrapperClass}>
          <Image
            contentfulImage={blackArrow?imageConstants?.arrowBlack:imageConstants?.arrowWhite}
            className={icon}
          />
        </div>
        {linkLabel && (
          <Typography
            content={linkLabel}
            className={`${linkText} ${textColor}`}
            linkClass={style?.linkTextColor}
          />
        )}
      </Link>
    </div>
  )
}

LinkWrapper.propTypes = {
  linkLabel: PropTypes.string,
  variant: PropTypes.string,
  link: PropTypes.object,
  textTheme: PropTypes.string,
}

export default LinkWrapper
