export const backLink = {
    "link": {
      "label": "See overview of all 'BRANDS'",
      "title": null,
      "url": "/brands/",
      "target": "_self",
      "scrollToElementId": null,
      "gaEventClass": null,
      "gaEventLabel": null
    }
  }