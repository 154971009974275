const query = (id, isPreview) => `
{
  imageAndText(id: "${id}", preview: ${isPreview}) {
   title
    imageTextCollection(limit:10){
      items{
        imageText
        imageCollection(limit:20){
          items{
                    altText
                    imageDesktop {
                      url
                      title
                      description
                      width
                      height
                    }
                    imageMobile {
                      url
                      title
                      description
                      width
                      height
                    }  
          }
        }
      }
    }
  }
}
`
module.exports = { query }
