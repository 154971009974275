export const twoSectionLayout = {
        "sectionTitle": "sectionTitle",
        "middleContent": "middleContent",
        "imageLeft": {
            "title": "imageLeft",
            "description": "",
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/7d8GtIa8mVx5pDk4i6k7R1/694db2197b0488c22c999a25468ce081/conservation-forests.png",
            "height": 688,
            "width": 950
        },
        "imageRight": {
            "title": "imageRight",
            "description": "",
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/7d8GtIa8mVx5pDk4i6k7R1/694db2197b0488c22c999a25468ce081/conservation-forests.png",
            "height": 688,
            "width": 950
        },
        "link": null,
        "description": "## __COMBATTING DEFORESTATION AND BUILDING CLIMATE CHANGE RESILIENCE__\nCocoa Life is committed to eliminating deforestation, maintaining cocoa ecosystems and protecting the land and the forests – it’s our promise to future generations.\n\nCocoa farmers are already seeing the impacts of climate change and if we don’t take action, current cocoa-producing regions may no longer be suitable for cocoa production in the next 30 years.\n\nThrough Cocoa Life we invest in farmer training and adoption of good practices which can include agroforestry, mobilise communities to protect forests, plant trees and more. But we cannot do this alone. Partnerships are key to lasting change and we work together with the industry and governments to put the right policies and plans in place.",
        "variant": "RIGHT_IMAGE_LEFT_CONTENT",
        "id": null
    }
