export const defaultStyle = {
    containerWrapper: 'videoWrapper w-full md:table border-b-2 border-white',
    cardWrapper: 'videoWrapperEach text-textBlack w-full md:table-cell md:w-[50%] border-white md:border-l-1 md:border-r-1',
    titleContainer: 'titleContainer',
    videoCard: 'videoCard',
    videoframe: "h-full w-full relative pt-[30px] pb-[54.15%]",
    videoItem: "h-full w-full max-w-full top-0 left-0 absolute",
    videoCntr: "videocontainer relative max-w-full",
    title:'TitleClass'
}
