import React from "react"
import PropTypes from "prop-types"
import Image from "../UI/Image/Image"
import Link from "../UI/Link/Link"
import Typography from "../UI/Typography/Typography"
import * as styles from "./TwoSectionLayout-tw-styles"
import "../../styles/textContent.css"
import useImage from "../../hooks/useImage"

const TwoSectionLayout = props => {
  const {
    sectionTitle,
    middleContent,
    imageLeft,
    imageRight,
    link,
    description,
    variant,
    id = "",
  } = props

  const style = styles[variant] || styles["defaultStyle"]
  const { altText: atlTextLeft, foregroundImg: leftImage } = useImage(imageLeft)
  const { altText: atlTextRight, foregroundImg: rightImage } =
    useImage(imageRight)
  const { altText: atlTextRightwithLink, foregroundImg: rightImagewithLink } =
    useImage(link?.image)

  return (
    <>
      <div className={style?.sectionContainerWrapper} id={id}>
        <div className={style?.sectionContainer}>
          <div className={style?.sectionHeadingContentPanel}>
            <div className={style?.title}>
              <div className={style?.sectionTitleWrap}>
                <span className={style?.sectionTitle}>{sectionTitle}</span>
              </div>
            </div>
            {middleContent && (
              <Typography className={style?.sectionMiddleContentText} content={middleContent} />
            )}
          </div>
          <div className={style?.sectionMiddleRow}>
            {leftImage && (
              <div className={style?.sectionImage}>
                <Image
                  contentfulImage={leftImage}
                  className={style?.sectionImageIcon}
                  alt={atlTextLeft}
                />
              </div>
            )}
            <div className={style?.contentWrapper}>
              {description && (
                <Typography
                  content={description}
                  allow_br_tag={true}
                  className={style?.description}
                />
              )}
              {link?.label && <Link {...link} className={style?.linkWrpr} />}
            </div>
            {link?.image &&<div className={style?.sectionImage}> <Link {...link} > <Image contentfulImage={rightImagewithLink} alt={atlTextRightwithLink} className={style?.sectionImg} /></Link></div>}
            {rightImage && (
              <div className={style?.sectionImage}>
                <Image contentfulImage={rightImage} alt={atlTextRight} className={style?.sectionImg} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

TwoSectionLayout.propTypes = {
  sectionTitle: PropTypes.string,
  middleContent: PropTypes.string,
  imageLeft: PropTypes.object,
  imageRight: PropTypes.object,
  link: PropTypes.object,
  description: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.string,
}

export default TwoSectionLayout
