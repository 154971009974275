const query = (isPreview) => `{
  headerCollection(limit: 1, preview: ${isPreview}) {
    items {
      logoContainerCollection(limit: 5) {
        items {
          url
          target
          gaEventClass
          gaEventLabel
          image {
            altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
          }
        }
      }
      navigationCollection(limit: 10) {
        items {
          label
          link {
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
          }
          dropdownItemsCollection(limit: 10) {
            items {
              ... on Link {
                __typename
                url
                label
                target
                scrollToElementId
              }
            }
          }
        }
      }
      countriesSelectorCollection(limit: 10) {
        items {
          label
          value
          dataPath
        }
      }
    }
  }
}
`

module.exports = { query }
