export const formContainer = {
    "heading": "THERE IS A FUTURE IN COCOA AND IT IS BEING PLANTED TODAY! FOLLOW OUR PROGRESS AND SIGN UP TO OUR NEWSLETTER.",
    "formFields": {
      "text": "Not Registered?",
      "submit": {
        "name": "submit",
        "error": "Some information seems incomplete or incorrect. To make corrections please to continue.",
        "label": "Submit"
      },
      "heading": "Free Education <strong>Book</strong>",
      "bookType": 1,
      "variant": "formInputStyle",
      "checkbox": {
        "aggrementCheckbox": {
          "icon": "",
          "name": "aggrementCheckbox",
          "error": "",
          "label": "<p>If you would like to receive future news, information on trends and new products by email or paper, please tick this box. If you no longer wish to receive emails or paper, you may unsubscribe <a href='https://contactus.mdlzapps.com/form?siteId=mZxEmnBvrkt3Ey1XJ8o8Vpuy6OncMIjle70mYoYdMP4%3d'>here</a>.</p>  ",
          "rules": [
            {
              "type": "CHECKBOX",
              "errorMessage": "If you would like to receive future news from Cocoa Life by email, please tick this box. If you no longer wish to receive emails, may unsubscribe here (*). must be filled in."
            }
          ],
          "value": true,
          "isValid": true,
          "fieldType": "checkbox",
          "isRequired": true,
          "submittion": true,
          "elementType": "input",
          "elementConfig": {
            "type": "checkbox",
            "placeholder": ""
          }
        }
      },
      "fieldList": {
        "email": {
          "icon": "",
          "name": "email",
          "error": "",
          "label": "Email",
          "rules": [
            {
              "type": "REQUIRED",
              "errorMessage": "Email must be filled in."
            },
            {
              "type": "EMAIL",
              "errorMessage": "Email contains an invalid address."
            }
          ],
          "value": "test@gmail.com",
          "isValid": true,
          "fieldType": "input",
          "isRequired": true,
          "submittion": true,
          "elementType": "input",
          "elementConfig": {
            "type": "text",
            "placeholder": ""
          }
        },
        "lastName": {
          "name": "lastName",
          "error": "",
          "label": "Last name",
          "rules": [
            {
              "type": "REQUIRED",
              "errorMessage": "Last Name must be filled in."
            }
          ],
          "value": "lastName",
          "isValid": true,
          "fieldType": "input",
          "maxLength": 256,
          "isRequired": true,
          "submittion": true,
          "elementType": "input",
          "elementConfig": {
            "type": "text",
            "placeholder": ""
          }
        },
        "firstName": {
          "name": "firstName",
          "error": "",
          "label": "First name",
          "rules": [
            {
              "type": "REQUIRED",
              "errorMessage": "First Name must be filled in."
            }
          ],
          "value": "firstName",
          "isValid": true,
          "fieldType": "input",
          "maxLength": 256,
          "isRequired": true,
          "submittion": true,
          "elementType": "input",
          "elementConfig": {
            "type": "text",
            "placeholder": ""
          }
        }
      },
      "dataSiteKey": "6LfDJhkTAAAAAM576sI872c5ll6_6mKE9MZdQDmy",
      "submitSuccessMsg": "Please check your e-mail to confirm your registration to the Cocoa Life newsletter.",
      "description": "<p>We value your trust when sharing your personal data with us. We always treat your data in a fair and respectful manner limited to the purpose above mentioned. If you would like to know more about how we handle your data, please read our <a href='/privacy-notice/'>Privacy Notice</a>.</p>",
      "reCaptchaError": "Recaptcha validation failed. Please try again.",
      "ageError": "You must be 18 years old or above"
    }
  }