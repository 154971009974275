const query = (id, isPreview) => `{
    newsCardsList(id: "${id}", preview: ${isPreview}) {
        internalTitle
        title
        variant
        loadMoreArticle {
          name
          url
          label
          title
          target
          scrollToElementId
          imageType
          gaEventClass
          gaEventLabel
        }
        articlesCollection (limit:50){
          items{
          internalTitle
            tag
            title
            description
            link{
              url
              target
              title
              label
            }
        }
          
        }
    }
}
`

module.exports = { query }
