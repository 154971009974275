import React, { useState } from "react"
import { PASSWORD } from "../../../constants/rules.constants"
import * as styles from "./Input-tw-styles"
import PropTypes from "prop-types"

function Input(props) {
  const {
    type,
    label,
    id,
    name,
    placeholder,
    isRequired,
    variant,
    errorMessage,
    onChange,
    button,
    onBlur = () => {},
    isValid,
    maxLength,
    value,
  } = props
  const style = styles[variant] || styles.defaultStyle
  const [emailValid ,setEmailError] = useState(true)
  const [emailErrorMsg ,setEmailErrorMsg] = useState('')
  const handleBlur= (e)=>{
    const validateEmail = onBlur(e)
    setEmailError(validateEmail?.isValid)
    setEmailErrorMsg(validateEmail?.error)
  }

  return (
    <div className={style?.inputContainer}>
      <label htmlFor={name} className={style?.labelStyle}>
        {label}
      </label>
      <div className={style?.inputWrpr}>
        <input
          type={type}
          className={`${style?.text} ${
            !isValid  ? style?.error : "border-1 border-inputBrdr"
          }`}
          id={id}
          placeholder={placeholder}
          name={name}
          onChange={event => onChange(event, id)}
          onBlur={event => handleBlur(event)}
          maxLength={maxLength}
          value={value}
        />
        {(!emailValid && name ==="email") && <div className={style?.emailErrorMsg}>{emailErrorMsg}</div>}
      </div>
      { isRequired && <span className={isValid? style?.asteriskStyle : style?.errorLabel}>*</span>}
      {name === PASSWORD && (
        <button
          id={button.id}
          name={button.name}
          className=""
          onClick={event => onChange(event, button.id)}
        >
          +
        </button>
      )}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  button: PropTypes.object,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
}

export default Input
