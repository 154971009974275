const defaultStyle = { 
    container: "max-w-screen-lg mx-auto p-4",
    heading: "text-2xl font-bold mb-4",
    label: "block font-medium text-gray-700 mb-2",
    input: "w-full border rounded-lg py-2 px-3 mb-4",
    checkboxLabel: "inline-flex items-center text-sm text-gray-700",
    checkboxInput: "form-checkbox h-5 w-5 text-green-500",
    
   
}
// styles.js
 export const formStyles = {
    label:'birthday w-[100px] relative top-32 inline-block text-16 italic leading-[22px] text-textBlack',
    asteriskBirthday:'asteriskBirthday relative top-30 text-[2rem] inline-block p-[4px] leading-[22px] text-darkGrey align-middle',
    selectWrapper: 'selectWrapper flex',
    formTitleWrap: "mx-auto md:px-30 md:w-[960px] max-w-full text-left mb-56",
    formTitle: 'max-w-[590px] text-[40px] leading-[36px] text-textBlack font-alternate formHead',
    formDesc: 'md:w-[560px] text-left text-16 text-darkGrey mb-15 leading-22 formFooterPara',
    btnStyleWrapper: "submitButton md:px-30 mx-auto md:max-w-[960px]",
    btnStyle: "primaryBtn max-w-[150px] !ml-0",
    selectStyle:{
      selectConatiner: 'selectConatiner',
      labelStyle: 'labelStyle text-16 italic text-textBlack',
      select: 'select text-black w-full border-1 border-[#767676] rounded',
      error: 'error',
      text: 'text'
    },
    error:'text-red text-16 leading-[22px]',
    asteriskBirthdayError: 'asteriskBirthdayError text-red relative top-30 text-[2rem] inline-block p-[4px] leading-[22px] text-darkGrey align-middle',
    ageError:'ageError text-red text-16 leading-[22px]',
    loaderStyle:{
      loaderWrapper: 'loader mx-auto lg:w-[750px] max-w-full text-center text-black',
      spinner: 'animate-spin',
      svgWrapper: 'inline-block text-center text-black align-middle'
    }
  };
  
  