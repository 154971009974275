export const defaultStyle = {
  articleMainContainer: "articleMainContainer flex text-textBlack mb-[32px]",
  articleSubContainer:
    "articleSubContainer w-full flex-column md:flex justify-around mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[0px]",
  articleLeftDiv: "articleLeftDiv w-full md:w-[66.66%] md:pr-[16px]",
  articleRightDiv:
    "articleRightDiv w-full md:w-[33.33%] md:pl-[16px] mt-[16px] md:mt-0",
  articleHeading:
    "articleHeading left-[-10px] relative mb-[12px] uppercase font-alternate text-[30.4px] leading-[0.96]",
  articleHeadingBig:
    "articleHeadingBig max-w-[590px] mb-[16px] uppercase font-alternate leading-[0.9] text-[48px] text-textBlack",
  articlepublishedDate: "articlepublishedDate italic max-w-[590px]",
  readMoreContainer: "readMoreContainer max-w-[590px]",
  readMoreText:
    "readMoreText text-darkGrey mr-[12px] mb-[8px] text-[14px] font-medium",
  socialShareDiv: "socialShareDiv max-w-[590px]",
  socialShareList: "socialShareList mb-[24px]",
  socialShareListItem:
    "socialShareListItem inline-block mr-4 pr-10 text-[17px]",
  articleTagList: "articleTagList",
  articleTagListItem:
    "articleTagListItem inline-block pr-[10px] mr-[4px] mb-[8px]",
  articleTagLink:
    "articleTagLink rounded-[38px] bg-darkGrey hover:bg-darkerGrey transition ease-out duration-300 text-white h-[26px] leading-[26px] py-0 pr-[5px] pl-[10px] italic font-medium text-[17px]",
  articleVideoContainer: 
    "articleVideoContainer relative max-w-full mb-[16px]",
  articleVideoSub:
    "articleVideoSub h-full w-full relative pt-[30px] pb-[54.15%]",
  articlesubHeading: "articlesubHeading max-w-[590px]",
  brandImgDiv: "brandImgDiv w-full",
  renderImagesList: "renderImagesList !list-none !mb-0 !p-0",
  renderImagesListItem: "renderImagesListItem max-w-[120px] mb-[22px] max-[768px]:mx-auto max-[768px]:mt-[24px]",
}

export const ARTICLE_HEADER_BG_VARIANT = {
  articleMainContainer: "articleMainContainer flex text-textBlack mb-[32px]",
  articleSubContainer:
  "articleSubContainer w-full flex-column md:flex justify-around mx-auto min-[960px]:w-[960px]",
  articleLeftDiv: "articleLeftDiv w-full md:w-[66.66%] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px]",
  articleRightDiv:
      "articleRightDiv w-full md:w-[33.33%] mt-0 px-[15px] min-[567px]:px-[30px] pt-0 md:pt-[50px] pb-[40px]",
  articleHeading:
    "articleHeading left-[-10px] relative mb-[12px] uppercase font-alternate text-[30.4px] leading-[0.96] text-textBlack",
  articleHeadingBig:
    "articleHeadingBig max-w-[590px] mb-[16px] uppercase font-alternate leading-[0.9] text-[48px] text-white",
  articlepublishedDate: "articlepublishedDate italic max-w-[590px]",
  readMoreContainer: "readMoreContainer max-w-[590px]",
  readMoreText:
    "readMoreText text-white mr-[12px] mb-[8px] text-[14px] font-medium",
  socialShareDiv: "socialShareDiv max-w-[590px]",
  socialShareList: "socialShareList mb-[24px]",
  socialShareListItem:
    "socialShareListItem inline-block mr-4 pr-10 text-[17px]",
  articleTagList: "articleTagList",
  articleTagListItem:
    "articleTagListItem inline-block pr-[10px] mr-[4px] mb-[8px]",
  articleTagLink:
    "articleTagLink rounded-[38px] bg-darkGrey hover:bg-darkerGrey transition ease-out duration-300 text-white h-[26px] leading-[26px] py-0 pr-[5px] pl-[10px] italic font-medium text-[17px]",
  articleVideoContainer: 
    "articleVideoContainer relative max-w-full mb-[16px]",
  articleVideoSub:
    "articleVideoSub h-full w-full relative pt-[30px] pb-[54.15%]",
  articlesubHeading: "articlesubHeading max-w-[590px]",
  brandImgDiv: "brandImgDiv w-full",
  renderImagesList: "renderImagesList !list-none !mb-0 !p-0",
  renderImagesListItem: "renderImagesListItem max-w-[120px] mb-[22px] max-[768px]:mx-auto max-[768px]:mt-[24px]",
}
export const PINK_BG = {
  ...ARTICLE_HEADER_BG_VARIANT,
  articleMainContainer: "articleMainContainer flex text-white mb-[32px] bg-darkPink",
}

export const YELLOW_BG = {
  ...ARTICLE_HEADER_BG_VARIANT,
  articleMainContainer: "articleMainContainer flex text-white mb-[32px] bg-yellow",
}

export const GREEN_BG = {
  ...ARTICLE_HEADER_BG_VARIANT,
  articleMainContainer: "articleMainContainer flex text-white mb-[32px] bg-lightGreen",
}

export const ORANGE_BG = {
  ...ARTICLE_HEADER_BG_VARIANT,
  articleMainContainer: "articleMainContainer flex text-white mb-[32px] bg-orange",
}

export const REDVELVET_BG = {
  ...ARTICLE_HEADER_BG_VARIANT,
  articleMainContainer: "articleMainContainer flex text-white mb-[32px] bg-redVelvet",
  articleHeading:
    "articleHeading left-[-10px] relative mb-[12px] uppercase font-alternate text-[30.4px] leading-[0.96] text-lightGreen",
} 

export const ARTICLE_HEADER_WITH_SMALL_TITLE = {
  ...defaultStyle,
  articleHeadingBig:
    "articleHeadingSmall max-w-[590px] mb-[16px] uppercase font-alternate leading-[0.9] text-[40px] text-textBlack",
}
