export const  newsCardsList = {
    "internalTitle": "Latest News",
    "title": "LATEST NEWS",
    "variant": "NEWS_CARDS_LIST",
    "loadMoreArticle": {
      "name": "Load more Articles",
      "url": "/",
      "label": "Load more Articles",
      "title": null,
      "target": "_self",
      "scrollToElementId": null,
      "imageType": null,
      "gaEventClass": null,
      "gaEventLabel": null
    },
    "articlesCollection": {
      "items": [
        {
          "internalTitle": "CADBURY-MAKER MONDELEZ TO INVEST $600 MLN ON SUSTAINABLE COCOA SOURCING",
          "tag": "FINANCIAL TIMES",
          "title": "CADBURY-MAKER MONDELEZ TO INVEST $600",
          "description": "<b>The owner of brands from Cadbury to Toblerone said on Wednesday that it would commit an additional $600mn in sustainability funding until 2030, on top of $400mn invested over the past decade.</b> - *10/26/22*",
          "link": {
            "url": "https://www.ft.com/",
            "target": "_blank",
            "title": "https://www.ft.com/",
            "label": "https://www.ft.com/"
          }
        },
        {
          "internalTitle": "CADBURY-MAKER MONDELEZ TO INVEST $600 MLN ON SUSTAINABLE COCOA SOURCING",
          "tag": "REUTERS",
          "title": "CADBURY-MAKER MONDELEZ TO INVEST $600 MLN ON SUSTAINABLE COCOA SOURCING",
          "description": "<b>Cadbury chocolate-maker Mondelez International doubled down on sustainable cocoa sourcing on Wednesday, pledging to spend an additional $600 million by 2030 on efforts to combat child labour, farmer poverty and deforestation in cocoa.</b> - *10/25/22*",
          "link": {
            "url": "https://www.reuters.com/",
            "target": "_blank",
            "title": "https://www.reuters.com/",
            "label": "https://www.reuters.com/"
          }
        },
        {
          "internalTitle": "COCOA LIFE INDIA RECOGNIZED AT FICCI SUSTAINABLE AGRI AWARDS 2021",
          "tag": "AWARD",
          "title": "COCOA LIFE INDIA RECOGNIZED AT FICCI SUSTAINABLE AGRI AWARDS 2021",
          "description": "<b>Mondelēz’ Cocoa Life program in India has been recognized in the category \"Outstanding Sustainable Farmer Income Enhancement\" at the Sustainable Agri Awards organized by the Federation of Indian Chambers of Commerce & Industry (FICCI)</b>. - *11/18/21*",
          "link": {
            "url": "https://twitter.com/ficci_india/status/1461352713837416460?t=ozzVKmRlFOn671_hNMzuNQ&s=08",
            "target": "_blank",
            "title": "https://twitter.com/",
            "label": "https://twitter.com/"
          }
        },
        {
          "internalTitle": "LIVING INCOME IN COCOA",
          "tag": "WAGENINGEN UNIVERSITY & RESEARCH",
          "title": "LIVING INCOME IN COCOA",
          "description": "<b>Wageningen University & Research and Mondelēz International pooled data and resources to contribute to a shared understanding of the magnitude and nature of the living income challenge. The insights deriving from their report can be used for vision and policy development and designing interventions.</b> - *11/17/21*",
          "link": {
            "url": "https://www.wur.nl/en/newsarticle/Living-income-in-cocoa.htm",
            "target": "_blank",
            "title": "https://www.wur.nl/",
            "label": "https://www.wur.nl/"
          }
        },
        {
          "internalTitle": "COCOA LIFE GHANA TAKES HOME TWO PRIZES AT THE GHANA COCOA AWARDS 2021",
          "tag": "AWARD",
          "title": "COCOA LIFE GHANA TAKES HOME TWO PRIZES AT THE GHANA COCOA AWARDS 2021",
          "description": "<b>Cocoa Life Ghana was honoured with two awards at The Ghana Cocoa Awards 2021, the Ghana cocoa industry’s biggest and most prestigious gathering. For the third consecutive year, the team was awarded with the title of \"Sustainability Programme of the Year\", recognizing Cocoa Life’s continued commitment to drive sustainable change in cocoa communities, and our Head of Cocoa Life Ghana, Yaa Peprah Amekudzi, was named as ‘Sustainability Personality of the Year’ for her outstanding achievements and impactful contribution to the sustainability of the cocoa sector</b> - *11/12/21*",
          "link": {
            "url": "http://ghanacocoaawards.com/blog/2021-award-recipients",
            "target": "_blank",
            "title": "http://ghanacocoaawards.com/",
            "label": "http://ghanacocoaawards.com/"
          }
        },
        {
          "internalTitle": "FOR PEOPLE AND PLANET: MONDELEZ WORLD TRAVEL RETAIL AND TRAVEL RETAIL NORWAY HOST ZERO WASTE ACTIVATION AT OSLO AIRPORT",
          "tag": "THE MOODIE DAVITT REPORT",
          "title": "FOR PEOPLE AND PLANET: MONDELEZ WORLD TRAVEL RETAIL AND TRAVEL RETAIL NORWAY HOST ZERO WASTE ACTIVATION AT OSLO AIRPORT",
          "description": "__Mondelez World Travel Retail (WTR) has partnered with Gebr Heinemann-led Travel Retail Norway to launch a sustainably designed multi-brand activation at Oslo Airport that raises awareness on the Cocoa Life program.__ - *09/06/21*",
          "link": {
            "url": "https://www.moodiedavittreport.com/for-people-and-planet-mondelez-world-travel-retail-and-travel-retail-norway-host-zero-waste-activation-at-oslo-airport/",
            "target": "_blank",
            "title": "https://www.moodiedavittreport.com/",
            "label": "https://www.moodiedavittreport.com/"
          }
        },
        {
          "internalTitle": "COCOA LIFE INDONESIA RECEIVES AWARD FROM THE MINISTRY OF CHILD PROTECTION AND WOMEN EMPOWERMENT OF THE REPUBLIC OF INDONESIA",
          "tag": "AWARD",
          "title": "COCOA LIFE INDONESIA RECEIVES AWARD FROM THE MINISTRY OF CHILD PROTECTION AND WOMEN EMPOWERMENT OF THE REPUBLIC OF INDONESIA",
          "description": "__Mondelēz International through Cocoa Life in Indonesia has been recognized by The Ministry of Child Protection and Women Empowerment of the Republic of Indonesia for its efforts to help reduce child labor in the country. This recognition was a significant achievement for #CocoaLife Indonesia on how we work to ensure our cocoa communities are empowered and inclusive.__ - *06/23/21*",
          "link": {
            "url": "https://kemenpppa.go.id/index.php/page/read/29/3248/indonesia-hadapi-tantangan-pekerja-anak-di-sektor-pertanian-lebih-dari-800-ribu-anak-terjebak-dalam-bentuk-pekerjaan-terburuk-bagi-anak",
            "target": "_blank",
            "title": "https://kemenpppa.go.id/",
            "label": "https://kemenpppa.go.id/"
          }
        },
        {
          "internalTitle": "COCOA FARMERS IN GHANA AWARDED SEED CAPITAL TO DIVERSIFY THEIR INCOMES IN CLIMATE SMART VENTURES",
          "tag": "FAIRTRADE FOUNDATION",
          "title": "COCOA FARMERS IN GHANA AWARDED SEED CAPITAL TO DIVERSIFY THEIR INCOMES IN CLIMATE SMART VENTURES",
          "description": "__As part of our partnership with Fairtrade Foundation, nine Ghanaian cocoa farming unions have been awarded grants to invest in new climate-smart small businesses on behalf of their members.__ - *05/26/21*",
          "link": {
            "url": "https://www.fairtrade.org.uk/media-centre/news/cocoa-farmers-in-ghana-awarded-seed-capital-to-diversify-their-incomes-in-climate-smart-ventures/",
            "target": "_blank",
            "title": "https://www.fairtrade.org.uk/",
            "label": "https://www.fairtrade.org.uk/"
          }
        },
        {
          "internalTitle": "CADBURY FARMER RESILIENCE FUND SUPPORTS THOUSANDS OF WOMEN TO IMPROVE THEIR INCOME IN COCOA GROWING COMMUNITIES",
          "tag": "FAIRTRADE FOUNDATION",
          "title": "CADBURY FARMER RESILIENCE FUND SUPPORTS THOUSANDS OF WOMEN TO IMPROVE THEIR INCOME IN COCOA GROWING COMMUNITIES",
          "description": "__Fairtrade Foundation and Mondelēz International established the \"Cadbury Farmer Resilience Fund\" as part of the Vulnerable Supply Chains Facility (VSCF), a rapid COVID-19 response facility set up by the UK Foreign, Commonwealth and Development Office (FCDO), and managed by Mott MacDonald Ltd. As part of this work, the grant facility aims to support a total of 15,600 women to set up a range of climate smart small enterprises, helping build the resilience of farming communities during an unprecedented year.__ - *05/26/21*",
          "link": {
            "url": "https://www.fairtrade.org.uk/media-centre/news/cadbury-farmer-resilience-fund-supports-thousands-of-women-to-improve-their-income-in-cocoa-growing-communities/",
            "target": "_blank",
            "title": "https://www.fairtrade.org.uk/",
            "label": "https://www.fairtrade.org.uk/"
          }
        },
        {
          "internalTitle": "CADBURY COCOA LIFE SOFA SESSION WITH THE ONE SHOW HOST, ALEX JONES",
          "tag": "YOUTUBE",
          "title": "CADBURY COCOA LIFE SOFA SESSION WITH THE ONE SHOW HOST, ALEX JONES",
          "description": "__The Sofa Sessions are hosted by Alex Jones alongside three panellists including Cathy Pieters, Senior Director for Cocoa Life at Mondelēz International, Laurie Lee, Chief Executive Officer of Care International UK and Margreet Groot, Communications Lead for the UNDP Green Commodities Program.__ - *05/26/21*",
          "link": {
            "url": "https://www.youtube.com/watch?v=puPLmKbj7b0",
            "target": "_blank",
            "title": "https://www.youtube.com/",
            "label": "https://www.youtube.com/"
          }
        },
        {
          "internalTitle": "MONDELĒZ PLANTS MORE THAN 2.2M NON-COCOA TREES TO HELP RESTORE ECOSYSTEMS ON COCOA FARMS",
          "tag": "CONFECTIONERYNEWS.COM",
          "title": "MONDELĒZ PLANTS MORE THAN 2.2M NON-COCOA TREES TO HELP RESTORE ECOSYSTEMS ON COCOA FARMS",
          "description": "__In an exclusive interview with Confectionery News, Cedric Van Cutsem, Associate Director of Cocoa Life, shared Cocoa Life's latest report demonstrating Mondelez’s continued commitment to the Cocoa & Forests Initiative. Check out the podcast to hear how we are working towards a forest-positive future through innovative approaches including a carbon measurement tool, the scale-up of payments for environmental services and ambitious partnerships for landscape-wide impact.__ - *04/29/21*",
          "link": {
            "url": "https://www.confectionerynews.com/Article/2021/04/29/Mondelez-plants-more-than-2.2m-non-cocoa-trees-to-help-restore-ecosystems-on-cocoa-farms-LISTEN",
            "target": "_blank",
            "title": "https://www.confectionerynews.com/",
            "label": "https://www.confectionerynews.com/"
          }
        },
        {
          "internalTitle": "AUSTRALIAN-MADE CADBURY CHOCOLATE FROM SUSTAINABLY SOURCED COCOA",
          "tag": "FOOD PROCESSING AUSTRALIA",
          "title": "AUSTRALIAN-MADE CADBURY CHOCOLATE FROM SUSTAINABLY SOURCED COCOA",
          "description": "__Cadbury has announced that 100% of the cocoa volume sourced for its Australian-made products comes from the Cocoa Life program.__ - *03/25/21*",
          "link": {
            "url": "https://www.foodprocessing.com.au/content/sustainability/news/australian-made-cadbury-chocolate-from-sustainably-sourced-cocoa-635899545",
            "target": "_blank",
            "title": "https://www.foodprocessing.com.au/",
            "label": "https://www.foodprocessing.com.au/"
          }
        },
        {
          "internalTitle": "MONDELĒZ REPORT HIGHLIGHTS $10 BILLION ANNUAL INCOME GAP FOR GHANA AND IVORY COAST FARMERS",
          "tag": "CONFECTIONERYPRODUCTION.COM",
          "title": "MONDELĒZ REPORT HIGHLIGHTS $10 BILLION ANNUAL INCOME GAP FOR GHANA AND IVORY COAST FARMERS",
          "description": "__Mondelēz International has released a report, in partnership with Wageningen University & Research, outlining the $10 billion annual income gap for cocoa farmers in Ghana and Ivory Coast. The report, titled ‘No Silver Bullets’, offers new insights into the true scale of the cocoa farmer income challenge.__ - *11/20/20*",
          "link": {
            "url": "https://www.confectioneryproduction.com/news/32317/mondelez-report-highlights-10-billion-annual-income-gap-for-ghana-and-ivory-coast-farmers/",
            "target": "_blank",
            "title": "https://www.confectioneryproduction.com",
            "label": "https://www.confectioneryproduction.com"
          }
        },
        {
          "internalTitle": "GHANA COCOA AWARDS: FULL LIST OF WINNERS",
          "tag": "MYJOYONLINE.COM",
          "title": "GHANA COCOA AWARDS: FULL LIST OF WINNERS",
          "description": "__Mondelēz International was recognised as a ‘COVID-19 Hero’ at the 2020 Ghana Cocoa Awards for our work to protect the health and wellbeing of those at the heart of the Cocoa Life program. The ceremony also awarded Cocoa Life Ghana Country Lead Yaa Preprah Amekudzi ‘Sustainability Personality of the Year’, while Cocoa Life Ghana was awarded ‘Sustainability Initiative of the Year’.__ - *11/15/20*",
          "link": {
            "url": "https://www.myjoyonline.com/",
            "target": "_blank",
            "title": "https://www.myjoyonline.com",
            "label": "https://www.myjoyonline.com"
          }
        },
        {
          "internalTitle": "“WE WANT TO TRANSFORM THE SECTOR”- MONDELĒZ’S CATHY PIETERS ON SNACKS GIANT’S COCOA LIFE PROGRAMME, ITS PROGRESS AND CHALLENGES AHEAD",
          "tag": "JUST FOOD",
          "title": "“WE WANT TO TRANSFORM THE SECTOR”- MONDELĒZ’S CATHY PIETERS ON SNACKS GIANT’S COCOA LIFE PROGRAMME, ITS PROGRESS AND CHALLENGES AHEAD",
          "description": "__In 2016, we embarked on a new partnership with the Fairtrade Foundation. In an exclusive interview with Just Food, Cocoa Life Director Cathy Pieters speaks about the partnership, as well as the joint publication of the Sustainable Livelihoods Landscape Study, which looks at sustainable livelihood initiatives in the West African cocoa sector.__ - *06/19/20*",
          "link": {
            "url": "https://www.just-food.com/interview/we-want-to-transform-the-sector-mondelezs-cathy-pieters-on-snacks-giants-cocoa-life-programme-its-progress-and-challenges-ahead_id143891.aspx",
            "target": "_blank",
            "title": "https://www.just-food.com",
            "label": "https://www.just-food.com"
          }
        },
        {
          "internalTitle": "MONDELĒZ INTERNATIONAL ACCELERATES PROGRESS IN DELIVERING AGAINST ITS SUSTAINABILITY AND WELL-BEING GOALS",
          "tag": "BUSINESS INSIDER",
          "title": "MONDELĒZ INTERNATIONAL ACCELERATES PROGRESS IN DELIVERING AGAINST ITS SUSTAINABILITY AND WELL-BEING GOALS",
          "description": "__In May 2020, Mondelēz International published its 2019 Snacking Made Right Report, highlighting significant progress towards achieving the company’s 2025 sustainable and mindful snacking goals – including sourcing 63% of its cocoa volume for chocolate brands through Cocoa Life as of the end of 2019.__ - *05/08/20*",
          "link": {
            "url": "https://markets.businessinsider.com/news/stocks/mondel%C4%93z-international-accelerates-progress-in-delivering-against-its-sustainability-and-well-being-goals-1029181801",
            "target": "_blank",
            "title": "https://markets.businessinsider.com",
            "label": "https://markets.businessinsider.com"
          }
        },
        {
          "internalTitle": "CADBURY DAIRY MILK RECEIVES FIRST BRAND REFRESH IN 50 YEARS",
          "tag": "CONFECTIONERYNEWS.COM",
          "title": "CADBURY DAIRY MILK RECEIVES FIRST BRAND REFRESH IN 50 YEARS",
          "description": "__Cadbury has announced a brand refresh for its iconic Cadbury and Cadbury Dairy Milk bars. The new modern look features the Cocoa Life logo on the front of the packaging, highlighting Mondelēz International’s long-standing Cocoa Life sustainability program and its commitment to cocoa farmers and the environment. Ben Wicks, global brand director at Cadbury, explains the thinking behind the brand refresh.__ - *04/23/20*",
          "link": {
            "url": "https://www.confectionerynews.com/Article/2020/04/23/Cadbury-Dairy-Milk-receives-first-brand-refresh-in-50-years",
            "target": "_blank",
            "title": "https://www.confectionerynews.com",
            "label": "https://www.confectionerynews.com"
          }
        },
        {
          "internalTitle": "REAPING THE GENDER DIVIDEND TO MAKE FOOD SYSTEMS MORE SUSTAINABLE",
          "tag": "ETHICAL CORPORATION",
          "title": "REAPING THE GENDER DIVIDEND TO MAKE FOOD SYSTEMS MORE SUSTAINABLE",
          "description": "__Empowering women is key to achieving the Sustainable Development Goals and improving conditions in agricultural supply chains. Manuel Kiewisch, Global Monitoring and Evaluation, Verification, and Learning Manager for the Cocoa Life program at Mondelēz International, shares lessons from Cocoa Life about what this means in practice.__ - *09/12/19*",
          "link": {
            "url": "https://www.reutersevents.com/sustainability/reaping-gender-dividend-make-food-systems-more-sustainable",
            "target": "_blank",
            "title": "http://ethicalcorp.com",
            "label": "http://ethicalcorp.com"
          }
        },
        {
          "internalTitle": "MONDELĒZ TO SECURE 100% COCOA VOLUME THROUGH SUSTAINABILITY PROGRAM BY 2025",
          "tag": "WALL STREET JOURNAL",
          "title": "MONDELĒZ TO SECURE 100% COCOA VOLUME THROUGH SUSTAINABILITY PROGRAM BY 2025",
          "description": "__The Wall Street Journal reports on Mondelēz International’s 2025 commitment to sustainably source 100% of the cocoa volume needed for its chocolate brands through the Cocoa Life program. The expansion will see an increase in the number of farmers and communities Mondelēz International supports across six cocoa origin countries.__ - *04/30/19*",
          "link": {
            "url": "https://www.wsj.com/articles/mondelez-to-secure-100-cocoa-volume-through-sustainability-program-by-2025-11556638011?mod=searchresults&page=1&pos=6",
            "target": "_blank",
            "title": "https://www.wsj.com",
            "label": "https://www.wsj.com"
          }
        },
        {
          "internalTitle": "DEFORESTATION: THE ECONOMICS AND POLITICS OF COCOA",
          "tag": "CONFECTIONERY NEWS",
          "title": "DEFORESTATION: THE ECONOMICS AND POLITICS OF COCOA",
          "description": "__In an exclusive interview with Confectionery News on the Chocoa Conference in Amsterdam, Cédric van Cutsem gives an update on Cocoa Life’s approach on deforestation.__ - *05/03/19*",
          "link": {
            "url": "https://www.confectionerynews.com/Article/2019/02/26/Deforestation-the-economics-and-politics-of-cocoa#",
            "target": "_blank",
            "title": "https://www.confectionerynews.com",
            "label": "https://www.confectionerynews.com"
          }
        },
        {
          "internalTitle": "MONDELĒZ WINS SAVE THE CHILDREN’S VISIONARY AWARD",
          "tag": "AWARD",
          "title": "MONDELĒZ WINS SAVE THE CHILDREN’S VISIONARY AWARD",
          "description": "__Save the Children’s Visionary Award was presented to Mondelēz International for its commitment to driving positive impact on people and the planet through its Cocoa Life initiative and the Mondelēz Foundation. Chief sustainability, well-being and public & government affairs officer, Christine McGrath, accepted the award for the organization. \"I truly believe that through our long-standing partnership, Save the Children and Mondelēz International together are empowering communities to be healthier and more sustainable – now and into the future.\"__ - *15/11/18*",
          "link": {
            "url": "https://www.savethechildren.org/us/about-us/media-and-news/2018-press-releases/6th-annual-save-the-children-illumination-gala",
            "target": "_blank",
            "title": "https://www.savethechildren.org",
            "label": "https://www.savethechildren.org"
          }
        },
        {
          "internalTitle": "BRAZIL COCOA PRODUCTION TO BENEFIT FROM TWO SCHEMES TO PROMOTE SUSTAINABILITY, SAYS DIRECTOR OF MONDELĒZ’S COCOA LIFE PROGRAM",
          "tag": "CONFECTIONERY NEWS",
          "title": "BRAZIL COCOA PRODUCTION TO BENEFIT FROM TWO SCHEMES TO PROMOTE SUSTAINABILITY, SAYS DIRECTOR OF MONDELĒZ’S COCOA LIFE PROGRAM",
          "description": "__In an exclusive interview with Confectionery News, Cathy Pieters shares details of the recently launched Cocoa Life Brazil. A little different from the program currently in place in West Africa, Cocoa Life Brazil will focus on empowering 500 cocoa farmers and 100 families, while promoting agroforestry to restore land. The program will work with WCF CocoaAction Brazil to secure government influence, all the while improving the welfare of cocoa farmers in the country.__ - *28/10/18*",
          "link": {
            "url": "https://www.confectionerynews.com/Article/2018/10/28/Brazil-cocoa-production-to-benefit-from-two-schemes-to-promote-sustainability",
            "target": "_blank",
            "title": "https://www.confectionerynews.com",
            "label": "https://www.confectionerynews.com"
          }
        },
        {
          "internalTitle": "MONDELĒZ INTERNATIONAL RANKS FIRST IN GES BENCHMARK",
          "tag": "AWARD",
          "title": "MONDELĒZ INTERNATIONAL RANKS FIRST IN GES BENCHMARK",
          "description": "__GES recognizes Mondelēz International as leading the industry for its holistic approach to tackle child labor and its root causes in cocoa growing communities through Cocoa Life. Backed by 60 institutional investors, GES International - an advisory group focused on sustainability performance - benchmarks cocoa and chocolate companies on their efforts to combat child labor. GES recognized Cocoa Life for the roll out of the community-centric Child Labor Monitoring and Remediation System (CLMRS), as well as the program’s efforts to improve farmers and communities’ livelihoods.__ - *09/10/18*",
          "link": {
            "url": "https://www.bitc.org.uk/resources-training/resources/case-studies/unilever-global-development-award-mondelez-international",
            "target": "_blank",
            "title": "https://www.gesinternational.com/",
            "label": "https://www.gesinternational.com/"
          }
        },
        {
          "internalTitle": "COCOA LIFE WINS PRESTIGIOUS BITC AWARD",
          "tag": "AWARD",
          "title": "COCOA LIFE WINS PRESTIGIOUS BITC AWARD",
          "description": "__Jonathan Horrell, Director Sustainability, accepts the Unilever Global Development Award at the Business in the Community (BITC) Responsible Business Award 2018, on behalf of Cocoa Life. The judges recognized Cocoa Life’s leading approach,  and praised to its long term significant and positive impact. -__ *07/10/18*",
          "link": {
            "url": "https://www.bitc.org.uk/resources-training/resources/case-studies/unilever-global-development-award-mondelez-international",
            "target": "_blank",
            "title": "https://www.bitc.org.uk",
            "label": "https://www.bitc.org.uk"
          }
        },
        {
          "internalTitle": "COCOA LIFE WINS THE FOOD AND DRINK FEDERATION AWARDS 2018",
          "tag": "AWARD",
          "title": "COCOA LIFE WINS THE FOOD AND DRINK FEDERATION AWARDS 2018",
          "description": "__Cocoa Life wins Environmental Leadership Award 2018 for its innovative work on environment and climate change in the cocoa growing origins. The Food and Drink Federation (FDF) is a UK platform that celebrates excellence and innovation in the industry. -__ *20/09/18*",
          "link": {
            "url": "https://www.fdf.org.uk/awards-winners-2018-environmental.aspx",
            "target": "_blank",
            "title": "https://www.fdf.org.uk",
            "label": "https://www.fdf.org.uk"
          }
        },
        {
          "internalTitle": "COCOA LIFE WINS SEDEX AWARD 2018",
          "tag": "SEDEX",
          "title": "COCOA LIFE WINS SEDEX AWARD 2018",
          "description": "__Francesco Tramontin, Director of Public Affairs, accepts Sedex Award 2018 for Best Example of Continuous Improvement on behalf of the Cocoa Life program in London. Mondelēz International has been recognized by Sedex for its longstanding efforts and ever-improving holistic approach to tackling the root causes of child labor in the cocoa supply chain through the Cocoa Life program. -__ *13/03/18*",
          "link": {
            "url": "https://www.sedexglobal.com/sedex-awards-winners-2018/",
            "target": "_blank",
            "title": "https://www.sedexglobal.com",
            "label": "https://www.sedexglobal.com"
          }
        },
        {
          "internalTitle": "WHAT DOES “GOING BEYOND CERTIFICATION” IN COCOA SUSTAINABILITY REALLY MEAN?",
          "tag": "CONFECTIONERY NEWS",
          "title": "WHAT DOES “GOING BEYOND CERTIFICATION” IN COCOA SUSTAINABILITY REALLY MEAN?",
          "description": "__This article emphasizes the need for the chocolate industry to widen the commitment to sustainable cocoa beyond certification, compares different initiatives, and reports on the efforts and uniqueness of Mondelēz International Cocoa Life program.\nThe chocolate industry needs to widen their commitment to sustainable cocoa beyond certification. -__ *20/02/18*",
          "link": {
            "url": "https://www.confectionerynews.com/Article/2018/02/20/What-does-going-beyond-certification-in-cocoa-sustainability-mean",
            "target": "_blank",
            "title": "https://www.confectionerynews.com",
            "label": "https://www.confectionerynews.com"
          }
        },
        {
          "internalTitle": "GHANAIAN COCOA FARMERS SHOWN UK CHOCOLATE FACTORY",
          "tag": "YAHOO NEWS",
          "title": "GHANAIAN COCOA FARMERS SHOWN UK CHOCOLATE FACTORY",
          "description": "__Cocoa Life farmers from Ghana are invited to visit Bournville, home to Cadbury, and witness the transformation of their cocoa into chocolate. -__ *12/04/17*",
          "link": {
            "url": "https://consent.yahoo.com/v2/collectConsent?sessionId=4_cc-session_1df5d27b-df61-4b28-820d-a9995b6c7151",
            "target": "_blank",
            "title": "https://uk.news.yahoo.com",
            "label": "https://uk.news.yahoo.com"
          }
        },
        {
          "internalTitle": "PRIVATE SECTOR ENGAGEMENT AND WOMEN’S ECONOMIC EMPOWERMENT: LESSONS LEARNED",
          "tag": "CHATHAM HOUSE",
          "title": "PRIVATE SECTOR ENGAGEMENT AND WOMEN’S ECONOMIC EMPOWERMENT: LESSONS LEARNED",
          "description": "__Linda Scott, from Oxford University Said Business School, presents her report and encourages the industry to share their practice in advancing women empowerment in the supply chain. Mondelēz International was among 9 other companies who participated to the Global Business Coalition for Women’s Economic Empowerment discussion. -__ *11/30/17*",
          "link": {
            "url": "https://www.chathamhouse.org/events/all/research-event/private-sector-engagement-and-womens-economic-empowerment-lessons-learned",
            "target": "_blank",
            "title": "https://www.chathamhouse.org",
            "label": null
          }
        },
        {
          "internalTitle": "COCOA FARMERS MUST EARN MORE FROM THEIR TOIL",
          "tag": "THE PRESIDENCY REPUBLIC OF GHANA",
          "title": "COCOA FARMERS MUST EARN MORE FROM THEIR TOIL",
          "description": "__Mary Barnard, Europe Chocolate Category President of Mondelēz International, Cathy Pieters, Cocoa Life Director, and other members of the Cocoa Life team in Ghana met with President Akufo-Addo of Ghana to discuss the current situation of cocoa farmers in Ghana, and the expansion of the Cocoa Life program in the country. -__ *10/30/17*",
          "link": {
            "url": "https://presidency.gov.gh/index.php/2017/10/30/cocoa-farmers-must-earn-more-from-their-toil-president-akufo-addo",
            "target": "_blank",
            "title": "http://presidency.gov.gh",
            "label": "http://presidency.gov.gh"
          }
        },
        {
          "internalTitle": "HOW FAIR IS OUR FOOD? BIG COMPANIES TAKE REINS ON SOURCING SCHEMES",
          "tag": "REUTERS",
          "title": "HOW FAIR IS OUR FOOD? BIG COMPANIES TAKE REINS ON SOURCING SCHEMES",
          "description": "__Reuters shares in depth reflection on in-house certification programs, such as Cocoa Life, and highlights the challenges and benefits of such initiatives.\n\nAmong the innovations of in-house programs, Reuters mentions Cocoa Life increased transparency and traceability through the uses digital farm mapping in Ghana, Ivory Coast and Indonesia -__ *09/03/17*",
          "link": {
            "url": "https://www.reuters.com/article/us-food-fairtrade-sustainability-insight/how-fair-is-our-food-big-companies-take-reins-on-sourcing-schemes-idUSKCN1BE0GI",
            "target": "_self",
            "title": "www.reuters.com",
            "label": "www.reuters.com"
          }
        },
        {
          "internalTitle": "FAIRTRADE AND COCOA LIFE TO BUILD CLIMATE CHANGE PROGRAM IN GHANA",
          "tag": null,
          "title": "FAIRTRADE AND COCOA LIFE TO BUILD CLIMATE CHANGE PROGRAM IN GHANA",
          "description": "__Cocoa Life and Fairtrade build their partnership, by putting forward their first program to tackle climate change and deforestation in Ghana. This articles highlights the importance of such partnerships in the cocoa industry, especially in regards to climate resilience. -__ *08/24/17*",
          "link": {
            "url": "https://www.confectionerynews.com/Article/2017/08/24/Fairtrade-and-Cocoa-Life-to-build-climate-change-program-in-Ghana",
            "target": "_blank",
            "title": "www.confectionerynews.com",
            "label": "www.confectionerynews.com"
          }
        },
        {
          "internalTitle": "MONDELĒZ’S SUSTAINABLE COCOA SOURCING PROGRAM COMBATS DEFORESTATION",
          "tag": "FOOD ENGINEERING",
          "title": "MONDELĒZ’S SUSTAINABLE COCOA SOURCING PROGRAM COMBATS DEFORESTATION",
          "description": "__Cathy Pieters, Director of Cocoa Life, is interviewed by Food Engineering on the aims and vision for the program. She shares insights on the importance of building a sustainable cocoa farming and sourcing strategy, and the importance of climate resilience. -__ *07/25/17*",
          "link": {
            "url": "https://www.foodengineeringmag.com/articles/96830-mondel%C4%93z-internationals-sustainable-cocoa-sourcing-program",
            "target": "_blank",
            "title": "www.foodengineeringmag.com",
            "label": "www.foodengineeringmag.com"
          }
        },
        {
          "internalTitle": "GREENER CHOCOLATE: WHY BUILDING A MORE RESILIENT COCOA INDUSTRY IS CRUCIAL FOR CADBURY",
          "tag": "BUSINESSGREEN",
          "title": "GREENER CHOCOLATE: WHY BUILDING A MORE RESILIENT COCOA INDUSTRY IS CRUCIAL FOR CADBURY",
          "description": "__Cocoa Life is featured as an explanatory example on sustainable chocolate production in Ghana, while using the iconic brand Cadbury as a case study. This article explains the reasons behind the program’s forth coming as a response to the current challenges in cocoa production, and gives insight into the investments and plans for its future. -__ *05/31/17*",
          "link": {
            "url": "/progress/greener-chocolate/",
            "target": "_self",
            "title": "Read More",
            "label": "Read More"
          }
        },
        {
          "internalTitle": "INDIA: BITTERSWEET CHOCOLATE",
          "tag": "SUNDAY ET",
          "title": "INDIA: BITTERSWEET CHOCOLATE",
          "description": "__This article highlights the main challenges for cocoa growth and production, leading India to currently import a big part of it from other producing countries. Experts argue that, by putting strategies into place and giving farmers opportunity and access to the right information and practices, it would increase their income and allow India to rely more on its local cocoa corps. -__ *05/21/17*",
          "link": {
            "url": "/progress/india-bittersweet-chocolate/",
            "target": "_self",
            "title": "Read More",
            "label": "Read More"
          }
        },
        {
          "internalTitle": "CHOCOLATE GIANTS IN NEW BID TO SAVE THE RAINFORESTS",
          "tag": "THE HUFFINGTON POST",
          "title": "CHOCOLATE GIANTS IN NEW BID TO SAVE THE RAINFORESTS",
          "description": "__A new initiative has been launched to save tropical rainforests from further clearance and degradation as a result of cocoa production. Twelve of the world’s largest cocoa and chocolate companies, that have been working together in a process started by The Prince of Wales’s International Sustainability Unit, the World Cocoa Foundation and IDH, have agreed to adopt a declaration committing them to action to halt deforestation and forest degradation. -__ *03/14/17*",
          "link": {
            "url": "https://www.huffingtonpost.co.uk/tony-juniper/save-rainforests_b_15313272.html",
            "target": "_blank",
            "title": "www.huffingtonpost.co.uk/save-rainforests",
            "label": "www.huffingtonpost.co.uk/save-rainforests"
          }
        },
        {
          "internalTitle": "NOODKLOK KLINKT IN CHOCOLADEWERELD",
          "tag": "ALGEMEEN DAGBLAD",
          "title": "NOODKLOK KLINKT IN CHOCOLADEWERELD",
          "description": "__Artikel van Annemike van Dongen over de crisis in de cacaoteelt en interview met Cathy Pieters. Als de straatarme cacaoboeren in Afrika het niet snel beter krijgen, kan volgens haar de chocoladereep naar het museum en zijn we ons favoriete snoepgoed kwijt. -__ *02/25/17*",
          "link": {
            "url": "https://myprivacy.dpgmedia.nl/consent?siteKey=V9f6VUvlHxq9wKIN&callbackUrl=https%3A%2F%2Fwww.ad.nl%2Fprivacy-gate%2Faccept-tcf2%3FredirectUri%3D%252Fbuitenland%252Fnoodklok-klinkt-in-chocoladewereld~a2bd7eb7%252F",
            "target": "_blank",
            "title": "www.ad.nl/buitenland/",
            "label": "www.ad.nl/buitenland/"
          }
        },
        {
          "internalTitle": "WOMEN’S EMPOWERMENT",
          "tag": "HANDELSZEITUNG",
          "title": "WOMEN’S EMPOWERMENT",
          "description": "__This article emphasizes the great value of women in economy, especially on smaller enterprises. The text summarizes Mondelēz efforts towards a sustainable transformation of the cocoa sector as well as the strategy regarding women's empowerment in the cocoa production, from the gender action plans to the results of the latest CARE study. -__ *02/01/17*",
          "link": {
            "url": "/progress/womens-empowerment/",
            "target": "_self",
            "title": "Read more",
            "label": "Read more"
          }
        },
        {
          "internalTitle": "FEMALE COCOA FARMER GROUPS RECEIVE SUPPORT TO IMPROVE YIELDS IN GHANA",
          "tag": "GRAPHIC ONLINE",
          "title": "FEMALE COCOA FARMER GROUPS RECEIVE SUPPORT TO IMPROVE YIELDS IN GHANA",
          "description": "__177 cocoa-growing communities have received motorized pruners, sprayers and training to empower women in cocoa farming. Harvesting and pruning are the two most burdensome activities in the cultivation of cocoa. From now on these women will be able to perform those activities by themselves increasing the yield from their farms. -__ *01/12/17*",
          "link": {
            "url": "https://www.graphic.com.gh/news/general-news/female-cocoa-farmer-groups-receive-support-to-improve-yields.html",
            "target": "_blank",
            "title": "www.graphic.com.gh/news",
            "label": "www.graphic.com.gh/news"
          }
        },
        {
          "internalTitle": "COCOA LIFE GHANA DISTRIBUTES COCOA FARMING CALENDAR TO PROMOTE GOOD AGRICULTURAL PRACTICES",
          "tag": "GRAPHIC ONLINE",
          "title": "COCOA LIFE GHANA DISTRIBUTES COCOA FARMING CALENDAR TO PROMOTE GOOD AGRICULTURAL PRACTICES",
          "description": "__25.000 cocoa farmers in Ghana received another edition of the special cocoa farming calendar which includes information to guide cocoa farmers to produce cocoa sustainable. The calendar, distributed each year, has specific information aimed at educating cocoa farmers on recommended farming activities to be undertaken each month. Adopting these practices will help them to improve yields and enhance their livelihoods. -__ *12/21/16*",
          "link": {
            "url": "https://www.graphic.com.gh/news/general-news/calendar-for-cocoa-farmers-launched-2.html",
            "target": "_blank",
            "title": "www.graphic.com.gh/news",
            "label": "www.graphic.com.gh/news"
          }
        },
        {
          "internalTitle": "CHILD LABOUR IS PART OF MOST OF WHAT WE BUY TODAY: WHAT CAN WE DO?",
          "tag": "THE GUARDIAN",
          "title": "CHILD LABOUR IS PART OF MOST OF WHAT WE BUY TODAY: WHAT CAN WE DO?",
          "description": "__Article by Annie Kelly on the Guardian Sustainable business hub on how child labor is still present in many of the world’s largest global commodities, from gold, coffee, tobacco and bananas to sugarcane, cotton and rubber. -__ *11/24/16*",
          "link": {
            "url": "https://www.theguardian.com/sustainable-business/2016/nov/24/child-labour-what-can-we-do-africa-modern-slavery?CMP=Share_iOSApp_Other",
            "target": "_blank",
            "title": "www.theguardian.com/sustainable-business/",
            "label": "www.theguardian.com/sustainable-business/"
          }
        },
        {
          "internalTitle": "EMPOWERING WOMEN; A PATH TO LARGER COCOA CROPS",
          "tag": "FOOD BUSINESS NEWS",
          "title": "EMPOWERING WOMEN; A PATH TO LARGER COCOA CROPS",
          "description": "__Article by Laura Lloyd about Cocoa Life’s strategy towards building success in improving women's roles and status in cocoa production in Ivory Coast and Ghana. The article highlights actions undertaken by Cocoa Life to improve gender equality among cocoa farmers in Ghana including training women working on cocoa farms, increasing their access to farm inputs, land ownership and membership to farmer groups. -__ *12/12/16*",
          "link": {
            "url": "https://www.foodbusinessnews.net/articles/8854-empowering-women-a-path-to-larger-cocoa-crops",
            "target": "_blank",
            "title": "www.foodbusinessnews.net/articles/news",
            "label": "www.foodbusinessnews.net/articles/news"
          }
        },
        {
          "internalTitle": "$400MILLION COCOA INVESTMENT AIMS TO TACKLE POVERTY AND EMPOWER FARMERS",
          "tag": "HUFFINGTON POST",
          "title": "$400MILLION COCOA INVESTMENT AIMS TO TACKLE POVERTY AND EMPOWER FARMERS",
          "description": "__Fairtrade will now partner with Cocoa Life in an expansion of its $400 million sustainability programme across Cadbury brands meaning that by 2019 five times as much Cadbury chocolate in the UK and Ireland will be made with sustainably sourced cocoa. -__ *11/22/16*",
          "link": {
            "url": "https://www.huffingtonpost.co.uk/michael-gidney-879/400-million-cocoa-investm_b_13018924.html",
            "target": "_blank",
            "title": "www.huffingtonpost.co.uk/michael-gidney-/",
            "label": "www.huffingtonpost.co.uk/michael-gidney-/"
          }
        },
        {
          "internalTitle": "‘NEXT STAGE OF EVOLUTION’: MONDELĒZ TEAMS WITH FAIRTRADE TO EXPAND COCOA LIFE TO CADBURY BRAND",
          "tag": "CONFECTIONERY NEWS",
          "title": "‘NEXT STAGE OF EVOLUTION’: MONDELĒZ TEAMS WITH FAIRTRADE TO EXPAND COCOA LIFE TO CADBURY BRAND",
          "description": "__Mondelēz International has partnered with Fairtrade to grow the chocolate maker’s own program Cocoa Life to cover all Cadbury products in the UK & Ireland. -__ *11/17/16*",
          "link": {
            "url": "https://www.confectionerynews.com/Article/2016/11/17/Mondelez-teams-with-Fairtrade-to-expand-Cocoa-Life-to-UK-Cadbury-brand",
            "target": "_blank",
            "title": "www.confectionerynews.com/Manufacturers/",
            "label": "www.confectionerynews.com/Manufacturers/"
          }
        },
        {
          "internalTitle": "FITNESS FÜR FARMER",
          "tag": "LEBENSMITTEL ZEITUNG",
          "title": "FITNESS FÜR FARMER",
          "description": "__Artikel von Christiane Düthmann über Cocoa Life, den Zielen zur Nachhaltigkeit und die Fortschritte, die bereits mit Cocoa Life erreicht wurden und wie dies gemessen wird. -__ *08/26/16*",
          "link": {
            "url": "/progress/lebensmittel-zeitung-fitness-fur-farmer/",
            "target": "_self",
            "title": "Read more",
            "label": "Read more"
          }
        },
        {
          "internalTitle": "MONDELEZ INDIA HELPS EXPAND COCOA FOOTPRINT",
          "tag": "THE HINDU",
          "title": "MONDELEZ INDIA HELPS EXPAND COCOA FOOTPRINT",
          "description": "__Article by A.D Rangarajan on how cocoa, once considered an alien crop, has now expanded its footprint in south India where Cocoa Life is implementing its program. -__ *10/06/16*",
          "link": {
            "url": "https://www.thehindu.com/news/national/andhra-pradesh/Mondelez-India-helps-expand-cocoa-footprint/article15472067.ece",
            "target": "_blank",
            "title": "www.newsite.thehindu.com/news",
            "label": "www.newsite.thehindu.com/news"
          }
        },
        {
          "internalTitle": "FITNESS FÜR FARMER",
          "tag": "LEBENSMITTEL ZEITUNG",
          "title": "FITNESS FÜR FARMER",
          "description": "__Artikel von Christiane Düthmann über Cocoa Life, den Zielen zur Nachhaltigkeit und die Fortschritte, die bereits mit Cocoa Life erreicht wurden und wie dies gemessen wird. -__ *08/26/16*",
          "link": {
            "url": "/progress/lebensmittel-zeitung-fitness-fur-farmer",
            "target": "_self",
            "title": "Read more",
            "label": "Read more"
          }
        },
        {
          "internalTitle": "EMPOWER A WOMAN AND A WHOLE COMMUNITY WILL THRIVE",
          "tag": "BUSINESS FIGHTS POVERTY",
          "title": "EMPOWER A WOMAN AND A WHOLE COMMUNITY WILL THRIVE",
          "description": "__Article by Cathy Pieters, Director of Cocoa Life. To increase the income of cocoa farming families women should receive equal access to land, finance, training and inputs. -__ *08/11/16*",
          "link": {
            "url": "http://community.businessfightspoverty.org/profiles/blogs/cathy-pieters-empower-a-woman-and-a-whole-cocoa-community-will-th",
            "target": "_blank",
            "title": "www.community.businessfightspoverty.org",
            "label": "www.community.businessfightspoverty.org"
          }
        }
      ]
    }
  }