import React from 'react';
import Link from "../../UI/Link/Link"
import PropTypes from "prop-types";
import Typography from '../../UI/Typography/Typography';
import moment from "moment";
import { dateFormat } from '../../../constants/form.constants';
import SearchElement from './SearchElement';

const ArticlesList = props => {
  const { containerStyle = {}, index, slug, seoData: { title = '' }, } = props
  return (
    <div className={containerStyle?.blogWrapper} key={`tag-${index}`}>
      {
        slug ?
          <Link url={slug} className={containerStyle?.linkContainer} gaEventLabel={title}>
            <SearchElement {...props} />
          </Link>
          : <SearchElement {...props} />
      }
    </div>
  )
}

ArticlesList.propTypes = {
  index: PropTypes.number,
  slug: PropTypes.string,
  seoData: PropTypes.shape({
    title: PropTypes.string
  }),
  containerStyle: PropTypes.object,
}


export default ArticlesList
