import React from "react"
import PropTypes from "prop-types"
import * as styles from "./ArticleVideo-tw-styles"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"
import LinkWrapper from "../UI/LinkWrapper/LinkWrapper"
import Video from "../UI/Video/Video"
import LinkWrappersLayout from "../LinkWrappersLayout/LinkWrappersLayout"

const ArticleVideo = props => {
  const {
    heading = "",
    description = "",
    video,
    isContentReversed,
    variant,
    link,
    linkWrapper,
  } = props
  const style = styles[variant] || styles.defaultStyle
  return (
    <div className={style?.contentWrapper}>
      <div
        className={`${style?.aVContainer} ${
          isContentReversed ? "flex flex-col-reverse" : ""
        }`}
      >
        <div>
          {video && <Video {...video} style={style} />}
          {linkWrapper?.linksCollection?.items?.length > 0 &&
            <LinkWrappersLayout {...linkWrapper} />
          }
        </div>
        {(heading || description || link) && (
          <div className={style?.aVcontent}>
            {heading && (
              <Typography
                className={style?.aVcontentHeading}
                content={heading}
              />
            )}
            {description && (
              <Typography
                content={description}
                className={style?.description}
              />
            )}
            {link && <Link {...link} />}
          </div>
        )}
      </div>
    </div>
  )
}

ArticleVideo.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.object,
  isContentReversed: PropTypes.bool,
  variant: PropTypes.string,
  link: PropTypes.object,
  linkWrapper: PropTypes.object,
}

export default ArticleVideo
