import React from "react"
import * as styles from "./ImageTextGrid-tw-styles"
import PropTypes from "prop-types"
import { get } from "../../../helpers/utils"
import Image from "../Image/Image"
import Typography from "../Typography/Typography"
import ImageCard from "../ImageCard/ImageCard"
import Link from "../Link/Link"
import useImage from "../../../hooks/useImage"

function renderImageCards(cards, style) {
  const { imageCardsCollection = {} } = cards
  const imageCards = get(imageCardsCollection, "items", [])
  return (
    <div className={style?.measureImageCardWrapper}>
      {imageCards?.length > 0 &&
        imageCards?.map((item, index) => (
          <div
            key={"image Cards"+index}
            id={index + 1}
            className={`${style?.measureImageCard} ${style?.cardNum}`}
          >
            <ImageCard className={style?.interventionImage} {...item} />
          </div>
        ))}
    </div>
  )
}

const ImageTextGrid = props => {
  const {
    title = "",
    variant = "",
    image = {},
    textBefore = "",
    textAfter = "",
    imageCardsCollection = {},
    link = {},
  } = props
  const { altText, foregroundImg } = useImage(image)
  const style = styles[variant] || styles["defaultStyle"]
  return (
    <div key={title} className={style?.interventionImageCard}>
      {textBefore && (
        <Typography content={textBefore} className={style?.tagWrapper} />
      )}
      {image && (
        <Image
          className={style?.interventionImage}
          contentfulImage={foregroundImg}
          alt={altText}
        />
      )}
      {textAfter && <Typography content={textAfter} />}
      {imageCardsCollection && renderImageCards(props, style)}
      {link && <Link {...link} />}
    </div>
  )
}

ImageTextGrid.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  image: PropTypes.object,
  textBefore: PropTypes.string,
  textAfter: PropTypes.string,
  link: PropTypes.object,
  imageCardsCollection: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

export default ImageTextGrid
