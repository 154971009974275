export const defaultStyle = {
  wrapper: "twoCardWrapper relative border-b-[2px] border-solid border-white",
  wrprHeadingContainer:
    "wrprHeadingContainer w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px] text-center text-textBlack border-b-[2px] border-solid border-white",
  wrprHeadingDiv:
    "wrprHeadingDiv leading-[0.9] pt-12 mb-[50px] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[0.5px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[0.5px] after:content-[' '] after:bg-darkGrey after:z-14",
  wrprHeading:
    "wrprHeading text-[40px] bg-white px-[28px] inline-block font-alternate z-[15] relative",
  wrprLink: "wrprLink wrprContainer relative md:flex w-full cursor-pointer",
  wrprDiv: "wrprDiv wrprContainer md:flex w-full",
  cardsWrpr: "cardsWrprDefault cardsWrpr w-full md:table-cell md:w-[50%] md:h-full overflow-hidden",
  bgStyle: "bgStyle bg-cover bg-no-repeat align-top",
  tagHeading: "tagHeading",
  redirectionDiv:
    "redirectionDiv block w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px] border-t-[2px] border-solid border-white",
  redirectionLink:
    "redirectionLink block font-alternate text-center text-[30.4px] w-[270px] h-[40px] text-white rounded-[38px] bg-lightGreen my-[15px] mx-auto transition transition-all duration-300 ease-in-out hover:bg-green",
  cardLink: "cardLink",
  noContentWrpr: "noContentWrpr",
}
export const TESTIMONIAL = {
  wrapper: "testimonialWrapper",
  wrprHeadingContainer:
    "wrprHeadingContainer w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px] text-center text-textBlack",
  wrprHeadingDiv:
    "wrprHeadingDiv pt-12 mb-[50px] leading-[0.9] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[0.5px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[0.5px] after:content-[' '] after:bg-darkGrey after:z-14",
  wrprHeading:
    "wrprHeading text-[40px] bg-white px-[28px] inline-block font-alternate z-[15] relative",
  wrprLink: "wrprLink flex w-full md:flex mx-auto min-[960px]:w-[960px]",
  wrprDiv: "wrprDiv md:flex w-full mx-auto min-[960px]:w-[960px] min-[567px]:px-[30px]",
  cardsWrpr:
    "cardsWrapper w-full px-[15px] min-[567px]:p-0 min-[1100px]:relative min-[1100px]:left-[-100px]",
}

export const PROGRESS_BLOG_WRAPPER_VARIANTS = {
  ...defaultStyle,
}

export const PROGRESS_BLOG_CARDS_WRAPPER = {
  ...defaultStyle,
}


export const LEFT_RIGHT_CONTENT = {
  ...defaultStyle,
  wrprDiv: "cocoaWarper wrprDiv wrprContainer md:table w-full px-[16px] md:px-30",
  wrprLink: "wrprLink wrprContainer relative md:table w-full cursor-pointer",
  cardsWrpr: "cardsWrpr w-full md:table-cell md:w-[50%] md:h-full overflow-hidden",
}

export const PROGRESS_BLOG_CARDS_LINK_TOP = {
  ...defaultStyle,
  wrapper: "followOurProgress flex flex-col-reverse twoCardWrapper relative border-b-[2px] border-solid border-white",
  redirectionDiv:
    "redirectionLinkBlock block w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pb-[40px] border-t-[2px] border-solid border-white",
  redirectionLink:
    "redirectionLink uppercase px-15 block font-alternate text-center text-[30.4px] w-full md:w-[525px] text-white rounded-[38px] bg-lightGreen my-[15px] mt-0 leading-[40px] mx-auto transition transition-all duration-300 ease-in-out hover:bg-green",
}

export const TESTIMONIAL_WITH_SOCIAL_ICON={
  ...TESTIMONIAL,
  sWrprLink:'sWrprLink absolute top-[70%] left-[50%] md:top-unset md:relative md:left-[0] lg:left-[-75px]',
  wrapper: "testimonialWrapper items-center md:flex w-full mx-auto min-[960px]:w-[960px] min-[567px]:px-[30px] relative",
  wrprDiv: "wrprDiv basis-[62%]",
}

export const RIGHT_CARD_MARGIN_LEFT_AUTO = {
  ...defaultStyle,
  wrprLink: "wrprLink wrprContainer wrprContainerRight relative md:flex w-full cursor-pointer",
  wrprDiv: "wrprDiv wrprContainer wrprContainerRight md:flex w-full",
}

export const TESTIMONIAL_WITHIN_CONTENT = {
  ...TESTIMONIAL,
  cardsWrpr:
    "testimonialWithinContent cardsWrapper w-full px-[15px] min-[567px]:px-20 min-[1100px]:relative",
}

export const TWO_CARD_WRAPPER_NO_HOVER_EFFECT = {
  ...defaultStyle,
  wrapper: "twoCardWrprNoHover relative border-b-[2px] border-solid border-white",
  wrprDiv: "cocoaWarper wrprDiv wrprContainer md:table w-full px-[16px] md:px-30",
}