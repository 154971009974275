import React from "react"
import Slider from "react-slick"
import "../../styles/slider.css"
import ImageCard from "../UI/ImageCard/ImageCard"
import * as styles from "./ImageCardsSlider-tw-styles"
import PropTypes from "prop-types"
import { gaSliderClass } from "../../constants/gtm.constants"

const SampleArrow = ({ currentSlide, slideCount, ...props }) => {
  const { eventlabel } = props
  return (
    <button
      {...props}
      className={`${props?.className} ${gaSliderClass}`}
      data-action-detail={eventlabel}
    />
  )
}

SampleArrow.propTypes = {
  eventlabel: PropTypes.string,
  className: PropTypes.any,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
}

const ImageCardsSlider = props => {
  const { cardsCollection, variant, sliderSettings } = props
  const {
    slidesToShow,
    slidesToShowTab,
    slidesToShowMobile,
    slidesToScroll,
    dots,
    arrow,
    fade,
  } = sliderSettings
  const settings = {
    arrows: arrow,
    nextArrow: (
      <SampleArrow eventlabel={"ArrowRight"} aria-label="right arrow" />
    ),
    prevArrow: <SampleArrow eventlabel={"ArrowLeft"} aria-label="left arrow" />,
    dots: dots,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    fade: fade,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          swipe: true,
          swipeToSlide: true,
          slidesToShow: slidesToShowTab,
          slidesToScroll: Math.round(slidesToShowTab),
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          swipe: true,
          swipeToSlide: true,
          slidesToShow: slidesToShowMobile,
          slidesToScroll: Math.round(slidesToShowMobile),
        },
      },
    ],
  }

  const style = styles[variant] || styles?.defaultStyle

  return (
    <div className={style?.imageSlider}>
      <div className={style?.imageSliderWrapper}>
        <Slider {...settings}>
          {cardsCollection?.items &&
            cardsCollection?.items?.map(item => {
              return (
                item && (
                  <div className={style?.imageSlide} key={item?.title}>
                    <ImageCard
                      {...item}
                      variant={item?.variant}
                      style={style?.cardStyle}
                    />
                  </div>
                )
              )
            })}
        </Slider>
      </div>
    </div>
  )
}

ImageCardsSlider.propTypes = {
  cardsCollection: PropTypes.object,
  variant: PropTypes.string,
  sliderSettings: PropTypes.object,
}
export default ImageCardsSlider
