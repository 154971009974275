import React from "react"
import * as styles from "./MultiColumnsGrid-tw-styles"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import ImageTextGrid from "../UI/ImageTextGrid/ImageTextGrid"

const MultiColumnsGrid = props => {
  const {
    title = "",
    textBefore = "",
    descriptionBefore = "",
    subTitle = "",
    contentColumnsCollection = {},
    descriptionAfter = "",
    variant = "",
    elementId = ""
  } = props
  const style = styles[variant] || styles["defaultStyle"]

  const renderContentColumns = () => {
    return contentColumnsCollection?.items?.map((item, idx) => {
      return <ImageTextGrid {...item} key={idx} />
    })
  }

  
  return (
    <div className={style?.interventionContainer} id = {elementId}>
      <div className={style?.interventionSubContainer}>
        <div className={style?.interventionTitleWrap}>
          {title && <h1 className={style?.interventionTitle}>{title}</h1>}
        </div>
        <div>{textBefore && <h1>{textBefore}</h1>}</div>
        {descriptionBefore && (
          <Typography
            content={descriptionBefore}
            className={style?.interventionMiddleContentText}
          />
        )}
        {subTitle && (
          <Typography
            content={subTitle}
            className={style?.interventionSubTitle}
          />
        )}
        <div className={style?.interventionMiddleColmns}>
          {contentColumnsCollection?.items?.length > 0 &&
            renderContentColumns()}
        </div>

        {descriptionAfter && (
          <Typography content={descriptionAfter} className="" />
        )}
      </div>
    </div>
  )
}

MultiColumnsGrid.propTypes = {
  title: PropTypes.string,
  textBefore: PropTypes.string,
  descriptionBefore: PropTypes.string,
  subTitle: PropTypes.string,
  contentColumnsCollection: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  descriptionAfter: PropTypes.string,
  variant: PropTypes.string,
  elementId: PropTypes.string,
}

export default MultiColumnsGrid
