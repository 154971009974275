import React, { useEffect, useState } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import PropTypes from "prop-types"
import * as styles from './GlobalSearchListing-tw-styles'
import Typography from "../UI/Typography/Typography"
import { getSearchResults } from "../../adapters/algolia-adapter"
import { dateFormat } from '../../constants/form.constants'
import { COCOA_LIFE_TAG_LABEL } from '../../constants/label.constants'
import moment from "moment";
import Link from "../UI/Link/Link"
import Button from "../UI/Button/Button"

export const getUrlValues = () => {
  if (typeof window !== "undefined") {
    const queryParameters = new URLSearchParams(window?.location?.search)
    return {
      searchText:queryParameters.get('SearchText'),
      pageNo: queryParameters.get('page') ? queryParameters.get('page') :0
    }
  }
}

const GlobalSearchListing = props => {
  const { heading = '' } = props
  const { contentStyle = {}, style = {}, containerStyle = {} } = styles
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [onload, setOnLoad] = useState(true)
  const [searchResult, setSearchResult] = useState([])
  const [pageNo, setPageNo] = useState(0)
  const [pageDetails, setPageDetails] = useState(null)
  const { hits = [], nbHits, nbPages, hitsPerPage, page } = searchResult

  useDeepCompareEffect(() => {
    setPageDetails({
      minValue: pageNo * hitsPerPage + 1,
      maxValue: pageNo * hitsPerPage + hits?.length,
    })
  }, [hits])

  const updatePageUrl = ({ search, pageNo }) => {
    if (typeof window !== "undefined") {
      // page number query
      const pathName = window?.location?.pathname
      // create query params
      let queryParams = ""
      queryParams += `?SearchText=${search}`
      queryParams += pageNo ? `&page=${pageNo}` : ""
      const newUrl =
        window.location.origin +
        pathName + queryParams
      window?.history?.pushState(null, null, newUrl)
    }
  }
  useEffect(() => {
    if(!onload){
      (async () => {
        if(typeof window !="undefined"){
          window?.scrollTo(0,0)
        }
        const values = await getUrlValues()
        await setSearchTerm(values?.searchText)
        await updatePageUrl({
          search: values?.searchText,
          pageNo: pageNo
        })
        await fetchSearchResults(values.searchText,pageNo)
      })();
    }
  }, [pageNo])

  useEffect(() => {
    const values = getUrlValues()
    setSearchTerm(values?.searchText)
    setPageNo(values?.pageNo)
    updatePageUrl({
      search: values?.searchText,
      pageNo: values?.pageNo
    })
    fetchSearchResults(values?.searchText,values?.pageNo)
    setOnLoad(false)
  }, [])

  const fetchSearchResults = (searchTerm, page) => {
    setIsLoading(true)
    const obj = {
      search: searchTerm,
      pageNo: page ? page : pageNo,
      pageType: '',
      filters: {}
    }
    // get search results from Algolia
    getSearchResults(obj)
      .then(res => {
        setSearchResult(res)
        setIsLoading(false)
      })
      .catch(err => {
        console.log("ERROR", err)
        setIsLoading(false)
      })
  }

  const renderSearchResult = (article, index) => {
    const {
      slug,
      seoData: { title = '', articleType = '', articleDate, description = {} },
    } = article;

    let dateVar = moment(articleDate);
    let publishDate = dateVar.utc().format(dateFormat);

    return (
      <div className={containerStyle?.blogWrapper} key={`tag-${index}`}>
        <Link url={slug} className={containerStyle?.linkContainer}>
          <div className={contentStyle?.resultContent}>
            <div className={contentStyle?.resultMetaData}>
              {articleType &&
              <span>
                  <span className={contentStyle?.resultTypeText}>
                  <span className={contentStyle?.libraryBox}>LIBRARY</span>
                  {articleType}
                  </span>
                  <span>
                    &nbsp;-&nbsp;
                  </span>
              </span>
              }
              {articleDate &&
                <span className={contentStyle?.resultDate}>
                {publishDate}
                </span>
              }
            </div>
            {title && <Typography className={contentStyle?.textGreen} content={`${COCOA_LIFE_TAG_LABEL} - ${title}`} />}
            {description && <Typography className={contentStyle?.resultDescription} content={description?.description} />}
          </div>
        </Link>
      </div>
    )
  }

  const renderPagination = () => {
    return (
      <div className={style?.paginationBlock}>
        <span className={style?.paginationNumber}>
          {pageDetails?.minValue} - {pageDetails?.maxValue} / {nbHits}
        </span>
        <Button
          className={`${style?.paginationPrev} ${parseInt(pageNo) === 0 ? 'opacity-50 inactive':'active hover:bg-[#cbcbcb]'}`}
          disabled={parseInt(pageNo) === 0}
          onClick={() => {
            setPageNo(parseInt(pageNo) - 1)
          }}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </Button>

        <Button
          className={`${style?.paginationNext} ${pageNo === parseInt(nbPages) - 1 ? 'opacity-50 inactive':'active hover:bg-[#cbcbcb]'}`}
          disabled={pageNo ===  parseInt(nbPages) - 1}
          onClick={() => {
            setPageNo(parseInt(pageNo) + 1)
          }}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </Button>
      </div>
    )
  }

  const renderNoRecordFound = () => {
    return (
        <div className={style?.noResulstFound}>
          <span>No Results Found</span>
        </div>
    )
  }

  if (isLoading) {
    return <div className={style?.globalSearchLoading}>Loading...</div>
  }

  return (
    <div className={style?.mainContainer}>
      <div className={style?.headingWrpr}>
        <Typography className={style?.headingStyle} content={heading} />
        {searchTerm && <span className={style?.searchTerm}>&nbsp; {`'${searchTerm}'`}</span>}
      </div>
      <div className={style?.containerWrapper}>
      {!hits?.length ? (
        renderNoRecordFound()
      ) : (
        <div className="searchResultsWrapper">
          {(hits?.length > 0) &&
          <div className='resulstFound p-12 md:pl-30 text-17 text-textBlack font-brand font-bold border-b-[3px] border-darkGrey'>
          <span>{nbHits} result(s) found</span>
          </div>
          }
          {hits?.length > 0 &&  hits?.map((article, idx) => renderSearchResult(article, idx))}
          {renderPagination()}
        </div>
      )}
      </div>
    </div>
  )
}

GlobalSearchListing.propTypes = {
  heading: PropTypes.string
}

export default GlobalSearchListing

