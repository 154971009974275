export const linkWrappersLayout = {
    "linksCollection": {
      "items": [
        {
          "linkLabel": "Download publication",
          "textTheme": null,
          "variant": "BG_ORANGE",
          "link": {
            "url": "https://assets.ctfassets.net/qggsjlmpzfmx/4MsGXiJzcadJPlIJbqKLcs/b1007811e66a5aba60c559e792d6ebb6/April_2015_Cocoa_Life_Cote_dIvoire_Needs_Assessment_Exec_summary.pdf",
            "target": "_blank",
            "gaEventClass": null,
            "gaEventLabel": null,
            "scrollToElementId": null
          }
        }
      ]
    }
  }