export const headerCollection = {
    "logoContainerCollection": {
        "items": [
            {
                "url": "https://www.mondelezinternational.com/",
                "gaEventClass":"event_logo",
                "gaEventLabel":"mondelez logo",
                "image": {
                    "title": "mondelez logo color",
                    "description": "logo color",
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/6kVpadjCadothDlK5Eiwvf/fd6212c00f6448c443d6bdabf074e9e2/mondelez_logo_color.png",
                    "width": 1985,
                    "height": 687
                }
            },
            {
                "url": "/",
                "gaEventClass":"event_logo",
                "gaEventLabel":"cocoalife logo",
                "image": {
                    "title": "cocoalife-10years-logo",
                    "description": "cocoalife-10years-logo",
                    "url": "https://images.ctfassets.net/qggsjlmpzfmx/2n9V8fQ8LZ7EO7GLs3fFdS/034b245b6df06665046d91f7485f3082/cocoalife-10years-logo.png",
                    "width": 360,
                    "height": 247
                }
            }
        ]
    },
    "navigationCollection": {
        "items": [
            {
                "label": "10 Years",
                "link": {
                    "url": "/ten-stories-for-ten-years/",
                    "target": "_self",
                    "scrollToElementId": null
                },
                "dropdownItemsCollection": {
                    "items": []
                }
            },
            {
                "label": "The Program",
                "link": {
                    "url": "/the-program/approach/",
                    "target": "_self",
                    "scrollToElementId": null
                },
                "dropdownItemsCollection": {
                    "items": [
                        {
                            "__typename": "Link",
                            "url": "/the-program/approach/",
                            "label": "Approach",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/the-program/approach/",
                            "label": "Key Areas",
                            "target": "_self",
                            "scrollToElementId": "focus-areas"
                        },
                        {
                            "__typename": "Link",
                            "url": "/the-program/child-protection/",
                            "label": "Child Protection",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/the-program/womens-empowerment/",
                            "label": "Women's Empowerment",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/the-program/climate-change/",
                            "label": "Climate Change",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/the-program/partners/",
                            "label": "Partners",
                            "target": "_self",
                            "scrollToElementId": null
                        }
                    ]
                }
            },
            {
                "label": "Impact",
                "link": {
                    "url": "/impact/",
                    "target": "_self",
                    "scrollToElementId": null
                },
                "dropdownItemsCollection": {
                    "items": [
                        {
                            "__typename": "Link",
                            "url": "/impact/",
                            "label": "Our Progress",
                            "target": "_self",
                            "scrollToElementId": "progress"
                        },
                        {
                            "__typename": "Link",
                            "url": "/impact/",
                            "label": "Dashboard",
                            "target": "_self",
                            "scrollToElementId": "dashboard"
                        },
                        {
                            "__typename": "Link",
                            "url": "/impact/",
                            "label": "KPIs",
                            "target": "_self",
                            "scrollToElementId": "kpis"
                        },
                        {
                            "__typename": "Link",
                            "url": "/impact/",
                            "label": "Verification",
                            "target": "_self",
                            "scrollToElementId": "verification"
                        },
                        {
                            "__typename": "Link",
                            "url": "/impact/",
                            "label": "Impact Evaluations",
                            "target": "_self",
                            "scrollToElementId": "assessment"
                        }
                    ]
                }
            },
            {
                "label": "In the cocoa Origins",
                "link": {
                    "url": "/in-the-cocoa-origins/",
                    "target": "_self",
                    "scrollToElementId": null
                },
                "dropdownItemsCollection": {
                    "items": [
                        {
                            "__typename": "Link",
                            "url": "/in-the-cocoa-origins/",
                            "label": "Cocoa Growing",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/in-the-cocoa-origins/interactive-map/",
                            "label": "Interactive Map",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/in-the-cocoa-origins/cocoa-life-in-ghana/",
                            "label": "Ghana",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/in-the-cocoa-origins/cocoa-life-in-cote-divoire/",
                            "label": "Côte d'Ivoire",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/in-the-cocoa-origins/cocoa-life-in-indonesia/",
                            "label": "Indonesia",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/in-the-cocoa-origins/cocoa-life-in-dominican-republic/",
                            "label": "Dominican Republic",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/in-the-cocoa-origins/cocoa-life-in-india/",
                            "label": "India",
                            "target": "_self",
                            "scrollToElementId": null
                        },
                        {
                            "__typename": "Link",
                            "url": "/in-the-cocoa-origins/cocoa-life-in-brazil/",
                            "label": "Brazil",
                            "target": "_self",
                            "scrollToElementId": null
                        }
                    ]
                }
            },
            {
                "label": "Brands",
                "link": {
                    "url": "/brands/",
                    "target": "_self",
                    "scrollToElementId": null
                },
                "dropdownItemsCollection": {
                    "items": []
                }
            },
            {
                "label": "Progress Blog",
                "link": {
                    "url": "/progress?SearchText=*&Filter=Progress%20Blog",
                    "target": "_self",
                    "scrollToElementId": null
                },
                "dropdownItemsCollection": {
                    "items": []
                }
            },
            {
                "label": "Newsroom",
                "link": {
                    "url": "/newsroom/",
                    "target": "_self",
                    "scrollToElementId": null
                },
                "dropdownItemsCollection": {
                    "items": []
                }
            },
            {
                "label": "FAQ",
                "link": {
                    "url": "/faq/",
                    "target": "_self",
                    "scrollToElementId": null
                },
                "dropdownItemsCollection": {
                    "items": []
                }
            }
        ]
    },
    "countriesSelectorCollection": {
        "items": [
            {
                "label": "English",
                "value": "en",
                "dataPath": "https://www.cocoalife.org/"
            },
            {
                "label": "Français",
                "value": "fr",
                "dataPath": "https://fr.cocoalife.org/"
            },
            {
                "label": "Deutsch",
                "value": "de",
                "dataPath": "https://de.cocoalife.org/"
            },
            {
                "label": "Español",
                "value": "es",
                "dataPath": "https://es.cocoalife.org/"
            },
            {
                "label": "Português",
                "value": "pt",
                "dataPath": "https://pt.cocoalife.org/"
            }
        ]
    }
}