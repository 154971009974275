import React from "react"
import PropTypes from "prop-types"
import { gaEventClick } from "../../../constants/gtm.constants"

const Button = props => {
  const {
    className,
    text,
    children,
    dataActionDetail,
    onClick,
    disabled,
    id,
    gaEventClass = gaEventClick,
  } = props
  const buttonClass = `${!disabled && "cursor-pointer"} ${className || ""}`

  return (
    <button
      // role={"button"}
      className={`${buttonClass} ${gaEventClass}`}
      data-action-detail={dataActionDetail}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      {children || text}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.any,
  dataActionDetail: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  gaEventClass: PropTypes.string,
}

export default Button
