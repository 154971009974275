export const defaultStyle = {
    container: "",
    imgWrpr: "h-min overflow-hidden rounded-[2rem] px-auto text-sm tracking-wide text-darkBlue font-medium before:pr-5 w-[195px] grid",
    heading: "text-xl text-white text-center",
    description: "text-xl text-white px-auto text-center",
    linkWrpr: "",
    btnStyle: "hidden",
    contentWrpr: "relative",
    img: "rounded-[2rem]",
    cardsSliderWrapr: "",
    cardWrapr: "cardWrapr text-white  text-[40px] px-auto w-full text-center pt-50 font-bold",
    cardDesc: "cardDesc text-white  text-[18px] px-auto lg:w-1/3 mx-auto w-full text-center pb-40",
    cardWrpr: "lg:w-[1170px] mx-auto max-w-full",
}
export const IMAGE_TEXT_BOX_LAYOUT = {
    container: " xl:w-[1170px] w-auto overflow-hidden mx-auto py-20 mt-40 max-w-full IMAGE_TEXT_BOX_LAYOUT print-noshow",
    heading: "receipeHeading text-[30px] lg:mb-[20px] font-ProxNarrow text-darkBlue flex w-full lg:mx-auto x-15 justify-center text-center leading-[49px] [&>h2]:leading-[37.5px]",
    gradientTop: "reciepeGradient absolute bottom-[0px] left-[0px] lg:mb-[20px] lg:mb-0 w-full rounded-b-[2rem] h-[140px] bg-toTopGradient",
    liWrpr: "relative",
    imgCardWrpr: "relative",
    cardsContainer: "cardsContainer lg:grid lg:grid-cols-3 grid-cols-6 lg:gap-25 lg:w-[1170px] mx-auto px-15 lg:px-30 ",
    linkWrpr: "linkWrpr relative",
    linkCardsWrpr: " linkCardsWrpr overflow-hidden relative rounded-[20px]",
    sliderMob: "sliderMob",
    cardWrpr: "max-w-full lg:w-[1170px] mx-auto receipeCardsSection",
}

export const IMAGE_TEXT_BOX_LAYOUT_TWO = {
    container: "lg:max-w-full lg:w-[1170px] w-auto overflow-hidden mx-auto py-20 mt-40 max-w-full diffCards print-noshow",
    heading: "receipeHeading recipeHeaderText lg:text-[40px] text-[30px] lg:mb-[20px] font-ProxRegular text-darkBlue flex w-full lg:mx-auto x-15 justify-center text-center lg:leading-[49px] leading-[37px]",
    gradientTop: "reciepeGradient absolute bottom-[0px] left-[0px] lg:mb-[20px] lg:mb-0 w-full rounded-b-[2rem] h-[140px] bg-toTopGradient",
    liWrpr: "relative",
    imgCardWrpr: "relative",
    cardsContainer: "cardsContainer lg:grid lg:grid-cols-3 grid-cols-6 lg:gap-25 lg:w-[1170px] mx-auto px-15 lg:px-30 ",
    linkWrpr: "linkWrpr relative",
    linkCardsWrpr: " linkCardsWrpr overflow-hidden  relative lg:left-[0] rounded-[20px]",
    sliderMob: "sliderMob",
    cardWrpr: "lg:max-w-full lg:w-[1170px] lg:px-20 mx-auto",
}

export const ROW_REVERSE_CARD_LAYOUT = {
    description: "lg:text-[40px] text-[30px] text-darkBlue px-auto text-center lg:px-auto px-[15px] md:px-30 lg:px-15 mx-auto lg:w-[64%]  mb-[30px] lg:leading-[49px] leading-[37px] rowRev",
    cardsWrapr: "cardsWrapr cardsContainer lg:w-[750px] mx-auto py-20",
    liWrpr: "liWrpr even:py-[5%] even:flex even:justify-center",
    container: "lg:max-w-full lg:w-[1170px] mx-auto py-20 mt-60",
    // cardWrpr: "lg:w-[1170px] mx-auto"
}

export const imageWithText = {
    cardsWrapr: "cardsWrapr grid grid-cols-1 lg:grid-cols-3 gap-4 lg:w-[1170px] mx-auto py-20",
    imgWrpr: "imgWrpr flex  rounded-20 ",
    cardWrpr: "lg:w-[1170px] mx-auto",
    gradTop: "absolute bottom-[0px]  w-[195px] rounded-b-[2rem] h-[140px] bg-toTopGradient",
}

export const FIVE_COLUMN_CARDS_LAYOUT = {
    container: "bg-darkBlue text-white py-50 lg:pt-[44px] lg:pb-40 phillyfivecards lg:relative ",
    cardsContainer: "grid lg:grid-cols-5 bg-darkBlue carousel-item active md:w-auto lg:w-[1170px] mx-auto py-25",
    cardWrpr: "lg:max-w-full lg:w-[1170px] mx-auto mt-30 mb-0 lg:mb-15 lg:pl-0 lg:ml-auto caroselScrnSize lg:max-h-[227px]",
    cardsWrapr: "grid lg:grid-cols-5 bg-darkBlue carousel-item active lg:w-[1170px] mx-auto py-20 ",
    imgWrpr: "h-min overflow-hidden rounded-[2rem] px-auto text-sm tracking-wide text-darkBlue font-medium before:pr-5 w-[195px] lg:w-[180px] grid ",
    heading: "text-[40px] text-white text-center w-full lg:w-3/4 font-ProxNarrow lg:mx-auto px-15 [&>h2]:leading-[49px] tracking-wide:leading-[50px]",
    description: "philyFiveCrd text-[18px] md:w-[455px] flex w-full  text-white px-auto mx-auto pb-20 text-center justify-center leading-30 px-10 tracking-[0]",
    linkWrpr: "lg:mt-0 lg:mr-0 lg:mx-auto mb-20 flex justify-center lg:px-5 px-[12px] md:min-w-[180px]",
    contentWrpr: "relative",
    img: "rounded-[2rem]",
    btnWrpr: ""
}

export const IMAGE_TEXT_COLUMN_LAYOUT = {
    cardsContainer: "bg-white  md:w-[1170px] mx-auto py-20 grid lg:grid-cols-4 bg-white",
    container: "bg-white abtPhildlpi lg:w-[1170px] lg:mx-auto py-20  cookingSteps max-w-full md:mx-25",
    imgWrpr: "imgWrpr",
    heading: " heading lg:mx-auto font-ProxNarrow flex justify-center text-[40px] text-center md:w-[75%] md:mx-auto w-[90%] mx-15 leading-[49px] pb-20",
    description: "description 1 text-[30px] mb-30 lg:mb-0 text-center mx-auto lg:w-3/5 w-full leading-[40px]",
    linkWrpr: "linkWrpr px-15 mb-40",
    contentWrpr: "contentWrpr",
    img: "img",
    cardWrpr: "lg:w-[1170px] mx-auto max-w-full",
}

export const FOUR_IMG_TEXT_LAYOUT = {
    cardsContainer: "bg-white  md:w-[1170px] mx-auto py-20 grid lg:grid-cols-4 bg-white",
    container: "bg-white abtPhildlpi lg:max-w-full lg:w-[1170px] lg:mx-auto py-20 mt-50 lg:mt-7 cookingSteps",
    imgWrpr: "imgWrpr",
    heading:
        " heading lg:mx-auto font-ProxBold text-[30px] lg:text-[40px] text-center mx-10 leading-[37px] lg:leading-[49px] md:child:block child:pr-10 lg:child:pr-0 tracking-normal",
    description:
        "description 2 lg:text-[30px] text-[23px] mb-30 text-center mx-auto lg:w-[54%] md:w-[80%] w-full lg:leading-[40px] leading-[30px] tracking-normal px-10 md:px-0",
    linkWrpr: "linkWrpr px-15 mb-40",
    contentWrpr: "contentWrpr",
    img: "img",
    cardWrpr: "lg:max-w-full lg:w-[1170px] lg:px-20 mx-auto fourImageTextLayout",
}

export const BLUE_TWO_COLUMN_LAYOUT = {
    cardsWrapr: "lg:grid justify-center lg:gap-30 md:gap-12 gap-18 lg:grid-cols-2 carousel-item active lg:w-[1170px] md:w-3/4  mx-auto py-30 lg:px-15 rangeCardWrpr lg:mb-100 max-w-full",
    heading: "px-29 text-3xl font-ProxBold py-[49px] leading-[34.5px] text-white bg-darkBlue text-center tracking-[1px]",
    linkWrpr: "lg:mt-0 lg:mr-0 mb-30px lg:ml-0 lg:w-[555px] lg:h-[555px] w-full md:h-[600px] mb-20px bg-darkBlue rounded-[20px] flex mx-[7px] bluLinkWrpr",
    cardWrpr: "lg:w-[1170px] mx-auto",
    linkCardsWrpr: "linkCardsWrpr flex justify-center w-full lg:mb-0 md:mb-30 mb-30",
}

export const THREE_COLUMN_IMAGE_CARDS_LAYOUT = {
    container: "py-50 my-0 mx-auto lg:mt-[320px] md:mt-[250px] mt-[330px] lg:max-w-full lg:w-[1170px] THREE_COLUMN_IMAGE_CARDS_LAYOUT print-noshow",
    cardsContainer: "lg:grid lg:grid-cols-3 py-15 justify-center align-center mx-auto lg:w-[1140px] mb-30",
    cardsWrapr: "lg:grid lg:grid-cols-3 py-15 justify-center items-center mx-auto lg:w-[1140px] mb-30",
    imgWrpr: "2",
    heading: "3",
    description: "text-3xl text-center mx-0 mb-30 mt-px font-ProxNarrow leading-[37.5px] font-normal tracking-[1px]",
    linkCardsWrpr: "lg:w-[354px] lg:h-[354px] lg:flex lg:justify-center lg:flex-wrap",
    linkWrpr: "",
    btnWrpr: "buttonEffect2",
    contentWrpr: "5",
    img: "6",
    sliderMob: "md:mb-0 mb-30",
    cardWrpr: "lg:w-[1140px] mx-auto rangeSubpage",
}
export const recipePageSlider = {
    container: "bg-darkBlue text-white py-50 recipePagecards",
    cardsContainer: "grid lg:grid-cols-5 bg-darkBlue carousel-item active lg:w-[1170px] mx-auto py-25",
    cardsWrapr: "grid lg:grid-cols-5 bg-darkBlue carousel-item active lg:w-[1170px] mx-auto py-20 ",
    imgWrpr: "h-min overflow-hidden rounded-[2rem] px-auto text-sm tracking-wide text-darkBlue font-medium before:pr-5 w-[195px] lg:w-[180px] grid ",
    heading: "text-[40px] text-white text-center mb-8",
    description: "text-3xl flex w-full text-white px-auto mx-auto pb-20 text-center justify-center leading-30 px-10",
    linkWrpr: "lg:mt-0 mr-0 mx-auto mb-20 flex justify-center",
    contentWrpr: "relative",
    img: "rounded-[2rem]",
    cardWrpr: "lg:w-[1170px] mx-auto ",
}

export const BOTTOM_RECEPE_CARDS = {
    container: " lg:w-[1170px] w-auto overflow-hidden mx-auto py-20 mt-40 max-w-full",
    heading: "receipeHeading text-[30px] mb-[20px] text-darkBlue flex w-full lg:mx-auto x-15 justify-center text-center",
    gradientTop: "reciepeGradient absolute bottom-[0px] left-[0px] lg:mb-[20px] lg:mb-0 w-full rounded-b-[2rem] h-[140px] bg-toTopGradient",
    liWrpr: "relative",
    imgCardWrpr: "relative",
    cardsContainer: "cardsContainer lg:grid lg:grid-cols-3 grid-cols-6 lg:gap-25 lg:w-[1170px] mx-auto px-15 lg:px-30 ",
    linkWrpr: "linkWrpr relative",
    linkCardsWrpr: " linkCardsWrpr overflow-hidden rounded-20",
    sliderMob: "sliderMob",
    cardWrpr: "lg:w-[1170px] mx-auto",
}
export const Similar_Recipe_variant = {
    container: "pt-50 my-0 mx-auto overflow-hidden lg:max-w-full lg:w-[1170px] ClsRecipeCards",
    cardsContainer: "lg:grid lg:grid-cols-4 py-15 justify-center align-center mx-auto lg:w-[1140px] mb-30",
    cardsWrapr: "p-15 flex justify-center flex-wrap mx-auto lg:text-center",
    imgWrpr: "",
    heading: "",
    description: "text-[30px] font-ProxBold mx-0 mt-px ml-[16px] lg:text-left text-center",
    linkCardsWrpr: " linkCardsWrpr overflow-hidden   md:left-[0px] relative left-[-65px] lg:left-[0] rounded-[20px]",
    linkWrpr: "",
    btnWrpr: "buttonEffect2",
    contentWrpr: "",
    img: "",
    sliderMob: "sliderMob mb-30",
    cardWrpr: "lg:w-[1170px] mx-auto max-w-full",
    gradientTop: "reciepeGradient absolute bottom-[0px] left-[0px] lg:mb-[20px] lg:mb-0 w-full rounded-b-[2rem] h-[140px] bg-toTopGradient",
}

export const TWO_COLUMN_IMAGE_CARDS = {
    container: "pt-50 pb-10 my-0 mx-auto overflow-hidden lg:max-w-full lg:w-[1170px] ClsRecipeCards",
    cardsContainer: "lg:grid lg:grid-cols-4 py-15 justify-center align-center mx-auto lg:w-[1140px] mb-30",
    cardsWrapr: "p-15 flex justify-center flex-wrap mx-auto lg:text-center",
    imgWrpr: "",
    heading: "",
    description: "text-[30px] font-ProxBold mx-0 mt-px ml-10 lg:text-left text-center",
    linkCardsWrpr: " linkCardsWrpr overflow-hidden  md:left-[0px] relative left-[-65px] lg:left-[0] rounded-[20px]",
    linkWrpr: "",
    btnWrpr: "buttonEffect2",
    contentWrpr: "",
    img: "",
    sliderMob: "sliderMob mb-30",
    cardWrpr: "lg:w-[1170px] mx-auto max-w-full",
    gradientTop: "reciepeGradient absolute bottom-[0px] left-[0px] lg:mb-[20px] lg:mb-0 w-full rounded-b-[2rem] h-[140px] bg-toTopGradient",
}

export const RECIPE_CARDS_SLIDER = {
    container: "py-50 my-0 mx-auto  lg:max-w-full lg:w-[1170px] bg-darkBlue text-white",
    cardsContainer: "lg:grid lg:grid-cols-3 py-15 justify-center align-center mx-auto lg:w-[1140px] mb-30",
    cardsWrapr: "lg:grid lg:grid-cols-3 py-15 justify-center ",
    imgWrpr: "2",
    heading: "3",
    description: "text-3xl text-center mx-0 mb-40 mt-px",
    linkCardsWrpr: "lg:w-[354px] lg:h-[354px] lg:flex lg:justify-center lg:flex-wrap",
    linkWrpr: "",
    btnWrpr: "buttonEffect2",
    contentWrpr: "5",
    img: "6",
    sliderMob: "mb-30",
    cardWrpr: "lg:w-[1170px] mx-auto",
}

export const FORM_SUBMIT_CARDS = {
    container: "bg-darkBlue lg:w-[945px] md:w-[440px] rounded-20 bg-white md:mx-auto p-25 mx-10",
    cardsContainer: "",
    cardWrpr: "",
    cardsWrapr: "",
    imgWrpr: "",
    heading: "text-[40px] mt-5 text-center w-full font-ProxBold lg:mx-auto md:px-15 tracking-wide leading-[49px] ",
    description: "text-[25px] flex w-full px-auto mx-auto text-center justify-center leading-30 lg:px-10 md:px-0 tracking-[0] mt-15 mb-20 lg:w-[750px] text-[#7f7f7f] formSubmitCard",
    linkWrpr: "",
    contentWrpr: "",
    img: "",
    btnWrpr: " before:bg-downloadIcon before:bg-no-repeat before:bg-left-top before:bg-[length:100%_100%] before:inline-block before:mr-10 before:relative before:top-6 before:w-24 before:h-24",
    btnContainer:"  buttonEffect2 downloadBtn"
}

export const MONTHLY_ACTIVITIES_CARDS_LAYOUT = {
    container: "activityContainer w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[32px] pb-[96px]",
    linkCardsWrpr: "cardsWrapper relative",
    linkWrpr: "linkWrpr",
    cardsContainer: "",
    cardWrpr: "",
    cardsWrapr: "",
    imgWrpr: "",
    heading: "left-[-10px] relative mb-[12px] uppercase font-alternate text-[30.4px] text-lightGreen leading-[0.96]",
    description: "text-[17px] w-full text-textBlack max-w-[590px] mb-[1rem] leading-[1.375]",
    linkWrpr: "",
    contentWrpr: "",
    img: "",
    btnWrpr: " before:bg-downloadIcon before:bg-no-repeat before:bg-left-top before:bg-[length:100%_100%] before:inline-block before:mr-10 before:relative before:top-6 before:w-24 before:h-24",
    btnContainer:" buttonEffect2 downloadBtn",
}

export const TESTIMONIALS_CARDS_WRAPPER= {
    container: "testimonials_img_cards pt-50 pb-40 px-30 max-w-[960px] mx-auto overflow-hidden",
    heading: "heading text-center pt-12 mb-[50px] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[1px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[1px] after:content-[' '] after:bg-darkGrey after:z-14 text-textBlack",
    linkCardsWrpr: "linkCardsWrpr w-full min-[567px]:w-[50%] mb-[60px] min-[567px]:mb-0 min-[567px]:odd:pr-[16px] min-[860px]:odd:pr-[75px] min-[567px]:even:pl-[16px] min-[860px]:even:pl-[75px] min-[567px]:even:pr-10",
    cardsWrapr: "testimonials_img_cards_wrpr flex justify-center flex-wrap mx-auto",
}

export const TESTIMONIALS_TWOSECTION_LAYOUT= {
    container: "testimonialWrapper testimonials_img_cards pt-50 pb-0 max-w-[960px] mx-auto overflow-hidden",
    heading: "heading text-center pt-12 mx-15 min-[567px]:mx-30 mb-[50px] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[1px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[1px] after:content-[' '] after:bg-darkGrey after:z-14 text-textBlack",
    linkCardsWrpr: "linkCardsWrpr w-full min-[768px]:w-[50%] px-15 min-[567px]:px-30 pt-50 pb-40",
    cardsWrapr: "flex justify-start flex-wrap mx-auto mt-[90px]",
}