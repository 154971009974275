export const  liberarySearch ={
  heading: "THE COCOA LIFE LIBRARY",
  filters: {
    region: [
      "Brazil",
      "Cote d'Ivoire",
      "Dominican Republic",
      "Ghana",
      "India",
      "Indonesia",
    ],
    focusAreas: [
      "Child labor",
      "Chocolate",
      "Community",
      "Environment",
      "Farming",
      "Impact",
      "Livelihoods",
      "Verification",
      "Women's empowerment",
      "Youth",
    ],
    documentTypes: [
      "Action Plan",
      "Needs Assessment",
      "Progress Blog",
      "Press Release",
      "Report",
      "Newsroom Article",
    ],
  },
}

export const progressBlog = {
  heading: "THE COCOA LIFE LIBRARY",
  filters: {
    region: [
      "Brazil",
      "Cote d'Ivoire",
      "Dominican Republic",
      "Ghana",
      "India",
      "Indonesia",
    ],
    focusAreas: [
      "Child labor",
      "Chocolate",
      "Community",
      "Environment",
      "Farming",
      "Impact",
      "Livelihoods",
      "Verification",
      "Women's empowerment",
      "Youth",
    ],
    documentTypes: [
      "Action Plan",
      "Needs Assessment",
      "Progress Blog",
      "Press Release",
      "Report",
      "Newsroom Article",
    ],
  },
  pageSlug : "/progress/"
}

export const searchResult = {
  "hits": [
      {
          "slug": "/progress/critical-support-to-face-covid-19/",
          "seoData": {
              "title": "Providing Critical Support to Cocoa Farmers and Communities to Face COVID-19",
              "description": {
                  "description": "A closer look at how Cocoa Life is responding to the pandemic in  cocoa growing countries"
              },
              "keywords": {
                  "keywords": [
                      "Library Item",
                      " Progress Blog",
                      " Ghana",
                      " child labor",
                      " World Day Against Child Labor",
                      " CLMRS",
                      " West Africa"
                  ]
              },
              "articleType": "Progress Blog",
              "articleDate": "2023-12-21"
          },
          "objectID": "797cc705-3811-5e27-802b-f07012a7072f",
          "_highlightResult": {
              "seoData": {
                  "title": {
                      "value": "Providing Critical Support to Cocoa Farmers and Communities to Face COVID-19",
                      "matchLevel": "none",
                      "matchedWords": []
                  },
                  "description": {
                      "description": {
                          "value": "A closer look at how Cocoa Life is responding to the pandemic in  cocoa growing countries",
                          "matchLevel": "none",
                          "matchedWords": []
                      }
                  },
                  "keywords": {
                      "keywords": [
                          {
                              "value": "Library Item",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " Progress Blog",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " Ghana",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " child labor",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " World Day Against Child Labor",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " CLMRS",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " West Africa",
                              "matchLevel": "none",
                              "matchedWords": []
                          }
                      ]
                  }
              }
          }
      },
      {
          "slug": "/progress/strategy-to-help-protect-children/",
          "seoData": {
              "title": "How Cocoa Life continues to help combat child labor by enhancing child protection systems",
              "description": {
                  "description": "MDLZ releases new Cocoa Life strategy to help protect children and improve access to quality education "
              },
              "keywords": {
                  "keywords": [
                      "Progress Blog",
                      " Cocoa Life",
                      " impact",
                      " Child Labor",
                      " Child Protection"
                  ]
              },
              "articleType": "Progress Blog",
              "articleDate": "2022-12-22T00:00+05:30"
          },
          "objectID": "86f4255d-2b3f-5541-8626-cdd0dc870de2",
          "_highlightResult": {
              "seoData": {
                  "title": {
                      "value": "How Cocoa Life continues to help combat child labor by enhancing child protection systems",
                      "matchLevel": "none",
                      "matchedWords": []
                  },
                  "description": {
                      "description": {
                          "value": "MDLZ releases new Cocoa Life strategy to help protect children and improve access to quality education ",
                          "matchLevel": "none",
                          "matchedWords": []
                      }
                  },
                  "keywords": {
                      "keywords": [
                          {
                              "value": "Progress Blog",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " Cocoa Life",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " impact",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " Child Labor",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " Child Protection",
                              "matchLevel": "none",
                              "matchedWords": []
                          }
                      ]
                  }
              }
          }
      },
      {
          "slug": "/progress/next-phase-of-cocoa-life/",
          "seoData": {
              "title": "Mondelēz International announces the next phase of Cocoa Life",
              "description": {
                  "description": "Today, we announced the next phase of Cocoa Life backed by an additional $600M for a total $1B investment since the start of the program with the goal to increase our cocoa volume scale and work with ~300,000 cocoa farmers through our Cocoa Life program by 2030."
              },
              "keywords": {
                  "keywords": [
                      "Progress Blog",
                      " Cocoa Life",
                      " impact",
                      " Community",
                      " Farming"
                  ]
              },
              "articleType": "Progress Blog",
              "articleDate": "2022-10-25"
          },
          "objectID": "d8d14ac1-bfc4-53a9-8755-ac68544fd437",
          "_highlightResult": {
              "seoData": {
                  "title": {
                      "value": "Mondelēz International announces the next phase of Cocoa Life",
                      "matchLevel": "none",
                      "matchedWords": []
                  },
                  "description": {
                      "description": {
                          "value": "Today, we announced the next phase of Cocoa Life backed by an additional $600M for a total $1B investment since the start of the program with the goal to increase our cocoa volume scale and work with ~300,000 cocoa farmers through our Cocoa Life program by 2030.",
                          "matchLevel": "none",
                          "matchedWords": []
                      }
                  },
                  "keywords": {
                      "keywords": [
                          {
                              "value": "Progress Blog",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " Cocoa Life",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " impact",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " Community",
                              "matchLevel": "none",
                              "matchedWords": []
                          },
                          {
                              "value": " Farming",
                              "matchLevel": "none",
                              "matchedWords": []
                          }
                      ]
                  }
              }
          }
      }
  ],
  "nbHits": 66,
  "page": 0,
  "nbPages": 4,
  "hitsPerPage": 20
}

export const SearchElement={
      index: 0,
      slug: "/article-slug",
      seoData: {
        title: "title",
        articleType: "News",
        articleDate: "2023-04-10T00:00:00Z",
        description: {
          description: "Article Description",
        },
      },
    
}