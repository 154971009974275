export const input_type = {
  text: " form-control block w-full px-3 py-1.5 text-base font-normal   text-gray-700  bg-white bg-clip-padding  border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  number:
    "form-control block w-full  px-3  py-1.5  text-base  font-normal  text-gray-700  bg-white bg-clip-padding border border-solid border-gray-300  rounded-full  transition  ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  email:
    "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
}

export const formInputStyle = {
  inputContainer: "flex mb-5",
  labelStyle: "capitalize label w-[100px] inline-block text-16 italic leading-[22px] text-textBlack",
  asteriskStyle: "asterisk text-[2rem] inline-block p-[4px] leading-[22px] text-darkGrey align-middle",
  inputWrpr: "inputWrpr inline-block w-[420px]",
  text: "form-control block w-full p-13 text-base font-normal text-gray-700 border border-[#979797] border-1 m-0 focus:text-gray-700 focus:outline-none formInput h-30",
  select:
    "form-select bg-dropDownArrow bg-no-repeat bg-right block w-full p-14 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat  border border-solid  rounded-[25px] transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none bg-[94% center]  bg-[0% 96%]",
  chkWrpr: "my-20 relative flex items-start",
  asteriskCheckboxStyle: "asteriskCheckbox text-[2rem] inline-block p-[4px] leading-[22px] text-darkGrey align-middle",
  checkBox: "form-check-input custCheckBox mr-0 formInput mt-[5px]",
  checkBoxText: "inline-block text-16 leading-[22px] ml-16 text-textBlack w-full italic aggrementCheckbox",
  error: "border-1 border-red-600 error",
  errorLabel: "text-red error text-[2rem] inline-block py-[10px] px-[4px] leading-[22px] align-middle",
  emailErrorMsg: "emailErrorMsg text-red text-16",
  errorLabelCheckbox: "errorLabelCheckbox text-red error text-[2rem] inline-block py-[8px] px-[4px] leading-[22px] align-middle",
}

export const defaultStyle = {
  inputContainer: "lg:mb-3 mb-27 xl:w-96",
  labelStyle: "",
  text: "form-control block w-full px-3 py-1.5 text-base font-normal   text-gray-700  bg-white bg-clip-padding  border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  select: "",
  chkWrpr: "flex mb-20",
  checkBox: "",
  checkBoxText: "",
  error: "border-4 border-red-500",
}


