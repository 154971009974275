const query = (id, isPreview) => `
{
  articleVideo(id: "${id}", preview: ${isPreview}) {
    heading
    description
    video {
      title
      youtubeVideoId
      enableWatchLater
      posterImage {
        altText
        imageDesktop {
          url
          title
          description
          width
          height
        }
        imageMobile {
          url
          title
          description
          width
          height
        }
      }
      isModal
    }
    variant
    isContentReversed
     linkWrapper{
      variant
       linksCollection(limit: 20) {
        items {
          linkLabel
          textTheme
          variant
          link {
            url
            target
            gaEventClass
            gaEventLabel
            scrollToElementId
          }
        }
      }
    }
  }
}
`
module.exports = { query }
