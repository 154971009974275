import React from "react"
import PropTypes from "prop-types"
import { deslashify } from "../../../helpers/utils"
import Link from "../Link/Link"

const SocialShare = props => {
  const {
    linkType,
    pageSlug,
    pageHeading,
    hashTag,
    className = "",
    image,
  } = props
  const domainUrl = deslashify(process.env.GATSBY_URL)
  const isMobile =
    typeof window !== "undefined"
      ? window?.navigator?.userAgentData?.mobile
      : ""
  const whatsappUrl = isMobile ? "whatsapp://" : "https://web.whatsapp.com://"
  const url = `${domainUrl}${pageSlug}`
  let socialShareUrl = ""
  switch (linkType.toLowerCase()) {
    case "facebook":
      socialShareUrl = `https://facebook.com/sharer/sharer.php?u=${url}`
      break
    case "twitter":
      socialShareUrl = `https://twitter.com/intent/tweet?url=${url}&hashtags=${hashTag}`

      break
    case "share":
      socialShareUrl = `mailto:info@example.com?subject=Suggested%20article=${pageHeading}&body=${url}`

      break
    case "whatsapp":
      socialShareUrl = `${whatsappUrl}send?text=${url}`
      break
    case "linkedin":
      socialShareUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${url}&source=${domainUrl}`

      break
    case "pinterest":
      socialShareUrl = `http://pinterest.com/pin/create/bookmarklet/?url=${url}`

      break
    default:
      break
  }

  return <Link url={socialShareUrl} className={className} image={image} />
}

SocialShare.propTypes = {
  linkType: PropTypes.string,
  pageSlug: PropTypes.string,
  pageHeading: PropTypes.string,
  hashTag: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.object,
}

export default SocialShare
