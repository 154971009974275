export const wrapper = {
    containerWrapper:'container-wrapper'
}
export const style = {
    noResulstFound: 'noResulstFound p-12 md:pl-30 text-17 text-textBlack font-brand font-bold border-b-[3px] border-darkGrey',
    globalSearchLoading: 'globalSearchLoading pt-50 mx-auto md:w-[960px] max-w-full text-left mb-56 px-15 md:px-30 text-48 font-alternate text-textBlack',
    containerWrapper:'searchResultsWrapper',
    resulstFound: 'resulstFound p-12 md:pl-30 text-17 text-textBlack font-brand font-bold border-b-[3px] border-darkGrey',
    mainContainer :'globalSearchResults pt-50 mx-auto md:w-[960px] max-w-full text-left mb-56',
    headingWrpr: 'searchHeading px-15 md:px-30 text-48 mb-16 font-alternate text-textBlack leading-[0.9] pb-10',
    headingStyle: 'inline-block',
    searchTerm: 'uppercase',
    paginationBlock: 'paginationBlock globalSearch flex justify-end',
    paginationNumber:'paginationNumber italic mr-15 text-darkGrey text-14 font-medium py-8',
    paginationPrev:'paginationPrev bg-footerGrey text-darkGrey w-43 h-43 text-20 cursor-pointer',
    paginationNext:'paginationNext bg-footerGrey text-darkGrey w-43 h-43 text-20 cursor-pointer' 
}
export const containerStyle = {
    blogWrapper:'searchResult px-12 py-[25px] border-b border-darkGrey pointer hover:bg-[#e5e5e580] transition ease-in-out',
    linkContainer:'link-container'
}

export const contentStyle = {
    resultContent:'result-content',
    resultMetaData:'result-meta-data mb-[5px]',
    resultTypeText:'result-type-text font-bold text-textBlack',
    libraryBox:'library-box text-white uppercase text-xs py-[2px] px-[5px] mr-[5px]',
    resultDate:'result-date text-textBlack',
    textGreen:'text-lightGreen font-bold text-[19px] leading-6',
    resultDescription:'result-description text-textBlack'
}

export const noRecordStyle = {
    noRecordContainer: 'no-record-container'
}