import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import * as styles from "./TextContent-tw-styles"
import "../../styles/textContent.css"
import variants from "../../constants/variants.constants"
import Image from "../UI/Image/Image"
import useImage from "../../hooks/useImage"

const TextContent = props => {
  const {
    title,
    subTitle,
    markerTextCollection,
    shortDescription,
    shortDescriptionSecondary,
    variant,
    elementId,
  } = props

  const style = styles[variant] || styles["defaultStyle"]

  return (
    <div
      className={style?.textContainer}
      id={elementId ? elementId : style?.sectionID}
    >
      <div className={style?.textContainerBlock}>
        <div className={style?.textWrapper}>
          {title && (
            <div className={style?.title}>
              {/*{variant === variants?.TEXT_WITH_WHITE_BG_HEADING_WRAPPER ? */}
              <div className={style?.lineOnSides}>
                <span className={style?.titleWithLine}> {title}</span>
              </div>
              {/*:
                    <span>{title}</span> 
                    } */}
            </div>
          )}

          {shortDescription && (
            <Typography
              content={shortDescription}
              allow_br_tag={true}
              data-testid="typography"
              className={style?.paraContent}
              linkClass={style?.linkstyle}
            />
          )}

          {shortDescriptionSecondary && (
            <Typography
              content={shortDescriptionSecondary}
              allow_br_tag={true}
              data-testid="typography"
              className={style?.paraContent}
            />
          )}

          {(subTitle || markerTextCollection?.items?.length > 0) && (
            <div className={style.markerMainWrapper}>
              {subTitle && (
                <Typography content={subTitle} className={style.title} />
              )}
              {markerTextCollection?.items.length > 0 && (
                <ul className={style.listTypeNone}>
                  {markerTextCollection?.items?.map(item => {
                    const { image } = item
                    const { altText, foregroundImg } = useImage(image)
                    return (
                      <li key={item.imageText} className={style.listItem}>
                        {item?.imageText && (
                          <Typography
                            content={item?.imageText}
                            className={style.imageText}
                          />
                        )}
                        {foregroundImg && (
                          <Image
                            alt={altText}
                            contentfulImage={foregroundImg}
                            className={style.image}
                          />
                        )}
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

TextContent.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  markerTextCollection: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  shortDescription: PropTypes.string,
  shortDescriptionSecondary: PropTypes.string,
  variant: PropTypes.string,
  elementId: PropTypes.string,
}

export default TextContent
