const query = (id, isPreview) => `{
  multiColumnsGrid(id: "${id}", preview: ${isPreview}) {
    title
    descriptionBefore
    subTitle
    contentColumnsCollection(limit: 10) {
      items {
        title
        textBefore
        image {
         altText
          imageDesktop {
            url
            title
            description
            width
            height
          }
          imageMobile {
            url
            title
            description
            width
            height
          }
        }
        textAfter
        link {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
          }
        }
        variant
        imageCardsCollection(limit: 10) {
          items {
            variant
            title
            text
            images {
              altText
              imageMobile {
                url
                title
                description
                width
                height
              }
              imageDesktop {
                url
                title
                description
                width
                height
              }
            }
          }
        }
      }
    }
    descriptionAfter
    variant
    elementId
  }
}
`

module.exports = { query }
