import React from "react"
import { screen, render } from "@testing-library/react"
import MultiColumnsGrid from "./MultiColumnsGrid"
import { multiColumnsGrid } from "./MultiColumnsGrid.mock"

describe("MultiColumnsGrid", () => {
  render(<MultiColumnsGrid {...multiColumnsGrid} />)
  test("testing  title", () => {
    expect(screen.getByText("OUR PROGRESS"))
  })
  test("testing  descriptionBefore", () => {
    render(<MultiColumnsGrid {...multiColumnsGrid} />)
    expect(screen.getByText("descriptionBefore"))
  })
  test("testing  subTitle", () => {
    render(<MultiColumnsGrid {...multiColumnsGrid} />)
    expect(screen.getByText("BY THE END OF 2021:"))
  })
  test("testing  descriptionAfter", () => {
    render(<MultiColumnsGrid {...multiColumnsGrid} />)
    expect(screen.getByText("descriptionAfter"))
  })
  test("testing  ImgeTextGrid testBefore textAfter", () => {
    render(<MultiColumnsGrid {...multiColumnsGrid} />)
    expect(screen.getByText("testBefore"))
    expect(screen.getByText("textAfter"))
  })
  xtest("testing  ImgeTextGrid Image", () => {
    render(<MultiColumnsGrid {...multiColumnsGrid} />)
    const image = screen.getByAltText('title');
    expect(image.src).toContain('https://images.ctfassets.net/qggsjlmpzfmx/4VUf8AFXUm7Myh0qdNU1Pf/480f7da1033b42cc9fac409cbb9151db/farmers.svg')
  })

})
