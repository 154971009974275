const query = (id, isPreview) => `{
    linkWrappersLayout(id: "${id}", preview: ${isPreview}) {
      variant
      linksCollection(limit: 20) {
        items {
          linkLabel
          textTheme
          variant
          link {
            url
            target
            gaEventClass
            gaEventLabel
            scrollToElementId
          }
        }
      }
    }
  }`

module.exports = { query }
