const query = (id, isPreview) => `
{
  twoSectionLayout(id: "${id}", preview: ${isPreview}) {
    sectionTitle
    middleContent
    imageLeft {
      altText
      imageDesktop {
        url
        title
        description
        width
        height
      }
      imageMobile {
        url
        title
        description
        width
        height
      }
    }
    imageRight {
      altText
      imageDesktop {
        url
        title
        description
        width
        height
      }
      imageMobile {
        url
        title
        description
        width
        height
      }
    }
    link {
      label
      title
      url
      target
      scrollToElementId
      gaEventClass
      gaEventLabel
      image {
        altText
        imageDesktop {
          url
          title
          description
          width
          height
        }
        imageMobile {
          url
          title
          description
          width
          height
        }
      }
    }
    description
    variant
    id
  }
}`

module.exports = { query }
