export const defaultStyle = {
    interventionImage: "h-[100px] mx-auto relative mb-[26px] w-auto",
    interventionImageCard: "image-card flex-1 p-16 min-[567px]:py-0",
    tagWrapper: "text",
  }
  
  export const GRID_WITH_DARK_YELLOW_BG = {
    interventionImage: "w-[170px] md:w-[125px] mx-auto mt-15",
    interventionImageCard: "image-card md:w-[20%] lg:w-[18%] mx-auto md:inline-grid md:px-7",
    measureImageCardWrapper: "measure-image-wrapper my-15",
    measureImageCard: "measure-image-card md:pr-10 md:w-full mx-auto bg-lightOrange first:rounded-t last:rounded-b first:border-b-[3px] border-lightOrange",
    tagWrapper: "tag-wrapper font-brand font-black text-orange text-20",
    cardNum: "card-1",
  }

  export const GRID_WITH_DARK_RED_BG = {
    interventionImage: "w-[170px] md:w-[125px] mx-auto mt-15",
    interventionImageCard: "image-card md:w-[20%] lg:w-[18%] mx-auto md:inline-grid md:px-7",
    measureImageCardWrapper: "measure-image-wrapper my-15",
    measureImageCard: "measure-image-card md:pr-10 md:w-full mx-auto bg-lightPink first:rounded-t last:rounded-b first:border-b-[3px] border-lightPink",
    tagWrapper: "tag-wrapper font-brand font-black text-darkPink text-20",
    cardNum: "card-2",
  }

  export const GRID_WITH_LIGHT_YELLOW_BG = {
    interventionImage: "w-[170px] md:w-[125px] mx-auto mt-15",
    interventionImageCard: "image-card md:w-[20%] lg:w-[18%] mx-auto md:inline-grid md:px-7",
    measureImageCardWrapper: "measure-image-wrapper my-15",
    measureImageCard: "measure-image-card md:pr-10 md:w-full mx-auto bg-lightYellow first:rounded-t last:rounded-b first:border-b-[3px] border-lightYellow",
    tagWrapper: "tag-wrapper font-brand font-black text-yellow text-20",
    cardNum: "card-3",
  }

  export const GRID_WITH_DARK_BROWN_BG = {
    interventionImage: "w-[170px] md:w-[125px] mx-auto mt-15",
    interventionImageCard: "image-card md:w-[20%] lg:w-[18%] mx-auto md:inline-grid md:px-7",
    measureImageCardWrapper: "measure-image-wrapper my-15",
    measureImageCard: "measure-image-card md:pr-10 md:w-full mx-auto bg-lightRedVelvet first:rounded-t last:rounded-b first:border-b-[3px] border-lightRedVelvet",
    tagWrapper: "tag-wrapper font-brand font-black text-redVelvet text-20",
    cardNum: "card-4",
  }

  export const GRID_WITH_DARK_GREEN_BG = {
    interventionImage: "w-[170px] md:w-[125px] mx-auto mt-15",
    interventionImageCard: "image-card md:w-[20%] lg:w-[18%] mx-auto md:inline-grid md:px-7",
    measureImageCardWrapper: "measure-image-wrapper my-15",
    measureImageCard: "measure-image-card md:pr-10 md:w-full mx-auto bg-lighterGreen first:rounded-t last:rounded-b first:border-b-[3px] border-lighterGreen",
    tagWrapper: "tag-wrapper font-brand font-black text-lightGreen text-20",
    cardNum: "card-5",
  }

  export const GRID_WITH_TOP_LINK = {
    ...defaultStyle,
    interventionImageCard: "grid_with_top_img image-card flex-1 flex flex-col-reverse first-of-type:pb-40 last-of-type:pt-20 md:first-of-type:pr-30 md:last-of-type:pl-30 [&>div:first-of-type]:max-w-[590px] [&>div:first-of-type]:mx-auto md:!py-0 justify-end",
  }