import React, { useState, useEffect } from "react"
import Button from "../UI/Button/Button"
import PropTypes, { func } from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"
import {
  validate,
  orderList
} from "../../helpers/FormValidations"
import * as styles from "./FormContainer-tw-styles"
import Typography from "../UI/Typography/Typography"
import RenderField from "../../helpers/RenderField"

import { MANDATORY, API_ERROR_MSG } from "../../constants/label.constants"
import { gaEventClickButton,formSubmitObj } from "../../constants/gtm.constants"
import LoaderIcon from "../../assests/icons/LoaderIcon"
import { constantReqBody } from "../../constants/form.constants"
import Select from "../UI/Input/Select"
import { buildDobObject, getAge, sendData } from "../../helpers/form"

let orderedFieldList = {
  firstName: "",
  lastName: "",
  email: "",
}

let orderedcheckboxList = {
  aggrementCheckbox: "",
}

const Form = props => {
  const { heading, formFields, description } = props
  const { fieldList, checkbox, submit, reCaptchaError, ageError, variant, submitSuccessMsg = '' } =
    formFields
  orderList(orderedFieldList, fieldList)
  orderList(orderedcheckboxList, checkbox)

  const [form, setForm] = useState({firstName:orderedFieldList?.firstName,lastName:orderedFieldList?.lastName})
  const [emailField, setEmailField] = useState({email:orderedFieldList?.email})
  const [isAPISucess, setIsAPISucess] = useState(false)
  const [aPIFailureMsg, setAPIFailureMsg] = useState('')
  const [checkBox, setCheckBox] = useState(orderedcheckboxList)
  const [isLoading, setIsLoading] = useState(false)
  const [recaptchaValue, setRecaptchaValue] = useState(null)
  const [age, setAge] = useState(null)
  const [showError, setShowError] = useState(false)
  const [dayMonthYearData, setDayMonthYearData] = useState({})
  const [selectedDateOfBirth, setselectedDateOfBirth] = useState({
    year: 1930,
    month: 1,
    day: 20,
  })

  useEffect(() => {
    const dobObject = buildDobObject(selectedDateOfBirth.year, selectedDateOfBirth.month)
    setDayMonthYearData(dobObject)
  }, [selectedDateOfBirth])

  const style = styles?.formStyles
  const { formTitle, formDesc, formTitleWrap, loaderStyle = {} } = style

  function ValidateForm(orgObj, tempObj, isEmail) {
    if(isEmail){
      return Object.entries(orgObj).map(([key, value]) => {
        tempObj[key] = validate(value)
        setEmailField(field => ({
          ...field,
          [key]: {
            ...field[key],
            isValid: tempObj[key].isValid,
            isRequired: tempObj[key].isValid ? false : true
          },
        }))
        return tempObj[key].isValid
      })
    }else{
      return Object.entries(orgObj).map(([key, value]) => {
        tempObj[key] = validate(value)
        setForm(field => ({
          ...field,
          [key]: {
            ...field[key],
            isValid: tempObj[key].isValid,
            isRequired: tempObj[key].isValid ? false : true
          },
        }))
        return tempObj[key].isValid
      })
    }
  }

  function ValidateCheckBox(orgObj, tempObj) {
    return Object.entries(orgObj).map(([key, value]) => {
      tempObj[key] = validate(value)
      setCheckBox(field => ({
        ...field,
        [key]: {
          ...field[key],
          isValid: tempObj[key].isValid,
          isRequired: tempObj[key].isValid ? false : true
        },
      }))
      return tempObj[key].isValid
    })
  }

  function validateAge() {
    const { day = '', month = '', year = '' } = selectedDateOfBirth;
    var age = getAge(year + '-' + month + '-' + day)
    setAge(age);
    return age >= 16 ? true : false
  }

  function handleCheckboxList(event) {
    return Object.entries(checkBox).map(([key, value]) => {
      setCheckBox(field => ({
        ...field,
        [event.target.name]: {
          ...field[event.target.name],
          value: event.target.checked,
          isValid: !event.target.checked ? true : value.isValid,
        },
      }))
    })
  }

  function handleEmailChange(event) {
    return Object.entries(emailField).map(([key, value]) => {
      setEmailField(field => ({
        ...field,
        [event.target.name]: {
          ...field[event.target.name],
          value: event.target.value,
        },
      }))
    })
  }

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      handleCheckboxList(event)
    }else if(event.target.name === 'email'){
      handleEmailChange(event)
    }
    return Object.entries(form).map(([key, value]) => {
      setForm(field => ({
        ...field,
        [event.target.name]: {
          ...field[event.target.name],
          value: event.target.value,
        },
      }))
    })
  }

  const handleOnBlur = (event) => {
    if (event.target.name === "email") {
      const { email } = emailField;
      const validateEmail = validate(email)
      return validateEmail
    }
  }

  function onChange(e) {
    if (e.target.name === 'day' || e.target.name === 'month' || e.target.name === 'year') {
      setselectedDateOfBirth({
        ...selectedDateOfBirth,
        [e.target.name]: parseInt(e.target.value)
      })
    }
  }

  function renderFields(list) {
    return Object.entries(list).map(([key, value]) => (
      <RenderField
        key={key}
        id={key}
        field={value}
        onBlur={handleOnBlur}
        handleChange={handleChange}
        variant={variant}
        error=""
      />
    ))
  }

  const renderDay = () => {
    const { days } = dayMonthYearData
    return (
      <Select key={'days'} {...days} style={style?.selectStyle} onChange={onChange} selectedValue={selectedDateOfBirth?.day} />
    )
  }
  const renderMonth = () => {
    const { months } = dayMonthYearData
    return (
      <Select key={'month'} {...months} style={style?.selectStyle} onChange={onChange} selectedValue={selectedDateOfBirth?.month} />
    )
  }
  const renderYear = () => {
    const { years } = dayMonthYearData
    return (
      <Select key={'year'} {...years} style={style?.selectStyle} onChange={onChange} selectedValue={selectedDateOfBirth?.year} />
    )
  }
  const renderFieldListErrors = (form) => {
    return (
      <ul className="errorMsg text-16 px-15 md:px-45">
        {Object.entries(form).map(([key, value]) => {
          return (
            form[key]?.error &&
            <li className={style?.error}>
              {form[key]?.error}
            </li>
          )
        })}
      </ul>)
  }
  const renderCheckBoxListErrors = (checkBox) => {
    return (
      <ul className="errorMsg text-16 px-15 md:px-45">
        {Object.entries(checkBox).map(([key, value]) => {
          return (
            checkBox[key]?.error && <li className={style?.error}>
              <Typography content={checkBox[key]?.error} />
            </li>)
        })}
      </ul>)
  }
  const renderRecaptchaErr = () => {
    if (recaptchaValue === null) {
      return (
        <ul className="errorMsg text-16 px-15 md:px-45">
          <li className={style?.error}>
            <Typography content={reCaptchaError} />
          </li>
        </ul>
      )
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const validateField = form
    const validateEmailField = emailField
    const validateCheckbox = checkBox
    const isfieldsValid = ValidateForm(form, validateField, false)
    const isEmailValid = ValidateForm(emailField, validateEmailField, true)
    const isCheckBoxValid = ValidateCheckBox(checkBox, validateCheckbox)
    const isAgeValidated = validateAge();
    if (
      !isfieldsValid.includes(false) &&
      !isEmailValid.includes(false) &&
      !isCheckBoxValid.includes(false) &&
      !(recaptchaValue === null) &&
      isAgeValidated
    ) {
      setIsLoading(true)
      const requestBodyObj = {
        requestBody: {
          'firstName': form?.firstName?.value,
          'lastName': form?.lastName?.value,
          'dob': selectedDateOfBirth?.year + '-' + selectedDateOfBirth?.month + '-' + selectedDateOfBirth?.day,
          'email': form?.email?.value,
          'confirmNewsletter': checkBox?.aggrementCheckbox?.value ? '1' : '0',
        },
        'g-recaptcha-response': recaptchaValue,
        ...constantReqBody
      }
      // axios API call here to submit the form
      const res = await sendData(process.env.GATSBY_FORM_API, requestBodyObj)
      try {
      if (res === 'Success') {
        setIsLoading(false)
        setIsAPISucess(true)
        formSubmitObj.GAeventAction = { ...requestBodyObj, submit: "success" }
          if (location && location.href) {
            formSubmitObj.GAeventLabel = location.href
          }
          if (window !== "undefined" && window.dataLayer) {
            return window.dataLayer.push({ ...formSubmitObj })
          }
      } else {
        setIsLoading(false)
        setIsAPISucess(false)
        setAPIFailureMsg(API_ERROR_MSG)
        formSubmitObj.GAeventAction = { ...requestBodyObj, submit: "failure" }
      if (location && location.href) {
        formSubmitObj.GAeventLabel = location.href
      }
      if (window !== "undefined" && window.dataLayer) {
        return window.dataLayer.push({ ...formSubmitObj })
      }
      throw new Error(error)
      }
    } catch (error) {
      formSubmitObj.GAeventAction = { ...requestBodyObj, submit: "failure" }
      if (location && location.href) {
        formSubmitObj.GAeventLabel = location.href
      }
      if (window !== "undefined" && window.dataLayer) {
        return window.dataLayer.push({ ...formSubmitObj })
      }
      throw new Error(error)
    }
    } else {
      setIsLoading(false)
      setShowError(true)
    }

  }

  function renderSubscribMsg() {
    return (
      <>
      <div className='mx-auto px-15 md:px-30 pt-50 pb-40 md:w-[960px] max-w-full text-left mb-16'>
        <h3 className={formTitle}>
          {<Typography content={heading}></Typography>}
        </h3>
      </div>
      <div className="pb-40 mx-auto px-15 md:px-30 text-textBlack md:w-[960px] max-w-full text-left">
        <div className="max-w-[360px]">
          {submitSuccessMsg}
        </div>
      </div>
      </>
    )
  }

  return (
    <>
      {isAPISucess ? (
        renderSubscribMsg()
      ) : (
        <div id="form" className="bg-white pt-50 px-15 text-textBlack">
          <div className={formTitleWrap}>
            <h3 className={formTitle}>
              {<Typography content={heading}></Typography>}
            </h3>
            <div className={formDesc}>
              {<Typography content={description}></Typography>}
            </div>
          </div>
          {aPIFailureMsg && <Typography className="mx-auto md:px-30 md:w-[960px] max-w-full text-left mb-16 text-left text-red" content={aPIFailureMsg}></Typography>}
          {isLoading && <LoaderIcon {...loaderStyle} />}
          {showError && renderFieldListErrors(form)}
          {showError && renderFieldListErrors(emailField)}
          {showError && renderCheckBoxListErrors(checkBox)}
          {showError && renderRecaptchaErr()}
          <div className="md:px-30 mt-[22px] mb-0 m-auto w-full md:max-w-[960px] phillyFiveForm">
            {renderFields(form)}
          </div>
          <div className="md:px-30 mt-[22px] mb-0 m-auto w-full md:max-w-[960px] phillyFiveForm">
            {age != null && age < 16 && (
              <span className={style?.error}>{ageError}</span>
            )}
            <div className={style?.selectWrapper}>
              {dayMonthYearData?.label && (
                <Typography
                  content={dayMonthYearData?.label}
                  className={style?.label}
                />
              )}
              <div className="birthdayWrapper w-full flex md:w-[420px]">
                <div className="date mr-16 md:w-[130px]">{renderDay()}</div>
                <div className="month mr-16 md:w-[130px]">{renderMonth()}</div>
                <div className="year md:w-[130px]">{renderYear()}</div>
              </div>
              <span
                className={
                  age != null && age < 16
                    ? style?.asteriskBirthdayError
                    : style?.asteriskBirthday
                }
              >
                *
              </span>
            </div>
          </div>
          <div className="md:px-30 mt-[22px] mb-0 m-auto w-full md:max-w-[960px] phillyFiveForm">
            {renderFields(emailField)}
          </div>
          <div className="phillySubmit md:px-30 mx-auto my-20 w-full md:max-w-[960px]">
            <div className="md:w-[560px]">{renderFields(checkBox)}</div>
            <div className="reCaptcha mt-16">
              <ReCAPTCHA
                sitekey={process.env.GATSBY_APP_SITE_KEY}
                onChange={value => setRecaptchaValue(value)}
              />
              {showError && recaptchaValue === null && (
                <Typography content={reCaptchaError} className={style?.error} />
              )}
            </div>
          </div>
          <div className="md:px-30 mx-auto md:max-w-[960px]">
            <div className={style?.formDesc}>
              {<Typography content={formFields?.description}></Typography>}
            </div>
          </div>
          <div className={style?.btnStyleWrapper}>
            <Button
              text={submit.label}
              onClick={handleSubmit}
              className={style?.btnStyle}
              gaEventClass={gaEventClickButton}
              dataActionDetail={"submit"}
              disabled={isLoading}
            />
            <p className="text-textBlack text-left my-50">{MANDATORY}</p>
          </div>
        </div>
      )}
    </>
  )
}

Form.propTypes = {
  description: PropTypes.string,
  heading: PropTypes.string,
  formFields: PropTypes.any,
}

export default Form
