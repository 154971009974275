import React, { useState } from "react"
import { screen, render, fireEvent, cleanup } from "@testing-library/react"
import VideosWrapper from "./VideosWrapper"
import { videosWrapper, videosWrapperModelView} from "./VideosWrapper.mock"
import "@testing-library/jest-dom"
describe("render VideosWrapper component", () => {
  beforeEach(() => {
    render(<VideosWrapper {...videosWrapper} />)
  })
  afterEach(() => {
    cleanup()
  })
  test("render section Poster Image", () => {
   const button = screen.getByRole('button')
   fireEvent.click(button)
  })
})

describe("render video With Modal component", () => {
  beforeEach(() => {
    render(<VideosWrapper {...videosWrapperModelView} />)
  })
  afterEach(() => {
    cleanup()
  })
  test("render video", () => {
   const button = screen.getByRole('button')
   fireEvent.click(button)
  })
})


