import React from "react"
import { screen, render } from "@testing-library/react"
import FixedProductBrandImage from "./FixedProductBrandImage"
import { fixedProductBrandImage } from "./FixedProductBrandImg.mock"
import "@testing-library/jest-dom"

describe("FixedProductBrandImage", () => {
  render(<FixedProductBrandImage {...fixedProductBrandImage} />)
  test("testing imagesCollection[0] Image and description", () => {
    expect(screen.getByText("Matthias Lange,")).toBeInTheDocument()
  })
  test("testing imagesCollection[1]  and description", () => {
    render(<FixedProductBrandImage {...fixedProductBrandImage} />)
    expect(screen.getByText("Irina Hotz,")).toBeInTheDocument()
  })
  test("testing imagesCollection[2]  and description", () => {
    render(<FixedProductBrandImage {...fixedProductBrandImage} />)
    expect(screen.getByText("Michael Komlorm,")).toBeInTheDocument()
  })

})
