import React from "react"
import { screen, render, cleanup } from "@testing-library/react"
import RichTextModel from "./RichTextModel"
import { RichTextModelProps } from "./RichTextModel.mock"
import "@testing-library/jest-dom"

describe("render RichTextModel component", () => {
    beforeEach(() => {
        render(<RichTextModel {...RichTextModelProps} />)
    });
    afterEach(() => {
        cleanup();
    });
    test("render section title", () => {
        const title = screen.getByText("Our Mission and Vision")
        expect(title).toBeInTheDocument()
    })
    test("render richText content", () => {
        expect(screen.getByText("Independent chef insight conducted by Good Sense Research, March 2020.")).toBeInTheDocument()
        expect(screen.getByText("86% of chefs said Philadelphia Original's liquid release")).toBeInTheDocument()
    })
    test("render link", () => {
        const link = screen.getByRole('link', {name:/Learn more/i})
        expect(link).toBeInTheDocument()
    })


})