export const defaultStyle = {
  linkWrapper:
    "linkWrapper w-full bg-lightGreen hidden min-[901px]:block text-white text-17 font-brand font-bold leading-[1.375] border-white border-b-2",
  linkClass:
    "highlightLink flex p-2 py-[6px] items-center w-full text-left rotate-180 leading-8 relative",
  linkWrapperSub:
    "linkWrapperSub w-full max-w-[960px] mx-auto h-auto leading-[50px] text-center",
  linkWrapperText: "linkWrapperText max-w-[590px]",
  linkText: "linkText w-full",
  icon: "",
}

export const TEXT_CENTER = {
  ...defaultStyle,
  linkWrapperText: "linkWrapperText",
}