export const map = {
    "title": "CÔTE D'IVOIRE",
    "backgroundImage": {
      "title": "cl-origins-cotedivoire",
      "description": null,
      "url": "https://images.ctfassets.net/qggsjlmpzfmx/6sL3eA7uwMfkoKahDjO9hZ/873cfdc2476f32acd23dd8824b69efb2/cl-origins-cotedivoire.png",
      "height": 638,
      "width": 770
    },
    "factsCollection": {
      "items": [
        {
          "description": "Farmers currently participating in Cocoa Life",
          "variant": "TEXT_WITH_ORANGE_BG"
        },
        {
          "description": "<p>Cocoa Life active in country since</p>\n<div class=\"text-big\">2013</div>",
          "variant": "TEXT_WITH_BURGUNDY_BG"
        }
      ]
    }
  }