const query = (id, isPreview) => `
{
  accordian(id: "${id}", preview: ${isPreview}) {
    title
    description
    contentRowsCollection(limit: 20) {
      items {
        title
        image {
          altText
          imageDesktop {
            url
            title
            description
            width
            height
          }
          imageMobile {
            url
            title
            description
            width
            height
          }
        }
        link {
          label
          url
          image {
            altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
          }
        }
        contentCollection(limit: 15) {
          items {
            description
            linkWrapperCollection(limit: 5) {
              items {
                linkLabel
                variant
                link {
                  label
                  title
                  url
                  target
                  scrollToElementId
                  gaEventClass
                  gaEventLabel
                  image {
                    altText
                    imageDesktop {
                      url
                      title
                      description
                      width
                      height
                    }
                    imageMobile {
                      url
                      title
                      description
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        bottomText
        variant
        reverseContent
      }
    }
    quickLinksCollection(limit: 15) {
      items {
        label
        title
        url
        target
        scrollToElementId
        gaEventClass
        gaEventLabel
      }
    }
    variant
    elementId
  }
}
`
module.exports = { query }
