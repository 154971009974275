export const imageCardsLayout = {
    "title": "2017 YEAR IN REVIEW",
    "text": "MONTHLY ACTIVITES",
    "variant": "MONTHLY_ACTIVITIES_CARDS_LAYOUT",
    "isSlider": true,
    "sliderSettings": null,
    "link": null,
    "cardsCollection": {
      "items": [
        {
          "title": "Q1",
          "text": "IPSOS PROVIDES THIRD-PARTY VERIFICATION",
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_TWO_COLUMN",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/20t1DovxdsiZaDCXU49QbD/e4a2d458e142930c867d47ec9e4e59a6/january.png",
            "width": 380,
            "height": 380,
            "title": "January activity Image",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_ARROW_IMAGE",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/44YAF3InVr1vKF3f4RrvL9/168d65e25354960ed562a99e876cba2e/dottedarrowright1x.png",
            "width": 1236,
            "height": 106,
            "title": "Arrow icon image ",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": "<p class=\"activityHeading\">FEBRUARY 14, 2017 – JOY AMBASSADORS TRAVEL TO GHANA</p>\n<p class=\"activityDescription\">A Mondelēz International Joy Ambassador shares what he learned from his trip to Ghana, including challenges farmers face and how Cocoa Life is making a difference in improving farming practices and so much more.</p>\n<a href=\"/progress/cocoa-life-progress-report-2017/\">Discover More</a>",
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_TWO_COLUMN",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/46JyGkagy0hH3etVbrahsC/b9f09b5d6850dcd8c0ca8f7bfc4287cb/february.png",
            "width": 380,
            "height": 380,
            "title": "February Activity Image",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_ARROW_IMAGE",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/44YAF3InVr1vKF3f4RrvL9/168d65e25354960ed562a99e876cba2e/dottedarrowright1x.png",
            "width": 1236,
            "height": 106,
            "title": "Arrow icon image ",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": "<p class=\"activityHeading\">MARCH 17, 2017 – PROTECTING FORESTS IN WEST AFRICA</p>\n<p class=\"activityDescription\">Mondelēz International signs an agreement with the World Cocoa Foundation, The Prince of Wales’ International Sustainability Unit and other cocoa companies to develop a plan to address deforestation and forest degradation.</p>\n<a href=\"/progress/cocoa-life-progress-report-2017/\">Discover More</a>\n",
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_TWO_COLUMN",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/mUvijGoTXKACEL6G6pkBy/6e3fa85e9ae36f51d6b5c78bd6302f56/march.png",
            "width": 380,
            "height": 380,
            "title": "March Card Image",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_ARROW_IMAGE",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/44YAF3InVr1vKF3f4RrvL9/168d65e25354960ed562a99e876cba2e/dottedarrowright1x.png",
            "width": 1236,
            "height": 106,
            "title": "Arrow icon image ",
            "description": ""
          },
          "link": null
        },
        {
          "title": "Q2",
          "text": "APRIL 19, 2017 – BUSINESS SKILLS GAINED IN INDONESIA",
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_TWO_COLUMN",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3KbGL0DQE5MzkOvrWoeags/e6377a9a0a58e6eaca500ca17f29eb8f/april.png",
            "width": 380,
            "height": 380,
            "title": "April Card Image",
            "description": ""
          },
          "link": null
        }
      ]
    }
  }
  export const FIVE_COLUMN_CARDS_LAYOUT = {
    "title": "2017 YEAR IN REVIEW",
    "text": "MONTHLY ACTIVITES",
    "variant": "FIVE_COLUMN_CARDS_LAYOUT",
    "isSlider": false,
    "sliderSettings": null,
    "link": null,
    "cardsCollection": {
      "items": [
        {
          "hasCardAnimation":false,
          "title": "Q1",
          "text": "IPSOS PROVIDES THIRD-PARTY VERIFICATION",
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_TWO_COLUMN",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/20t1DovxdsiZaDCXU49QbD/e4a2d458e142930c867d47ec9e4e59a6/january.png",
            "width": 380,
            "height": 380,
            "title": "January activity Image",
            "description": ""
          },
          "link": null
        },
        {
          "hasCardAnimation":true,
          "title": "Q5",
          "text": null,
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_ARROW_IMAGE",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/44YAF3InVr1vKF3f4RrvL9/168d65e25354960ed562a99e876cba2e/dottedarrowright1x.png",
            "width": 1236,
            "height": 106,
            "title": "Arrow icon image ",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": "<p class=\"activityHeading\">FEBRUARY 14, 2017 – JOY AMBASSADORS TRAVEL TO GHANA</p>\n<p class=\"activityDescription\">A Mondelēz International Joy Ambassador shares what he learned from his trip to Ghana, including challenges farmers face and how Cocoa Life is making a difference in improving farming practices and so much more.</p>\n<a href=\"/progress/cocoa-life-progress-report-2017/\">Discover More</a>",
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_TWO_COLUMN",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/46JyGkagy0hH3etVbrahsC/b9f09b5d6850dcd8c0ca8f7bfc4287cb/february.png",
            "width": 380,
            "height": 380,
            "title": "February Activity Image",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_ARROW_IMAGE",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/44YAF3InVr1vKF3f4RrvL9/168d65e25354960ed562a99e876cba2e/dottedarrowright1x.png",
            "width": 1236,
            "height": 106,
            "title": "Arrow icon image ",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": "<p class=\"activityHeading\">MARCH 17, 2017 – PROTECTING FORESTS IN WEST AFRICA</p>\n<p class=\"activityDescription\">Mondelēz International signs an agreement with the World Cocoa Foundation, The Prince of Wales’ International Sustainability Unit and other cocoa companies to develop a plan to address deforestation and forest degradation.</p>\n<a href=\"/progress/cocoa-life-progress-report-2017/\">Discover More</a>\n",
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_TWO_COLUMN",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/mUvijGoTXKACEL6G6pkBy/6e3fa85e9ae36f51d6b5c78bd6302f56/march.png",
            "width": 380,
            "height": 380,
            "title": "March Card Image",
            "description": ""
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_ARROW_IMAGE",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/44YAF3InVr1vKF3f4RrvL9/168d65e25354960ed562a99e876cba2e/dottedarrowright1x.png",
            "width": 1236,
            "height": 106,
            "title": "Arrow icon image ",
            "description": ""
          },
          "link": null
        },
        {
          "title": "Q2",
          "text": "APRIL 19, 2017 – BUSINESS SKILLS GAINED IN INDONESIA",
          "elementId": null,
          "variant": "MONTHLY_ACTIVITIES_TWO_COLUMN",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qggsjlmpzfmx/3KbGL0DQE5MzkOvrWoeags/e6377a9a0a58e6eaca500ca17f29eb8f/april.png",
            "width": 380,
            "height": 380,
            "title": "April Card Image",
            "description": ""
          },
          "link": null
        }
      ]
    }
  }