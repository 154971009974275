import React from "react"
import { screen, render } from "@testing-library/react"
import Footer from "./Footer"
import { footerCollection } from "./Footer.mock"
import "@testing-library/jest-dom"

describe("render Footer component", () => {
  render(<Footer {...footerCollection} />)
  test("mainLinksCollection render contact-us anchor tag", () => {
    render(<Footer {...footerCollection} />)
    const linkEl = screen.getAllByRole('link', { name: 'Contact Us' });
    expect(linkEl[0]).toHaveAttribute('href', '/contact-us/')
  })
  test("mainLinksCollection render Privacy-Notice anchor tag", () => {
    render(<Footer {...footerCollection} />)
    const linkEl = screen.getAllByRole('link', { name: 'Privacy Notice' });
    expect(linkEl[0]).toHaveAttribute('href', '/privacy-notice/')
  })
  test("mainLinksCollection render Terms of Service anchor tag", () => {
    render(<Footer {...footerCollection} />)
    const linkEl = screen.getAllByRole('link', { name: 'Terms of Service' });
    expect(linkEl[0]).toHaveAttribute('href', '/terms-of-service/')
  })
  test("mainLinksCollection render Sitemap anchor tag", () => {
    render(<Footer {...footerCollection} />)
    const linkEl = screen.getAllByRole('link', { name: 'Sitemap' });
    expect(linkEl[0]).toHaveAttribute('href', '/sitemap/')
  })
  test("mainLinksCollection render Sitemap anchor tag", () => {
    render(<Footer {...footerCollection} />)
    const linkEl = screen.getAllByRole('link', { name: 'Cookie Policy' });
    expect(linkEl[0]).toHaveAttribute('href', '/cookie-policy/')
  })
  xtest("brandLinksCollection render logo in footer", () => {
    render(<Footer {...footerCollection} />)
    const image = screen.getByRole(/presentation/i);
    expect(image.src).toContain('cocoa_mondelez_logo')
  })


})
