import React from "react"
import { screen, render, cleanup } from "@testing-library/react"
import ImageCardsSlider from "./ImageCardsSlider"
import { imageCardsSlider } from "./ImageCardsSlider.mock"
import { get } from "../../helpers/utils"
import "@testing-library/jest-dom"

describe("render ImageCardsSlider component", () => {
    beforeEach(() => {
        render(<ImageCardsSlider {...imageCardsSlider} />)
    });
    afterEach(() => {
        cleanup();
    });
    xtest("render Slider previous Button", () => {
        const btn = screen.getByRole('button', { name: /Previous/i })
        expect(btn).toBeInTheDocument()
    })
    xtest("render Slider next Button", () => {
        const btn = screen.getByRole('button', { name: /Next/i })
        expect(btn).toBeInTheDocument()
    })
    test("render Slider a link", () => {
        const links = screen.getByRole('link', { name: /Read more/i })
        expect(links).toBeInTheDocument()
    })
    test("render Slider sliding dots", () => {
        const dot1 = screen.getByRole('button', { name: /1/i })
        expect(dot1).toBeInTheDocument()
        const dot2 = screen.getByRole('button', { name: /2/i })
        expect(dot2).toBeInTheDocument()
    })
})