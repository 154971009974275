export const defaultStyle = {
  accordianComponent: "accordionContainer w-full pt-16 pb-40 px-0",
  accordionItem: " relative border-b-2 border-blue ",
  accordianBtnActive: "active",
  accordianBtn:
    "accordianBtn text-[19.2px] min-[567px]:text-[22.4px] font-bold leading-[1.375] min-[567px]:leading-[30px] flex items-center justify-between text-white text-left px-15 py-20 w-full",
  accordianIcon: "flex  ",
  accordianWrapper: " h-0 overflow-hidden transition-all ease-in-out duration-500 accordianWrapper",
  accordianWrapperContent: "accordianWrapperContent",
  accordianContentIcon: "flex justify-center mt-15 pb-30",
  accordianContentText: "accordianContentText flex flex-wrap  ",  
  accordianWrap: "accordianWrap flex flex-wrap min-[901px]:px-[30px]",
  textLeft: "textLeft w-full py-30 border-white border-b-1",
  textRight: "w-1/2 px-10 py-15 text-3xl break-all text-right",
  footerwrap: "flex flex-wrap px-5 py-15 ",
  footerText: "  text-white  border-t-1  pt-5 text-12",
  accordianwholeText: "accordionFootNotes w-full text-white text-left text-14 min-[901px]:border-t-1 border-white pl-20 max-w-[590px] pt-[8px] pb-[8px] min-[901px]:mt-[60px]",
  title:'text-[48px] leading-[0.9] bg-white px-[28px] inline-block font-alternate z-[15] relative text-textBlack',
  description:'description',
  interventionTitleWrap: "intervention-heading pt-12 mb-[50px] overflow-hidden relative before:absolute before:top-1/2 before:overflow-hidden before:w-[50%] before:h-[1px] before:content-[' '] before:bg-darkGrey before:z-[14]  before:text-right before:left-0 after:absolute after:top-1/2 after:overflow-hidden after:w-[50%] after:h-[1px] after:content-[' '] after:bg-darkGrey after:z-14",
  interventionSubContainer: "total-invention-sub-area w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px]",
  interventionContainer: "total-invention-area text-center text-textBlack",
  quickLinkMain:'quickLinkSection flex flex-wrap md:flex-nowrap py-20 px-15 bg-smokeWhite',
  quickLinkWrapper:'quickLinkWrapper flex flex-wrap',
  quickLink:'quickLink arrowLink green text-17 font-bold text-lightGreen leading-[30px]',
  quickLinkLeft:'quickLinkLeft text-darkGrey font-bold text-17 text-left',
  quickLinkRight:'quickLinkRight w-11/12 md:ml-[20px] lg:ml-40 pt-[20px] md:pt-0',
  quickLinkDiv:'quickLinkDiv flex',
  accordianIcon:"accordianIcon absolute w-[40px] h-[40px] leading-[40px] text-center font-black text-[40px] right-[12px] text-white",
  accordianDetailWrap:
  "accordianDetailWrap w-1/2 max-[900px]:w-full px-0 lg:px-30 text-white min-[901px]:last-of-type:mb-[60px]",
  descriptionWrapper:
    "descriptionWrapper items-center flex justify-between text-left px-15 font-bold text-[17.2px] max-[900px]:[&>div:first-child]:w-[50%] [&>div:first-child]:w-[70%] [&>div:last-child]:text-[35.2px] [&>div:last-child]:font-alternate [&>div:last-child]:font-normal",
}

export const FAQ_ACCORDIAN = {
  ...defaultStyle,
  accordionItem: " relative border-b-2 border-blue",
  accordianComponent: "w-full pt-16 faqAccordian",
  accordianDetailWrap:'accordianDetailWrap',
  accordianWrap:'accordianWrap px-[25px] md:px-20 ',
  textLeft:"text-left text-white w-full pt-[5px] text-17 leading-[23px] px-0 md:px-[5px]",
  accordianBtn:"accordianBtn text-[19px] font-bold leading-[26px] flex items-center justify-between text-white text-left px-15 py-10 w-full",
  accordianIcon:"accordianIcon absolute w-[30px] h-[30px] leading-[30px] text-center font-black text-[32px] right-[12px]",
  interventionTitleWrap: "intervention-heading mb-[30px] overflow-hidden relative text-left ",
  title:'text-[40px] leading-[0.9] bg-white inline-block font-alternate relative text-textBlack',
  accordianWrapperContent: "px-15 pb-20 ",
  accordianDetailWrap:
  "w-full max-[768px]:w-full px-0 pt-15 text-white",
  descriptionWrapper:
    "descriptionWrapper flex justify-between text-left text-17 flex-wrap",
  accordianBtn:
    "accordianBtn text-[19px] font-bold leading-[26px] flex items-center justify-between text-white text-left px-15 py-10 w-full pr-[50px] md:pr-0",
  accordianContentImage:"accordianContentImage",
  interventionSubContainer: "total-invention-sub-area w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px]",
  linkColor :"whiteLink",
  accordianContentIcon: "flex justify-center mt-15",
  loaderStyle:"pt-50 mx-auto md:w-[960px] max-w-full text-left mb-56 px-15 md:px-30 text-48 font-alternate text-black h-[700px]"
}

export const LEFT_ALIGNED_TEXT_ACCORDIAN = {
  ...defaultStyle,
  interventionTitleWrap: "intervention-heading pt-12 mb-16 overflow-hidden relative ",
  interventionSubContainer: "total-invention-sub-area w-full mx-auto min-[960px]:w-[960px] px-[15px] min-[567px]:px-[30px] pt-[50px] pb-[40px]",
  interventionContainer: "total-invention-area text-left text-textBlack",
  title:'text-[48px] leading-[0.9] bg-white max-w-[590px] inline-block font-alternate z-[15] relative text-textBlack',
  description:'description max-w-[590px]',

}

export const CENTER_ALIGNED_TEXT_ACCORDION = {
  ...defaultStyle,
  description:'description max-w-[590px] mx-auto',
}