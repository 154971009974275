import React from "react"
import { render,waitFor } from "@testing-library/react"
import InteractiveMap from "./InteractiveMap"

describe("InteractiveMap component", () => {
test("renders iFrame with the correct source URL", async () => {
  const { getByRole } = render(<InteractiveMap />)
  setTimeout(() => {
    const iFrame = getByRole("iframe")
    expect(iFrame.src).toBe(
      "https://editor.giscloud.com/rest/1/maps/1042067/render.iframe?popups=true"
    )
  }, 1000) 
})

test("renders iFrame with correct properties set", async () => {
  const { getByRole } = render(<InteractiveMap />)
  setTimeout(() => {
    const iFrame = getByRole("iframe")
   expect(iFrame).toHaveAttribute("gesture", "media")
   expect(iFrame).toHaveAttribute("allow", "encrypted-media")
   expect(iFrame).toHaveAttribute("allowFullScreen")
  }, 1000) 
 
})
})
