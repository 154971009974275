const query = (id, isPreview) => `{
  imageCardsSlider(id:"${id}", preview: ${isPreview}) {
    cardsCollection {
      items {
        title
        text
        images {
          altText
            imageDesktop {
              url
              title
              description
              width
              height
            }
            imageMobile {
              url
              title
              description
              width
              height
            }
        }
        link {
          label
          url
          scrollToElementId
          target
        }
        variant
      }
    }
    sliderSettings {
      slidesToShow
      slidesToScroll
      slidesToShowTab
      slidesToScrollTab
      slidesToShowMobile
      slidesToScrollMobile
      dots
      arrow
      fade
    }
    variant
  }
}

`
module.exports = { query }
